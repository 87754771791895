export const HELP_CHART_DETAIL = 'helpChartDetail';
export const HELP_CHART_DETAIL_WEEKLY = 'helpChartDetailWeekly';
export const HELP_CHART_RANKING = 'HelpChartRanking';
export const HELP_HOME_APPLIANCE = 'helpHomeAppliance';
export const HELP_HOME_TOP = 'helpHomeTop';
export const HELP_GENERATION_RESULT = 'helpGenerationResult';
export const HELP_GENERATION_GAIN = 'helpGenerationGain';
export const HELP_GENERATED_POWER = 'helpGeneratedPower';
export const HELP_SOLD_CHARGE = 'helpSoldCharge';
export const HELP_FUEL_ADJUSTMENT_FEE_DISCOUNT = 'helpFuelAdjustmentFeeDiscount';
