// @flow

import type { Node } from 'react';
import React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import TopHourlyApplianceStateList from './TopHourlyApplianceStateList';
import Prefixer from '../lib/Prefixer';
import { currentHour } from '../lib/topPageUtils';
import { OPEN_POP_UP } from '../actions/types';
import { HELP_HOME_APPLIANCE } from '../actions/helpPopupTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: {
    color: '#908572',
    textAlign: 'center',
    padding: '0px',
    margin: '10px 0px',
    fontSize: '0.8em',
    width: '100%',
    height: '25px',
    lineHeight: '25px',
    background: '#f2efe0',
    borderRadius: '30px',
    position: 'relative',
  },
  currentTime: {
    fontSize: '0.5em',
    color: 'gray',
    verticalAlign: 'middle',
    height: '25px',
    lineHeight: '25px',
  },
  updateButton: {
    width: '20px',
    height: 'auto',
    border: 'none',
    float: 'right',
    margin: '2px 5px 2px 0px',
  },
  updateButtonDisable: {
    opacity: '0.3',
  },
  learningTitle: {
    color: '#aaa',
    textAlign: 'center',
    fontSize: '1.0em',
    marginBottom: '5px',
  },
  learningText: {
    color: '#bbb',
    textAlign: 'center',
    fontSize: '0.7em',
    marginBottom: '25px',
  },
  infoButton: {
    fontSize: '24px',
    float: 'right',
    color: 'rgb(177, 169, 154)',
    padding: '5px 10px 5px 0px',
    height: '60%',
    top: '0%',
    right: '10%',
    position: 'absolute',
    transform: 'translate(85%, 0%)',
  },
});

const selectedHourApls = (selectedHour, hourlyDurations, idsToShow = []) => {
  const hourlyDuration = hourlyDurations.find((hd) => hd.hour === selectedHour);
  if (!hourlyDuration) {
    return [];
  }

  return hourlyDuration.appliances
    .map(({ id, isUsed }) => {
      if (!idsToShow.includes(id)) {
        return null;
      }
      return { id, isUsed };
    })
    .filter((v) => v);
};

const learningMain = (appliances, intl) =>
  appliances.find((x) => x && x.isUsed)
    ? intl.formatMessage({ id: 'topHourlyApplianceState.lm1' })
    : intl.formatMessage({ id: 'topHourlyApplianceState.lm2' });

const renderLearningMessage = (selectedHour, hourlyDurations, idsToShow, intl) => (
  <div>
    <div style={style.learningTitle}>
      {learningMain(selectedHourApls(selectedHour, hourlyDurations, idsToShow), intl)}
    </div>
    <div style={style.learningText}>
      {intl.formatMessage({ id: 'topHourlyApplianceState.lsm1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceState.lsm2' })}
    </div>
  </div>
);

const renderCurrentTimeAlt = (intl) => (
  <span>{intl.formatMessage({ id: 'topHourlyApplianceState.alt' })}</span>
);

type Props = {
  status: string,
  selectedHour: number,
  currentTime: string,
  applianceState: {
    disaggregated: boolean,
    requesting: boolean,
    hourlyDurations: Array<{
      hour: number,
      appliances: Array<{
        id: string,
        isUsed: boolean,
      }>,
    }>,
  },
  idsToShow?: Array<string>,
  dispatch: Dispatch,
  intl: intlShape,
};

export const PureTopHourlyApplianceState = ({
  status,
  selectedHour,
  currentTime,
  applianceState,
  idsToShow,
  intl,
  dispatch,
}: Props): Node => (
  <div>
    <div style={style.title}>
      {intl.formatMessage({ id: 'topHourlyApplianceState.title' })}
      &nbsp;
      {status === 'learning' && renderCurrentTimeAlt(intl)}
      <Icon
        data-testid="icon"
        icon={faQuestionCircle}
        style={style.infoButton}
        onClick={(e) =>
          dispatch({
            type: OPEN_POP_UP,
            contentType: HELP_HOME_APPLIANCE,
            target: e.target,
          })
        }
      />
    </div>
    <TopHourlyApplianceStateList
      selectedCurrent={selectedHour === currentHour(currentTime)}
      status={status}
      disaggregated={applianceState.disaggregated}
      appliances={selectedHourApls(selectedHour, applianceState.hourlyDurations, idsToShow)}
      idsToShow={idsToShow}
      intl={intl}
    />
    {applianceState.disaggregated &&
      status === 'learning' &&
      renderLearningMessage(selectedHour, applianceState.hourlyDurations, idsToShow, intl)}
  </div>
);

PureTopHourlyApplianceState.defaultProps = {
  idsToShow: [],
};

const mapStateToProps = (state) => ({
  status: state.userInfo.status,
  currentTime: state.timeRange.currentTime,
  applianceState: state.applianceState,
  idsToShow: state.applianceState.idsToShow,
});

const connectedPureTopHourlyApplianceState: any = connect(mapStateToProps)(
  PureTopHourlyApplianceState
);
export default connectedPureTopHourlyApplianceState;
