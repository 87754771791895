import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { autoRehydrate } from 'redux-persist';
import { apiMiddleware } from 'redux-api-middleware';

import { gaReduxMiddleware } from './middleware/gaReduxMiddleware';
import cancelActionMiddleware from './middleware/cancelActionMiddleware';
import localStorageMiddleware from './middleware/localStorageMiddleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const getReducer = () => {
  const reducers = require('../reducers').default;
  return combineReducers({
    ...reducers,
  });
};

export const store = createStore(
  getReducer(),
  composeEnhancers(
    applyMiddleware(
      apiMiddleware,
      localStorageMiddleware,
      cancelActionMiddleware,
      gaReduxMiddleware
    ),
    autoRehydrate()
  )
);
