// @flow

import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import qs from 'qs';
import Scroll from 'react-scroll';

import PropTypes from 'prop-types';
import HeaderBar from '../HeaderBar';
import FadeTransition from '../FadeTransition';
import Prefixer from '../../lib/Prefixer';
import { apiPostQuestionnaire } from '../../actions/apis';
import '../../css/questionnaire.css';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#454545',
  },
  boxUp: { width: '100%', padding: '43px 0px 0px' },
  body: { maxWidth: '480px', margin: '0px auto', fontSize: '90%' },
  card: {
    position: 'relative',
    width: '90%',
    margin: '0px auto 10px',
    padding: '16px 10px',
    background: '#fff',
    color: '#454545',
    fontWeight: 'bold',
    border: '1px solid #e5e5e5',
    borderBottom: '2px solid #e5e5e5',
    borderRadius: '5px',
  },
  footer: {
    width: '100%',
    margin: '20px auto 0px',
    padding: '8px 0px',
    textAlign: 'center',
    borderTop: '1px solid #e5e5e5',
    background: '#fff',
    fontSize: '0.8em',
    fontWeight: 'bold',
    clear: 'both',
  },
  message: { fontWeight: 'normal', textAlign: 'left' },
  question: { marginBottom: '24px' },
  questionTitle: { textAlign: 'left', marginBottom: '4px' },
  required: { color: '#f46e5c' },
  formItem: {
    borderBottom: '1px solid #e5e5e5',
    fontWeight: 'normal',
    textAlign: 'left',
    display: 'block',
  },
  formLastItem: { fontWeight: 'normal', textAlign: 'left', display: 'block' },
  submit: {
    width: '80%',
    height: '60px',
    background: '#298c8e',
    border: 'none',
    color: '#fafafa',
    fontWeight: 'bold',
    fontSize: '16px',
    borderRadius: '8px',
    margin: '26px 0 13px 0',
  },
  disable: { background: '#ebebeb' },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '26px auto',
    textDecoration: 'none',
  },
});

type Props = {
  location: { pathname: string },
  loginState: string,
  tutorialFinished: boolean,
  status: string,
  dispatch: Dispatch,
  questionnaires: { family_number?: string, facilities?: string[], at_home?: string },
  questionnaireUpdated: number | null,
  lastBillingDate: string,
  intl: intlShape,
};

type State = {
  submitting: boolean,
  succeed: boolean,
  form: { family_number: string, facilities: string[], at_home: string },
};

const QuestionTitle = ({
  title,
  intl,
}: {
  title: 'family_number' | 'facilities' | 'at_home',
  intl: intlShape,
}) => (
  <p style={style.questionTitle}>
    {intl.formatMessage({ id: `questionnaire.question.title.${title}` })}
    <span style={style.required}>*</span>
  </p>
);

const Input = ({
  type,
  name,
  value,
  checked,
  onChange,
  lastItem,
  intl,
}: {
  type: 'checkbox' | 'radio',
  name: 'family_number' | 'facilities' | 'at_home',
  value: string,
  checked: boolean,
  onChange: Function,
  lastItem: boolean,
  intl: intlShape,
}) => (
  <label htmlFor={`${name}_${value}`} style={lastItem ? style.formLastItem : style.formItem}>
    <input
      id={`${name}_${value}`}
      name={name}
      type={type}
      value={value}
      checked={checked}
      onChange={onChange}
    />
    <span className={type}>
      {intl.formatMessage({ id: `questionnaire.question.label.${name}.${value}` })}
    </span>
  </label>
);

Input.defaultProps = { lastItem: false };

class PureQuestionnairePage extends Component<Props, State> {
  constructor(props) {
    super(props);
    const {
      questionnaires: { family_number, facilities, at_home },
    } = this.props;
    this.state = {
      submitting: false,
      succeed: false,
      form: {
        family_number: family_number || 'one',
        facilities: facilities || [],
        at_home: at_home || 'all_day',
      },
    };
    (this: any).handleChange = this.handleChange.bind(this);
    (this: any).handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidUpdate(prevProps: Props) {
    const { questionnaireUpdated } = this.props;
    if (prevProps.questionnaireUpdated !== questionnaireUpdated) {
      this.setState({ ...this.state, succeed: true });
    }
  }

  handleChange(event) {
    event.persist();
    if (event.target.type === 'checkbox') {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          [event.target.name]: event.target.checked
            ? [...this.state.form[event.target.name], event.target.value]
            : [...this.state.form[event.target.name].filter((v) => v !== event.target.value)],
        },
      });
    } else {
      this.setState({
        ...this.state,
        form: { ...this.state.form, [event.target.name]: event.target.value },
      });
    }
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ ...this.state, submitting: true });
    this.props.dispatch(apiPostQuestionnaire(this.state.form));
  }
  render() {
    const {
      location: { pathname },
      questionnaires,
      lastBillingDate,
      intl,
    } = this.props;
    const { form, submitting, succeed } = this.state;
    const isForceShow = qs.parse(window.location.search.substr(1) || '').force;

    if (isForceShow && Object.keys(questionnaires).length) {
      return <Redirect to="/" />;
    }

    if (succeed) {
      return (
        <FadeTransition pathname={pathname}>
          <div key="questionnairePage">
            <HeaderBar pageType={isForceShow ? 'questionnaireForce' : 'questionnaire'} />
            <div style={style.content}>
              <div style={style.boxUp}>
                <div style={style.body}>
                  <h4>{intl.formatMessage({ id: 'questionnaire.submittedTitle' })}</h4>
                  <div style={style.card}>
                    <div style={style.message}>
                      {intl.formatMessage({ id: 'questionnaire.submittedMessage' })}
                    </div>
                    {lastBillingDate && (
                      <Link
                        style={{ ...style.submit, ...style.link }}
                        to={`/report/${lastBillingDate}`}
                      >
                        {intl.formatMessage({ id: 'questionnaire.submittedLink' })}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FadeTransition>
      );
    }
    const submittable = form.family_number && form.facilities.length && form.at_home;
    return (
      <FadeTransition pathname={pathname}>
        <div key="questionnairePage">
          <HeaderBar pageType={isForceShow ? 'questionnaireForce' : 'questionnaire'} />
          <div style={style.content}>
            <div style={style.boxUp}>
              <div style={style.body}>
                <form onSubmit={this.handleSubmit}>
                  <h4>{intl.formatMessage({ id: 'questionnaire.title' })}</h4>
                  <div style={style.card}>
                    <div style={style.message}>
                      {intl.formatMessage({ id: 'questionnaire.detail' })}
                    </div>
                  </div>
                  <div style={style.card}>
                    { /* prettier-ignore */ }
                    <div style={style.question}>
                      <QuestionTitle title="family_number" intl={intl} />
                      <Input type="radio" name="family_number" value="one" checked={form.family_number === "one"} onChange={this.handleChange} intl={intl} />
                      <Input type="radio" name="family_number" value="two" checked={form.family_number === "two"} onChange={this.handleChange} intl={intl} />
                      <Input type="radio" name="family_number" value="three" checked={form.family_number === "three"} onChange={this.handleChange} intl={intl} />
                      <Input type="radio" name="family_number" value="four" checked={form.family_number === "four"} onChange={this.handleChange} intl={intl} />
                      <Input type="radio" name="family_number" value="more" checked={form.family_number === "more"} onChange={this.handleChange} intl={intl} lastItem />
                    </div>
                    { /* prettier-ignore */ }
                    <div style={style.question}>
                      <QuestionTitle title="facilities" intl={intl} />
                      <Input type="checkbox" name="facilities" value="ih" checked={form.facilities.includes("ih")} onChange={this.handleChange} intl={intl} />
                      <Input type="checkbox" name="facilities" value="water_heater" checked={form.facilities.includes("water_heater")} onChange={this.handleChange} intl={intl} />
                      <Input type="checkbox" name="facilities" value="solar_power" checked={form.facilities.includes("solar_power")} onChange={this.handleChange} intl={intl} />
                      <Input type="checkbox" name="facilities" value="storage_battery" checked={form.facilities.includes("storage_battery")} onChange={this.handleChange} intl={intl} />
                      <Input type="checkbox" name="facilities" value="nothing" checked={form.facilities.includes("nothing")} onChange={this.handleChange} intl={intl} lastItem/>
                    </div>
                    { /* prettier-ignore */ }
                    <div style={style.question}>
                      <QuestionTitle title="at_home" intl={intl} />
                      <Input type="radio" name="at_home" value="all_day" checked={form.at_home === "all_day"} onChange={this.handleChange} intl={intl} />
                      <Input type="radio" name="at_home" value="night" checked={form.at_home === "night"} onChange={this.handleChange} intl={intl} />
                      <Input type="radio" name="at_home" value="random" checked={form.at_home === "random"} onChange={this.handleChange} intl={intl} lastItem/>
                    </div>
                    <div style={style.message}>
                      {intl.formatMessage({ id: 'questionnaire.annotation' })}
                    </div>
                    <button
                      style={{ ...style.submit, ...(submittable ? {} : style.disable) }}
                      type="submit"
                      disabled={!submittable || submitting}
                    >
                      {intl.formatMessage({ id: 'questionnaire.submit' })}
                    </button>
                  </div>
                </form>
                <div style={style.footer}>
                  <div onClick={() => Scroll.animateScroll.scrollToTop({ duration: 500 })}>
                    {intl.formatMessage({ id: 'chartBody.toTop' })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeTransition>
    );
  }
}

PureQuestionnairePage.contextTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  loginState: state.loginInfo.loginState,
  tutorialFinished: state.tutorial.tutorialFinished,
  status: state.userInfo.status,
  questionnaires: state.userInfo.questionnaires,
  questionnaireRequesting: state.userInfo.questionnaires,
  questionnaireUpdated: state.userInfo.questionnaireUpdated,
  lastBillingDate: state.userInfo.lastBillingDate,
});

const connectedQuestionnairePage: any = connect(mapStateToProps)(injectIntl(PureQuestionnairePage));

export default connectedQuestionnairePage;
