// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { createSelector } from 'reselect';
import SettingApplianceCheckBox from './SettingApplianceCheckBox';
import { CLOSING_POP_UP } from '../actions/types';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  entireFixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: '100100',
    fontSize: 'initial',
  },
  background: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.3)',
    margin: '0px auto',
    display: 'table',
  },
  boxVerticalPosition: {
    verticalAlign: 'middle',
    display: 'table-cell',
  },
  box: {
    width: 310,
    maxWidth: '80%',
    backgroundColor: '#fff',
    textAlign: 'center',
    lineHeight: '150%',
    borderRadius: 5,
    color: '#000',
    position: 'relative',
    margin: '0px auto',
  },
  content: {
    position: 'relative',
    overflow: 'auto',
  },
  title: {
    color: '#298c8e',
    fontWeight: 700,
    fontSize: 18,
    margin: '10px 0px 5px',
  },
  border: {
    borderBottom: '0px solid #cfcfcf',
  },
  description: {
    fontSize: '0.8em',
    lineHeight: '150%',
  },
  checkBoxArea: {
    padding: '10px',
    height: '210px',
    overflow: 'scroll',
  },
  close: {
    width: 23,
    height: 23,
    marginRight: '5px',
    verticalAlign: 'middle',
  },
  button: {
    margin: '15px auto',
    maxWidth: '100px',
    padding: '5px 10px',
    backgroundColor: '#999',
    borderRadius: 5,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: '#fff',
  },
  buttonText: {
    verticalAlign: 'middle',
  },
  crossWhiteArea: {
    height: '20px',
    verticalAlign: 'middle',
    marginRight: '5px',
  },
  crossWhite: {
    fill: '#fafafa',
    height: '20px',
  },
  list: {
    textAlign: 'left',
  },
  listItem: {
    width: '33%',
    textAlign: 'center',
    display: 'inline-block',
  },
});

const renderCheckBoxList = (aplFilter, dispatch) => (
  <div style={style.list}>
    {aplFilter.map((appliance) => (
      <div key={appliance.id} style={style.listItem}>
        <SettingApplianceCheckBox appliance={appliance} dispatch={dispatch} />
      </div>
    ))}
  </div>
);

const renderBox = (contentType, aplFilter, dispatch, intl) => (
  <div
    style={style.box}
    onClick={(e) => {
      e.stopPropagation();
    }}
  >
    <div style={style.content}>
      <div style={style.title}>{intl.formatMessage({ id: 'settingAppliancePopup.title' })}</div>
      <hr style={style.border} />
      <div style={style.description}>
        {intl.formatMessage({ id: 'settingAppliancePopup.description1' })}
        <br />
        {intl.formatMessage({ id: 'settingAppliancePopup.description2' })}
      </div>
      <div style={style.checkBoxArea}>{renderCheckBoxList(aplFilter, dispatch)}</div>
      <hr style={style.border} />
      <div style={style.button} onClick={() => dispatch({ type: CLOSING_POP_UP })}>
        <svg style={style.crossWhiteArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <path
            style={style.crossWhite}
            d="M112.73,100l53.63-53.64a9,9,0,1,0-12.72-12.72L100,87.27,46.36,33.64A9,9,0,0,0,33.64,46.36L87.27,100,33.64,153.64a9,9,0,1,0,12.72,12.72L100,112.73l53.64,53.63a9,9,0,0,0,12.72-12.72Z"
          />
        </svg>
        <span style={style.buttonText}>
          {intl.formatMessage({ id: 'settingAppliancePopup.close' })}
        </span>
      </div>
    </div>
  </div>
);

type Props = {
  contentType: string,
  aplFilter: Array<{
    id: string,
    display: boolean,
  }>,
  dispatch: Dispatch,
  intl: intlShape,
};

export const PureSettingAppliancePopup = ({
  contentType,
  aplFilter,
  dispatch,
  intl,
}: Props): Node => (
  <div>
    <div style={style.entireFixed}>
      <div style={style.background} onClick={() => dispatch({ type: CLOSING_POP_UP })}>
        <div style={style.boxVerticalPosition}>
          {renderBox(contentType, aplFilter, dispatch, intl)}
        </div>
      </div>
    </div>
  </div>
);

const filterApplianceFilters = createSelector(
  [(state) => state.userSetting.aplFilter, (state) => state.applianceState.idsToShow],
  (aplFilter, idsToShow) => aplFilter.filter(({ id }) => idsToShow.includes(id))
);

const mapStateToProps = (state) => ({
  contentType: state.popup.contentType,
  aplFilter: filterApplianceFilters(state),
});

const connectedPureSettingAppliancePopup: any = connect(mapStateToProps)(
  injectIntl(PureSettingAppliancePopup)
);
export default connectedPureSettingAppliancePopup;
