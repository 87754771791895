// @flow

import React from 'react';
import type { Node } from 'react';
import { intlShape } from 'react-intl';
import { VictoryPie, VictoryLabel } from 'victory';

const VIEW_BOX_WIDTH = 280;
const MINIMUM_WIDTH_PERCENTAGE = 10;

const labelStyles = {
  fontFamily: "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
  fill: '#847965',
};

type Props = {
  data: {
    x: 'midnight' | 'morning' | 'noon' | 'night' | 'background',
    y: number,
    color: string,
  }[],
  totalCharge: number,
  intl: intlShape,
};

const formatCharge = (charge, intl: intlShape): string => {
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const currency = intl.formatNumber(charge, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return intl.formatMessage({ id: 'report.currency' }, { currency });
};

/**
 * ラベルを表示するための最小幅を持っているか
 * 最小幅 = 全体に対しての MINIMUM_WIDTH_PERCENTAGE
 */
const hasMinimumWidth = (target: number, total: number): boolean =>
  (target / total) * 100 >= MINIMUM_WIDTH_PERCENTAGE;

const PureCircleChart = (props: Props): Node => {
  // 値 0 のデータを取り除く
  const filteredData = props.data.filter((data) => data.y);
  return (
    <svg
      width={VIEW_BOX_WIDTH}
      height={VIEW_BOX_WIDTH}
      viewBox={`0 0 ${VIEW_BOX_WIDTH} ${VIEW_BOX_WIDTH}`}
    >
      <VictoryLabel
        text={formatCharge(props.totalCharge, props.intl)}
        x={VIEW_BOX_WIDTH / 2}
        y={VIEW_BOX_WIDTH / 2}
        style={{ ...labelStyles, fontSize: 20 }}
        textAnchor="middle"
        verticalAnchor="middle"
      />
      <VictoryPie
        standalone={false}
        data={filteredData}
        colorScale={filteredData.map((data) => data.color)}
        width={VIEW_BOX_WIDTH}
        height={VIEW_BOX_WIDTH}
        radius={VIEW_BOX_WIDTH / 2}
        innerRadius={VIEW_BOX_WIDTH / 4.8}
        labelRadius={VIEW_BOX_WIDTH / 4.8 + (VIEW_BOX_WIDTH / 2 - VIEW_BOX_WIDTH / 4.8) / 2}
        labelComponent={
          <VictoryLabel
            style={{ ...labelStyles, fill: '#fff', fontSize: 16 }}
            textAnchor="middle"
            verticalAnchor="middle"
            text={[
              (d) =>
                hasMinimumWidth(d.y, props.totalCharge)
                  ? props.intl.formatMessage({ id: `report.${d.x}` })
                  : null,
              (d) =>
                hasMinimumWidth(d.y, props.totalCharge) ? formatCharge(d.y, props.intl) : null,
            ]}
            lineHeight={[1.4, 1.4]}
          />
        }
      />
    </svg>
  );
};

export default PureCircleChart;

export const PureCircleChartForTest = PureCircleChart;
