// @flow

import moment from 'moment';
import { timeFormat, timeUnitStyle, subTimeUnit } from '../constants/timeUnit';
import { makeTemporaryWeighingPeriod } from '../common/periodUtils';
import type { EnergyPerDay } from '../types/electricTypes';
import type { WeighingPeriod } from '../types/priceInfoTypes';

export const isOneHourPassed = (currentTime: string): boolean =>
  moment(currentTime).diff(moment(), 'hours') < 0;

export const latestPeriod = (
  unit: string,
  currentWeighingPeriod: {
    startDate: string,
    endDate: string,
    billingMonth: string,
  }
): string => {
  const format = timeFormat[unit];
  const now = moment();

  return unit === 'day' || unit === 'week'
    ? now.startOf(unit).format(format)
    : moment(currentWeighingPeriod.billingMonth, format).format(format);
};

export const nextPeriod = (unit: string, period: string): string => {
  const unitMulti = timeUnitStyle[unit].toMulti;
  const format = timeFormat[unit];
  return moment(period, format)
    .add(1, unitMulti)
    .format(format);
};

export const prevPeriod = (unit: string, period: string): string => {
  const unitMulti = timeUnitStyle[unit].toMulti;
  const format = timeFormat[unit];
  return moment(period, format)
    .subtract(1, unitMulti)
    .format(format);
};

// 予測電気代折れ線グラフのX軸を取得
export const currentMonthXAxis = (
  startDate: string,
  endDate: moment,
  billingDate: string,
  period: string,
  unit: string
): Array<string> => {
  const subUnit = subTimeUnit[unit];
  const subFormat = timeFormat[subUnit];

  const startDateList = [];
  const iter = moment(startDate, 'YYYY-MM-DD').startOf('day');
  const billingDateMoment = moment(billingDate, 'YYYY-MM');
  while (iter.valueOf() < endDate.startOf('day').valueOf()) {
    if (
      billingDateMoment.isSame(moment(period, timeFormat[unit]), timeUnitStyle[subUnit].toMulti)
    ) {
      startDateList.push(iter.format(subFormat));
    }
    iter.add(1, timeUnitStyle[subUnit].toMulti);
  }

  return startDateList;
};

export const getStartDateList = (
  subPeriod: {
    subStartDate: string,
    subEndDate: string,
    subDateTime: string,
  },
  unit: string,
  lastBillingMonth: string
): string[] => {
  if (!subPeriod || !subPeriod.subStartDate || !subPeriod.subEndDate || !subPeriod.subDateTime) {
    return [];
  }

  const subUnit: string = subTimeUnit[unit];
  const format: string = timeFormat[subUnit];

  const f = {
    day: 'DDD',
    week: 'DDD',
    month: 'w',
    year: 'M',
  };

  const createArray = (startDate: string, start: number, end: number) => {
    if (start > end) {
      const endDate = moment(subPeriod.subEndDate, timeFormat[unit])
        .startOf('month')
        .subtract(1, subUnit);

      const diff = Number(endDate.format(f[unit])) - start + end + 1;
      return Array(diff)
        .fill(startDate)
        .map((date, i) =>
          moment(date, format)
            .add(i, subUnit)
            .format(format)
        );
    }

    const diff = end - start + 1;
    return Array(diff)
      .fill(startDate)
      .map((date, i) =>
        moment(date, format)
          .add(i, subUnit)
          .format(format)
      );
  };

  if (unit === 'year') {
    const monthAfterLastBilling = moment(lastBillingMonth, 'YYYY-MM').add(1, 'months');
    const firstMonth = moment(subPeriod.subDateTime, 'YYYY').startOf(unit);
    const startMonth = monthAfterLastBilling.isAfter(firstMonth)
      ? monthAfterLastBilling
      : firstMonth;

    const start = startMonth.format(f[unit]);
    const end = moment(subPeriod.subDateTime, 'YYYY')
      .endOf(unit)
      .format(f[unit]);

    return createArray(startMonth.format(format), Number(start), Number(end));
  }

  const start = moment(subPeriod.subStartDate, 'YYYY-MM-DD').format(f[unit]);
  const end = moment(subPeriod.subEndDate, 'YYYY-MM-DD').format(f[unit]);

  return createArray(subPeriod.subStartDate, Number(start), Number(end));
};

export const startWeekDates = (
  startOfPeriod: string,
  subEndDate: moment,
  billingDate: string,
  period: string,
  unit: string
): Array<string> => {
  const subUnit = subTimeUnit[unit];
  const subFormat = timeFormat[subUnit];

  const startDateList = [];
  const iter = moment(startOfPeriod, 'YYYY-MM-DD').startOf('week');
  const billingDateMoment = moment(billingDate, 'YYYY-MM');
  while (iter.valueOf() < subEndDate.startOf('day').valueOf()) {
    if (
      billingDateMoment.isSame(moment(period, timeFormat[unit]), timeUnitStyle[subUnit].toMulti)
    ) {
      startDateList.push(iter.format(subFormat));
    }
    iter.add(1, timeUnitStyle[subUnit].toMulti);
  }
  return startDateList;
};

export const weighingStartDates = (
  startOfPeriod: string,
  subEndDate: moment,
  subDateTime: string,
  period: string,
  unit: string
): Array<string> => {
  const makeWeighingStartDate = moment(startOfPeriod, 'YYYY-MM-DD');
  const makeBillingMonth = moment(subDateTime, 'YYYY-MM');

  // 値が不正なら空で返す
  const blankWeighingPeriod = [];
  if (makeWeighingStartDate.isAfter(subEndDate, 'day')) {
    return blankWeighingPeriod;
  }

  const subUnit = subTimeUnit[unit];
  const subFormat = timeFormat[subUnit];

  let weighingPeriod = makeTemporaryWeighingPeriod(makeWeighingStartDate, makeBillingMonth);
  const startDateList = [];
  if (weighingPeriod) {
    if (
      moment(weighingPeriod.billingMonth, timeFormat[unit]).isSame(
        moment(period, timeFormat[unit]),
        timeUnitStyle[unit].toMulti
      )
    ) {
      startDateList.push(moment(weighingPeriod.billingMonth, subFormat).format(subFormat));
    }
  }
  while (
    weighingPeriod &&
    !subEndDate.isBetween(
      moment(weighingPeriod.startDate, 'YYYY-MM-DD'),
      moment(weighingPeriod.endDate, 'YYYY-MM-DD').endOf('day'),
      '',
      '[]'
    )
  ) {
    weighingPeriod = makeTemporaryWeighingPeriod(
      moment(weighingPeriod.endDate, 'YYYY-MM-DD').add(1, 'day'),
      moment(weighingPeriod.billingMonth, 'YYYY-MM').add(1, 'month')
    );
    if (weighingPeriod) {
      if (
        moment(weighingPeriod.billingMonth, timeFormat[unit]).isSame(
          moment(period, timeFormat[unit]),
          timeUnitStyle[unit].toMulti
        )
      ) {
        startDateList.push(moment(weighingPeriod.billingMonth, subFormat).format(subFormat));
      }
    }
  }

  return startDateList;
};

const requestDates = (startDateList, energyList, unit) => {
  if (!energyList.length) {
    return startDateList;
  }
  return startDateList.filter(
    startDate =>
      !(energyList.find(elem => elem[unit] === startDate) || moment().isBefore(startDate))
  );
};

export const getSubUnitDateTime = (
  subStartDate: string,
  subEndDate: moment,
  subDateTime: string,
  unit: string,
  period: string,
  firstWeighingStartDate: string,
  subUnitEnergyList: EnergyPerDay[] = []
): string[] => {
  const subUnit = subTimeUnit[unit];
  const startDateList =
    unit === 'month'
      ? startWeekDates(subStartDate, subEndDate, subDateTime, period, unit)
      : weighingStartDates(subStartDate, subEndDate, subDateTime, period, unit);
  const requestDateLimit =
    subUnit === 'week'
      ? moment(firstWeighingStartDate, 'YYYY-MM-DD').subtract(7, 'days')
      : moment(
          moment(firstWeighingStartDate, 'YYYY-MM-DD').date() < 15
            ? moment(firstWeighingStartDate, 'YYYY-MM-DD').format('YYYY-MM')
            : moment(firstWeighingStartDate, 'YYYY-MM-DD')
                .add(1, 'month')
                .format('YYYY-MM'),
          timeFormat[subUnit]
        );
  const requestDateList = requestDates(startDateList, subUnitEnergyList, subUnit);

  return requestDateList.filter(requestDate => moment(requestDate).isSameOrAfter(requestDateLimit));
};

export const getSubPeriod = (
  temporaryBillingDataPeriod: WeighingPeriod | null,
  billingPeriodData: ?WeighingPeriod,
  weighingPeriodList: WeighingPeriod[],
  unit: string,
  period: string
): {
  subStartDate: string,
  subEndDate: string,
  subDateTime: string,
} => {
  const format = timeFormat[unit];
  const subUnit = subTimeUnit[unit];
  const subUnitFormat = timeFormat[subUnit];

  switch (unit) {
    case 'month': {
      const weighingPeriod =
        !temporaryBillingDataPeriod && weighingPeriodList.find(v => v.billingMonth === period);
      const billingStartDate = temporaryBillingDataPeriod
        ? temporaryBillingDataPeriod.startDate
        : (weighingPeriod && weighingPeriod.startDate) || period;
      const billingEndDate = temporaryBillingDataPeriod
        ? temporaryBillingDataPeriod.endDate
        : (weighingPeriod && weighingPeriod.endDate) || period;

      const startDate = billingPeriodData ? billingPeriodData.startDate : billingStartDate;
      const endDate = billingPeriodData ? billingPeriodData.endDate : billingEndDate;

      return {
        subStartDate: moment(startDate, 'YYYY-MM-DD')
          .startOf(subUnit)
          .format(subUnitFormat),
        subEndDate: moment(endDate, 'YYYY-MM-DD')
          .endOf(subUnit)
          .format(subUnitFormat),
        subDateTime: period,
      };
    }
    case 'year': {
      const filter = weighingPeriodList.filter(
        weighingPeriod => moment(weighingPeriod.billingMonth, 'YYYY-MM').format(format) === period
      );
      return {
        subStartDate: filter.length
          ? moment(filter[0].startDate, 'YYYY-MM-DD')
              .startOf('day')
              .format('YYYY-MM-DD')
          : '',
        subEndDate: filter.length
          ? moment(filter[filter.length - 1].endDate, 'YYYY-MM-DD')
              .endOf('day')
              .format('YYYY-MM-DD')
          : '',
        subDateTime: period,
      };
    }
    default:
      return {
        subStartDate: moment(period, format)
          .startOf(unit)
          .format(subUnitFormat),
        subEndDate: moment(period, format)
          .endOf(unit)
          .format(subUnitFormat),
        subDateTime: period,
      };
  }
};

export const isValidDateFormat = (unit: string, period: string) => {
  switch (unit) {
    case 'day':
    case 'week':
      return period.match(/^\d{4}-\d{2}-\d{2}$/);
    case 'month':
      return period.match(/^\d{4}-\d{2}$/);
    case 'year':
      return period.match(/^\d{4}$/);
    default:
      return false;
  }
};
