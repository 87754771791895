// @flow

import type { State, Action } from 'redux';

import {
  REQUEST_API_BILLING_INFO,
  SUCCESS_API_BILLING_INFO,
  FAILURE_API_BILLING_INFO,
  REQUEST_API_BILLING_FORECAST,
  SUCCESS_API_BILLING_FORECAST,
  FAILURE_API_BILLING_FORECAST,
  LOAD_TOP_PAGE_BILLING_INFO,
  LOAD_CURRENT_WEIGHTING_PERIOD,
} from '../actions/types';

const initialState = {
  billingInitialized: false,
  billingRequesting: false,
  predictInitialized: false,
  predictRequesting: false,
  billingData: [],
  forecastPower: 0,
  consumedForecastPower: 0,
  weighingPeriodInitialized: false,
  currentWeighingPeriod: {},
  weighingPeriodArray: [],
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REQUEST_API_BILLING_INFO: {
      const billingRequesting = !action.error;
      return {
        ...state,
        billingRequesting,
      };
    }
    case SUCCESS_API_BILLING_INFO: {
      const { data = {} } = action.payload || {};
      const billingInfoData = [];
      if (data) {
        data.forEach(amount => {
          billingInfoData.push({
            billingDate: amount.billing_date,
            nextBillingDate: amount.next_billing_date,
            prevBillingDate: amount.prev_billing_date,
            startDate: amount.weighing_start_date,
            endDate: amount.weighing_end_date,
            billingAmount: amount.billing_amount,
            consumedBillingAmount: amount.consumed_billing_amount,
            soldAmount: amount.sold_amount,
            fuelCostAdjustmentAmount: amount.fuel_cost_adjustment_amount,
            renewableEnergyPowerPromotionSurchargeAmount:
              amount.renewable_energy_power_promotion_surcharge_amount,
          });
        });
      }
      return {
        ...state,
        billingInitialized: true,
        billingRequesting: false,
        billingData: billingInfoData,
      };
    }
    case FAILURE_API_BILLING_INFO: {
      return {
        ...state,
        billingInitialized: true,
        billingRequesting: false,
      };
    }
    case REQUEST_API_BILLING_FORECAST: {
      const predictRequesting = !action.error;
      return {
        ...state,
        predictRequesting,
      };
    }
    case SUCCESS_API_BILLING_FORECAST: {
      const { payload = {} } = action || {};
      return {
        ...state,
        predictInitialized: true,
        predictRequesting: false,
        forecastPower: payload.forecast_power,
        consumedForecastPower: payload.consumed_forecast_power,
      };
    }
    case FAILURE_API_BILLING_FORECAST: {
      return {
        ...state,
        predictInitialized: true,
        predictRequesting: false,
      };
    }
    case LOAD_TOP_PAGE_BILLING_INFO: {
      return {
        ...state,
      };
    }
    case LOAD_CURRENT_WEIGHTING_PERIOD: {
      const { currentWeighingPeriod, weighingPeriodArray } = action;
      return {
        ...state,
        weighingPeriodInitialized: true,
        currentWeighingPeriod,
        weighingPeriodArray,
      };
    }
    default: {
      return state;
    }
  }
};
