// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from 'victory';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup, gtagOpenTips } from './gtagEvent';
import Tips from './common/Tips';
import linkImg from '../../../images/pickup/circlator.png';

const ROUND_THRESHOLD = 12;
const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: { textAlign: 'center', fontSize: '18px' },
  digest: { fontSize: '14px', border: '1px solid #cfcfcf', padding: '10px 0' },
  digestText: { fontWeight: 'normal' },
  digestValue: { color: '#ff8f86', fontSize: '24px', marginLeft: '4px' },
  digestUnit: { margin: '0 4px' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  tipsTitle: { textAlign: 'left', margin: '8px 0', fontSize: '14px' },
  linkImg: { width: '100%', marginBottom: '10px' },
  legend: {
    textAlign: 'left',
    fontSize: '0.9em',
    marginTop: '0px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSelfIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendCompareIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendMargin: {
    marginLeft: 30,
  },
});

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#454545', padding: 5 },
};

const PureChart = ({
  counts,
  size: { width },
}: {
  counts: { x: number, y: number, color: string }[],
  size: { width: number },
}) => (
  <div>
    <div style={style.legend}>
      <div style={{ ...style.legendIcon, ...style.legendSelfIcon }} />
      <span>あなたの家</span>
      <div
        style={{
          ...style.legendIcon,
          ...style.legendCompareIcon,
          ...style.legendMargin,
        }}
      />
      <span>ほかの家</span>
    </div>
    <VictoryChart
      theme={VictoryTheme.material}
      containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
      width={width}
      height={286}
      padding={{ right: 3, left: 3, bottom: 50 }}
      domainPadding={20}
    >
      <VictoryAxis
        dependentAxis
        tickCount={7}
        style={{
          grid: { strokeWidth: 1, stroke: '#ccc' },
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { fill: 'transparent' },
        }}
      />
      <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
      <VictoryBar
        style={{ data: { fill: ({ color }) => color, width: (width / counts.length) * 0.6 } }}
        data={counts.map((count) => ({
          x: count.x + 1,
          y: count.y,
          color: count.color,
        }))}
      />
      <VictoryAxis
        tickValues={counts.map((v) => v.x + 1)}
        tickFormat={counts.map((v) => (v.x >= ROUND_THRESHOLD ? `${v.x}~` : `${v.x}`))}
        style={xAxisStyle}
      />
    </VictoryChart>
  </div>
);

const Chart = sizeMe({ monitorHeight: true })(PureChart);

export type Props = {
  data: { self_avg_hours: number, avg_hours: number[] },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureCirculator = ({ userInfo, data: { self_avg_hours, avg_hours } }: Props) => {
  const link = `https://heartonemall.com/iot-circulator/?utm_source=iotdroom&utm_campaign=ci${
    self_avg_hours >= 6 ? 6 : self_avg_hours
  }&k=cn`;
  const over12 = avg_hours.slice(ROUND_THRESHOLD).reduce((p, n) => p + n, 0);
  const counts = [...avg_hours.slice(0, ROUND_THRESHOLD), over12].map((total, hours) => ({
    x: hours,
    y: total,
    color:
      self_avg_hours === hours || (ROUND_THRESHOLD === hours && self_avg_hours >= ROUND_THRESHOLD)
        ? '#ff8f86'
        : '#cfcfcf',
  }));

  const openTips = (title: string) => {
    gtagOpenTips(title, userInfo, 'circulator', self_avg_hours);
  };

  gtagShowPickup(link, userInfo, 'circulator', self_avg_hours);

  return (
    <div>
      <p style={style.title}>みんなはどのくらいエアコンを使ってる？</p>
      <Chart counts={counts} />
      {(self_avg_hours && (
        <div style={style.digest}>
          <span style={style.digestText}>エアコンを日に平均</span>
          <span style={style.digestValue}>{self_avg_hours}</span>
          <span style={style.digestUnit}>時間</span>
          <span style={style.digestText}>使いました</span>
        </div>
      )) || (
        <div style={style.digest}>
          <span style={style.digestText}>この月はエアコンをほとんど使っていませんでした</span>
        </div>
      )}
      <div style={style.body}>
        <p>
          グラフは1日あたりのエアコン平均使用時間と、それに対する世帯の分布です。あなたの家は色がついたところに含まれます。
        </p>
      </div>
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) => gtagClickPickup(e, link, userInfo, 'circulator', self_avg_hours)}
      >
        <img src={linkImg} style={style.linkImg} alt="bedding" />
      </a>
      <div style={style.tipsTitle}>エアコンの豆知識をご紹介：</div>
      <Tips title="節約のポイント" onOpenClick={() => openTips('saving_points')}>
        電気代節約を考えるなら、エアコンより電気代の安いサーキュレーターの併用を検討してみてはいかがでしょう。
        冷房の時はエアコンの風向きを水平、暖房の時はエアコンの風向きを下向きに設定し、
        サーキュレーターを天井に向けて空気を循環させることで温度のムラをなくし、適切な体感温度で快適に過ごすことができます。
      </Tips>
      <Tips title="効率のよい使い方" onOpenClick={() => openTips('efficient_usage')}>
        エアコンは室温を設定温度まで上げ下げする間に一番電気代がかかります。そのため、こまめにスイッチをオン・オフ切り替えてしまうことで電気代が高くなる場合があります。
        ずっと家にいたり、30分程度の外出くらいならつけっぱなし、数時間の外出ならいったんスイッチをオフにすることをオススメします。
      </Tips>
      <Tips title="環境への影響" onOpenClick={() => openTips('environment_influence')}>
        今年の夏は電力不足の問題がここ数年でもっとも厳しいと言われています。
        特に暑い昼間の時間帯に使われるエアコンは電力消費が多いので、使い始める時間をずらしたり、使い方を工夫することで電力不足の解消に貢献できる可能性があります。
        電力需要のピークとなる午後1時から午後4時の間、まずはできる範囲から行動を変えてみてはいかがでしょう。
      </Tips>
      <div style={style.body}>
        ※ヒートポンプ式の衣類乾燥機や電気給湯器（エコキュート）の使用時間が、AIの推定したエアコンの使用時間に含まれる場合があります
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureCirculator: any = connect(mapStateToProps)(PureCirculator);
export default connectedPureCirculator;
