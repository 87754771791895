// @flow
import moment from 'moment';

import { convertToEndOfDay } from '../common/globalUtils';
import { sum, daytimeTotal, calcTotalEnergy } from '../common/calculated';
import type { UnitPrice, BillingInfo } from '../types/priceInfoTypes';
import type {
  EnergyPerDay,
  EnergyPerDayDetails,
  ApplianceEnergyDetails,
} from '../types/electricTypes';
import { checkBillingPeriod, filterUnitPrices, getBillingDataList } from './priceUtils';

// 消費電力 * 電力量料金単価
// consumed_energy * power_unit_price → consumedEnergy * powerUnitPrice
// 消費電力 * 電力量料金単価
// consumed_energy * fuelCost_adjustment_unit_price → consumedEnergy * fuelCostAdjustmentUnitPrice
// 消費電力 * 再生可能エネルギー発電促進賦課金単価
// consumed_energy * renewable_energy_power_promotion_surcharge → consumedEnergy * renewableEnergyPrice
export const calcPowerCharge = (energy: number, unitPrice: UnitPrice): number => {
  const { powerUnitPrice, fuelCostAdjustmentUnitPrice, renewableEnergy } = unitPrice;
  return energy * powerUnitPrice + energy * fuelCostAdjustmentUnitPrice + energy * renewableEnergy;
};

export const calcPrice = (
  startTime: number,
  endTime: number,
  unitPrices: UnitPrice[],
  unitEnergy: EnergyPerDay,
  target: 'buyEnergy'
): number => {
  const { data = [] } = unitEnergy;

  if (!data.length) return 0;

  const filterUnitPricesData = filterUnitPrices(
    unitPrices,
    moment(unitEnergy.startDate).unix(),
    convertToEndOfDay(moment(unitEnergy.endDate).unix())
  );

  const totalEnergyList = data.map(v => ({
    daily: v.daily,
    energy: daytimeTotal(v[target], startTime, endTime),
  }));

  const buyCharges = filterUnitPricesData.map((unitPrice: UnitPrice) => {
    const filter = totalEnergyList.filter(item =>
      checkBillingPeriod(
        unitPrice.sts,
        convertToEndOfDay(unitPrice.ets),
        moment(item.daily).unix(),
        null
      )
    );

    const totalEnergy = filter.reduce(
      (p: number, n: { daily: number, energy: number }) => p + n.energy || 0,
      0
    );
    return calcPowerCharge(totalEnergy, unitPrice);
  });

  return sum(buyCharges);
};

export const calcConsumedPrice = (
  startTime: number,
  endTime: number,
  consumedUnitPrice: number,
  energyList: EnergyPerDayDetails[] = []
): number => {
  if (!energyList.length) return 0;
  const totalEnergyList = energyList.map(v => daytimeTotal(v.consumeEnergy, startTime, endTime));

  return sum(totalEnergyList) * consumedUnitPrice;
};

export const calcAppliancePrice = (
  startTime: number,
  endTime: number,
  unitPrices: UnitPrice[],
  aplEnergy: ApplianceEnergyDetails
): number => {
  const { data = [] } = aplEnergy;

  if (!data.length) return 0;

  const filterUnitPricesData = filterUnitPrices(
    unitPrices,
    data[0].unix,
    convertToEndOfDay(moment.unix(data[data.length - 1].unix).unix())
  );

  const totalEnergyList = data.map(v => ({
    unix: v.unix,
    energy: daytimeTotal(v.energy, startTime, endTime),
  }));

  const buyCharges = filterUnitPricesData.map((unitPrice: UnitPrice) => {
    const filter = totalEnergyList.filter(item =>
      checkBillingPeriod(unitPrice.sts, convertToEndOfDay(unitPrice.ets), item.unix, null)
    );

    const totalEnergy = filter.reduce(
      (p: number, n: { unix: number, energy: number }) => p + n.energy || 0,
      0
    );
    return calcPowerCharge(totalEnergy, unitPrice);
  });

  return sum(buyCharges);
};

export const calcBuyCharge = (unitPrices: UnitPrice[], unitEnergy: EnergyPerDay): number => {
  const filterUnitPricesData = filterUnitPrices(
    unitPrices,
    moment(unitEnergy.startDate).unix(),
    convertToEndOfDay(moment(unitEnergy.endDate).unix())
  );

  const buyCharges = filterUnitPricesData.map((unitPrice: UnitPrice) => {
    const filter = (unitEnergy.data || [])
      .filter(item =>
        checkBillingPeriod(
          unitPrice.sts,
          convertToEndOfDay(unitPrice.ets),
          moment(item.daily).unix(),
          null
        )
      );

    const buyEnergy = filter.reduce(
      (p: number, n: EnergyPerDayDetails) => p + sum(n.buyEnergy) || 0,
      0
    );
    return calcPowerCharge(buyEnergy, unitPrice);
  });
  return sum(buyCharges);
};

export const calcConsumedCharge = (unitPrices: UnitPrice[], unitEnergy: EnergyPerDay): number => {
  const filterUnitPricesData = filterUnitPrices(
    unitPrices,
    moment(unitEnergy.startDate).unix(),
    convertToEndOfDay(moment(unitEnergy.endDate).unix())
  );

  const consumedCharges = filterUnitPricesData.map((unitPrice: UnitPrice) => {
    const filter = (unitEnergy.data || [])
      .filter(item =>
        checkBillingPeriod(
          unitPrice.sts,
          convertToEndOfDay(unitPrice.ets),
          moment(item.daily).unix(),
          null
        )
      );

    const consumedEnergy = filter.reduce(
      (p: number, n: EnergyPerDayDetails) => p + sum(n.consumeEnergy) || 0,
      0
    );
    return calcPowerCharge(consumedEnergy, unitPrice);
  });
  return sum(consumedCharges);
};

export const calcCheaperByConsumedPrice = (
  unitPrice: UnitPrice[],
  unitEnergy: EnergyPerDay,
  consumedUnitPrice: number
): number =>
  calcConsumedCharge(unitPrice, unitEnergy) -
  calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice;

// 自家消費電力量と単価を元に、本来買電を利用した場合からいくら安くなったのかを算出する
export const calcCheaperChargeByConsumed = (
  unitPrice: UnitPrice,
  consumeEnergy: number,
  consumedUnitPrice: number
): number => {
  const { powerUnitPrice, fuelCostAdjustmentUnitPrice, renewableEnergy } = unitPrice;
  return (
    consumeEnergy * powerUnitPrice +
    consumeEnergy * fuelCostAdjustmentUnitPrice +
    consumeEnergy * renewableEnergy -
    consumeEnergy * consumedUnitPrice
  );
};

export const calcSellCharge = (sellEnergy: number, soldUnitPrice: number): number =>
  sellEnergy * soldUnitPrice;

export const totalSoldAmount = (
  requesting: boolean,
  billingInfo: BillingInfo,
  unit: string,
  period: string
): number | null => {
  if (requesting || unit === 'week' || unit === 'day') {
    return null;
  }

  const billingDataList = getBillingDataList(billingInfo, period, unit);

  if (!billingDataList || !billingDataList.length) {
    return null;
  }

  if (unit === 'month') {
    return typeof billingDataList[0].soldAmount === 'number' ? billingDataList[0].soldAmount : null;
  }

  return billingDataList.reduce((prev, current) => prev + (current.soldAmount || 0), 0);
};
