// @flow

import moment from 'moment';
import React from 'react';
import type { Node } from 'react';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import TopHourlyApplianceStateItem from './TopHourlyApplianceStateItem';
import Prefixer from '../lib/Prefixer';
import { extractServiceProviderIdFromState } from '../lib/common/globalUtils';
import processingHouse from '../images/img_processing_house.png';
import loadingAnime from '../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  list: {
    display: 'table',
    tableLayout: 'fixed',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',
  },
  applianceCell: {
    display: 'table-cell',
    width: '33%',
  },
  altMessage: {
    padding: '100px 0px',
  },
  altMessageTitle: {
    width: '97%',
    margin: '0px auto',
  },
  altMesssgeText: {
    fontSize: '0.8em',
  },
  notfoundLearning: {
    textAlign: 'center',
    margin: '20px',
  },
  notfoundLearningHeader: {
    color: '#888',
    fontSize: '1.2em',
  },
  notfoundLearningFooter: {
    color: '#bbb',
    fontSize: '0.8em',
  },
  processingHouse: {
    width: '100%',
  },
  noDisplayApl: {
    color: 'gray',
  },
  loading: {
    margin: '80px auto',
  },
});

const renderApplianceList = (status, aplFilter, appliances, serviceProviderId, intl) => {
  const visibleApplianceIds = aplFilter
    .filter((filter) => filter.display)
    .map((filter) => filter.id);
  const appliancesToShow = appliances.filter((appliance) =>
    visibleApplianceIds.includes(appliance.id)
  );
  const applianceList = appliancesToShow.map((appliance, key) => {
    const cellKey = `appliance-cell-${key}`;
    const applianceKeyPrefix = `appliance-${key}`;
    return (
      <div key={cellKey} style={style.applianceCell}>
        <TopHourlyApplianceStateItem
          key={`${applianceKeyPrefix}-${appliance.id}`}
          learning={status === 'learning'}
          display
          appliance={appliance}
          serviceProviderId={serviceProviderId}
          intl={intl}
        />
      </div>
    );
  });
  const columnCount = 3;
  const rowCount = Math.ceil(applianceList.length / 3);
  const range = (upTo) => Array.from(Array(upTo).keys());
  const listPadding = range(columnCount - (applianceList.length % columnCount)).map((number) => (
    <div key={`padded-cell-${number}`} style={style.applianceCell} />
  ));
  return (
    <div style={style.list}>
      {range(rowCount).map((rowIndex) => (
        <div key={`appliance-list-row-${rowIndex}`} style={{ display: 'table-row' }}>
          {applianceList
            .concat(listPadding)
            .slice(rowIndex * columnCount, (rowIndex + 1) * columnCount)}
        </div>
      ))}
    </div>
  );
};

const renderImperfectLearningMessage = (intl) => (
  <div style={style.notfoundLearning}>
    <div style={style.notfoundLearningHeader}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.da1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.da2' })}
    </div>
    <img src={processingHouse} alt="house" style={style.processingHouse} />
    <div style={style.notfoundLearningFooter}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.daSub1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.daSub2' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.daSub3' })}
    </div>
  </div>
);

const renderNoDisplayAplMessage = (intl) => (
  <div style={style.altMessage}>
    <div style={style.noDisplayApl}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.na1' })}
      <br />
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.na2' })}
    </div>
  </div>
);

const renderMeasuringMessage = (intl) => (
  <div style={style.altMessage}>
    <div style={style.altMessageTitle}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.ms' })}
    </div>
    <div style={style.altMesssgeText}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.msSub' })}
    </div>
  </div>
);

const renderNoDataMessage = (intl) => (
  <div style={style.altMessage}>
    <div style={style.altMessageTitle}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.nd' })}
    </div>
    <div style={style.altMesssgeText}>
      {intl.formatMessage({ id: 'topHourlyApplianceStateList.ndSub' })}
    </div>
  </div>
);

const hasDisplayApls = (aplFilter, idsToShow) =>
  !!aplFilter.find((apl) => apl.display && idsToShow.includes(apl.id));

const renderLoadingAnime = () => (
  <div style={style.loading}>
    <img src={loadingAnime} alt="loading" />
  </div>
);

const mainDisplay = (
  aplFilter,
  selectedCurrent,
  status,
  disaggregated,
  appliances,
  serviceProviderId,
  requesting,
  idsToShow,
  intl
) => {
  if (requesting) {
    return renderLoadingAnime();
  }
  if (status === 'imperfect_learning') {
    return renderImperfectLearningMessage(intl);
  }
  if (!disaggregated) {
    if (status === 'learning') {
      return null;
    }
    if (moment().minute() > 10) {
      return renderNoDataMessage(intl);
    }
    return renderMeasuringMessage(intl);
  }
  if ((!appliances.length || !hasDisplayApls(aplFilter, idsToShow)) && !requesting) {
    if (status === 'learning') {
      return null;
    }
    return renderNoDisplayAplMessage(intl);
  }
  return renderApplianceList(status, aplFilter, appliances, serviceProviderId, intl);
};

type Props = {
  aplFilter: Array<{
    id: string,
    display: boolean,
  }>,
  selectedCurrent: boolean,
  status: string,
  disaggregated: boolean,
  appliances: Array<{
    id: string,
    isUsed: boolean,
  }>,
  serviceProviderId: string,
  requesting: boolean,
  idsToShow: string[],
  intl: intlShape,
};

export const PureTopHourlyApplianceStateList = ({
  aplFilter,
  selectedCurrent,
  status,
  disaggregated,
  appliances,
  serviceProviderId,
  requesting,
  idsToShow,
  intl,
}: Props): Node => (
  <div>
    {mainDisplay(
      aplFilter,
      selectedCurrent,
      status,
      disaggregated,
      appliances,
      serviceProviderId,
      requesting,
      idsToShow,
      intl
    )}
  </div>
);

PureTopHourlyApplianceStateList.defaultProps = {
  idsToShow: [],
};

const mapStateToProps = (state) => ({
  aplFilter: state.userSetting.aplFilter,
  requesting: state.applianceState.requesting,
  serviceProviderId: extractServiceProviderIdFromState(state),
});

const connectedPureTopHourlyApplianceStateList: any = connect(mapStateToProps)(
  PureTopHourlyApplianceStateList
);
export default connectedPureTopHourlyApplianceStateList;
