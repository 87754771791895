// @flow

import React from 'react';
import moment from 'moment';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import TopApplianceRankingItem from './TopApplianceRankingItem';
import Prefixer from '../lib/Prefixer';
import { makeValidPeriod, makeCurrentWeighingPeriod } from '../lib/common/periodUtils';
import { sortRanking } from '../lib/common/globalUtils';
import { calcTotalEnergy } from '../lib/common/calculated';
import { calcSellCharge } from '../lib/price/calculatedPrice';
import type { UnitPrice, BillingInfo } from '../lib/types/priceInfoTypes';
import type { ApplianceEnergy, EnergyPerDay } from '../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  container: {
    width: '100%',
    fontSize: '0.8em',
  },
  overlap: {
    color: 'white',
    opacity: 0.2,
  },
  soldArea: {
    color: '#33B2B5',
    width: '90%',
    borderBottom: '1px solid #33B2B5',
    marginBottom: '10px',
    paddingBottom: '10px',
    height: '2.6rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  soldMessage: {
    fontSize: '1.3em',
  },
  soldPrice: {
    fontSize: '1.6em',
    fontWeight: 'bolder',
  },
});

type Props = {
  applianceEnergy: { monthly: ApplianceEnergy[] },
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  requesting: boolean,
  unitPrices: UnitPrice[],
  soldEnergyOwner: string,
  soldUnitPrice: number,
  monthlyEnergy: EnergyPerDay[],
  intl: intlShape,
};

const renderSoldEnergy = (
  soldUnitPrice: number,
  soldEnergyOwner: string,
  monthlyEnergy: EnergyPerDay[],
  intl: intlShape
) => {
  if (soldEnergyOwner !== 'resident' || !monthlyEnergy || !monthlyEnergy.length) {
    return null;
  }
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const plusCurrency = intl.formatNumber(
    calcSellCharge(calcTotalEnergy(monthlyEnergy[0].data, 'sellEnergy'), soldUnitPrice),
    {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }
  );
  const messages = defineMessages({
    plusCurrency: {
      id: 'topMonthlyTotalAmount.plusCurrency',
      description: '',
    },
  });
  return (
    <div style={style.soldArea}>
      <div style={style.soldMessage}>
        <span>{intl.formatMessage({ id: 'topMonthlyTotalAmount.sellEnergy' })}</span>
      </div>
      <div style={style.soldPrice}>
        <span>{intl.formatMessage(messages.plusCurrency, { plusCurrency })}</span>
      </div>
    </div>
  );
};

export const PureTopApplianceRanking = ({
  applianceEnergy,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  requesting,
  unitPrices,
  soldEnergyOwner,
  soldUnitPrice,
  monthlyEnergy,
  intl,
}: Props): Node => {
  const { billingRequesting, billingData } = billingInfo;

  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingData,
    lastBillingDate,
    accountStartDate
  );
  const billingMonth = currentWeighingPeriod
    ? moment(currentWeighingPeriod.billingMonth, 'YYYY-MM').format('YYYY-MM')
    : '';
  const period = makeValidPeriod('month', moment(billingMonth, 'YYYY-MM'));
  const currentAplEnergy = applianceEnergy.monthly.find(
    ele => moment(period, 'YYYY-MM').format('YYYY-MM') === ele.dateTime
  );

  if (
    !currentAplEnergy ||
    currentAplEnergy.applianceEnergy.length === 0 ||
    requesting ||
    billingRequesting
  ) {
    return (
      <div style={style.container}>
        <TopApplianceRankingItem rank={1} rankItem={{ id: '', energy: 0 }} />
        <TopApplianceRankingItem rank={2} rankItem={{ id: '', energy: 0 }} />
        {soldEnergyOwner !== 'resident' ? (
          <TopApplianceRankingItem rank={3} rankItem={{ id: '', energy: 0 }} />
        ) : (
          renderSoldEnergy(soldUnitPrice, soldEnergyOwner, monthlyEnergy, intl)
        )}
      </div>
    );
  }
  // 売電の所有者が入居者の場合、使用家電ランキングの3位を表示せず売電の金額を表示する
  const displayRankCount = soldEnergyOwner !== 'resident' ? 3 : 2;
  const soldPrice =
    soldEnergyOwner === 'resident'
      ? renderSoldEnergy(soldUnitPrice, soldEnergyOwner, monthlyEnergy, intl)
      : null;
  const rank = sortRanking(currentAplEnergy.applianceEnergy, unitPrices)
    .slice(0, displayRankCount)
    .map((value, index) => (
      <TopApplianceRankingItem key={`ranking-${value.id}`} rankItem={value} rank={index + 1} />
    ));
  return (
    <div style={style.container}>
      {rank}
      {soldPrice}
    </div>
  );
};

const mapStateToProps = state => ({
  monthlyEnergy: state.energy.monthly,
  applianceEnergy: state.applianceEnergy,
  unitPrices: state.userInfo.unitPrices,
  soldEnergyOwner: state.userInfo.soldEnergyOwner,
  soldUnitPrice: state.userInfo.soldUnitPrice,
});

const connectedPureTopApplianceRanking: any = connect(mapStateToProps)(
  injectIntl(PureTopApplianceRanking)
);
export default connectedPureTopApplianceRanking;
