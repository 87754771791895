// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  CAUTION
  This css styles are temporary to develop v2 design copied from v1.
  We should reconsider to use this when decided direction of v2 design.
*/
.aplCheckboxLabel{
  box-sizing: border-box;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  position: relative;
  display: inline-block;
  margin: 0 20px 4px 8px;
  padding: 12px 12px 12px 42px;
  border-radius: 8px;
  vertical-align: middle;
  cursor: pointer;
}

.aplCheckboxLabel:after {
  -webkit-transition: border-color 0.2s linear;
  transition: border-color 0.2s linear;
  position: absolute;
  top: 50%;
  left: 23px;
  display: block;
  margin-top: -10px;
  width: 16px;
  height: 16px;
  border: 2px solid #bbb;
  border-radius: 6px;
  content: '';
}

.aplCheckboxLabel:before {
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  position: absolute;
  top: 50%;
  left: 28px;
  display: block;
  margin-top: -7px;
  width: 5px;
  height: 9px;
  border-right: 3px solid #298c8e;
  border-bottom: 3px solid #298c8e;
  content: '';
  opacity: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.aplCheckbox[type=checkbox]:checked + .aplCheckboxLabel:before {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/client/css/aplCheckboxLabel.css"],"names":[],"mappings":"AAAA;;;;CAIC;AACD;EACE,sBAAsB;EACtB,gDAAgD;EAChD,wCAAwC;EACxC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,4BAA4B;EAC5B,kBAAkB;EAClB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,4CAA4C;EAC5C,oCAAoC;EACpC,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,uCAAuC;EACvC,+BAA+B;EAC/B,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,cAAc;EACd,gBAAgB;EAChB,UAAU;EACV,WAAW;EACX,+BAA+B;EAC/B,gCAAgC;EAChC,WAAW;EACX,UAAU;EACV,gCAAgC;EAChC,4BAA4B;EAC5B,wBAAwB;AAC1B;AACA;EACE,UAAU;AACZ","sourcesContent":["/*\n  CAUTION\n  This css styles are temporary to develop v2 design copied from v1.\n  We should reconsider to use this when decided direction of v2 design.\n*/\n.aplCheckboxLabel{\n  box-sizing: border-box;\n  -webkit-transition: background-color 0.2s linear;\n  transition: background-color 0.2s linear;\n  position: relative;\n  display: inline-block;\n  margin: 0 20px 4px 8px;\n  padding: 12px 12px 12px 42px;\n  border-radius: 8px;\n  vertical-align: middle;\n  cursor: pointer;\n}\n\n.aplCheckboxLabel:after {\n  -webkit-transition: border-color 0.2s linear;\n  transition: border-color 0.2s linear;\n  position: absolute;\n  top: 50%;\n  left: 23px;\n  display: block;\n  margin-top: -10px;\n  width: 16px;\n  height: 16px;\n  border: 2px solid #bbb;\n  border-radius: 6px;\n  content: '';\n}\n\n.aplCheckboxLabel:before {\n  -webkit-transition: opacity 0.2s linear;\n  transition: opacity 0.2s linear;\n  position: absolute;\n  top: 50%;\n  left: 28px;\n  display: block;\n  margin-top: -7px;\n  width: 5px;\n  height: 9px;\n  border-right: 3px solid #298c8e;\n  border-bottom: 3px solid #298c8e;\n  content: '';\n  opacity: 0;\n  -webkit-transform: rotate(45deg);\n  -ms-transform: rotate(45deg);\n  transform: rotate(45deg);\n}\n.aplCheckbox[type=checkbox]:checked + .aplCheckboxLabel:before {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
