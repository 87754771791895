export default {
  // application
  'application.title': 'うちワケ',

  // topPage
  'topBody.pvAlert': '太陽光発電ができていません',
  'topBody.overForecastValue': '{overForecastValue}円を超えそう！',
  'topBody.waitingT1': 'アプリの利用開始まで',
  'topBody.waitingT2': 'もうしばらくお待ち下さい',
  'topBody.waitingDF1': 'センサー取付作業報告が取付業者から提出されるのを待っています。',
  'topBody.waitingDS1': '通常、作業後、数日(営業日)にてアプリの利用が開始されます。',
  'topBody.waitingDT1': '利用開始までもうしばらくお待ち下さい。',
  'topBody.waitingA1': '※この画面が１週間以上継続して表示される場合は',
  'topBody.waitingA2': 'こちら',
  'topBody.waitingA3': 'を参照ください',
  'topBody.disconnectedT1': 'センサーをインターネットに',
  'topBody.disconnectedT2': '接続して下さい',
  'topBody.disconnectedD': '電力センサーを無線LANに接続するとアプリが利用できるようになります',
  'topBody.disconnectedButton': '接続方法はこちら',
  'topMonthlyTotalAmount.title': '今月これまで',
  'topMonthlyTotalAmount.currency': '{currency} 円',
  'topMonthlyTotalAmount.plusCurrency': '+{plusCurrency} 円',
  'topMonthlyTotalAmount.weighingPeriod': '{weighingStartDate}~{weighingEndDate}',
  'topMonthlyTotalAmount.sellEnergy': '発電して販売',
  'topMonthlyTotalAmount.detailButton': 'うちわけを見る',
  'topMonthlyTotalAmount.reportButton': '月レポート',
  'topApplianceRankingItem.place': '位,位,位',
  'topApplianceRankingItem.currency': '{currency} 円',
  'topApplianceRankingItem.airConditioner': 'エアコン',
  'topApplianceRankingItem.clothesWasher': '洗濯機',
  'topApplianceRankingItem.microwave': '電子レンジ',
  'topApplianceRankingItem.dishwasher': '食洗機',
  'topApplianceRankingItem.oven': '電気オーブン',
  'topApplianceRankingItem.refrigerator': '冷蔵庫',
  'topApplianceRankingItem.riceCooker': '炊飯器',
  'topApplianceRankingItem.tv': 'テレビ等',
  'topApplianceRankingItem.vacuumCleaner': '掃除機',
  'topApplianceRankingItem.ih': 'IH調理器',
  'topApplianceRankingItem.highFever': '高熱家電',
  'topApplianceRankingItem.heater': '暖房器具',
  'topApplianceRankingItem.light': '照明',
  'topApplianceRankingItem.background': '待機電力',
  'topApplianceRankingItem.nd1': 'データが',
  'topApplianceRankingItem.nd2': '記録されていません',
  'topApplianceRankingItem.ndSub1': 'センサーがネットに',
  'topApplianceRankingItem.ndSub2': '接続できていなかったようです',
  'topTimeItem.hour': 'H時',
  'topHourlyAnalysisArea.today': '今日',
  'topHourlyAnalysisArea.yesterday': '昨日',
  'topHourlyAnalysisArea.hour': ' {oclock}時台',
  'topHourlyAnalysisArea.settingButton': '電気画面の設定',
  'topHourlyCharge.charge': '電気代',
  'topHourlyCharge.buyEnergy': '買って利用',
  'topHourlyCharge.sellEnergy': '発電して販売',
  'topHourlyCharge.buyCurrency': '{buyCurrency} 円',
  'topHourlyCharge.sellCurrency': '{sellCurrency} 円',
  'topHourlyCharge.cheaperByConsumedCurrency': '発電して約{cheaperByConsumedCurrency}円お得になりました',
  'TopHourlyApplianceStateItem.hourLabel': '時間',
  'TopHourlyApplianceStateItem.minuteLabel': '分',
  'topHourlyApplianceState.lm1': '家電使用を発見しました',
  'topHourlyApplianceState.lm2': 'ご自宅の電気使用を学習しています',
  'topHourlyApplianceState.lsm1': '発見した家電の種類を推定するため',
  'topHourlyApplianceState.lsm2': 'その特徴を分析しています',
  'topHourlyApplianceState.title': '家電の使用状況',
  'topHourlyApplianceState.current': '{currentTime}',
  'topHourlyApplianceState.alt': '(初期学習中)',
  'topHourlyApplianceStateList.da1': '家電の動きの学習に',
  'topHourlyApplianceStateList.da2': '時間がかかっています',
  'topHourlyApplianceStateList.daSub1': '同時に複数の家電が使われていると',
  'topHourlyApplianceStateList.daSub2': '学習に時間がかかる場合があります。',
  'topHourlyApplianceStateList.daSub3': '学習完了までもうしばらくお待ち下さい。',
  'topHourlyApplianceStateList.daBtn': '対象家電とは？',
  'topHourlyApplianceStateList.nd': 'データが記録されていません',
  'topHourlyApplianceStateList.ndSub': 'センサーがネットに接続できていなかったようです',
  'topHourlyApplianceStateList.ms': 'データを取得中です',
  'topHourlyApplianceStateList.msSub': 'センサーから測定データの送信・処理完了を待っています',
  'topHourlyApplianceStateList.na1': '全ての家電について',
  'topHourlyApplianceStateList.na2': '表示設定がオフになっています',

  // chartPage
  'chartBody.toTop': 'ページのトップへ',
  'chartTimeUnit.day': '日',
  'chartTimeUnit.week': '週',
  'chartTimeUnit.month': '月',
  'chartTimeUnit.year': '年',
  'chartPeriod.year': '{year}年',
  'chartPeriod.month': '{year}年{month}月',
  'chartPeriod.weekOf': '{month}月{day}日の週',
  'chartPeriod.day': '{month}月{day}日（{dayOfWeek}）',
  'chartPeriod.weighingPeriod': '{weighingStartDate}~{weighingEndDate}',
  'chartCharge.currentMonthTitle': '今月の電気代予測',
  'chartCharge.dailyTitle': '日の電気代',
  'chartCharge.weeklyTitle': '週の電気代',
  'chartCharge.monthlyTitle': '月の電気代',
  'chartCharge.yearlyTitle': '年の電気代',
  'chartCharge.currency': '{currency} 円',
  'chartCharge.noBillingData': '請求確定前',
  'chartCharge.noAmountData': '金額確定前',
  'chartCharge.currentMonthlyTitle': '今日まで',
  'chartCharge.forecastTitle': '今月予想',
  'chartCharge.forecastCurrency': '{forecastCurrency} 円',
  'chartCharge.sellEnergy': '発電して販売',
  'chartCharge.sellCurrency': '+{sellCurrency} 円',
  'chartCharge.loading': 'データの取得中・・・',
  'chartApplianceRanking.title': '家電の電気代ランキング',
  'chartApplianceRanking.loading': 'データの取得中・・・',
  'chartLearning.title': 'ご自宅の電気使用を学習しています',
  'chartLearning.desc1': '発見した家電の種類を特定するため',
  'chartLearning.desc2': 'その特徴を分析しています',
  'chartImperfectLearning.title1': '家電使用が',
  'chartImperfectLearning.title2': '見つかっていません',
  'chartImperfectLearning.desc1': '対象家電の使用を発見すると、',
  'chartImperfectLearning.desc2': 'ここに表示されます。',
  'chartImperfectLearning.link': '対象家電とは？',
  'chartNoData.title1': 'データが',
  'chartNoData.title2': '記録されていません',
  'chartNoData.desc1': 'センサーがネットに',
  'chartNoData.desc2': '接続できていなかったようです',
  'chartNoData.link': 'ご確認方法',
  'chartApplianceRankingItem.place': '位,位,位,位,位,位,位,位,位,位,位,位,位,位,位',
  'chartApplianceRankingItem.currency': '{currency} 円',
  'chartApplianceRankingItem.lessThan10': '10 円 未満',
  'chartApplianceRankingItem.airConditioner': 'エアコン',
  'chartApplianceRankingItem.highFever': '高熱家電',
  'chartApplianceRankingItem.heater': '暖房器具',
  'chartApplianceRankingItem.microwave': '電子レンジ',
  'chartApplianceRankingItem.dishwasher': '食洗機',
  'chartApplianceRankingItem.oven': '電気オーブン',
  'chartApplianceRankingItem.riceCooker': '炊飯器',
  'chartApplianceRankingItem.refrigerator': '冷蔵庫',
  'chartApplianceRankingItem.clothesWasher': '洗濯機',
  'chartApplianceRankingItem.ih': 'IH',
  'chartApplianceRankingItem.vacuumCleaner': '掃除機',
  'chartApplianceRankingItem.tv': 'テレビ等',
  'chartApplianceRankingItem.background': '待機電力',
  'chartApplianceRankingItem.light': '照明',
  'chartSubUnit.hourlyTitle': '時間ごとの電気代',
  'chartSubUnit.dailyTitle': '日ごとの電気代',
  'chartSubUnit.weeklyTitle': '週ごとの電気代',
  'chartSubUnit.monthlyTitle': '月ごとの電気代',
  'chartSubUnit.buyAndUse': '買って利用',
  'chartSubUnit.generateAndUse': '発電してお得に利用',
  'chartSubUnit.loading': 'データの取得中・・・',
  'chartSubUnit.currency': '{currency} 円',
  'chartSubUnit.noData': 'データ未記録',
  'chartSubUnitChart.sun': '日',
  'chartSubUnitChart.mon': '月',
  'chartSubUnitChart.tue': '火',
  'chartSubUnitChart.wed': '水',
  'chartSubUnitChart.thu': '木',
  'chartSubUnitChart.fri': '金',
  'chartSubUnitChart.sat': '土',
  'chartSubUnitChart.place': '1,2,3,4,5,6,7,8,9',
  'chartSubUnitChart.day': '0時,,,・,,,6時,,,・,,,12時,,,・,,,18時,,,・,,23時',
  'chartSubUnitChart.dayTime': '6時,,,・,,,12時,,,・,,,18時',
  'chartSubUnitChart.week': '週目',
  'chartSubUnitChart.month': ',,3月,,,6月,,,9月,,,12月',
  'chartGeneratedPower.title': '発電した電力',
  'ChartForecastLineChart.5day': '5日目',
  'ChartForecastLineChart.10day': '10日目',
  'ChartForecastLineChart.15day': '15日目',
  'ChartForecastLineChart.20day': '20日目',
  'ChartForecastLineChart.25day': '25日目',
  'ChartForecastLineChart.30day': '30日目',
  'ChartForecastLineChart.forecastValue': '{forecastValue}円',
  'chartSubUnitChargeItem.sun': '日',
  'chartSubUnitChargeItem.mon': '月',
  'chartSubUnitChargeItem.tue': '火',
  'chartSubUnitChargeItem.wed': '水',
  'chartSubUnitChargeItem.thu': '木',
  'chartSubUnitChargeItem.fri': '金',
  'chartSubUnitChargeItem.sat': '土',
  'chartSubUnitChargeItem.place': '1,2,3,4,5,6,7,8,9',
  'chartSubUnitChargeItem.week': '週目',
  'chartSubUnitChargeItem.month': '1月,2月,3月,4月,5月,6月,7月,8月,9月,10月,11月,12月',
  'chartSubUnitChargeItem.noBillingData': '確定前',
  'chartSubUnitChargeItem.currency': '{currency} 円',
  'chartSubUnitChargeItem.period': '({periodDate}〜)',

  // reportPage
  'report.loading': 'データの取得中・・・',
  'report.resultTitle': '電気料金',
  'report.hourlyTitle': '時間ごとの電気料金',
  'report.weeklyTitle': '曜日ごとの平均電気料金',
  'report.comparisonTitle': '暮らしが似た家との電気料金比較',
  'report.midnight': '深夜',
  'report.morning': '朝',
  'report.noon': '昼',
  'report.night': '夜',
  'report.background': '常に消費',
  'report.currency': '{currency}円',
  'report.currencyPrefix': '¥{currency}',
  'report.legendSelf': 'あなたの家',
  'report.legendCompare': '暮らしが似た家',
  'report.monthSuffix': '月',
  'report.navigatorTitle': '{year}年{month}月のレポート',
  'report.navigatorSubTitle': '{startDate}~{endDate}',
  'report.result.successLabel': '省エネ達成！',
  'report.result.successText': 'おめでとうございます！ AIが算出した省エネ目標から{value}円節約できました。',
  'report.result.failedText': 'AIが算出した省エネ目標より{value}円多く電気を使いました。',
  'report.hourly.text':
    '家での活動が多い時間帯は電気もたくさん使う傾向があるようです。常時使用している家電は使用時間が長いため、省エネ機種でもひと月あたりの電気料金では目立ってきます。',
  'report.weekly.text': '家にいる時間が長かったり、家事をまとめてやったりする曜日が決まっていると、その曜日の平均電気料金が高くなります。',
  'report.comparison.leftTitle': '自宅',
  'report.comparison.rightTitle': '類似家庭の平均',
  'report.comparison.text': '家での活動時間や世帯人数などが似ている家と、電気料金を比べてみましょう。',
  'report.questionnaireLink': '暮らしの設定を変更する',
  'report.totalUseEnergy': '総消費電力',
  'report.consumedEnergy': '発電して利用',
  'report.sufficiencyRate': '自給自足率',

  // questionnairePage
  'questionnaire.title': '暮らし方についてお答えください',
  'questionnaire.detail': '家での活動時間や世帯人数などの環境が似ている家と、自宅の電気料金を比較することができます。設問に答えて、自宅の環境を設定しましょう。',
  'questionnaire.question.title.family_number': '何人で暮らしていますか？',
  'questionnaire.question.label.family_number.one': '１人',
  'questionnaire.question.label.family_number.two': '２人',
  'questionnaire.question.label.family_number.three': '３人',
  'questionnaire.question.label.family_number.four': '４人',
  'questionnaire.question.label.family_number.more': '５人以上',
  'questionnaire.question.title.facilities': '自宅に設置している電力設備はありますか？',
  'questionnaire.question.label.facilities.ih': 'IHクッキングヒーター（固定式）',
  'questionnaire.question.label.facilities.water_heater': '電気給湯器（エコキュートなど）',
  'questionnaire.question.label.facilities.solar_power': '太陽光発電',
  'questionnaire.question.label.facilities.storage_battery': '蓄電池',
  'questionnaire.question.label.facilities.nothing': 'どれも持っていない',
  'questionnaire.question.title.at_home': '1日にどのくらい自宅で過ごしますか？',
  'questionnaire.question.label.at_home.all_day': '一日中、誰かは家にいることが多い',
  'questionnaire.question.label.at_home.night': '昼は不在で、夜だけ家にいることが多い',
  'questionnaire.question.label.at_home.random': '家にいる時間は日によってまちまち',
  'questionnaire.annotation': 'ご登録いただいた内容は、月レポート画面からいつでも編集ができます。',
  'questionnaire.submit': '回答する',
  'questionnaire.submittedTitle': '設定を完了しました',
  'questionnaire.submittedMessage': 'AIが新しい設定で暮らしが似ている家との比較を開始しました。早ければ来月から適用されます。',
  'questionnaire.submittedLink': '戻る',

  // headerBar
  'headerBar.close': '閉じる',
  'headerBar.back': '戻る',

  // tutorialPage
  'tutorialPage.title1': '電気の',
  'tutorialPage.title2': 'うちワケ',
  'tutorialPage.title3': 'がわかる',
  'tutorialPage.details': '電力センサーのデータを元に「家電ごとの電力使用」を推定する家電分離技術「Metis Engine」(インフォメティス開発)が利用されています。',
  'tutorialPage.start': 'さっそく使ってみる',

  // settingPage
  'settingPage.contractorId': '設置宅ID',
  'settingPage.macAddress': 'MACアドレス',
  'settingPage.accountStartDate': 'サービス開始日',
  'settingPage.aplsDisplay': '表示対象家電',
  'settingPage.version': 'バージョン',
  'settingPage.change': '変更',
  'settingPage.tutorial': 'チュートリアル',
  'settingPage.display': '表示',
  'settingPage.faq': 'よくあるご質問',
  'settingAppliancePopup.title': '表示対象家電の変更',
  'settingAppliancePopup.description1': '「家電の使用状況」に',
  'settingAppliancePopup.description2': '表示する家電を変更できます',
  'settingAppliancePopup.close': '閉じる',

  // helpPopup
  'helpPopup.forDetails': '詳しくはこちら',
  'helpPopup.targetApls': '対象家電は？',
  'helpPopup.hourlyStateT': '時間帯毎の家電使用状況',
  'helpPopup.hourlyStateD': 'ご自宅の家電のうち、使用を検知した家電を光ってお知らせします。外出中にもご自宅の様子がうかがえます。',
  'helpPopup.topRankingT': '電気代ランキング',
  'helpPopup.topRankingD': '今月の電気代と、その中でも電気を多く使っている家電ランキングを表示します。日々の変化を確認してみましょう。',
  'helpPopup.chartRankingT': '電気代 家電ランキング',
  'helpPopup.chartRankingD':
    '「機器分離技術」が日々の電気波形を分析・学習し、家電ごとの電気代を推定します。なお、待機電力は「24時間常に電力消費する家電をまとめて」、高熱家電は「高熱を発生する家電をまとめて」表示します。',
  'helpPopup.transitionT': '電気代の変化',
  'helpPopup.transitionD': '毎日、毎週、毎月のご家庭の電気使用状況を振り返りながら、実際の電気代変化にどう影響しているか確認してみましょう',
  'helpPopup.transitionWeeklyD':
    '週ごとの電気代は日〜土曜日を1週間として集計しています。検針月の区切りと異なるため、週の電気代の合計は月の電気代と必ずしも一致しませんのでご注意ください。',
  'helpPopup.reportGeneratedT': '太陽光発電の見方',
  'helpPopup.reportGeneratedD':
    '発電して利用した電力の割合（自給自足率）に応じて惑星のイラストが変化します。CO2削減量は、林野庁資料を基に樹齢50年の杉が1ヶ月に吸収する量で換算しています。',
  'helpPopup.generationGainT': '発電してお得',
  'helpPopup.generationGainD': '太陽光発電の電力を利用すると、電力会社から買う場合よりお得にご利用いただけます。',
  'helpPopup.generatedPowerT': '発電した電力',
  'helpPopup.generatedPowerD':
    '太陽光発電は昼間の日差しが強い時間帯に効率良く発電します。天気が悪かったり、ソーラーパネルが汚れたりしていると発電量が落ちることがあります。',
  'helpPopup.soldChargeT': '発電して販売',
  'helpPopup.soldChargeD': '発電して自宅で利用しなかった分を売ることができます。金額には多少の誤差があります。',
  'helpPopup.fuelAdjustmentFeeDiscountT': '燃料調整費の割引',
  'helpPopup.fuelAdjustmentFeeDiscountD': '国の電気料金軽減措置により ご使用量1kWhあたり7円(税込)が 燃料調整費から割引されています',
  'helpPopup.revisedFuelAdjustmentFeeDiscountD':
    '国の電気料金軽減措置により ご使用量1kWhあたり3.5円(税込)が 燃料調整費から割引されています',
  'helpPopup.revisedFuelAdjustmentFeeDiscountD2':
    '国の電気料金軽減措置により ご使用量1kWhあたり1.8円(税込)が 燃料調整費から割引されています',
  'helpPopup.dayChartT': '家電ごとの使用電力とは',
  'helpPopup.dayChartD1': '１日の使用電力の推移が、',
  'helpPopup.dayChartD2': '家電ごとに表示されます。',
  'helpPopup.dayChartSTP': 'パソコンで操作する場合',
  'helpPopup.dayChartSMP': '＜特定の時間帯を拡大＞',
  'helpPopup.dayChartSMPP': '表示したい時間帯をドラッグ',
  'helpPopup.dayChartSMS': '＜他の時間帯を表示＞',
  'helpPopup.dayChartSMSP1': '「shift」キーを押しながら',
  'helpPopup.dayChartSMSP2': '横にドラッグ',

  // alertPopup
  'alertPopup.noAction1': 'このまま利用する',
  'alertPopup.noAction2': 'このまま使い続ける',
  'alertPopup.disableAquireT': 'データが取得できません',
  'alertPopup.disableAquireD1': 'サービスに一時的にアクセスできません',
  'alertPopup.disableAquireD2': 'しばらく時間をおいてから、',
  'alertPopup.disableAquireD3': 'アクセスしてください',
  'alertPopup.disableAquireLink': '障害メンテナンス情報',
  'alertPopup.StartT1': '家電の特徴を',
  'alertPopup.StartT2': '発見しました！',
  'alertPopup.StartD1': 'ご自宅で使った電気の中から',
  'alertPopup.StartD2': '一部の家電の特徴を発見しました',
  'alertPopup.StartD3': 'これから、各家電を使った時間帯と',
  'alertPopup.StartD4': 'その電力量を推定し表示します',
  'alertPopup.StartButton': '使用状況を見てみる',
  'alertPopup.loginFalied': 'ログインできませんでした',
  'alertPopup.inquiry': 'お問い合わせ先',
  'alertPopup.checkContract': 'お申し込み状況のご確認をお願いいたします',
  'alertPopup.userStatusErrorTitle': 'データが取得できません',
  'alertPopup.userStatusErrorMessage1': 'サービスに一時的にアクセスできません',
  'alertPopup.userStatusErrorMessage2': 'しばらく時間をおいてから、',
  'alertPopup.userStatusErrorMessage3': 'アクセスしてください',
  'alertPopup.pvAlertTitle': '太陽光発電ができていません',
  'alertPopup.pvAlertMessage': '太陽光が３日間以上停止している可能性があります。太陽光モニタを確認して、発電が無い場合は大和リビングへお問い合わせ下さい。',

  // errorPage
  'errorPage.title': 'エラーが発生しました',
  'errorPage.text': 'エラーコード',

  // others
  'others.timeOut': 'タイムアウトになりましたので、再ログインをお願いします',
  'others.expired': '契約が終了しています',
  'others.unavailable': '調整中につき、しばらくお待ちください',
  'others.networkError': 'ネットワーク接続に失敗しました',
  'others.notice': 'お知らせ',
  'others.close': '閉じる',
  'others.ok': 'OK',
  'others.criterion': '[目安]',
  'others.kWh': 'kWh',
  'others.percent': '%',

  // no data notices
  'topDailyNoData.Title': 'データが記録されていません',
  'topDailyNoData.Desc': 'センサーがネットに接続できていなかった様です',
  'topDailyNoData.Link': 'ご確認方法',
  'topDailyLoading.Title': 'データを取得中です',
  'topDailyLoading.Desc': 'センサーから測定データ送信・処理完了を待っています',
  'topDailyLoading.Link': 'ご確認方法',
  'topHourlyNoData.Title': 'データが記録されていません',
  'topHourlyNoData.Desc': 'センサーがネットに接続できていなかった様です',
  'topHourlyLoading.Title': 'データを取得中です',
  'topHourlyLoading.Desc': 'センサーから測定データ送信・処理完了を待っています',
};
