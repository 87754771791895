// @flow

import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import Prefixer from '../../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  periodArea: {
    color: '#454545',
    fontWeight: 'bold',
    padding: '16px 0px 12px',
    display: 'table',
    width: '80%',
    margin: '0px auto',
  },
  subTitle: {
    fontSize: '0.9em',
    fontWeight: 'normal',
  },
  moveButton: {
    display: 'table-cell',
    textAlign: 'center',
    color: '#298c8e',
  },
  arrowArea: {
    width: '17px',
    verticalAlign: 'middle',
  },
  arrow: {
    fill: '#298c8e',
  },
  arrowDisable: {
    opacity: '0.3',
    visibility: 'hidden',
  },
});

type Props = {
  requesting: boolean,
  billing: {
    billingDate: string,
    nextBillingDate: string | null,
    prevBillingDate: string | null,
    startDate: string,
    endDate: string,
    billingAmount: number,
    consumedBillingAmount: number | null,
    soldAmount: number | null,
  },
  intl: intlShape,
};

const goPrevMonth = (router, billing) => {
  if (!billing.prevBillingDate) {
    return;
  }
  router.history.push(`/report/${billing.prevBillingDate}`);
};

const goNextMonth = (router, billing) => {
  if (!billing.nextBillingDate) {
    return;
  }
  router.history.push(`/report/${billing.nextBillingDate}`);
};

export const PureNavigator = ({ requesting, billing, intl }: Props, { router }: Object) => {
  if (requesting) {
    return null;
  }

  const billingDate = moment(billing.billingDate, 'YYYY-MM');
  const { startDate, endDate, prevBillingDate, nextBillingDate } = billing;

  return (
    <div style={style.periodArea}>
      <div onClick={() => goPrevMonth(router, billing)} style={style.moveButton}>
        <svg
          style={prevBillingDate ? style.arrowArea : { ...style.arrowArea, ...style.arrowDisable }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <path style={style.arrow} d="M166,20V180L26,100Z" />
        </svg>
      </div>
      <div>
        {intl.formatMessage(
          { id: 'report.navigatorTitle' },
          { year: billingDate.year(), month: billingDate.month() + 1 }
        )}
      </div>
      <span style={style.subTitle}>
        {intl.formatMessage(
          { id: 'report.navigatorSubTitle' },
          {
            startDate: moment(startDate, 'YYYY-MM-DD').format('M/D'),
            endDate: moment(endDate, 'YYYY-MM-DD').format('M/D'),
          }
        )}
      </span>
      <div onClick={() => goNextMonth(router, billing)} style={style.moveButton}>
        <svg
          style={nextBillingDate ? style.arrowArea : { ...style.arrowArea, ...style.arrowDisable }}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 200 200"
        >
          <path style={style.arrow} d="M179,100,39,180V20Z" />
        </svg>
      </div>
    </div>
  );
};

const mapStateToProps = () => ({});

PureNavigator.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectedPureNavigator: any = connect(mapStateToProps)(injectIntl(PureNavigator));

export default connectedPureNavigator;
