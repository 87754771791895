/* eslint-env browser */
// @flow

import  React from 'react';
import type { ChildrenArray, Node } from 'react';
import { IntlProvider as BaseIntlProvider } from 'react-intl';

import messagesJa from '../lib/messages-ja';
import controllersJa from '../lib/controllers-ja';

// 日本語のみ対応
// 英語は存在するが読み込まない
const messagesMap = {
  ja: { ...messagesJa, ...controllersJa },
};
const LANGUAGE = 'ja';

const IntlProvider = ({children}: {children: ChildrenArray<Node>}) => {
  const messages = messagesMap[LANGUAGE]
  return (
    <BaseIntlProvider messages={messages} locale={LANGUAGE}>
      {children}
    </BaseIntlProvider>
  )
}

export default IntlProvider;


