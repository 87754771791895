/* eslint-env browser */
// @flow

import { createElement as ele } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { asyncSessionStorage } from 'redux-persist/storages';

import isSupportedBrowser from './lib/browserCheck';
import { store, getReducer } from './lib/store';

if (!isSupportedBrowser(navigator.userAgent)) {
  window.location.href = 'notsupport.html';
}

const locationHref = window.location.href;
declare var config: Object;
declare var gtag: Function;
const gaLink =
  (locationHref.indexOf('imt-stg-imt4-dl.appspot.com') >= 0 && 'imt-stg-imt4-dl.appspot.com') ||
  (locationHref.indexOf('imt4-dl-prd.informetis.com') >= 0 && 'imt4-dl-prd.informetis.com') ||
  'imt-dev-imt4-dl.appspot.com';

if (gaLink && config.GA_MEASUREMENT_ID) {
  gtag('config', config.GA_MEASUREMENT_ID, {
    user_id: 'unknown user',
    custom_map: {
      dimension1: 'contractor_id',
      dimension2: 'service_provider',
      dimension4: 'status',
      dimension9: 'app_user_id',
      dimension10: 'action',
      dimension11: 'product',
      dimension12: 'link',
      dimension13: 'event_value',
    },
    linker: { domains: [gaLink] },
    send_page_view: false,
  });
}

if (!global.intl) {
  require('intl');
  require('intl/locale-data/jsonp/ja.js');
}

const renderApp = () => {
  const AppComponent = require('./components/App.jsx').default;
  persistStore(store, {
    whitelist: ['userInfo'],
    keyPrefix: 'IMT4DL',
    storage: asyncSessionStorage,
  });
  const container = document.getElementById('app');
  if (!container) {
    throw new Error('missing root element.');
  }
  const root = createRoot(container);

  root.render(ele(Provider, { store }, ele(AppComponent)));
};

renderApp();

if (module.hot) {
  module.hot.accept('./components/App.jsx', renderApp);
  module.hot.accept('./reducers', () => {
    store.replaceReducer(getReducer());
  });
}
