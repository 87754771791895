// @flow

import React, { Component } from 'react';
import type { Element } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { apiCheckLogin, apiGetUserStatus /* , apiLogoutExecute */ } from '../../actions/apis';
import gaSendPageView from '../../lib/gaSendPageView';
import { ENTER_APPLICATION, FORCIBLY_TRANSITION_TO_LOGIN } from '../../actions/types';

const cannotLoginStatus = ['FAILED', 'UNAUTHORIZED', 'NONE'];

const guestWillTransfer = (props, router) => {
  const { loginState, initialized } = props.loginInfo;
  const { status: userStatus, infoRequesting, statusRequesting } = props.userInfo;
  if (!initialized) {
    props.dispatch(apiCheckLogin());
  } else if (
    loginState === 'SUCCESS' &&
    userStatus === 'no_data' &&
    !statusRequesting &&
    !infoRequesting
  ) {
    props.dispatch(apiGetUserStatus());
  } else if (cannotLoginStatus.includes(loginState) || userStatus === 'error') {
    props.dispatch({ type: FORCIBLY_TRANSITION_TO_LOGIN });
    router.history.replace({
      pathname: '/error',
      state: { nextPathname: props.location.pathname },
    });
  }
};

const trackPageview = (props) => {
  gaSendPageView(
    props.location.pathname,
    props.userInfo.contractorId,
    props.userInfo.serviceProviderId,
    props.userInfo.appUserId,
    props.userInfo.status
  );
};

const initializeStateByLocale = ({ intl, dispatch }) => {
  dispatch({ type: ENTER_APPLICATION, locale: intl.locale });
};

type Props = {
  location: {
    state?: Object,
    pathname?: string,
  },
  loginInfo: {
    loginState: string,
    initialized: boolean,
    requesting: boolean,
  },
  userInfo: {
    statusInitialized?: boolean,
    infoRequesting?: boolean,
    statusRequesting?: boolean,
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    macAddress?: string,
    accountStartDate?: string,
    status: string,
  },
  dispatch: Dispatch,
  intl: intlShape,
  children: Element<Object>,
};
class AuthAreaBase extends Component<Props> {
  componentDidMount() {
    initializeStateByLocale(this.props);
    guestWillTransfer(this.props, this.context.router);
    trackPageview(this.props);
  }

  componentDidUpdate(prevProps) {
    guestWillTransfer(this.props, this.context.router);
    if (
      prevProps.location.pathname !== this.props.location.pathname ||
      prevProps.userInfo.contractorId !== this.props.userInfo.contractorId ||
      prevProps.userInfo.appUserId !== this.props.userInfo.appUserId ||
      prevProps.userInfo.status !== this.props.userInfo.status
    ) {
      trackPageview(this.props);
    }
  }

  render() {
    const { loginState } = this.props.loginInfo;
    const { statusRequesting, statusInitialized } = this.props.userInfo;
    return (
      loginState === 'SUCCESS' &&
      !!statusInitialized &&
      !statusRequesting && <div>{this.props.children}</div>
    );
  }
}

AuthAreaBase.contextTypes = {
  router: PropTypes.object.isRequired,
};

const AuthArea = connect((state) => ({
  loginInfo: state.loginInfo,
  userInfo: state.userInfo,
}))(AuthAreaBase);

export default injectIntl(AuthArea);
