// @flow

import type { State, Action } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';

import {
  REQUEST_API_USER_INFO,
  SUCCESS_API_USER_INFO,
  FAILURE_API_USER_INFO,
  REQUEST_API_USER_STATUS,
  SUCCESS_API_USER_STATUS,
  FAILURE_API_USER_STATUS,
  FORCIBLY_TRANSITION_TO_LOGIN,
  SUCCESS_API_LOGIN_INFO,
  REQUEST_API_QUESTIONNAIRE,
  SUCCESS_API_QUESTIONNAIRE,
  FAILURE_API_QUESTIONNAIRE,
} from '../actions/types';

const initialState = {
  statusInitialized: false,
  infoRequesting: false,
  statusRequesting: false,
  contractorId: '', // the first 4 characters are the provider ID
  appUserId: '',
  serviceProviderId: '',
  macAddress: '',
  accountStartDate: '',
  learningStartDate: '',
  status: 'no_data', // pre_installation, null_root_power, learning, imperfect_learning, normal
  unitPrices: [],
  powerUnitPrice: 0,
  fuelCostAdjustmentUnitPrice: 0,
  renewableEnergy: 0,
  soldUnitPrice: 0,
  consumedUnitPrice: 0,
  sellingPattern: '',
  soldEnergyOwner: '',
  lastBillingDate: '',
  firstWeighingStartDate: '',
  questionnaires: {},
  questionnaireRequesting: false,
  questionnaireUpdated: null,
  hasInvoice: false,
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REQUEST_API_USER_INFO: {
      const infoRequesting = !action.error;
      return {
        ...state,
        infoRequesting,
      };
    }
    case SUCCESS_API_USER_INFO: {
      const { user = {} } = action.payload || {};
      return {
        ...state,
        infoRequesting: false,
        contractorId: user.contractor_id,
        macAddress: user.mac_address,
      };
    }
    case FAILURE_API_USER_INFO: {
      return {
        ...state,
        infoRequesting: false,
      };
    }
    case REQUEST_API_USER_STATUS: {
      const statusRequesting = !action.error;
      return {
        ...state,
        statusInitialized: true,
        statusRequesting,
      };
    }
    case SUCCESS_API_USER_STATUS: {
      const { user = {} } = action.payload || {};
      return {
        ...state,
        statusInitialized: true,
        statusRequesting: false,
        accountStartDate: user.account_start_date,
        learningStartDate: user.learning_start_date,
        status: user.contractor_state,
        unitPrices: user.unit_price,
        soldUnitPrice: user.sold_unit_price,
        consumedUnitPrice: user.consumed_unit_price,
        sellingPattern: user.selling_pattern,
        soldEnergyOwner: user.sold_energy_owner,
        lastBillingDate: user.last_billing_date,
        firstWeighingStartDate: user.first_weighing_start_date,
        questionnaires: user.questionnaires,
        hasInvoice: user.has_invoice,
      };
    }
    case FAILURE_API_USER_STATUS: {
      return {
        ...state,
        status: 'error',
        statusInitialized: true,
        statusRequesting: false,
      };
    }
    case REQUEST_API_QUESTIONNAIRE: {
      return {
        ...state,
        questionnaireRequesting: !action.error,
      };
    }
    case SUCCESS_API_QUESTIONNAIRE: {
      const { data = {} } = action.payload || {};
      return {
        ...state,
        questionnaireRequesting: false,
        questionnaires: data,
        questionnaireUpdated: Date.now(),
      };
    }
    case FAILURE_API_QUESTIONNAIRE: {
      return {
        ...state,
        status: 'error',
        questionnaireRequesting: false,
      };
    }
    case REHYDRATE: {
      const { loginInfo, userInfo } = action.payload;
      if (
        loginInfo &&
        loginInfo.loginState === 'SUCCESS' &&
        loginInfo.logoutState !== 'SUCCESS' &&
        !state.userInfo.contractorId
      ) {
        return { ...state, ...userInfo };
      }
      return { ...state, status: 'no_data' };
    }
    case FORCIBLY_TRANSITION_TO_LOGIN: {
      return initialState;
    }
    case SUCCESS_API_LOGIN_INFO: {
      const { user = {} } = action.payload || {};
      return {
        ...state,
        contractorId: user.contractorId,
        appUserId: user.appUserId,
        serviceProviderId: user.serviceProviderId,
      };
    }
    default: {
      return state;
    }
  }
};
