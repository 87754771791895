// @flow

import React from 'react';
import HighPerformanceRiceCooker from './HighPerformanceRiceCooker';
import AutomaticCooker from './AutomaticCooker';
import Bedding from './Bedding';
import WaterSavingClothesWasher from './WaterSavingClothesWasher';
import HighPerformanceMicrowave from './HighPerformanceMicrowave';
import Circulator from './Circulator';
import VacuumCleaner from './VacuumCleaner';

type Product =
  | 'high_performance_rice_cooker'
  | 'automatic_cooker'
  | 'bedding'
  | 'water_saving_clothes_washer'
  | 'high_performance_microwave'
  | 'circulator'
  | 'vacuum_cleaner';

type PickupData = {
  product: Product | null,
  data: Object | null,
  month: string,
};

type Props = { pickup: PickupData };

export const Pickup = ({ pickup }: Props) => {
  if (!pickup.data) {
    return null;
  }
  switch (pickup.product) {
    case 'high_performance_rice_cooker':
      return <HighPerformanceRiceCooker data={pickup.data} />;
    case 'automatic_cooker':
      return <AutomaticCooker data={pickup.data} />;
    case 'bedding':
      return <Bedding data={pickup.data} />;
    case 'water_saving_clothes_washer':
      return <WaterSavingClothesWasher data={pickup.data} />;
    case 'high_performance_microwave':
      return <HighPerformanceMicrowave data={pickup.data} />;
    case 'circulator':
      return <Circulator data={pickup.data} />;
    case 'vacuum_cleaner':
      return <VacuumCleaner data={pickup.data} />;
    default: {
      return null;
    }
  }
};

export default Pickup;
