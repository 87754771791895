// flow
import moment from 'moment';
import type { State, Action } from 'redux';
import { timeUnitId } from '../../lib/id';
import type { EnergyPerDay } from '../../lib/types/electricTypes';

const getEnergy = target => {
  const { data } = target;
  if (
    Array.isArray(data.bought_energy) ||
    Array.isArray(data.sold_energy) ||
    Array.isArray(data.consumed_energy)
  ) {
    return {
      buyEnergy: (data.bought_energy && data.bought_energy) || [],
      sellEnergy: (data.sold_energy && data.sold_energy) || [],
      consumeEnergy: (data.consumed_energy && data.consumed_energy) || [],
    };
  }
  return {
    buyEnergy: data.bought_energy || 0,
    sellEnergy: data.sold_energy || 0,
    consumeEnergy: data.consumed_energy || 0,
  };
};

const subUnitWeeklyData = (payload, dateTimeList: string[]): EnergyPerDay[] => {
  const weekEnergy = dateTimeList
    .map((startDate, index) => {
      const filter = payload.filter(data => {
        const endDate = dateTimeList[index + 1] || null;
        return (
          data.unix >= moment(startDate).unix() &&
          (!endDate || (endDate && data.unix < moment(endDate).unix()))
        );
      });

      if (!filter.length) {
        return null;
      }

      return {
        unit: 'week',
        period: startDate,
        startDate: moment.unix(filter[0].unix).format('YYYY-MM-DD'),
        endDate: moment.unix(filter[filter.length - 1].unix).format('YYYY-MM-DD'),
        data: filter.map(target => ({
          daily: moment.unix(target.unix).format('YYYY-MM-DD'),
          ...getEnergy(target),
        })),
      };
    })
    .filter(data => data !== null);
  return weekEnergy;
};

const subUnitMonthlyData = (payload, meta) => {
  const { dateTimeList, weighingPeriodList } = meta;
  const monthEnergy = dateTimeList.map(dateTime => {
    const weighingPeriod = weighingPeriodList.find(weighingDays =>
      moment(weighingDays.billingMonth, 'YYYY-MM').isSame(moment(dateTime), 'YYYY-MM')
    );
    const filter = payload.filter(target =>
      moment
        .unix(target.unix)
        .isBetween(
          moment(weighingPeriod.startDate, 'YYYY-MM-DD').startOf('day'),
          moment(weighingPeriod.endDate, 'YYYY-MM-DD').endOf('day'),
          '',
          '[]'
        )
    );
    if (filter.length === 0) {
      return null;
    }
    const unitEnergy = {
      unit: 'month',
      period: moment(weighingPeriod.billingMonth, 'YYYY-MM').format('YYYY-MM'),
      startDate: moment(weighingPeriod.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      endDate: moment(weighingPeriod.endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      data: filter.map(target => ({
        daily: moment.unix(target.unix).format('YYYY-MM-DD'),
        ...getEnergy(target),
      })),
    };

    return unitEnergy;
  });

  return monthEnergy.filter(energy => energy !== null);
};

const successApiEnergyDaily = (energyState, payload) => {
  const targetPayload = payload.filter(
    target =>
      !energyState.daily.find(
        item =>
          item.unit === 'day' &&
          item.period === moment.unix(target.unix).format('YYYY-MM-DD') &&
          item.data.find(v => v.buyEnergy.length === target.data.bought_energy.length)
      )
  );
  const unitEnergy: EnergyPerDay[] = targetPayload.map(target => ({
    unit: 'day',
    period: moment.unix(target.unix).format('YYYY-MM-DD'),
    startDate: moment.unix(target.unix).format('YYYY-MM-DD'),
    endDate: moment.unix(target.unix).format('YYYY-MM-DD'),
    data: [
      {
        daily: moment.unix(target.unix).format('YYYY-MM-DD'),
        ...getEnergy(target),
      },
    ],
  }));
  return {
    ...energyState,
    daily: [...energyState.daily, ...unitEnergy],
  };
};

const successApiEnergyWeekly = (energyState, payload, meta) => {
  if (
    (!meta.subUnitFlg && energyState.weekly.find(item => meta.dateTime === item.period)) ||
    (meta.subUnitFlg &&
      (meta.dateTimeList || [])
        .every(dateTime => energyState.weekly.find(item => dateTime === item.period)))
  ) {
    return energyState;
  }
  if (meta.subUnitFlg) {
    const weekEnergy = subUnitWeeklyData(payload, meta.dateTimeList);
    const filter = energyState.weekly.filter(
      item => !weekEnergy.find(data => data.period === item.period)
    );
    return {
      ...energyState,
      weekly: [...filter, ...weekEnergy],
    };
  }
  const unitEnergy: EnergyPerDay = {
    unit: 'week',
    period: meta.dateTime,
    startDate: moment.unix(payload[0].unix).format('YYYY-MM-DD'),
    endDate: moment.unix(payload[payload.length - 1].unix).format('YYYY-MM-DD'),
    data: payload.map(target => ({
      daily: moment.unix(target.unix).format('YYYY-MM-DD'),
      ...getEnergy(target),
    })),
  };

  return {
    ...energyState,
    weekly: [...energyState.weekly, unitEnergy],
  };
};

const successApiEnergyMonthly = (energyState, payload, meta) => {
  if (
    energyState.monthly.find(
      item =>
        (item.unit === 'month' && meta.dateTime === item.period) ||
        (meta.subUnitFlg &&
          meta.weighingPeriodList.length > 0 &&
          meta.weighingPeriodList[0].billingMonth === item.period)
    )
  ) {
    return energyState;
  }
  if (meta.subUnitFlg) {
    const monthEnergy = subUnitMonthlyData(payload, meta, energyState.monthly);
    const filter = energyState.monthly.filter(
      item => !monthEnergy.find(data => data.period === item.period)
    );
    return {
      ...energyState,
      monthly: [...filter, ...monthEnergy],
    };
  }
  const period = moment(meta.dateTime, 'YYYY-MM').format('YYYY-MM');
  const unitEnergy: EnergyPerDay = {
    unit: 'month',
    period,
    startDate: moment.unix(payload[0].unix).format('YYYY-MM-DD'),
    endDate: moment.unix(payload[payload.length - 1].unix).format('YYYY-MM-DD'),
    data: payload.map(target => ({
      daily: moment.unix(target.unix).format('YYYY-MM-DD'),
      ...getEnergy(target),
    })),
  };
  return {
    ...energyState,
    monthly: [...energyState.monthly, unitEnergy],
  };
};

const successApiEnergyYearly = (energyState, payload, meta) => {
  if (energyState.yearly.find(item => meta.dateTime === item.period)) {
    return energyState;
  }
  const period = moment(meta.dateTime, 'YYYY').format('YYYY');
  const unitEnergy: EnergyPerDay = {
    unit: 'year',
    period,
    startDate: moment.unix(payload[0].unix).format('YYYY-MM-DD'),
    endDate: moment.unix(payload[payload.length - 1].unix).format('YYYY-MM-DD'),
    data: payload.map(target => ({
      daily: moment.unix(target.unix).format('YYYY-MM-DD'),
      ...getEnergy(target),
    })),
  };
  return {
    ...energyState,
    yearly: [...energyState.yearly, unitEnergy],
  };
};

export default (energyState: State, action: Action = {}): State => {
  const { payload, meta } = action || {};
  if (!Object.keys(payload).length) {
    return energyState;
  }

  switch (meta.reqUnit) {
    case timeUnitId.day:
      return successApiEnergyDaily(energyState, payload);
    case timeUnitId.week:
      return successApiEnergyWeekly(energyState, payload, meta);
    case timeUnitId.month:
      return successApiEnergyMonthly(energyState, payload, meta);
    case timeUnitId.year:
      return successApiEnergyYearly(energyState, payload, meta);
    default: {
      return energyState;
    }
  }
};
