// @flow

import type { State, Action } from 'redux';
// eslint-disable-next-line no-redeclare
/* global localStorage */
import { REHYDRATE } from 'redux-persist/constants';
import {
  RESET_TUTORIAL,
  TUTORIAL_FINISHED,
  SUCCESS_API_USER_INFO,
  SUCCESS_API_LOGIN_INFO,
} from '../actions/types';

const initialState = {
  tutorialFinished: false,
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REHYDRATE:
    case SUCCESS_API_LOGIN_INFO:
    case SUCCESS_API_USER_INFO: {
      const { payload } = action;
      return {
        ...state,
        tutorialFinished: payload.tutorialFinished,
      };
    }
    case RESET_TUTORIAL: {
      return initialState;
    }
    case TUTORIAL_FINISHED: {
      return {
        ...state,
        tutorialFinished: true,
      };
    }
    default: {
      return state;
    }
  }
};
