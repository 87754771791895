// @flow

import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { VictoryContainer, VictoryBar } from 'victory';
import sizeMe from 'react-sizeme';
import Prefixer from '../lib/Prefixer';
import { getApplianceNameFromId } from '../lib/common/globalUtils';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  ranking: {
    display: 'table',
    width: '100%',
    height: '35px',
    lineHeight: '30px',
  },
  lower: {
    color: '#454545',
    borderBottom: '1px solid #ccc',
    fontSize: '0.8em',
  },
  no1: {
    color: '#ff5354',
    borderBottom: '1px solid #ff5354',
    fontSize: '0.9em',
  },
  no2: {
    color: '#ff9657',
    borderBottom: '1px solid #ff9657',
    fontSize: '0.9em',
  },
  no3: {
    color: '#ffb657',
    borderBottom: '1px solid #ffb657',
    fontSize: '0.9em',
  },
  rank: {
    display: 'table-cell',
    width: '10%',
    verticalAlign: 'middle',
  },
  small: {
    fontSize: '0.8em',
  },
  verySmall: {
    fontSize: '0.7em',
    marginLeft: '2px',
  },
  applianceName: {
    textAlign: 'left',
    padding: '0 2px 0 6px',
    width: '30%',
    display: 'table-cell',
    lineHeight: '1.2em',
    verticalAlign: 'middle',
  },
  chart: {
    height: '20px',
    width: '35%',
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  charge: {
    textAlign: 'right',
    width: '25%',
    display: 'table-cell',
    lineHeight: '1.2em',
    verticalAlign: 'middle',
  },
});

const renderApplianceCharge = (charge, intl) => {
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const lessThanPrice = intl.formatMessage({ id: 'currency.lessThan' });
  const currency = intl.formatNumber(charge, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const messages = defineMessages({
    currency: {
      id: 'chartApplianceRankingItem.currency',
      description: 'currency 円 or £{currency}',
    },
  });
  if (charge >= lessThanPrice) {
    return (
      <div>
        {intl.formatMessage(messages.currency, { currency })}
        <span style={style.small} />
      </div>
    );
  }
  return <div>{intl.formatMessage({ id: 'chartApplianceRankingItem.lessThan10' })}</div>;
};

const rankColor = rank => {
  switch (rank) {
    case 1:
      return '#ff5354';
    case 2:
      return '#ff9657';
    case 3:
      return '#ffb657';
    default:
      return '#d0cbc2';
  }
};

const renderEnergyChart = (energy, rank, max, width) => (
  <VictoryBar
    horizontal
    containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
    domain={{ x: [0, Math.round(max * 100)] }}
    width={width}
    height={20}
    style={{ data: { fill: rankColor(rank), width: 15 } }}
    padding={{ top: 15, bottom: 5 }}
    data={[{ x: 1, y: Math.round(energy * 100) }]}
  />
);

const rankingStyle = rank => {
  switch (rank) {
    case 1:
      return { ...style.ranking, ...style.no1 };
    case 2:
      return { ...style.ranking, ...style.no2 };
    case 3:
      return { ...style.ranking, ...style.no3 };
    default:
      return { ...style.ranking, ...style.lower };
  }
};

type Props = {
  appliance: {
    id: string,
    energyValue: number,
  },
  rank: number,
  energyMax: number,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
};
export const PureChartApplianceRankingItem = ({
  appliance,
  rank,
  energyMax,
  intl,
  size,
}: Props): Node => (
  <div style={rankingStyle(rank)}>
    <div style={style.rank}>
      {rank}
      <span style={style.verySmall}>
        {intl.formatMessage({ id: 'chartApplianceRankingItem.place' }).split(',')[rank - 1]}
      </span>
    </div>
    <div style={style.applianceName}>{getApplianceNameFromId(appliance.id, intl)}</div>
    <div style={style.chart}>
      {renderEnergyChart(appliance.energyValue, rank, energyMax, size.width * 0.33)}
    </div>
    <div style={style.charge}>{renderApplianceCharge(appliance.energyValue, intl)}</div>
  </div>
);

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChartApplianceRankingItem: any = connect()(
  sizeMeHOC(injectIntl(PureChartApplianceRankingItem))
);
export default connectedPureChartApplianceRankingItem;
