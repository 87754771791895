// @flow

import moment from 'moment';
import { timeFormat, timeUnitStyle } from '../constants/timeUnit';
import type { WeighingPeriod } from '../types/priceInfoTypes';

export const makeValidPeriod = (unit: string, momentObj: moment) => {
  switch (unit) {
    case 'year': {
      return momentObj.format('YYYY');
    }
    case 'month': {
      return momentObj.format('YYYY-MM');
    }
    case 'week': {
      return momentObj.startOf('week').isBefore(momentObj)
        ? momentObj
            .add(1, 'week')
            .startOf('week')
            .format('YYYY-MM-DD')
        : momentObj.startOf('week').format('YYYY-MM-DD');
    }
    case 'day': {
      return momentObj.format('YYYY-MM-DD');
    }
    default: {
      throw new Error(`unit ${unit} is not defined`);
    }
  }
};

export const getWeighingPeriod = (
  billingData: Array<{
    billingDate: string,
    startDate: string,
    endDate: string,
    billingAmount: number,
    consumedBillingAmount: number | null,
    soldAmount: number | null,
  }>,
  date: string,
  unit: string
) => {
  // 指定した請求月または請求年を含むデータを取得する
  // 存在しなければ空で返す
  const format = timeFormat[unit];
  if (unit === 'week') {
    return billingData.find(billing =>
      moment(date, 'YYYY-MM-DD').isBetween(
        moment(billing.startDate, 'YYYY-MM-DD'),
        moment(billing.endDate, 'YYYY-MM-DD'),
        '',
        '[]'
      )
    );
  }
  return billingData.find(billing =>
    moment(date, format).isSame(billing.billingDate, timeUnitStyle[unit].toMulti)
  );
};

export const getTemporaryWeighingPeriod = (
  weighingPeriodList: WeighingPeriod[],
  date: string,
  unit: string
): WeighingPeriod | null => {
  if (unit === 'week') {
    return null;
  }
  // 指定した請求月または請求年を含むデータを取得する
  // 存在しなければ空で返す
  const format = timeFormat[unit];
  return (
    weighingPeriodList.find(weighingPeriod =>
      moment(date, format).isSame(
        moment(weighingPeriod.billingMonth, 'YYYY-MM'),
        timeUnitStyle[unit].toMulti
      )
    ) || null
  );
};

export const makeTemporaryWeighingPeriod = (
  weighingStartDate: moment,
  makeBillingMonth: moment
): ?{ startDate: string, endDate: string, billingMonth: string } => {
  if (!weighingStartDate.isValid() || !makeBillingMonth.isValid()) {
    return null;
  }
  const addWeighingEndDate = weighingStartDate.clone().add(1, 'month');
  if (weighingStartDate.date() === addWeighingEndDate.date()) {
    return {
      startDate: weighingStartDate.format('YYYY-MM-DD'),
      endDate: addWeighingEndDate.subtract(1, 'days').format('YYYY-MM-DD'),
      billingMonth: makeBillingMonth.format('YYYY-MM'),
    };
  }
  return {
    startDate: weighingStartDate.format('YYYY-MM-DD'),
    endDate: addWeighingEndDate
      .startOf('month')
      .add(1, 'month')
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
    billingMonth: makeBillingMonth.format('YYYY-MM'),
  };
};

export const makeWeighingPeriod = (
  weighingStartDate: string,
  includingDate: moment,
  billingDate: string
): ?{ startDate: string, endDate: string, billingMonth: string } => {
  const makeWeighingStartDate = moment(weighingStartDate, 'YYYY-MM-DD');
  const makeBillingMonth = moment(billingDate, 'YYYY-MM');

  // 値が不正なら空で返す
  const blankWeighingPeriod = {
    startDate: '',
    endDate: '',
    billingMonth: '',
  };
  if (makeWeighingStartDate.isAfter(includingDate, 'day')) {
    return blankWeighingPeriod;
  }
  let weighingPeriod = makeTemporaryWeighingPeriod(makeWeighingStartDate, makeBillingMonth);

  while (
    weighingPeriod &&
    !includingDate.isBetween(
      moment(weighingPeriod.startDate, 'YYYY-MM-DD'),
      moment(weighingPeriod.endDate, 'YYYY-MM-DD').endOf('day'),
      '',
      '[]'
    )
  ) {
    weighingPeriod = makeTemporaryWeighingPeriod(
      moment(weighingPeriod.endDate, 'YYYY-MM-DD').add(1, 'day'),
      moment(weighingPeriod.billingMonth, 'YYYY-MM').add(1, 'month')
    );
  }
  return weighingPeriod || blankWeighingPeriod;
};

export const makeWeighingPeriodArray = (
  weighingStartDate: string,
  includingDate: moment,
  billingDate: string
): ?[{ startDate: string, endDate: string, billingMonth: string }] => {
  const makeWeighingStartDate = moment(weighingStartDate, 'YYYY-MM-DD');
  const makeBillingMonth = moment(billingDate, 'YYYY-MM');

  // 値が不正なら空で返す
  const blankWeighingPeriod = [
    {
      startDate: '',
      endDate: '',
      billingMonth: '',
    },
  ];
  if (makeBillingMonth.startOf('month').isAfter(includingDate.startOf('month'), 'month')) {
    return blankWeighingPeriod;
  }
  let weighingPeriod = makeTemporaryWeighingPeriod(makeWeighingStartDate, makeBillingMonth);

  const weighingPeriodArray = [
    {
      startDate: weighingPeriod ? weighingPeriod.startDate : '',
      endDate: weighingPeriod ? weighingPeriod.endDate : '',
      billingMonth: weighingPeriod ? weighingPeriod.billingMonth : '',
    },
  ];

  while (weighingPeriod && !includingDate.isSame(weighingPeriod.billingMonth, 'months')) {
    weighingPeriod = makeTemporaryWeighingPeriod(
      moment(weighingPeriod.endDate, 'YYYY-MM-DD').add(1, 'day'),
      moment(weighingPeriod.billingMonth, 'YYYY-MM').add(1, 'month')
    );
    if (weighingPeriod) {
      weighingPeriodArray.push({
        startDate: weighingPeriod.startDate,
        endDate: weighingPeriod.endDate,
        billingMonth: weighingPeriod.billingMonth,
      });
    }
  }

  return weighingPeriodArray || blankWeighingPeriod;
};

export const makeCurrentWeighingPeriod = (
  billingData: Array<{
    billingDate: string,
    startDate: string,
    endDate: string,
    billingAmount: number,
    consumedBillingAmount: number | null,
    soldAmount: number | null,
  }>,
  lastBillingDate: string,
  accountStartDate: string
): ?{ startDate: string, endDate: string, billingMonth: string } => {
  const billingDataPeriod = getWeighingPeriod(billingData, lastBillingDate, 'month');
  return makeWeighingPeriod(
    billingDataPeriod
      ? moment(billingDataPeriod.endDate)
          .add(1, 'day')
          .format('YYYY-MM-DD')
      : accountStartDate,
    moment(),
    billingDataPeriod
      ? moment(billingDataPeriod.billingDate, 'YYYY-MM')
          .add(1, 'month')
          .format('YYYY-MM')
      : moment(lastBillingDate, 'YYYY-MM').format('YYYY-MM')
  );
};

export const isCurrentMonth = (
  weighingPeriodInitialized: boolean,
  currentWeighingPeriod: {
    startDate: string,
    endDate: string,
    billingMonth: string,
  },
  unit: string,
  period: string
): boolean => {
  if (unit !== 'month' || !weighingPeriodInitialized || !currentWeighingPeriod) {
    return false;
  }
  const now = moment(currentWeighingPeriod.billingMonth, 'YYYY-MM');
  const target = moment(period, 'YYYY-MM');

  return now.isSame(target, 'months');
};
