// @flow

import React from 'react';
import { connect } from 'react-redux';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup } from './gtagEvent';
import headerImg from '../../../images/pickup/automatic_cooker.png';
import picture from '../../../images/pickup/automatic_cooker_picture.jpg';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  header: { width: '100%' },
  title: { textAlign: 'left', fontSize: '18px' },
  subtitle: { textAlign: 'left', fontSize: '14px' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  rankingArea: { display: 'flex', justifyContent: 'space-around' },
  leftBox: { width: '48%', display: 'flex', flexDirection: 'column' },
  rightBox: { width: '48%' },
  rankingBox: {
    margin: '8px 0',
    padding: '0 5px 8px 5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  ranking1: { borderBottom: '1px solid #ff5e5e', color: '#ff5e5e' },
  ranking2: { borderBottom: '1px solid #ffa162', color: '#ffa162' },
  ranking3: { borderBottom: '1px solid #ffbe62', color: '#ffbe62' },
  rankingSuffix: { fontSize: '0.8em' },
  img: { width: '100%' },
  annotation: {
    color: '#999',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'left',
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    margin: '0 auto',
    color: '#fafafa',
    background: '#298c8e',
    height: '65px',
    borderRadius: '8px',
  },
});

const getMessage = (ranking: { day_of_week: number, power: number }[]) => {
  if (ranking.filter((v) => v.power === 0).length > 1) {
    return {
      title: 'キッチンで活躍する賢い家電をご紹介',
      link: 'https://heartonemall.com/iot-chouriki/?utm_source=iotdroom&utm_campaign=chcyuu&k=cn',
      body: (
        <div style={style.body}>
          <p>
            最近では献立を提案したり、食材を入れるだけで加熱調理してくれる賢い家電が人気になっているのをご存知ですか？
            <br />
            外食が多くて気になる方の手軽な自炊にも、いつもの料理にバリエーションを追加するのにも、色々なシーンで大活躍間違いなし！
            <br />
            びっくりするほどおまかせで料理できるので、取り入れてみませんか？
          </p>
        </div>
      ),
    };
  }
  return {
    title: 'いっぱい調理家電を使った曜日は？',
    link: 'https://heartonemall.com/iot-chouriki/?utm_source=iotdroom&utm_campaign=chdai&k=cn',
    body: (
      <div style={style.body}>
        <p>電子レンジ、炊飯器、IHコンロの消費電力を曜日ごとにまとめてランキングにしました。</p>
        <p>
          電子レンジをたくさん使って料理したり、コンビニご飯を温めたり。いつもとちょっと違う行動をするだけで、調理家電の消費電力は変化します。
          <br />
          調理家電の動きを振り返ると曜日ごとの生活リズムが見えていきませんか？
        </p>
        <p>
          毎日の食事の用意が大変だなと思ったら、スマートな家電に頼るのも手かもしれませんよ。
          <br />
          最近では献立を提案したり、食材を入れるだけで加熱調理してくれる賢い家電があるんです。外食派で健康が気になる人にもぴったり。びっくりするほどおまかせで料理できるので、取り入れてみませんか？
        </p>
      </div>
    ),
  };
};

export type Props = {
  data: { ranking: { day_of_week: number, power: number }[] },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureAutomaticCooker = ({ userInfo, data: { ranking } }: Props) => {
  const weeks = ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'];
  const { title, body, link } = getMessage(ranking);
  const showData = ranking.filter((v) => v.power === 0).length < 2;
  const top3 = ranking.slice(0, 3);

  gtagShowPickup(link, userInfo, 'automatic_cooker');
  return (
    <div>
      <img src={headerImg} alt="automatic_cooker" style={style.header} />
      <p style={style.title}>{title}</p>
      {showData && <p style={style.subtitle}>お料理DAYランキング</p>}
      {showData && (
        <div style={style.rankingArea}>
          <div style={style.leftBox}>
            <div style={{ ...style.rankingBox, ...style.ranking1 }}>
              <span>
                <span>1</span>
                <span style={style.rankingSuffix}>位</span>
              </span>
              <span>{weeks[top3[0].day_of_week]}</span>
            </div>
            <div style={{ ...style.rankingBox, ...style.ranking2 }}>
              <span>
                <span>2</span>
                <span style={style.rankingSuffix}>位</span>
              </span>
              <span>{weeks[top3[1].day_of_week]}</span>
            </div>
            <div style={{ ...style.rankingBox, ...style.ranking3 }}>
              <span>
                <span>3</span>
                <span style={style.rankingSuffix}>位</span>
              </span>
              <span>{weeks[top3[2].day_of_week]}</span>
            </div>
          </div>
          <div style={style.rightBox}>
            <img src={picture} alt="automatic_cooker_picture" style={style.img} />
          </div>
        </div>
      )}
      {body}
      <p style={style.annotation}>
        ※AIによる推定技術を使っているため、機種によって表示されなかったり、学習に時間がかかることがあります。
      </p>
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) => gtagClickPickup(e, link, userInfo, 'automatic_cooker')}
      >
        スマート調理家電の特集を読む
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureAutomaticCooker: any = connect(mapStateToProps)(PureAutomaticCooker);
export default connectedPureAutomaticCooker;
