// @flow

import type { State, Action } from 'redux';
import moment from 'moment';
import { localDstTransferHours } from '../lib/localizedValues';

import {
  ENTER_APPLICATION,
  SELECT_NEXT_HOUR,
  SELECT_PREV_HOUR,
  SELECT_CLICKED_HOUR,
  LOAD_TOP_PAGE,
  LOAD_TOP_PAGE_LATEST_TWO_HOURS,
} from '../actions/types';

const initialState = {
  currentDate: '',
  currentTime: '15:00',
  selectedHour: 15,
  range: [],
  locale: 'en',
};

const DISPLAY_RANGE = 12;

const createRangesByMoment = locale => {
  const now = moment();
  const timeRange = Array.from(Array(12).keys()).map(value => {
    if (value === 0) {
      return now.hours();
    }
    return now.subtract(1, 'hour').hours();
  });
  if (Object.keys(localDstTransferHours).includes(locale)) {
    const { dstEndHour } = localDstTransferHours[locale];
    // サマータイム終了日に起こる時間の重複をチェックして値を変更する
    if (timeRange.indexOf(dstEndHour) !== timeRange.lastIndexOf(dstEndHour)) {
      const ADDITIONAL_FOR_UNIQUE = 100;
      const dupIndex = timeRange.indexOf(dstEndHour);
      timeRange[dupIndex] += ADDITIONAL_FOR_UNIQUE;
    }
  }
  return timeRange;
};

const prevHour = (selectedHour, range) => {
  const index = range.findIndex(hour => hour === selectedHour);
  return index === DISPLAY_RANGE - 1 ? range[0] : range[index + 1];
};

const nextHour = (selectedHour, range) => {
  const index = range.findIndex(hour => hour === selectedHour);
  return index === 0 ? range[DISPLAY_RANGE - 1] : range[index - 1];
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case ENTER_APPLICATION: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case SELECT_NEXT_HOUR: {
      const selectedHour = nextHour(state.selectedHour, state.range);
      return {
        ...state,
        selectedHour,
      };
    }
    case SELECT_PREV_HOUR: {
      const selectedHour = prevHour(state.selectedHour, state.range);
      return {
        ...state,
        selectedHour,
      };
    }
    case SELECT_CLICKED_HOUR: {
      return {
        ...state,
        selectedHour: action.clickedHour,
      };
    }
    case LOAD_TOP_PAGE:
    case LOAD_TOP_PAGE_LATEST_TWO_HOURS: {
      const { locale } = state;
      // BSTの計算はmomentにおまかせ
      const range = createRangesByMoment(locale);
      const now = moment();
      return {
        ...state,
        currentDate: now.format('YYYY-MM-DD'),
        currentTime: now.format('HH:mm'),
        selectedHour: Number(now.format('HH')),
        range,
      };
    }
    default: {
      return state;
    }
  }
};
