// @flow

import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { subTimeUnit, timeUnitStyle } from '../lib/constants/timeUnit';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  headerFixed: {
    position: 'fixed',
    zIndex: '10000',
    left: '0px',
    right: '0px',
    top: '0px',
    height: '43px',
    width: '100%',
  },
  headerBar: {
    backgroundColor: '#4ed7da',
    width: '100%',
    height: '100%',
  },
  headerItem: {
    float: 'left',
    height: '100%',
  },
  headerItemRight: {
    float: 'right',
    height: '100%',
  },
  headerItemSvg: {
    lineHeight: '43px',
  },
  logoMargin: {
    marginTop: '12px',
  },
  logoImage: {
    width: '110px',
  },
  back: {
    color: '#fff',
    lineHeight: '43px',
    paddingLeft: '10px',
  },
  textDecorationNone: {
    textDecoration: 'none',
  },
  actionDisable: {
    opacity: '0.3',
  },
  backArea: {
    height: '15px',
    verticalAlign: 'middle',
    margin: '0px 10px 0px 5px',
  },
  closeArea: {
    height: '20px',
    verticalAlign: 'middle',
    margin: '0px 5px 0px 10px',
  },
  leftItem: {
    fill: '#fff',
  },
  leftItemText: {
    color: '#fff',
    verticalAlign: 'middle',
  },
  notificationsArea: {
    height: '18px',
    verticalAlign: 'middle',
    marginRight: '20px',
  },
  notifications: {
    fill: '#fff',
  },
  settingArea: {
    height: '25px',
    verticalAlign: 'middle',
    marginRight: '10px',
  },
  setting: {
    fill: '#fff',
  },
});

const renderHeader = (type, requesting, lastBillingDate, intl) => {
  const buttonImage = (
    <div style={requesting ? { ...style.back, ...style.actionDisable } : style.back}>
      <svg style={style.backArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.16 18">
        <path
          style={style.leftItem}
          d="M6.65,10.78,4.54,9,6.65,7.22l4.88-4.09A1.75,1.75,0,0,0,11.74.64,1.76,1.76,0,0,0,9.24.42L.63,7.64a.12.12,0,0,1-.05.05l-.11.12s0,0,0,.05l0,0A.56.56,0,0,0,.35,8l-.09.13a.35.35,0,0,0-.05.08.59.59,0,0,0,0,.08l-.06.14s0,.07,0,.11,0,.05,0,.08a.89.89,0,0,0,0,.14.52.52,0,0,0,0,.11v.1S0,9,0,9s0,.05,0,.07v.1a.43.43,0,0,0,0,.11.89.89,0,0,0,0,.14s0,.06,0,.09l0,.09.06.15a.59.59,0,0,0,0,.08.35.35,0,0,0,.05.08L.35,10l.05.08s0,0,0,0l0,0,.11.12.05,0,8.61,7.23a1.78,1.78,0,0,0,2.5-.22,1.76,1.76,0,0,0-.21-2.5Z"
        />
      </svg>
      <span style={style.leftItemText}>{intl.formatMessage({ id: 'headerBar.back' })}</span>
    </div>
  );

  return (
    <div>
      {type === 'top' || type === 'error' ? (
        <div onClick={() => window.sendMessageToNative('close')} style={style.headerItem}>
          <span style={style.headerItemSvg}>
            <svg style={style.closeArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <path
                style={style.leftItem}
                d="M112.73,100l53.63-53.64a9,9,0,1,0-12.72-12.72L100,87.27,46.36,33.64A9,9,0,0,0,33.64,46.36L87.27,100,33.64,153.64a9,9,0,1,0,12.72,12.72L100,112.73l53.64,53.63a9,9,0,0,0,12.72-12.72Z"
              />
            </svg>
            <span style={style.leftItemText}>{intl.formatMessage({ id: 'headerBar.close' })}</span>
          </span>
        </div>
      ) : (
        <div style={style.headerItem}>
          {requesting || type === 'questionnaireForce' ? null : (
            <Link
              to={
                type === 'questionnaire' && lastBillingDate ? `/report/${lastBillingDate}` : '/top'
              }
              className="TopPage"
              style={style.textDecorationNone}
            >
              {buttonImage}
            </Link>
          )}
        </div>
      )}
      <div style={style.headerItemRight}>
        <span
          onClick={() => window.sendMessageToNative('moveToNotifications')}
          style={style.headerItemSvg}
        >
          <svg
            style={style.notificationsArea}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 26"
          >
            <rect style={style.notifications} width="36" height="2" rx="1" />
            <rect style={style.notifications} y="12" width="36" height="2" rx="1" />
            <rect style={style.notifications} y="24" width="36" height="2" rx="1" />
          </svg>
        </span>
        <span
          onClick={() => window.sendMessageToNative('moveToSettings')}
          style={style.headerItemSvg}
        >
          <svg style={style.settingArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
            <path
              style={style.setting}
              d="M20,30.79A10.79,10.79,0,1,1,30.79,20,10.8,10.8,0,0,1,20,30.79ZM20,11a9,9,0,1,0,9,9A9,9,0,0,0,20,11Z"
            />
            <path
              style={style.setting}
              d="M24.41,40H15.59l-1-3.65a17,17,0,0,1-2.41-1L9,37.11,2.81,30.88l1.8-3.19a17.23,17.23,0,0,1-.91-2.22l-3.7-1V15.63l3.7-1a15.89,15.89,0,0,1,.84-2.06L2.67,9.25,8.91,3l3.15,1.8a17.74,17.74,0,0,1,2.5-1.07l1-3.74h8.82l1,3.74a18.73,18.73,0,0,1,2.4,1l3.23-1.83L37.3,9.15l-1.88,3.32a16.59,16.59,0,0,1,.88,2.14l3.7,1v8.82l-3.7,1a17.12,17.12,0,0,1-.95,2.3L37.17,31l-6.24,6.23-3.2-1.82a16.07,16.07,0,0,1-2.31,1ZM17,38.19H23l.91-3.28.47-.14a15.13,15.13,0,0,0,2.9-1.2l.45-.24L30.63,35l4.28-4.29L33.28,27.8l.24-.44a14.67,14.67,0,0,0,1.2-2.89l.15-.48,3.32-.92V17l-3.32-.92-.15-.47a15.26,15.26,0,0,0-1.12-2.75l-.24-.44,1.69-3L30.76,5.17l-2.9,1.65-.45-.24a15.23,15.23,0,0,0-3-1.26L24,5.18,23,1.81H17L16,5.18l-.48.14a15.33,15.33,0,0,0-3.07,1.31l-.44.25L9.21,5.27,4.92,9.55,6.6,12.5l-.23.44a14.77,14.77,0,0,0-1.09,2.68l-.15.47L1.81,17v6.06L5.13,24l.15.48a15.07,15.07,0,0,0,1.16,2.82l.24.44L5.06,30.57l4.29,4.29,2.8-1.59.44.24a15.4,15.4,0,0,0,3,1.26l.48.14Z"
            />
          </svg>
        </span>
      </div>
    </div>
  );
};

type Props = {
  pageType: string,
  chartRequesting: boolean,
  lastBillingDate: string,
  intl: intlShape,
};

export const PureHeaderBar = ({
  pageType,
  chartRequesting,
  lastBillingDate,
  intl,
}: Props): Node => (
  <div style={style.headerFixed}>
    <div style={style.headerBar}>
      {renderHeader(pageType, chartRequesting, lastBillingDate, intl)}
    </div>
  </div>
);

PureHeaderBar.defaultProps = {
  chartRequesting: false,
};

const mapStateToProps = (state) => ({
  chartRequesting: state.energy[timeUnitStyle[subTimeUnit[state.chartTime.unit]].toRequesting],
  lastBillingDate: state.userInfo.lastBillingDate,
});

const connectedPureHeaderBar: any = connect(mapStateToProps)(injectIntl(PureHeaderBar));
export default connectedPureHeaderBar;
