// @flow
/* eslint-disable import/prefer-default-export */
import React from 'react';
import type { Dispatch } from 'redux';
import { intlShape } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Prefixer from '../../lib/Prefixer';
import { OPEN_POP_UP } from '../../actions/types';
import type { EnergyPerDayDetails } from '../../lib/types/priceInfoTypes';
import loadingAnime from '../../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  loadingAnime: {
    margin: '80px 0px',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#bbb',
    float: 'right',
  },
  chartTitle: {
    verticalAlign: 'middle',
  },
  chartBody: {
    marginTop: '10px',
  },
});

export const isNoBuyEnergyData = (energyList: { buyEnergy: number[] }[]) =>
  !energyList.filter((v) => v.buyEnergy.filter((energy) => energy > 0).length).length;

export const hasEnergy = (
  energy: EnergyPerDayDetails[],
  target: 'buyEnergy' | 'sellEnergy' | 'consumeEnergy'
): boolean => (energy || []).some((v) => (v[target] || []).some((value) => value > 0));

export const ChartLoading = ({ intl }: { intl: intlShape }) => (
  <div>
    <div style={style.loadingAnime}>
      <img src={loadingAnime} alt="loading" />
    </div>
    <div>{intl.formatMessage({ id: 'report.loading' })}</div>
  </div>
);

export const ChartInfo = ({
  dispatch,
  contentType,
}: {
  dispatch: Dispatch,
  contentType: string,
}) => (
  <div style={style.info}>
    <Icon
      icon={faQuestionCircle}
      style={style.infoButton}
      onClick={(e) => dispatch({ type: OPEN_POP_UP, contentType, target: e.target })}
    />
  </div>
);

export const ChartTitle = ({ id, intl }: { id: string, intl: intlShape }) => (
  <div>
    <span style={style.chartTitle}>{intl.formatMessage({ id })}</span>
  </div>
);

export const ChartBody = ({ children }: { children: any }) => (
  <div style={style.chartBody}>{children}</div>
);
