// @flow

import type { State, Action } from 'redux';
import {
  REQUEST_API_LOGIN_INFO,
  SUCCESS_API_LOGIN_INFO,
  FAILURE_API_LOGIN_INFO,
  REQUEST_API_LOGIN,
  SUCCESS_API_LOGIN,
  FAILURE_API_LOGIN,
  FORCIBLY_TRANSITION_TO_LOGIN,
} from '../actions/types';

const initialState = {
  initialized: false,
  requesting: false,
  loginState: 'NONE', // 'SUCCESS', 'FAILED', 'UNAUTHORIZED', 'REQUESTING', 'INFO_REQUESTING', 'NONE', 'LOADING_LOGIN'
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REQUEST_API_LOGIN_INFO: {
      return {
        ...state,
        initialized: true,
        loginState: 'INFO_REQUESTING',
      };
    }
    case REQUEST_API_LOGIN: {
      return {
        ...state,
        requesting: true,
        loginState: 'REQUESTING',
      };
    }
    case SUCCESS_API_LOGIN_INFO: {
      if (action.payload.result) {
        if (action.payload.status === 401 || action.payload.status === 403) {
          return {
            ...state,
            initialized: true,
            loginState: 'UNAUTHORIZED',
          };
        }
        return {
          ...state,
          loginState: 'SUCCESS',
        };
      }
      if (!action.payload.result) {
        return {
          ...state,
          loginState: 'LOADING_LOGIN',
        };
      }
      return {
        ...state,
      };
    }
    case SUCCESS_API_LOGIN: {
      return {
        ...state,
        requesting: true,
        loginState: 'SUCCESS',
      };
    }
    case FAILURE_API_LOGIN_INFO: {
      if (action.payload.status === 401) {
        return {
          ...state,
          initialized: true,
          loginState: 'UNAUTHORIZED',
        };
      }
      return {
        ...state,
        initialized: true,
        loginState: 'FAILED',
      };
    }
    case FAILURE_API_LOGIN: {
      return {
        ...state,
        requesting: true,
        loginState: 'FAILED',
      };
    }
    case FORCIBLY_TRANSITION_TO_LOGIN: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
