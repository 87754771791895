// @flow

import React from 'react';
import type { Node } from 'react';
import { intlShape } from 'react-intl';
import { switchImage } from '../lib/common/globalUtils';
import Prefixer from '../lib/Prefixer';

import bgOff from '../images/today_detail_content_box_bg_off.png';
import bgOnDefault from '../images/today_detail_content_box_bg_on_default.png';
import bgOnUsing from '../images/today_detail_content_box_bg_on_using.png';
import search from '../images/icn_search.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  applianceState: {
    position: 'relative',
    margin: '10px 5px 0px 5px',
  },
  notUsing: {
    opacity: '0.6',
  },
  state: {
    width: '85%',
    height: '85%',
    display: 'block',
    margin: 'auto',
  },
  appliance: {
    position: 'absolute',
    left: '20%',
    top: '16%',
    width: '60%',
  },
  usageInMinutes: {
    marginTop: '3px',
    marginBottom: '0px',
    fontSize: '0.7em',
    color: '#ff8f62',
  },
});

const renderLearningUsing = () => (
  <div>
    <div style={style.applianceState}>
      <img src={bgOnDefault} alt="learning-using" style={style.state} />
      <img src={search} alt="search" style={style.appliance} />
    </div>
    <div style={style.usageInMinutes}>&nbsp;</div>
  </div>
);

const renderLearningNotUsing = () => (
  <div>
    <div style={{ ...style.applianceState, ...style.notUsing }}>
      <img src={bgOff} alt="learning-not-using" style={style.state} />
      <img src={search} alt="search" style={style.appliance} />
    </div>
    <div style={style.usageInMinutes}>&nbsp;</div>
  </div>
);

/**
 * estimated、observed で判定を分ける
 * 現状 observed の中にのみチャージ可能な電化製品が含まれるため、そこも出し分ける
 */
const getImages = (id): { bgImage: string, icnImage: string } => ({
  bgImage: bgOnUsing,
  icnImage: switchImage(id),
});

const renderUsing = (id) => {
  const { bgImage, icnImage } = getImages(id);
  return (
    <div>
      <div style={style.applianceState}>
        <img src={bgImage} alt="using" style={style.state} />
        <img src={icnImage} alt="appliance" style={style.appliance} />
      </div>
      <div style={style.usageInMinutes}>&nbsp;</div>
    </div>
  );
};

const renderNotUsing = (id) => (
  <div>
    <div style={{ ...style.applianceState, ...style.notUsing }}>
      <img src={bgOff} alt="not-using" style={style.state} />
      <img src={switchImage(id)} alt="appliance" style={style.appliance} />
    </div>
    <div style={style.usageInMinutes}>&nbsp;</div>
  </div>
);

const renderIcon = (learning, appliance) => {
  if (learning && appliance.isUsed) {
    return renderLearningUsing();
  }
  if (learning) {
    return renderLearningNotUsing();
  }
  if (appliance.isUsed) {
    return renderUsing(appliance.id);
  }
  return renderNotUsing(appliance.id);
};

type Props = {
  learning: boolean,
  display: boolean,
  appliance: {
    id: string,
    isUsed: boolean,
  },
  serviceProviderId: string,
  intl: intlShape,
};

const TopHourlyApplianceStateItem = ({ learning, display, appliance }: Props): Node => (
  <div>{display && renderIcon(learning, appliance)}</div>
);

export default TopHourlyApplianceStateItem;
