// @flow

import React from 'react';
import { intlShape } from 'react-intl';
import { VictoryPie, VictoryLabel } from 'victory';
import Prefixer from '../../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  chart: { width: '100%', display: 'table', marginTop: '-10px' },
  message: { fill: '#33b2b5', fontFamily: 'inherit', fontWeight: 'normal', fontSize: 15 },
  value: { fontFamily: 'inherit', fontSize: 36 },
});

const colorScales = ['#33b2b5', '#cfcfcf'];

type Props = {
  data: { x: number, y: number }[],
  messageId: string | null,
  intl: intlShape,
};

const PureChartPie = ({ intl, data, messageId }: Props) => {
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const currency = intl.formatNumber(data[0].y, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

  return (
    <div style={style.chart}>
      <svg viewBox="40 40 320 160">
        <VictoryPie
          standalone={false}
          colorScale={colorScales}
          startAngle={270}
          endAngle={450}
          innerRadius={120}
          labels={() => null}
          data={data}
        />
        {messageId && (
          <VictoryLabel
            key="balance"
            textAnchor="middle"
            text={intl.formatMessage({ id: messageId })}
            x={200}
            y={130}
            style={style.message}
          />
        )}
        <VictoryLabel
          key="currency"
          textAnchor="middle"
          text={intl.formatMessage({ id: 'report.currencyPrefix' }, { currency })}
          x={200}
          y={messageId ? 170 : 160}
          style={style.value}
        />
      </svg>
    </div>
  );
};

export default PureChartPie;
