/* eslint-env browser */
// @flow

import type { Middleware, Action } from 'redux';
import { REQUEST_API_LOGIN, SUCCESS_API_LOGIN, FAILURE_API_LOGIN } from '../../actions/types';

export const CALL_GA = Symbol('Call Google Analytics');

const tryLogin = () => {
  window.gtag('event', 'login', {
    action: 'try',
    label: window.location.host,
  });
};

const loginFailed = () => {
  window.gtag('event', 'login', {
    action: 'failed',
    label: window.location.host,
  });
};

export const gaReduxMiddleware = () => (next: Middleware) => (action: Action) => {
  if (action.type === SUCCESS_API_LOGIN) {
    const success = action.payload && action.payload.result;
    if (!success) {
      loginFailed();
    }
  } else if (action.type === REQUEST_API_LOGIN) {
    tryLogin();
  }
  if (action.type === FAILURE_API_LOGIN) {
    loginFailed();
  }

  const callGA = action[CALL_GA];
  if (!callGA) {
    return next(action);
  }

  return next(action);
};
