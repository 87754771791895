// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import { injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import ChartNoData, { CHART_NO_DATA } from '../ChartNoData';
import ChartSubUnitChart from '../ChartSubUnitChart';
import Prefixer from '../../lib/Prefixer';
import { ChartBody, ChartLoading, ChartTitle, hasEnergy } from './Common';
import { calcBuyCharge, calcConsumedPrice } from '../../lib/price/calculatedPrice';
import type { EnergyPerDay } from '../../lib/types/electricTypes';
import type { UnitPrice } from '../../lib/types/priceInfoTypes';
import { calcTotalEnergy } from '../../lib/common/calculated';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  chartArea: {
    maxHeight: '320px',
    postion: 'relative',
  },
  message: { fontWeight: 'normal', fontSize: '0.9rem', textAlign: 'left', margin: '15px 8px 0' },
});

type Props = {
  requesting: boolean,
  dailyEnergies: EnergyPerDay[],
  intl: intlShape,
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
};

const renderBody = (
  requesting: boolean,
  intl: intlShape,
  dailyEnergies: EnergyPerDay[],
  unitPrices: UnitPrice[],
  consumedUnitPrice: number
) => {
  if (requesting) {
    return <ChartLoading intl={intl} />;
  }
  const weekEnergy = dailyEnergies.reduce((prev, next) => {
    const day = moment(next.period, 'YYYY-MM-DD').day();

    const buyCharge = calcBuyCharge(unitPrices, next);
    const consumeCharge = calcConsumedPrice(0, 24, consumedUnitPrice, next.data);

    return {
      ...prev,
      [day]: {
        data: [
          ...(prev[day] ? prev[day].data : []),
          {
            daily: next.period,
            buyEnergy: [buyCharge],
            consumeEnergy: [consumeCharge],
            sellEnergy: [0], // 使用はしないが、型と一致させるために追加
          },
        ],
      },
    };
  }, {});

  const unitEnergyList = Object.keys(weekEnergy).map(key => ({
    unit: 'week',
    period: key,
    startDate: '',
    endDate: '',
    data: weekEnergy[key]
      ? [
          {
            daily: '',
            buyEnergy: [
              calcTotalEnergy(weekEnergy[key].data, 'buyEnergy') / weekEnergy[key].data.length,
            ],
            consumeEnergy: [
              calcTotalEnergy(weekEnergy[key].data, 'consumeEnergy') / weekEnergy[key].data.length,
            ],
            sellEnergy: [0], // 使用はしないが、型と一致させるために追加
          },
        ]
      : [],
    isCalculated: true,
  }));

  if (hasEnergy(unitEnergyList, 'buyEnergy')) {
    return <ChartNoData type={CHART_NO_DATA} />;
  }
  return (
    <div>
      <ChartSubUnitChart unitEnergyList={unitEnergyList} unit="week" />
      <div style={style.message}>{intl.formatMessage({ id: 'report.weekly.text' })}</div>
    </div>
  );
};

export const PureChargeWeeklyAverageArea = ({
  requesting,
  dailyEnergies,
  intl,
  unitPrices,
  consumedUnitPrice,
}: Props) => (
  <div style={style.wrapper}>
    <ChartTitle id="report.weeklyTitle" intl={intl} />
    <ChartBody>
      {renderBody(requesting, intl, dailyEnergies, unitPrices, consumedUnitPrice)}
    </ChartBody>
  </div>
);

const mapStateToProps = state => ({
  unitPrices: state.userInfo.unitPrices,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChargeWeeklyAverageArea: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChargeWeeklyAverageArea))
);

export default connectedPureChargeWeeklyAverageArea;
