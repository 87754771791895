export const ENTER_APPLICATION = 'ENTER_APPLICATION';
export const FORCIBLY_TRANSITION_TO_LOGIN = 'FORCIBLY_TRANSITION_TO_LOGIN';

export const LOGIN_STATUS_200 = 'LOGIN_STATUS_200';
export const LOGIN_STATUS_500 = 'LOGIN_STATUS_500';

export const LOAD_TOP_PAGE = 'LOAD_TOP_PAGE';
export const LOAD_TOP_PAGE_BILLING_INFO = 'LOAD_TOP_PAGE_BILLING_INFO';
export const LOAD_CURRENT_WEIGHTING_PERIOD = 'LOAD_CURRENT_WEIGHTING_PERIOD';
export const LOAD_TOP_PAGE_LATEST_TWO_HOURS = 'LOAD_TOP_PAGE_LATEST_TWO_HOURS';
export const SELECT_NEXT_HOUR = 'SELECT_NEXT_HOUR';
export const SELECT_PREV_HOUR = 'SELECT_PREV_HOUR';
export const SELECT_CLICKED_HOUR = 'SELECT_CLICKED_HOUR';

export const SWITCH_APL_DISPLAY_FLAG = 'SWITCH_APL_DISPLAY_FLAG';

export const RESET_TUTORIAL = 'RESET_TUTORIAL';
export const TUTORIAL_FINISHED = 'TUTORIAL_FINISHED';

export const OPEN_ALERT = 'OPEN_ALERT';

export const OPEN_POP_UP = 'OPEN_POP_UP';
export const CLOSING_POP_UP = 'CLOSING_POP_UP';
export const CLOSED_POP_UP = 'CLOSED_POP_UP';

export const LOAD_CHART_PAGE = 'LOAD_CHART_PAGE';
export const RESET_CHART_PAGE = 'RESET_CHART_PAGE';
export const CHANGE_CHART_UNIT = 'CHANGE_CHART_UNIT';
export const CHANGE_CHART_SUB_PERIOD = 'CHANGE_CHART_SUB_PERIOD';
export const SELECT_NEXT_PERIOD = 'SELECT_NEXT_PERIOD';
export const SELECT_PREV_PERIOD = 'SELECT_PREV_PERIOD';

export const LOAD_REPORT_PAGE = 'LOAD_REPORT_PAGE';
export const RESET_REPORT_PAGE = 'RESET_REPORT_PAGE';

export const REQUEST_API_LOGIN_INFO = 'REQUEST_API_LOGIN_INFO';
export const SUCCESS_API_LOGIN_INFO = 'SUCCESS_API_LOGIN_INFO';
export const FAILURE_API_LOGIN_INFO = 'FAILURE_API_LOGIN_INFO';

export const REQUEST_API_LOGIN = 'REQUEST_API_LOGIN';
export const SUCCESS_API_LOGIN = 'SUCCESS_API_LOGIN';
export const FAILURE_API_LOGIN = 'FAILURE_API_LOGIN';

export const REQUEST_API_USER_INFO = 'REQUEST_API_USER_INFO';
export const SUCCESS_API_USER_INFO = 'SUCCESS_API_USER_INFO';
export const FAILURE_API_USER_INFO = 'FAILURE_API_USER_INFO';

export const REQUEST_API_USER_STATUS = 'REQUEST_API_USER_STATUS';
export const SUCCESS_API_USER_STATUS = 'SUCCESS_API_USER_STATUS';
export const FAILURE_API_USER_STATUS = 'FAILURE_API_USER_STATUS';

export const REQUEST_API_QUESTIONNAIRE = 'REQUEST_API_QUESTIONNAIRE';
export const SUCCESS_API_QUESTIONNAIRE = 'SUCCESS_API_QUESTIONNAIRE';
export const FAILURE_API_QUESTIONNAIRE = 'FAILURE_API_QUESTIONNAIRE';

export const REQUEST_API_OBJECTIVE_ENERGY = 'REQUEST_API_OBJECTIVE_ENERGY';
export const SUCCESS_API_OBJECTIVE_ENERGY = 'SUCCESS_API_OBJECTIVE_ENERGY';
export const FAILURE_API_OBJECTIVE_ENERGY = 'FAILURE_API_OBJECTIVE_ENERGY';

export const REQUEST_API_PICKUP = 'REQUEST_API_PICKUP';
export const SUCCESS_API_PICKUP = 'SUCCESS_API_PICKUP';
export const FAILURE_API_PICKUP = 'FAILURE_API_PICKUP';

export const REQUEST_API_PV_ALERT = 'REQUEST_API_PV_ALERT';
export const SUCCESS_API_PV_ALERT = 'SUCCESS_API_PV_ALERT';
export const FAILURE_API_PV_ALERT = 'FAILURE_API_PV_ALERT';

export const REQUEST_API_BILLING_FORECAST = 'REQUEST_API_BILLING_FORECAST';
export const SUCCESS_API_BILLING_FORECAST = 'SUCCESS_API_BILLING_FORECAST';
export const FAILURE_API_BILLING_FORECAST = 'FAILURE_API_BILLING_FORECAST';

export const REQUEST_API_BILLING_INFO = 'REQUEST_API_BILLING_INFO';
export const SUCCESS_API_BILLING_INFO = 'SUCCESS_API_BILLING_INFO';
export const FAILURE_API_BILLING_INFO = 'FAILURE_API_BILLING_INFO';

export const REQUEST_API_POWER_STATS = 'REQUEST_API_POWER_STATS';
export const SUCCESS_API_POWER_STATS = 'SUCCESS_API_POWER_STATS';
export const FAILURE_API_POWER_STATS = 'FAILURE_API_POWER_STATS';

export const REQUEST_API_USE_STATS = 'REQUEST_API_USE_STATS';
export const SUCCESS_API_USE_STATS = 'SUCCESS_API_USE_STATS';
export const FAILURE_API_USE_STATS = 'FAILURE_API_USE_STATS';

export const REQUEST_API_USE_STATS_HOURLY = 'REQUEST_API_USE_STATS_HOURLY';
export const SUCCESS_API_USE_STATS_HOURLY = 'SUCCESS_API_USE_STATS_HOURLY';
export const FAILURE_API_USE_STATS_HOURLY = 'FAILURE_API_USE_STATS_HOURLY';

export const REQUEST_API_ENERGY_HOURLY = 'REQUEST_API_ENERGY_HOURLY';
export const SUCCESS_API_ENERGY_HOURLY = 'SUCCESS_API_ENERGY_HOURLY';
export const FAILURE_API_ENERGY_HOURLY = 'FAILURE_API_ENERGY_HOURLY';
