// @flow

import type { Action } from 'redux';

import {
  SUCCESS_API_OBJECTIVE_ENERGY,
  FAILURE_API_OBJECTIVE_ENERGY,
  REQUEST_API_OBJECTIVE_ENERGY,
} from '../actions/types';

export type State = {
  initialized: boolean,
  requesting: boolean,
  data: {
    sts: number,
    ets: number,
    date: string,
    median: { appliances: { [key: string]: number }, whp: number } | null,
    rate: number,
  }[],
};

const initialState: State = {
  initialized: false,
  requesting: false,
  data: [],
};

export default (state: State = initialState, action: Action = {}): State & $Shape<State> => {
  switch (action.type) {
    case REQUEST_API_OBJECTIVE_ENERGY: {
      return { ...state, requesting: !action.error };
    }
    case SUCCESS_API_OBJECTIVE_ENERGY: {
      const { data = {} } = action.payload || {};
      const newData = [];
      if (data) {
        data.forEach(v => {
          newData.push({ sts: v.sts, ets: v.ets, date: v.date, median: v.median, rate: v.rate });
        });
      }
      const merged = [...state.data, ...newData];
      merged.sort((a, b) => a.sts - b.sts);
      return {
        ...state,
        initialized: true,
        requesting: false,
        data: merged,
      };
    }
    case FAILURE_API_OBJECTIVE_ENERGY: {
      return { ...state, initialized: true, requesting: false };
    }
    default: {
      return state;
    }
  }
};
