/* eslint-env browser */
// @flow

import type { State, Action } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';
import {
  SUCCESS_API_USER_INFO,
  FAILURE_API_LOGIN,
  SUCCESS_API_LOGIN_INFO,
  FAILURE_API_LOGIN_INFO,
  FAILURE_API_USER_STATUS,
  LOGIN_STATUS_200,
  LOGIN_STATUS_500,
} from '../actions/types';

const initialState = {
  statusCode: '200',
  errorCode: 500,
};

export default (state: State = initialState, action: Action = {}): State => {
  const { type, payload } = action;
  switch (type) {
    case REHYDRATE:
    case SUCCESS_API_USER_INFO: {
      return {
        ...state,
        haveShownStartDialog: payload.haveShownStartDialog,
        lastDateUnavailableShown: payload.lastDateUnavailableShown,
      };
    }
    case FAILURE_API_LOGIN:
    case SUCCESS_API_LOGIN_INFO:
    case FAILURE_API_LOGIN_INFO:
    case FAILURE_API_USER_STATUS: {
      if (action.payload.status === 200) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        errorCode: action.payload.status,
      };
    }
    case LOGIN_STATUS_200: {
      return {
        ...state,
        statusCode: '200',
      };
    }
    case LOGIN_STATUS_500: {
      return {
        ...state,
        statusCode: '500',
      };
    }
    default: {
      return state;
    }
  }
};
