export const timeUnitList = ['day', 'week', 'month', 'year'];

export const subTimeUnit = {
  day: 'day',
  week: 'day',
  month: 'week',
  year: 'month',
};

export const hourlyRequestUnit = {
  day: 'hour',
  week: 'day',
  month: 'day',
  year: 'day',
};

export const timeUnitStyle = {
  hour: { toUnit: 'hourly', toMulti: 'hours', toRequesting: 'hourlyRequesting' },
  day: { toUnit: 'daily', toMulti: 'days', toRequesting: 'dailyRequesting' },
  week: { toUnit: 'weekly', toMulti: 'weeks', toRequesting: 'weeklyRequesting' },
  month: { toUnit: 'monthly', toMulti: 'months', toRequesting: 'monthlyRequesting' },
  year: { toUnit: 'yearly', toMulti: 'years', toRequesting: 'yearlyRequesting' },
};

export const timeFormat = {
  hour: 'YYYY-MM-DD',
  day: 'YYYY-MM-DD',
  week: 'YYYY-MM-DD',
  month: 'YYYY-MM',
  year: 'YYYY',
};
