// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from 'victory';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup, gtagOpenTips } from './gtagEvent';
import Tips from './common/Tips';
import linkImg from '../../../images/pickup/water_saving_clothes_washer.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: { textAlign: 'center', fontSize: '18px' },
  digest: { fontSize: '14px', border: '1px solid #cfcfcf', padding: '10px 0' },
  digestText: { fontWeight: 'normal' },
  digestValue: { color: '#ff8f86', fontSize: '24px', marginLeft: '4px' },
  digestUnit: { margin: '0 4px' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  tipsTitle: { textAlign: 'left', margin: '8px 0', fontSize: '14px' },
  linkImg: { width: '100%', marginBottom: '10px' },
  legend: {
    textAlign: 'left',
    fontSize: '0.9em',
    marginTop: '0px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSelfIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendCompareIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendMargin: {
    marginLeft: 30,
  },
});

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#454545', padding: 5 },
};

const PureChart = ({
  counts,
  showSelfLegend,
  size: { width },
}: {
  counts: { x: number, y: number, color: string }[],
  showSelfLegend: boolean,
  width: number,
  size: { width: number },
}) => (
  <div>
    <div style={style.legend}>
      {showSelfLegend && <div style={{ ...style.legendIcon, ...style.legendSelfIcon }} />}
      {showSelfLegend && <span>あなたの家</span>}
      <div
        style={{
          ...style.legendIcon,
          ...style.legendCompareIcon,
          ...(showSelfLegend ? style.legendMargin : {}),
        }}
      />
      <span>ほかの家</span>
    </div>
    <VictoryChart
      theme={VictoryTheme.material}
      containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
      width={width}
      height={286}
      padding={{ right: 3, left: 3, bottom: 50 }}
      domainPadding={20}
    >
      <VictoryAxis
        dependentAxis
        tickCount={7}
        style={{
          grid: { strokeWidth: 1, stroke: '#ccc' },
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { fill: 'transparent' },
        }}
      />
      <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
      <VictoryBar
        style={{ data: { fill: ({ color }) => color, width: (width / counts.length) * 0.7 } }}
        data={counts.map((count) => ({
          x: count.x + 1,
          y: count.y,
          color: count.color,
        }))}
      />
      <VictoryAxis
        tickValues={counts.map((v) => v.x + 1)}
        tickFormat={counts.map((v) => (v.x % 5 === 0 ? v.x : ''))}
        style={xAxisStyle}
      />
    </VictoryChart>
  </div>
);

const Chart = sizeMe({ monitorHeight: true })(PureChart);

export type Props = {
  data: { self_count: number | null, total_count: number[] },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureWaterSavingClothesWasher = ({
  userInfo,
  data: { self_count, total_count },
}: Props) => {
  const utmCampaign = self_count === null ? 'sena' : self_count;
  const link = `https://heartonemall.com/iot-sentakuki/?utm_source=iotdroom&utm_campaign=${utmCampaign}&k=cn`;
  const counts = total_count.map((total, count) => ({
    x: count,
    y: total,
    color: self_count && self_count === count ? '#ff8f86' : '#cfcfcf',
  }));

  const openTips = (title: string) => {
    gtagOpenTips(
      title,
      userInfo,
      'water_saving_clothes_washer',
      self_count !== null ? self_count : 'null'
    );
  };

  gtagShowPickup(
    link,
    userInfo,
    'water_saving_clothes_washer',
    self_count !== null ? self_count : 'null'
  );
  return (
    <div>
      <p style={style.title}>みんなはどのくらい洗濯してる？</p>
      <Chart counts={counts} showSelfLegend={self_count !== null} />
      {self_count !== null && (
        <div style={style.digest}>
          <span style={style.digestText}>洗濯機を月に</span>
          <span style={style.digestValue}>{self_count}</span>
          <span style={style.digestUnit}>日</span>
          <span style={style.digestText}>使いました</span>
        </div>
      )}
      <div style={style.body}>
        {(self_count !== null && (
          <p>
            グラフは1ヶ月あたりの洗濯機利用日数と、それに対する家庭の分布です。あなたの家は色がついたところに含まれます。
          </p>
        )) || <p>グラフは1ヶ月あたりの洗濯機利用日数と、それに対する家庭の分布です。</p>}
      </div>
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) =>
          gtagClickPickup(
            e,
            link,
            userInfo,
            'water_saving_clothes_washer',
            self_count !== null ? self_count : 'null'
          )
        }
      >
        <img src={linkImg} style={style.linkImg} alt="bedding" />
      </a>
      <div style={style.tipsTitle}>洗濯機の豆知識をご紹介：</div>
      <Tips title="節約のポイント" onOpenClick={() => openTips('saving_points')}>
        洗濯機の光熱費、電気代より水道代の方がかかっていることをご存知ですか？使い方などにもよりますが、
        標準的な洗濯1回の場合、電気代が約2円、水道代が約22円と10倍も差があるという試算もあります。
        まとめて洗うことで頻度を減らす、お風呂の残り湯を使うなど、洗濯に使う水の量をどう減らすかが節約のポイントになりますよ。
      </Tips>
      <Tips title="効率のよい使い方" onOpenClick={() => openTips('efficient_usage')}>
        衣類などをどのような順番で洗濯槽に入れるか気にしたことはありますか？
        厚手の服やタオルなど水をよく含む衣類を底の方に、
        下着や小物など軽いものを上の方に入れると洗濯機が効率よく回転し、節水や節電につながります。また、詰め込みすぎにも注意が必要です。
        洗濯槽の8割程度が目安でそれ以上になると汚れが落ちきらない原因になることもありますよ。
      </Tips>
      <Tips title="環境への影響" onOpenClick={() => openTips('environment_influence')}>
        洗濯機の寿命は8年程度。なるべく長く、大切に使いたいところですが、
        古い洗濯機と省エネ性能が向上した最新型を比較すると使用する電気や水の量に大きな差が出てしまうケースもあります。
        適切なタイミングで買い替えをすることが、結果として節電や節水などのエコ活動にもつながります。洗濯機の調子が悪いと感じた時は、
        修理だけでなく買い替えを検討してみるよいタイミングかもしれませんよ。
      </Tips>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureWaterSavingClothesWasher: any = connect(mapStateToProps)(
  PureWaterSavingClothesWasher
);
export default connectedPureWaterSavingClothesWasher;
