// @flow

import type { State, Action } from 'redux';
import moment from 'moment';
import { applianceId, timeUnitId } from '../lib/id';
import { supportedLocale } from '../lib/common/globalUtils';
import { rankingFormat } from '../lib/localizedValues';
import {
  ENTER_APPLICATION,
  REQUEST_API_POWER_STATS,
  SUCCESS_API_POWER_STATS,
  FAILURE_API_POWER_STATS,
  LOAD_TOP_PAGE,
  REQUEST_API_ENERGY_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
  FAILURE_API_ENERGY_HOURLY,
} from '../actions/types';

const initialState = {
  requesting: false,
  applianceEnergy: [],
  daily: [],
  weekly: [],
  monthly: [],
  yearly: [],
  locale: 'ja',
};

const getRankingFormat = locale =>
  supportedLocale.includes(locale) ? rankingFormat[locale] : rankingFormat.en;

const extractionAplEnergy = (payload, locale) => {
  const apls = payload.map(target => {
    const {
      apl_2 = [],
      apl_5 = [],
      apl_20 = [],
      apl_24 = [],
      apl_25 = [],
      apl_30 = [],
      apl_31 = [],
      apl_34 = [],
      apl_35 = [],
      apl_37 = [],
      apl_300 = [],
      apl_301 = [],
      apl_304 = [],
      apl_998 = [],
    } = target.data.consumed_energy_apl;
    return {
      unix: target.unix,
      apl_2,
      apl_5,
      apl_20,
      apl_24,
      apl_25,
      apl_30,
      apl_31,
      apl_34,
      apl_35,
      apl_37,
      apl_300,
      apl_301,
      apl_304,
      apl_998,
    };
  });

  const energyKey = (id): string => {
    switch (id) {
      case applianceId.airConditioner:
        return 'apl_2';
      case applianceId.clothesWasher:
        return 'apl_5';
      case applianceId.microwave:
        return 'apl_20';
      case applianceId.refrigerator:
        return 'apl_24';
      case applianceId.riceCooker:
        return 'apl_25';
      case applianceId.tv:
        return 'apl_30';
      case applianceId.vacuumCleaner:
        return 'apl_31';
      case applianceId.dishwasher:
        return 'apl_34';
      case applianceId.oven:
        return 'apl_35';
      case applianceId.ih:
        return 'apl_37';
      case applianceId.highFever:
        return 'apl_300';
      case applianceId.heater:
        return 'apl_301';
      case applianceId.light:
        return 'apl_304';
      case applianceId.background:
        return 'apl_998';
      default:
        return '';
    }
  };

  return getRankingFormat(locale).map(appliance => ({
    ...appliance,
    data: [
      ...appliance.data,
      ...apls.map(
        apl =>
          (apl[energyKey(appliance.id)] && {
            unix: apl.unix,
            energy: apl[energyKey(appliance.id)],
          }) || {
            unix: apl.unix,
            energy: [],
          }
      ),
    ],
  }));
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case ENTER_APPLICATION: {
      return {
        ...state,
        applianceEnergy: getRankingFormat(action.locale),
        locale: action.locale,
      };
    }
    case REQUEST_API_POWER_STATS: {
      const requesting = !action.error;
      return {
        ...state,
        requesting,
      };
    }
    case SUCCESS_API_POWER_STATS: {
      const { power = {} } = action.payload || {};
      const energy = id => {
        if (id === applianceId.highFever) {
          return (power[id] || 0) + (power[applianceId.heater] || 0);
        }
        return power[id] || 0;
      };
      const compareNumbers = (a, b) => b.energy - a.energy;
      const applianceEnergy = getRankingFormat(state.locale)
        .map(appliance => ({
          ...appliance,
          energy: energy(appliance.id),
        }))
        .sort(compareNumbers);
      return {
        ...state,
        requesting: false,
        applianceEnergy,
      };
    }
    case FAILURE_API_POWER_STATS: {
      return {
        ...state,
        requesting: false,
      };
    }
    case LOAD_TOP_PAGE: {
      return {
        ...state,
        requesting: false,
        weekly: [],
        monthly: [],
        yearly: [],
        applianceEnergy: [],
      };
    }
    case REQUEST_API_ENERGY_HOURLY: {
      const requesting = !action.error;
      return {
        ...state,
        requesting,
      };
    }
    case SUCCESS_API_ENERGY_HOURLY: {
      const { payload = {}, meta = {} } = action || {};
      switch (meta.reqUnit) {
        case timeUnitId.day: {
          if (state.daily.find(item => meta.dateTime === item.dateTime)) {
            return {
              ...state,
              requesting: false,
            };
          }
          const applianceEnergy = extractionAplEnergy(payload, state.locale);
          const dailyArray = [
            ...state.daily,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];
          return {
            ...state,
            requesting: false,
            daily: dailyArray,
          };
        }
        case timeUnitId.week: {
          const { dateTimeList = [] } = meta;

          if (
            (state.weekly.find(item => meta.dateTime === item.dateTime) && !meta.subUnitFlg) ||
            (meta.subUnitFlg &&
              meta.subUnitFlg &&
              dateTimeList.every(dateTime => state.weekly.find(item => dateTime === item.dateTime)))
          ) {
            return {
              ...state,
              requesting: false,
            };
          }

          if (meta.subUnitFlg) {
            const weeklyArray = dateTimeList.map((dateTime, i) => {
              const filter = payload.filter(
                target =>
                  moment(dateTime, 'YYYY-MM-DD').unix() <= target.unix &&
                  (!dateTimeList[i + 1] ||
                    moment(dateTimeList[i + 1], 'YYYY-MM-DD').unix() > target.unix)
              );
              const applianceEnergy = extractionAplEnergy(filter, state.locale);
              return {
                dateTime,
                applianceEnergy,
              };
            });
            return {
              ...state,
              requesting: false,
              weekly: [...state.weekly, ...weeklyArray],
            };
          }

          const applianceEnergy = extractionAplEnergy(payload, state.locale);
          const weeklyArray = [
            ...state.weekly,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];
          return {
            ...state,
            requesting: false,
            weekly: weeklyArray,
          };
        }
        case timeUnitId.month: {
          const { dateTimeList = [] } = meta;
          if (
            (state.monthly.find(item => meta.dateTime === item.dateTime) && !meta.subUnitFlg) ||
            (meta.subUnitFlg &&
              meta.subUnitFlg &&
              dateTimeList.every(dateTime =>
                state.monthly.find(item => dateTime === item.dateTime)
              ))
          ) {
            return {
              ...state,
              requesting: false,
            };
          }

          if (meta.subUnitFlg) {
            const monthlyArray = dateTimeList.map(dateTime => {
              const filter = payload.filter(target =>
                moment.unix(target.unix).isSame(moment(dateTime, 'YYYY-MM', 'months'))
              );
              const applianceEnergy = extractionAplEnergy(filter, state.locale);
              return {
                dateTime,
                applianceEnergy,
              };
            });
            return {
              ...state,
              requesting: false,
              monthly: [...state.monthly, ...monthlyArray],
            };
          }

          const applianceEnergy = extractionAplEnergy(payload, state.locale);
          const monthlyArray = [
            ...state.monthly,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];

          return {
            ...state,
            requesting: false,
            monthly: monthlyArray,
          };
        }
        case timeUnitId.year: {
          if (state.yearly.find(item => meta.dateTime === item.dateTime)) {
            return {
              ...state,
              requesting: false,
            };
          }
          const applianceEnergy = extractionAplEnergy(payload, state.locale);
          const yearlyArray = [
            ...state.yearly,
            {
              dateTime: meta.dateTime,
              applianceEnergy,
            },
          ];
          return {
            ...state,
            requesting: false,
            yearly: yearlyArray,
          };
        }
        default:
          return {
            ...state,
            requesting: false,
          };
      }
    }

    case FAILURE_API_ENERGY_HOURLY: {
      return {
        ...state,
        requesting: false,
      };
    }
    default: {
      return state;
    }
  }
};
