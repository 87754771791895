// @flow
/* eslint-env browser */

import moment from 'moment';
import { RSAA, getJSON } from 'redux-api-middleware';
import * as types from './types';
import { timeFormat } from '../lib/constants/timeUnit';
import { timeUnitId } from '../lib/id';

const endpoint = {
  loginInfo: '/api/v1/login-info',
  login: '/api/v1/login',
  userInfo: '/api/v1/user/info',
  userStatus: '/api/v1/user/status',
  billingForecast: '/api/v1/billing/forecast',
  billingInfo: '/api/v1/billing/info',
  useStats: '/api/v1/use/stats',
  useStatsHourly: '/api/v1/use/stats/hourly',
  energyHourly: '/api/v1/energy/hourly',
  questionnaire: '/api/v1/questionnaire',
  objectiveEnergy: '/api/v1/objective-energy',
  pickup: '/api/v1/pickup',
  pvAlert: '/api/v1/pv-alert',
};

let xsrfToken;

const getHeaders = () => ({
  'Content-Type': 'application/json',
  'X-XSRF-TOKEN': xsrfToken,
});

const payload = (action, state, res) => {
  xsrfToken = res.headers.get('X-XSRF-TOKEN') || xsrfToken;
  return getJSON(res);
};

export const apiCheckLogin = (): Object => ({
  [RSAA]: {
    endpoint: endpoint.loginInfo,
    method: 'POST',
    headers: getHeaders(),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_LOGIN_INFO,
      {
        type: types.SUCCESS_API_LOGIN_INFO,
        payload,
      },
      types.FAILURE_API_LOGIN_INFO,
    ],
  },
});

export const apiLoginExecute = (token: string): Object => ({
  [RSAA]: {
    endpoint: endpoint.login,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ token }),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_LOGIN,
      {
        type: types.SUCCESS_API_LOGIN,
        payload,
      },
      types.FAILURE_API_LOGIN,
    ],
  },
});

export const apiGetUserInfo = (): Object => ({
  [RSAA]: {
    endpoint: endpoint.userInfo,
    method: 'POST',
    headers: getHeaders(),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_USER_INFO,
      {
        type: types.SUCCESS_API_USER_INFO,
        payload,
      },
      types.FAILURE_API_USER_INFO,
    ],
  },
});

export const apiGetUserStatus = (): Object => ({
  [RSAA]: {
    endpoint: endpoint.userStatus,
    method: 'POST',
    headers: getHeaders(),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_USER_STATUS,
      {
        type: types.SUCCESS_API_USER_STATUS,
        payload,
      },
      types.FAILURE_API_USER_STATUS,
    ],
  },
});

export const apiPostQuestionnaire = (questionnaire: Object): Object => ({
  [RSAA]: {
    endpoint: endpoint.questionnaire,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(questionnaire),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_QUESTIONNAIRE,
      { type: types.SUCCESS_API_QUESTIONNAIRE, payload },
      types.FAILURE_API_QUESTIONNAIRE,
    ],
  },
});

export const apiGetObjectiveEnergy = (
  data: { sts: number, ets: number, date: string }[]
): Object => ({
  [RSAA]: {
    endpoint: endpoint.objectiveEnergy,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(data),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_OBJECTIVE_ENERGY,
      { type: types.SUCCESS_API_OBJECTIVE_ENERGY, payload },
      types.FAILURE_API_OBJECTIVE_ENERGY,
    ],
  },
});

export const apiGetPickup = (month: string): Object => ({
  [RSAA]: {
    endpoint: endpoint.pickup,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ month }),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_PICKUP,
      { type: types.SUCCESS_API_PICKUP, payload },
      types.FAILURE_API_PICKUP,
    ],
  },
});

export const apiGetPvAlert = (): Object => ({
  [RSAA]: {
    endpoint: endpoint.pvAlert,
    method: 'POST',
    headers: getHeaders(),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_PV_ALERT,
      { type: types.SUCCESS_API_PV_ALERT, payload },
      types.FAILURE_API_PV_ALERT,
    ],
  },
});

export const apiGetBillingInfo = (sts: number, ets: number): Object => ({
  [RSAA]: {
    endpoint: endpoint.billingInfo,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ sts, ets }),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_BILLING_INFO,
      {
        type: types.SUCCESS_API_BILLING_INFO,
        payload,
      },
      types.FAILURE_API_BILLING_INFO,
    ],
  },
});

export const apiGetBillingForecast = (sts: number, ets: number): Object => ({
  [RSAA]: {
    endpoint: endpoint.billingForecast,
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({ sts, ets }),
    credentials: 'same-origin',
    types: [
      types.REQUEST_API_BILLING_FORECAST,
      {
        type: types.SUCCESS_API_BILLING_FORECAST,
        payload,
      },
      types.FAILURE_API_BILLING_FORECAST,
    ],
  },
});

export const apiGetUseStatsHourly = (hours: number): Object => {
  const now = moment();
  const ets = now.unix();
  const sts = now
    .subtract(hours, 'hours')
    .startOf('hour')
    .unix();
  return {
    [RSAA]: {
      endpoint: endpoint.useStatsHourly,
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ sts, ets }),
      credentials: 'same-origin',
      types: [
        types.REQUEST_API_USE_STATS_HOURLY,
        {
          type: types.SUCCESS_API_USE_STATS_HOURLY,
          payload,
        },
        types.FAILURE_API_USE_STATS_HOURLY,
      ],
    },
  };
};

export const apiGetUseStatsForLastYear = (): Object => {
  const now = moment();
  const ets = now.unix();
  const sts = now.subtract(1, 'years').unix();
  return {
    [RSAA]: {
      endpoint: endpoint.useStats,
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ sts, ets, timeUnit: timeUnitId.month }),
      credentials: 'same-origin',
      types: [
        types.REQUEST_API_USE_STATS,
        {
          type: types.SUCCESS_API_USE_STATS,
          payload,
        },
        types.FAILURE_API_USE_STATS,
      ],
    },
  };
};

export const apiGetEnergyHourly = (
  unitForMeta: { reqUnit: string, subUnitFlg: boolean },
  dateTime: string,
  timeUnit: string,
  sts: number,
  ets: number,
  dateTimeList: string[] = [],
  weighingPeriodList: {
    startDate: string,
    endDate: string,
    billingMonth: string,
  }[] = []
): Object => {
  const { reqUnit, subUnitFlg } = unitForMeta;
  return {
    [RSAA]: {
      endpoint: endpoint.energyHourly,
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({ timeUnit, sts, ets }),
      credentials: 'same-origin',
      types: [
        {
          type: types.REQUEST_API_ENERGY_HOURLY,
          meta: { reqUnit },
        },
        {
          type: types.SUCCESS_API_ENERGY_HOURLY,
          meta: { dateTime, reqUnit, subUnitFlg, dateTimeList, weighingPeriodList },
          payload,
        },
        {
          type: types.FAILURE_API_ENERGY_HOURLY,
          meta: { reqUnit },
        },
      ],
    },
  };
};
export const apiGetEnergyHourlyByUnit = (
  unitForMeta: { reqUnit: string, subUnitFlg: boolean },
  unit: string,
  requestUnit: string,
  period: string
): Object => {
  const format = timeFormat[unit];
  const dateTime = moment(period, format);
  return apiGetEnergyHourly(
    unitForMeta,
    moment(dateTime).format(format),
    requestUnit,
    dateTime.startOf(unit).unix(),
    dateTime.endOf(unit).unix() > moment().unix() ? moment().unix() : dateTime.endOf(unit).unix(),
    []
  );
};
export const apiGetEnergyHourlyBySubUnit = (
  meta: Object,
  unit: string,
  requestUnit: string,
  dateTimeList: string[]
): Object => {
  const format = timeFormat[unit];
  const formatDateTime = moment(dateTimeList[0], format);
  const lastIndex = dateTimeList.length;
  const createEts = () => {
    if (formatDateTime.endOf(unit).unix() > moment().unix()) {
      return moment().unix();
    }
    return unit === 'week'
      ? moment(dateTimeList[lastIndex - 1])
          .add(6, 'days')
          .unix()
      : moment(dateTimeList[lastIndex - 1])
          .endOf(unit)
          .unix();
  };
  return apiGetEnergyHourly(
    meta,
    moment(dateTimeList[0]).format(format),
    requestUnit,
    moment(dateTimeList[0]).unix(),
    createEts(),
    dateTimeList,
    []
  );
};
