// @flow
import moment from 'moment';

import { getWeighingPeriod, makeCurrentWeighingPeriod } from '../common/periodUtils';
import type { BillingData } from '../types/priceInfoTypes';

export const calcExcessAmount = (forecastValue: number, billingAmount: number): number =>
  ((forecastValue - billingAmount) / billingAmount) * 100;

export const floorForecastValue = (forecastValue: number): number => {
  if (forecastValue % 1000 === 0) {
    return forecastValue;
  }

  const hundredDigit = Math.floor(forecastValue / 1000);

  if (forecastValue % 1000 >= 500) {
    return hundredDigit * 1000 + 500;
  }
  if (forecastValue % 1000 < 500) {
    return hundredDigit * 1000;
  }
  return forecastValue;
};

export const isForecastExceeded = (
  billingData: BillingData[],
  lastBillingDate: string,
  accountStartDate: string,
  forecastValue: number
): number => {
  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingData,
    lastBillingDate,
    accountStartDate
  );

  if (currentWeighingPeriod) {
    const compareBillingData =
      getWeighingPeriod(
        billingData,
        moment(currentWeighingPeriod.billingMonth, 'YYYY-MM')
          .subtract(1, 'years')
          .format('YYYY-MM'),
        'month'
      ) ||
      getWeighingPeriod(
        billingData,
        moment(currentWeighingPeriod.billingMonth, 'YYYY-MM')
          .subtract(1, 'months')
          .format('YYYY-MM'),
        'month'
      );

    if (compareBillingData && forecastValue > compareBillingData.billingAmount) {
      return calcExcessAmount(forecastValue, compareBillingData.billingAmount);
    }
  }
  return 0;
};
