import React from 'react';
import PropTypes from 'prop-types';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { CHANGE_CHART_UNIT, RESET_CHART_PAGE } from '../actions/types';
import { latestPeriod, isOneHourPassed } from '../lib/chart/chartUtils';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  unitAreaFixed: {
    position: 'fixed',
    zIndex: '10000',
    left: '0px',
    right: '0px',
    top: '0px',
    height: '52px',
    width: '100%',
    marginTop: '43px',
    backgroundColor: '#ffffff',
  },
  unitCell: {
    width: '25%',
    height: '50px',
    display: 'inline-block',
    fontWeight: 'bold',
    color: '#c5c5c5',
    backgroundColor: '#ffffff',
    borderBottom: '2px solid #c5c5c5',
  },
  unitCellSelected: {
    borderBottom: '2px solid #298c8e',
  },
  unitTitle: {
    marginTop: '12px',
  },
  unitTitleSelected: {
    color: '#298c8e',
  },
  unitDisable: {
    opacity: '0.3',
    color: '#298c8e',
  },
});

const clickUnit = (
  requesting,
  unitEnergyList,
  subUnitEnergyList,
  unit,
  currentWeighingPeriod,
  currentTime,
  dispatch,
  router
) => {
  if (requesting) {
    return;
  }
  const period = latestPeriod(unit, currentWeighingPeriod);
  if (isOneHourPassed(currentTime)) {
    dispatch({ type: RESET_CHART_PAGE });
  }
  dispatch({
    type: CHANGE_CHART_UNIT,
    unit,
    period,
  });

  router.history.push(`/chart/${unit}/${period}`);
};

const unitTitle = (unit, intl) => {
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartTimeUnit.day' });
    case 'week':
      return intl.formatMessage({ id: 'chartTimeUnit.week' });
    case 'month':
      return intl.formatMessage({ id: 'chartTimeUnit.month' });
    case 'year':
      return intl.formatMessage({ id: 'chartTimeUnit.year' });
    default:
      return '';
  }
};

const unitStyle = (requesting, displayUnit, selectedUnit) => {
  if (requesting) {
    return { ...style.unitTitle, ...style.unitDisable };
  } 
   if (displayUnit === selectedUnit) {
    return { ...style.unitTitle, ...style.unitTitleSelected };
  }
  return style.unitTitle;
};

const cellStyle = (serviceProviderId, displayUnit, selectedUnit) =>
  displayUnit === selectedUnit ? { ...style.unitCell, ...style.unitCellSelected } : style.unitCell;

const renderUnitCell = (
  requesting,
  unitEnergyList,
  subUnitEnergyList,
  displayUnit,
  currentWeighingPeriod,
  selectedUnit,
  currentTime,
  dispatch,
  intl,
  serviceProviderId,
  router
) => (
  <div
    style={cellStyle(serviceProviderId, displayUnit, selectedUnit)}
    onClick={() =>
      clickUnit(
        requesting,
        unitEnergyList,
        subUnitEnergyList,
        displayUnit,
        currentWeighingPeriod,
        currentTime,
        dispatch,
        router
      )}
  >
    <div style={unitStyle(requesting, displayUnit, selectedUnit)}>
      {unitTitle(displayUnit, intl)}
    </div>
  </div>
);

type Props = {
  requesting: boolean,
  energyList: {
    daily: Array<{
      day?: string,
      buyEnergy: Array<number>,
    }>,
    weekly: Array<{
      week?: string,
      buyEnergy: Array<number>,
    }>,
    monthly: Array<{
      month?: string,
      buyEnergy: Array<number>,
    }>,
    yearly: Array<{
      yearly?: string,
      buyEnergy: Array<number>,
    }>,
  },
  currentWeighingPeriod: {
    startDate: string,
    endDate: string,
    billingMonth: string,
  },
  selectedUnit: string,
  currentTime: string,
  dispatch: Dispatch,
  intl: intlShape,
  serviceProviderId: string,
};

export const PureChartTimeUnit = (
  {
    requesting,
    energyList,
    currentWeighingPeriod,
    selectedUnit,
    currentTime,
    dispatch,
    intl,
    serviceProviderId,
  }: Props,
  { router }: Object
): Node => (
  <div style={style.unitAreaFixed}>
    {renderUnitCell(
      requesting,
      energyList.daily,
      energyList.hourly,
      'day',
      currentWeighingPeriod,
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
    {renderUnitCell(
      requesting,
      energyList.weekly,
      energyList.daily,
      'week',
      currentWeighingPeriod,
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
    {renderUnitCell(
      requesting,
      energyList.monthly,
      energyList.weekly,
      'month',
      currentWeighingPeriod,
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
    {renderUnitCell(
      requesting,
      energyList.yearly,
      energyList.monthly,
      'year',
      currentWeighingPeriod,
      selectedUnit,
      currentTime,
      dispatch,
      intl,
      serviceProviderId,
      router
    )}
  </div>
);

const mapStateToProps = state => ({
  energyList: {
    daily: state.energy.daily,
    weekly: state.energy.weekly,
    monthly: state.energy.monthly,
    yearly: state.energy.yearly,
  },
  currentWeighingPeriod: state.billingInfo.currentWeighingPeriod,
  selectedUnit: state.chartTime.unit,
  period: state.chartTime.period,
  currentTime: state.chartTime.currentTime,
  serviceProviderId: state.userInfo.serviceProviderId,
});

PureChartTimeUnit.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectedPureChartTimeUnit: any = connect(mapStateToProps)(injectIntl(PureChartTimeUnit));
export default connectedPureChartTimeUnit;
