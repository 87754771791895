// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import 'react-toggle/style.css';
import Prefixer from '../lib/Prefixer';
import HeaderBar from './HeaderBar';
import SettingAppliancePopup from './SettingAppliancePopup';
import { OPEN_POP_UP, RESET_TUTORIAL } from '../actions/types';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    paddingTop: '43px',
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '480px',
    margin: '0px auto',
    color: '#454545',
  },
  listItem: {
    padding: '0 10px 0 10px',
    margin: '0 0 0 0',
    borderTop: 'none',
    borderBottom: '1px solid #ddd',
    color: '#1f1f21',
    minHeight: '44px',
    lineHeight: '44px',
  },
  itemKey: {
    float: 'left',
  },
  itemValue: {
    textAlign: 'right',
  },
  button: {
    backgroundColor: '#298c8e',
    color: '#fff',
    width: '50px',
    height: '30px',
    borderRadius: '3px',
  },
  buttonItem: {
    float: 'right',
    fontSize: '0.9em',
    lineHeight: '30px',
    margin: '7px 0px',
    cursor: 'pointer',
  },
});

const renderStaticItem = (key, value) => (
  <div style={style.listItem}>
    <div style={style.itemKey}>{key}</div>
    <div style={style.itemValue}>{value}</div>
  </div>
);

const renderPopupItem = (key, contentType, dispatch, intl) => (
  <div style={style.listItem}>
    <div style={style.itemKey}>{key}</div>
    <div
      className={`${contentType}Button`}
      style={{ ...style.button, ...style.buttonItem }}
      onClick={() => dispatch({ type: OPEN_POP_UP, contentType })}
    >
      {intl.formatMessage({ id: 'settingPage.change' })}
    </div>
  </div>
);

const renderStartTutorial = (dispatch, intl) => (
  <div style={style.listItem}>
    <div style={style.itemKey}>{intl.formatMessage({ id: 'settingPage.tutorial' })}</div>
    <Link to="/tutorial" className="TutorialPage">
      <div
        style={{ ...style.button, ...style.buttonItem }}
        onClick={() => dispatch({ type: RESET_TUTORIAL })}
      >
        {intl.formatMessage({ id: 'settingPage.display' })}
      </div>
    </Link>
  </div>
);

declare var config: { URLS: Array<Object> };
const renderGoToFaq = (intl) => {
  const { FAQ } = config.URLS[intl.locale.toUpperCase()] || {};
  return (
    <div style={style.listItem}>
      <div style={style.itemKey}>{intl.formatMessage({ id: 'settingPage.faq' })}</div>
      <div>
        <a href={FAQ} rel="noopener noreferrer">
          <div style={{ ...style.button, ...style.buttonItem }}>
            {intl.formatMessage({ id: 'settingPage.display' })}
          </div>
        </a>
      </div>
    </div>
  );
};

type Props = {
  userSetting: {
    aplFilter: Array<{
      id: string,
      display: boolean,
    }>,
  },
  contractorId: string,
  macAddress: string,
  accountStartDate: string,
  userStatus: string,
  popup: {
    open?: boolean,
    contentType?: string,
  },
  dispatch: Dispatch,
  intl: intlShape,
};

declare var IMT4DL_VERSION: string;
export const PureSettingPage = ({
  contractorId,
  macAddress,
  accountStartDate,
  userStatus,
  popup,
  dispatch,
  intl,
}: Props): Node => (
  <div>
    <HeaderBar pageType="setting" />
    {popup.open && popup.contentType === 'aplsDisplay' && <SettingAppliancePopup />}
    <div style={style.content}>
      {renderStaticItem(intl.formatMessage({ id: 'settingPage.contractorId' }), contractorId)}
      {renderStaticItem(intl.formatMessage({ id: 'settingPage.macAddress' }), macAddress)}
      {renderStaticItem(
        intl.formatMessage({ id: 'settingPage.accountStartDate' }),
        accountStartDate
      )}
      {userStatus === 'normal'
        ? renderPopupItem(
            intl.formatMessage({ id: 'settingPage.aplsDisplay' }),
            'aplsDisplay',
            dispatch,
            intl
          )
        : ''}
      {renderStaticItem(intl.formatMessage({ id: 'settingPage.version' }), IMT4DL_VERSION)}
      {renderStartTutorial(dispatch, intl)}
      {renderGoToFaq(intl)}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  userSetting: state.userSetting,
  contractorId: state.userInfo.contractorId,
  macAddress: state.userInfo.macAddress,
  accountStartDate: state.userInfo.accountStartDate,
  userStatus: state.userInfo.status,
  popup: state.popup,
  dispatch: state.dispath,
});

const connectedPureSettingPage: any = connect(mapStateToProps)(injectIntl(PureSettingPage));
export default connectedPureSettingPage;
