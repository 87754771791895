// @flow

import React, { Component } from 'react';
import { Animate } from 'react-move';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  todayWp: {
    display: 'table',
    width: '98%',
    margin: '0px auto',
  },
  rightMargin: {
    width: '25%',
    display: 'table-cell',
    textAlign: 'left',
    verticalAlign: 'top',
  },
  button: {
    verticalAlign: 'top',
    background: '#298c8e',
    display: 'block',
    textAlign: 'center',
    borderRadius: '3px',
    color: '#fff',
    fontSize: '0.8em',
    padding: '8px 0px',
    cursor: 'pointer',
  },
});

type Props = {
  clickHandler: () => void,
  style: {
    marginTop?: string,
    marginBottom?: string,
  },
  children: any,
};

type State = {
  clicked: boolean,
};

export default class TopTimeBarUpDownButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    (this: any).click = this.click.bind(this);
    this.state = {
      clicked: false,
    };
  }

  click() {
    this.setState({ clicked: true });
    setTimeout(() => {
      this.setState({ clicked: false });
    }, 100);
    this.props.clickHandler();
  }

  render() {
    const inheritStyle = this.props.style || {};
    const origin = this.state.clicked ? { background: '#298c8e' } : { background: '#fff' };
    const target = this.state.clicked ? { background: '#fff' } : { background: '#298c8e' };
    return (
      <Animate default={origin} data={target} duration={100} easing="linear">
        {() => {
          return (
            <div style={style.todayWp}>
              <div
                data-testid="upDownButton"
                className="upDownButton"
                style={{ ...style.button, ...inheritStyle }}
                onClick={this.click}
              >
                {this.props.children}
              </div>
              <div style={style.rightMargin} />
            </div>
          );
        }}
      </Animate>
    );
  }
}
