// @flow
/* eslint-env browser */

import React from 'react';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import AuthArea from './AuthArea';
import RouteLogin from './RouteLogin';
import RouteTopPage from './RouteTopPage';
import RouteQuestionnairePage from './RouteQuestionnairePage';
import RouteChartPage from './RouteChartPage';
import RouteReportPage from './RouteReportPage';
import RouteSettingPage from './RouteSettingPage';
import RouteTutorialPage from './RouteTutorialPage';
import RouteErrorPage from './RouteErrorPage';
import AlertPopup from '../AlertPopup';
import HelpPopup from '../HelpPopup';
import ForceShowQuestionnaire from '../ForceShowQuestionnaire';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login">
        {withRouter(RouteLogin)}
      </Route>
      <Route path="/error">
        {withRouter(RouteErrorPage)}
      </Route>
      <AuthArea>
        <AlertPopup />
        <HelpPopup />
        <ForceShowQuestionnaire />
        <Switch>
          <Route path="/tutorial">
            <RouteTutorialPage />
          </Route>
          <Route path="/questionnaire">
            {withRouter(RouteQuestionnairePage)}
          </Route>
          <Route path="/chart/:unit?/:period?">
            {withRouter(RouteChartPage)}
          </Route>
          <Route path="/report/:period?">
            {withRouter(RouteReportPage)}
          </Route>
          <Route path="/setting">
            {withRouter(RouteSettingPage)}
          </Route>
          <Route path="/top">
            {withRouter(RouteTopPage)}
          </Route>
          <Redirect to="/top" />
        </Switch>
      </AuthArea>
    </Switch>
  </BrowserRouter>
);

export default Router;
