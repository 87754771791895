// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from 'victory';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup, gtagOpenTips } from './gtagEvent';
import Tips from './common/Tips';
import linkImg from '../../../images/pickup/high_performance_microwave.png';

const ROUND_THRESHOLD = 6;
const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: { textAlign: 'center', fontSize: '18px' },
  digest: { fontSize: '14px', border: '1px solid #cfcfcf', padding: '10px 0' },
  digestText: { fontWeight: 'normal' },
  digestValue: { color: '#ff8f86', fontSize: '24px', marginLeft: '4px' },
  digestUnit: { margin: '0 4px' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  tipsTitle: { textAlign: 'left', margin: '8px 0', fontSize: '14px' },
  linkImg: { width: '100%', marginBottom: '10px' },
  legend: {
    textAlign: 'left',
    fontSize: '0.9em',
    marginTop: '0px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSelfIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendCompareIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendMargin: {
    marginLeft: 30,
  },
});

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#454545', padding: 5 },
};

const PureChart = ({
  counts,
  size: { width },
}: {
  counts: { x: number, y: number, color: string }[],
  size: { width: number },
}) => (
  <div>
    <div style={style.legend}>
      <div style={{ ...style.legendIcon, ...style.legendSelfIcon }} />
      <span>あなたの家</span>
      <div
        style={{
          ...style.legendIcon,
          ...style.legendCompareIcon,
          ...style.legendMargin,
        }}
      />
      <span>ほかの家</span>
    </div>
    <VictoryChart
      theme={VictoryTheme.material}
      containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
      width={width}
      height={286}
      padding={{ right: 3, left: 3, bottom: 50 }}
      domainPadding={20}
    >
      <VictoryAxis
        dependentAxis
        tickCount={7}
        style={{
          grid: { strokeWidth: 1, stroke: '#ccc' },
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { fill: 'transparent' },
        }}
      />
      <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
      <VictoryBar
        style={{ data: { fill: ({ color }) => color, width: (width / counts.length) * 0.6 } }}
        data={counts.map((count) => ({
          x: count.x + 1,
          y: count.y,
          color: count.color,
        }))}
      />
      <VictoryAxis
        tickValues={counts.map((v) => v.x + 1)}
        tickFormat={counts.map((v) => (v.x >= ROUND_THRESHOLD ? `${v.x}以上` : `${v.x}`))}
        style={xAxisStyle}
      />
    </VictoryChart>
  </div>
);

const Chart = sizeMe({ monitorHeight: true })(PureChart);

export type Props = {
  data: { self_avg_count: number, avg_count: number[] },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureHighPerformanceMicrowave = ({
  userInfo,
  data: { self_avg_count, avg_count },
}: Props) => {
  const link = `https://heartonemall.com/iot-renji/?utm_source=iotdroom&utm_campaign=re${
    self_avg_count >= ROUND_THRESHOLD ? ROUND_THRESHOLD : self_avg_count
  }&k=cn`;
  const over6 = avg_count.slice(ROUND_THRESHOLD).reduce((p, n) => p + n, 0);
  const counts = [...avg_count.slice(0, 6), over6].map((total, count) => ({
    x: count,
    y: total,
    color:
      self_avg_count === count || (ROUND_THRESHOLD === count && self_avg_count >= ROUND_THRESHOLD)
        ? '#ff8f86'
        : '#cfcfcf',
  }));

  const openTips = (title: string) => {
    gtagOpenTips(title, userInfo, 'high_performance_microwave', self_avg_count);
  };

  gtagShowPickup(link, userInfo, 'high_performance_microwave', self_avg_count);

  return (
    <div>
      <p style={style.title}>みんなはどのくらい電子レンジを使ってる？</p>
      <Chart counts={counts} />
      <div style={style.digest}>
        <span style={style.digestText}>電子レンジを日に平均</span>
        <span style={style.digestValue}>{self_avg_count}</span>
        <span style={style.digestUnit}>回</span>
        <span style={style.digestText}>使いました</span>
      </div>
      <div style={style.body}>
        <p>
          グラフは1日あたりの電子レンジ平均使用回数と、それに対する家庭の分布です。あなたの家は色がついたところに含まれます。
        </p>
      </div>
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) =>
          gtagClickPickup(e, link, userInfo, 'high_performance_microwave', self_avg_count)
        }
      >
        <img src={linkImg} style={style.linkImg} alt="bedding" />
      </a>
      <div style={style.tipsTitle}>電子レンジの豆知識をご紹介：</div>
      <Tips title="節約のポイント" onOpenClick={() => openTips('saving_points')}>
        調理の下ごしらえをする時、ガスコンロよりも電子レンジを使って短時間で調理した方が光熱費節約につながる場合があります。
        目安としては少量の調理なら電子レンジ、量が多い場合はガスコンロという形で使い分けるのがオススメです。
        レンジで下ごしらえすることで、栄養分が水に溶け出すのを防いだり、スピード加熱で熱に弱いビタミンCの残存率を上げたりといった効果も期待できますよ。
      </Tips>
      <Tips title="効率のよい使い方" onOpenClick={() => openTips('efficient_usage')}>
        電子レンジの庫内が汚れていると、その汚れにもマイクロ波が反応して温めの効率が落ちてしまうことがあります。
        さらに発火など事故の原因になることもあるため、定期的なお掃除が必要です。頑固な汚れは耐熱ボウルなどに入れた水を3分ほど温め、
        しばらく庫内を蒸してから拭き掃除すると落ちやすくなりますよ。
      </Tips>
      <Tips title="環境への影響" onOpenClick={() => openTips('environment_influence')}>
        レンジで加熱するとき、ラップをどのように使っていますか。毎回ラップを使うのではなく、水分を逃さずしっとりさせたい食品はラップをして加熱、
        十分に水分がある食品やパリッとさせたい食品はラップなしで加熱など、できあがりの食感に応じて使い分けることで無駄なゴミの削減にもつながります。
        ラップの代わりに繰り返し使えるシリコンラップを活用してみるのもよいかもしれませんね。
      </Tips>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureHighPerformanceMicrowave: any = connect(mapStateToProps)(
  PureHighPerformanceMicrowave
);
export default connectedPureHighPerformanceMicrowave;
