// @flow

import React from 'react';
import moment from 'moment';
import type { Node } from 'react';
import { intlShape, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import type { Dispatch } from 'redux';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import TopNoData, { TOP_HOURLY_NO_DATA } from './TopNoData';
import Prefixer from '../lib/Prefixer';
import {
  calcPowerCharge,
  calcCheaperChargeByConsumed,
  calcSellCharge,
} from '../lib/price/calculatedPrice';
import { findUnitPrice } from '../lib/price/priceUtils';
import solarPng from '../images/icn_solar.png';
import buyPng from '../images/icn_buy.png';
import sellPng from '../images/icn_sell.png';
import { OPEN_POP_UP } from '../actions/types';
import { HELP_GENERATION_GAIN } from '../actions/helpPopupTypes';
import type { UnitPrice } from '../lib/types/priceInfoTypes';
import type { EnergyHourly } from '../lib/types/electricTypes';
import loadingAnime from '../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: {
    color: '#908572',
    textAlign: 'center',
    padding: '0px',
    margin: '10px 0px',
    fontSize: '0.8em',
    width: '100%',
    height: '25px',
    lineHeight: '25px',
    background: '#f2efe0',
    borderRadius: '30px',
    position: 'relative',
  },
  loadingWrapper: {
    marginBottom: '15px',
  },
  noData: {
    padding: '10px 0px',
    fontSize: '1.3em',
  },
  billing: {
    rightCell: {
      textAlign: 'right',
      energy: {
        fontWeight: 'lighter',
        opacity: '0.5',
        marginTop: '5px',
        fontSize: '0.8em',
      },
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '5px',
    paddingBottom: '3px',
    width: '20px',
  },
  chargeMessage: {
    fontSize: '1.0em',
  },
  buyArea: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px 0px',
    fontSize: '1.3em',
  },
  soldArea: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    marginTop: '15px',
    borderTop: '1px solid #cfcfcf',
    fontSize: '1.3em',
  },
  generatedMessage: {
    textAlign: 'left',
    padding: '10px 10px 15px',
    fontWeight: 'normal',
  },
  infoButton: {
    fontSize: '24px',
    float: 'right',
    color: 'rgb(177, 169, 154)',
    padding: '5px 10px 5px 0px',
    height: '60%',
    top: '0%',
    right: '10%',
    position: 'absolute',
    transform: 'translate(85%, 0%)',
  },
});

type Props = {
  hourlyEnergy?: EnergyHourly,
  selectedHour: number,
  requesting: boolean,
  intl: intlShape,
  unitPrices: UnitPrice,
  powerUnitPrice: number,
  fuelCostAdjustmentUnitPrice: number,
  renewableEnergy: number,
  sellingPattern: string,
  soldEnergyOwner: string,
  soldUnitPrice: number,
  consumedUnitPrice: number,
  dispatch: Dispatch,
};

const renderChargeArea = (
  hourlyEnergy?: EnergyHourly,
  selectedHour: number,
  requesting: boolean,
  intl: intlShape,
  unitPrices: UnitPrice[],
  sellingPattern: string,
  soldEnergyOwner: string,
  soldUnitPrice: number,
  consumedUnitPrice: number
) => {
  const { dateTime, buyEnergy = 0, sellEnergy = 0, consumeEnergy = 0 } = hourlyEnergy || {};

  if (requesting) {
    return (
      <div style={style.loadingWrapper}>
        <img src={loadingAnime} alt="loading" />
      </div>
    );
  }
  if (!dateTime || buyEnergy === 0) {
    return (
      <div style={style.noData}>
        <TopNoData type={TOP_HOURLY_NO_DATA} />
      </div>
    );
  }

  const currencyFractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const energyFractionDigits = intl.formatMessage({ id: 'energy.fractionDigits' });
  const buyCharge = calcPowerCharge(buyEnergy, findUnitPrice(moment(dateTime).unix(), unitPrices));
  const consumeCharge = consumeEnergy * consumedUnitPrice;
  const buyCurrency = intl.formatNumber(buyCharge + consumeCharge, {
    maximumFractionDigits: currencyFractionDigits,
    minimumFractionDigits: currencyFractionDigits,
  });
  const sellCurrency = intl.formatNumber(calcSellCharge(sellEnergy, soldUnitPrice), {
    maximumFractionDigits: currencyFractionDigits,
    minimumFractionDigits: currencyFractionDigits,
  });
  const cheaperByConsumedCurrency = intl.formatNumber(
    calcCheaperChargeByConsumed(
      findUnitPrice(moment(dateTime).unix(), unitPrices),
      consumeEnergy,
      consumedUnitPrice
    ),
    {
      maximumFractionDigits: currencyFractionDigits,
      minimumFractionDigits: currencyFractionDigits,
    }
  );
  // 買電・売電の電力量は少数第1位まで表示
  const displayBuyAndConsumeEnergy = intl.formatNumber(buyEnergy + consumeEnergy, {
    maximumFractionDigits: energyFractionDigits,
    minimumFractionDigits: energyFractionDigits,
  });
  const displaySellEnergy = intl.formatNumber(sellEnergy, {
    maximumFractionDigits: energyFractionDigits,
    minimumFractionDigits: energyFractionDigits,
  });

  const buyMessages = defineMessages({
    currency: {
      id: 'topHourlyCharge.buyCurrency',
      description: 'TOP下段買電の金額 £{buyCurrency}円',
    },
  });
  const sellMessages = defineMessages({
    currency: {
      id: 'topHourlyCharge.sellCurrency',
      description: 'TOP下段売電の金額 £{sellCurrency}円',
    },
  });
  const consumeMessages = defineMessages({
    currency: {
      id: 'topHourlyCharge.cheaperByConsumedCurrency',
      description: 'TOP下段自家消費によりお得になった金額 £{cheaperByConsumedCurrency}円',
    },
  });

  const buyAndConsumeEnergyKWh =
    soldEnergyOwner === 'resident' ? (
      <div>
        {displayBuyAndConsumeEnergy}
        {intl.formatMessage({ id: 'others.kWh' })}
      </div>
    ) : null;
  const sellEnergyKWh =
    soldEnergyOwner === 'resident' ? (
      <div>
        {displaySellEnergy}
        {intl.formatMessage({ id: 'others.kWh' })}
      </div>
    ) : null;

  const sellEnergyPrice =
    soldEnergyOwner === 'resident' ? (
      <div style={style.soldArea}>
        <div style={style.chargeMessage}>
          <img src={sellPng} alt="sell" style={style.icon} />
          {intl.formatMessage({ id: 'topHourlyCharge.sellEnergy' })}
        </div>
        <div style={style.billing.rightCell}>
          <div>+{intl.formatMessage(sellMessages.currency, { sellCurrency })}</div>
          <div style={style.billing.rightCell.energy}>{sellEnergyKWh}</div>
        </div>
      </div>
    ) : null;

  const cheaperByConsumedPrice =
    sellingPattern && soldEnergyOwner !== 'resident' && cheaperByConsumedCurrency > 0 ? (
      <div style={style.generatedMessage}>
        <img src={solarPng} alt="solar" style={style.icon} />
        {intl.formatMessage(consumeMessages.currency, {
          cheaperByConsumedCurrency,
        })}
      </div>
    ) : null;

  return (
    <div>
      <div style={style.buyArea}>
        <div style={style.chargeMessage}>
          {!!sellingPattern && (
            <>
              <img src={buyPng} alt="buy" style={style.icon} />
              {intl.formatMessage({ id: 'topHourlyCharge.buyEnergy' })}
            </>
          )}
        </div>
        <div style={style.billing.rightCell}>
          <div>{intl.formatMessage(buyMessages.currency, { buyCurrency })}</div>
          <div style={style.billing.rightCell.energy}>{buyAndConsumeEnergyKWh}</div>
        </div>
      </div>
      {sellEnergyPrice}
      {cheaperByConsumedPrice}
    </div>
  );
};

export const PureTopHourlyCharge = ({
  hourlyEnergy,
  selectedHour,
  requesting,
  intl,
  unitPrices,
  sellingPattern,
  soldEnergyOwner,
  soldUnitPrice,
  consumedUnitPrice,
  dispatch,
}: Props): Node => (
  <div>
    <div style={style.title}>
      {intl.formatMessage({ id: 'topHourlyCharge.charge' })}
      {!!sellingPattern && (
        <Icon
          icon={faQuestionCircle}
          style={style.infoButton}
          onClick={(e) =>
            dispatch({
              type: OPEN_POP_UP,
              contentType: HELP_GENERATION_GAIN,
              target: e.target,
            })
          }
        />
      )}
    </div>
    {renderChargeArea(
      hourlyEnergy,
      selectedHour,
      requesting,
      intl,
      unitPrices,
      sellingPattern,
      soldEnergyOwner,
      soldUnitPrice,
      consumedUnitPrice
    )}
  </div>
);

const mapStateToProps = (state) => ({
  requesting: state.energy.hourlyRequesting,
  unitPrices: state.userInfo.unitPrices,
  sellingPattern: state.userInfo.sellingPattern,
  soldEnergyOwner: state.userInfo.soldEnergyOwner,
  soldUnitPrice: state.userInfo.soldUnitPrice,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
});

const connectedPureTopHourlyCharge: any = connect(mapStateToProps)(PureTopHourlyCharge);
export default connectedPureTopHourlyCharge;
