// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import TopTimeItem from './TopTimeItem';
import UpDownButton from './TopTimeBarUpDownButton';
import { SELECT_NEXT_HOUR, SELECT_PREV_HOUR } from '../actions/types';
import { loadTopPageLatestTwoHours, needsUpdate, isUsedAtHour } from '../lib/topPageUtils';

const renderUpButton = (
  currentDate,
  currentTime,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  dispatch
) => (
  <UpDownButton
    style={{ marginBottom: '8px' }}
    clickHandler={() =>
      needsUpdate(currentDate, currentTime)
        ? loadTopPageLatestTwoHours(billingInfo, lastBillingDate, accountStartDate, dispatch)
        : dispatch({ type: SELECT_NEXT_HOUR })}
  >
    ▲
  </UpDownButton>
);

const renderTimeBar = (
  timeRange,
  aplFilter,
  hourlyDurations,
  billingData,
  lastBillingDate,
  accountStartDate,
  dispatch
) => (
  <div>
    {timeRange.range.map(hour => (
      <TopTimeItem
        key={hour}
        selectedHour={timeRange.selectedHour}
        currentDate={timeRange.currentDate}
        currentTime={timeRange.currentTime}
        hour={hour}
        used={isUsedAtHour(hour, aplFilter, hourlyDurations)}
        billingData={billingData}
        lastBillingDate={lastBillingDate}
        accountStartDate={accountStartDate}
        dispatch={dispatch}
      />
    ))}
  </div>
);

const renderDownButton = (
  currentDate,
  currentTime,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  dispatch
) => (
  <UpDownButton
    style={{ marginTop: '8px' }}
    clickHandler={() =>
      needsUpdate(currentDate, currentTime)
        ? loadTopPageLatestTwoHours(billingInfo, lastBillingDate, accountStartDate, dispatch)
        : dispatch({ type: SELECT_PREV_HOUR })}
  >
    ▼
  </UpDownButton>
);

type Props = {
  timeRange: {
    currentDate: string,
    currentTime: string,
    selectedHour: number,
    range: Array<string>,
  },
  aplFilter: Array<{
    id: string,
    display: boolean,
  }>,
  hourlyDurations: Array<{
    hour: number,
    appliances: Array<{
      id: string,
      isUsed: boolean,
    }>,
  }>,
  billingInfo: {
    billingRequesting: boolean,
    billingInitialized: boolean,
    predictRequesting: boolean,
    billingData: Array<{
      billingDate: string,
      startDate: string,
      endDate: string,
      billingAmount: number,
      consumedBillingAmount: number | null,
      soldAmount: number | null,
    }>,
    forecastPower: number,
  },
  lastBillingDate: string,
  accountStartDate: string,
  dispatch: Dispatch,
};

export const PureTopTimeBar = ({
  timeRange,
  aplFilter,
  hourlyDurations,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  dispatch,
}: Props): Node => (
  <div>
    {renderUpButton(
      timeRange.currentDate,
      timeRange.currentTime,
      billingInfo,
      lastBillingDate,
      accountStartDate,
      dispatch
    )}
    {renderTimeBar(
      timeRange,
      aplFilter,
      hourlyDurations,
      billingInfo,
      lastBillingDate,
      accountStartDate,
      dispatch
    )}
    {renderDownButton(
      timeRange.currentDate,
      timeRange.currentTime,
      billingInfo,
      lastBillingDate,
      accountStartDate,
      dispatch
    )}
  </div>
);

const mapStateToProps = state => ({
  timeRange: state.timeRange,
  aplFilter: state.userSetting.aplFilter,
  hourlyDurations: state.applianceState.hourlyDurations,
});

const connectedPureTopTimeBar: any = connect(mapStateToProps)(PureTopTimeBar);
export default connectedPureTopTimeBar;
