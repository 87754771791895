// @flow

import moment from 'moment';
import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {
  VictoryContainer,
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryStack,
  VictoryTheme,
  Rect,
} from 'victory';
import sizeMe from 'react-sizeme';
import { calcPowerCharge, calcBuyCharge } from '../lib/price/calculatedPrice';
import Prefixer from '../lib/Prefixer';
import { barChartPadding, barWidth, renderXAxis } from './ChartGraphVictoryAxis';
import { calcTotalEnergy } from '../lib/common/calculated';
import type { UnitPrice, BillingInfo } from '../lib/types/priceInfoTypes';
import type { UnitEnergyPerDay } from '../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  chartDetail: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
    marginTop: '10px',
  },
  square: {
    width: '15px',
    height: '15px',
    display: 'inline-block',
    borderRadius: '2px',
    marginRight: '5px',
  },
  buyIcon: {
    background: '#FF9A92',
  },
  generateIcon: {
    background: '#33B2B5',
  },
});

type Props = {
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  billingInfo: BillingInfo,
  unit: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
  sellingPattern: string,
};

const renderBuyBar = (
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  width: number,
  unitPrices: UnitPrice[]
) => (
  <VictoryBar
    style={{ data: { fill: '#ff8f86', width } }}
    data={unitEnergyList.map((unitEnergy, index) => ({
      x: index + 1,
      y:
        unitEnergy.isBillingData || unitEnergy.isCalculated
          ? calcTotalEnergy(unitEnergy.data, 'buyEnergy')
          : calcBuyCharge(unitPrices, unitEnergy),
    }))}
  />
);

const renderConsumeBar = (
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  width: number,
  consumedUnitPrice: number
) => (
  <VictoryBar
    style={{ data: { fill: '#33B2B5', width } }}
    data={unitEnergyList.map((unitEnergy, index) => ({
      x: index + 1,
      y:
        unitEnergy.isBillingData || unitEnergy.isCalculated
          ? calcTotalEnergy(unitEnergy.data, 'consumeEnergy')
          : calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice,
    }))}
  />
);

const renderBuyForecastBar = (
  key: number,
  data: {
    unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
    isForecast: boolean,
  },
  width: number,
  unitPrices: UnitPrice[]
) => {
  const { unitEnergyList, isForecast } = data;
  return (
    <VictoryBar
      key={key}
      style={{
        data: {
          fill: isForecast ? '#cfcfcf' : '#ff8f86',
          stroke: (d) =>
            isForecast &&
            d.x !== 0 &&
            calcTotalEnergy(unitEnergyList[d.x - 1].data, 'buyEnergy') !== 0 &&
            '#999',
          strokeDasharray: isForecast && '2',
          strokeWidth: isForecast && 1,
          width,
        },
      }}
      data={unitEnergyList.map((unitEnergy, index) => ({
        x: index + 1,
        y:
          unitEnergy.isBillingData || unitEnergy.isCalculated || isForecast
            ? calcTotalEnergy(unitEnergy.data, 'buyEnergy')
            : calcBuyCharge(unitPrices, unitEnergy),
      }))}
    />
  );
};

const renderBuyStack = (
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  billingInfo: BillingInfo,
  unitPrices: UnitPrice[],
  consumedUnitPrice: number
): {
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  isForecast: boolean,
}[] => {
  const { forecastPower, consumedForecastPower, currentWeighingPeriod } = billingInfo;
  const buyEnergyList = [];
  const forecastCharge =
    calcPowerCharge(forecastPower, unitPrices[unitPrices.length - 1]) +
    consumedForecastPower * consumedUnitPrice;
  const forecastValue = unitEnergyList.map((unitEnergy) => ({
    ...unitEnergy,
    data: moment(unitEnergy.period, 'YYYY-MM').isSame(
      moment(currentWeighingPeriod.billingMonth, 'YYYY-MM'),
      'YYYY-MM'
    )
      ? [
          {
            buyEnergy: [
              forecastCharge > calcBuyCharge(unitPrices, unitEnergy)
                ? forecastCharge -
                  calcBuyCharge(unitPrices, unitEnergy) -
                  calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice
                : 0,
            ],
            consumeEnergy: [0],
            sellEnergy: [0],
            isBillingData: false,
          },
        ]
      : [],
  }));

  buyEnergyList.push({ unitEnergyList, isForecast: false });
  buyEnergyList.push({ unitEnergyList: forecastValue, isForecast: true });
  return buyEnergyList;
};

const renderChart = (
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  billingInfo: BillingInfo,
  unit: string,
  intl: intlShape,
  width: number,
  unitPrices: UnitPrice[],
  consumedUnitPrice: number
) => (
  <VictoryChart
    theme={VictoryTheme.material}
    containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
    width={width}
    height={286}
    padding={{ right: 3, left: 3, bottom: 50 }}
    domainPadding={barChartPadding[unit]}
  >
    <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
    <VictoryAxis
      dependentAxis
      tickCount={7}
      style={{
        grid: { strokeWidth: 1, stroke: '#ccc' },
        axis: { stroke: 'transparent' },
        ticks: { stroke: 'transparent' },
        tickLabels: { fill: 'transparent' },
      }}
    />
    {unit === 'year' ? (
      <VictoryStack>
        {renderConsumeBar(
          unitEnergyList,
          barWidth(width, unitEnergyList.length),
          consumedUnitPrice
        )}
        {renderBuyStack(unitEnergyList, billingInfo, unitPrices, consumedUnitPrice).map(
          (data, index) =>
            renderBuyForecastBar(
              index,
              data,
              barWidth(width, data.unitEnergyList.length),
              unitPrices
            )
        )}
      </VictoryStack>
    ) : (
      <VictoryStack>
        {renderConsumeBar(
          unitEnergyList,
          barWidth(width, unitEnergyList.length),
          consumedUnitPrice
        )}
        {renderBuyBar(unitEnergyList, barWidth(width, unitEnergyList.length), unitPrices)}
      </VictoryStack>
    )}
    {renderXAxis(unit, unitEnergyList.length, false, intl)}
  </VictoryChart>
);

export const PureChartSubUnitChart = ({
  unitEnergyList,
  billingInfo,
  unit,
  intl,
  size,
  unitPrices,
  consumedUnitPrice,
  sellingPattern,
}: Props): Node => (
  <div>
    {renderChart(
      unitEnergyList,
      billingInfo,
      unit,
      intl,
      size.width,
      unitPrices,
      consumedUnitPrice
    )}
    {!!sellingPattern && (
      <>
        <div style={style.chartDetail}>
          <div style={{ ...style.square, ...style.buyIcon }} />
          <div>{intl.formatMessage({ id: 'chartSubUnit.buyAndUse' })}</div>
        </div>
        <div style={style.chartDetail}>
          <div style={{ ...style.square, ...style.generateIcon }} />
          <div>{intl.formatMessage({ id: 'chartSubUnit.generateAndUse' })}</div>
        </div>
      </>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  unitPrices: state.userInfo.unitPrices,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
  sellingPattern: state.userInfo.sellingPattern,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChartSubUnitChart: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartSubUnitChart))
);
export default connectedPureChartSubUnitChart;
