import moment from 'moment';
import type { EnergyPerDay } from '../types/electricTypes';
import type { WeighingPeriod } from '../types/priceInfoTypes';

export const getEnergy = (array: EnergyPerDay[], period: string): ?EnergyPerDay =>
  array.find(elem => elem.period === period);

/*
請求確定月でも、確定金額の値を使わずNRGPから取得した電力データを利用したい場合に使用する関数
主に自家消費によるお得金額を表示する際に使用する想定
 */
export const estimatedEnergy = (
  requesting: boolean,
  currentWeighingPeriod: WeighingPeriod,
  unitEnergyList: EnergyPerDay[],
  argPeriod: string
): EnergyPerDay | null => {
  if (
    requesting ||
    (!argPeriod && (!currentWeighingPeriod || !currentWeighingPeriod.billingMonth))
  ) {
    return null;
  }
  const period =
    argPeriod || moment(currentWeighingPeriod.billingMonth, 'YYYY-MM').format('YYYY-MM');

  const energy = getEnergy(unitEnergyList, period) || [];

  return energy;
};
