// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import Popup from './Popup';
import Alert from './Alert';
import { CLOSING_POP_UP, CLOSED_POP_UP } from '../actions/types';
import Prefixer from '../lib/Prefixer';
import {
  SERVICE_UNAVAILABLE,
  APPLIANCE_FOUND,
  NETWORK_DISCONNECTED,
  INFORMATION,
  USER_STATUS_ERROR,
  PV_ALERT,
} from '../actions/alertPopupTypes';

const prefixer = new Prefixer();

// エラーダイアログポップアップが表示されるタイミング
// * getUseTab 24時間のデータがnull
// * getUserInfo startDialog
// * config.informationLinkからの戻り値
// * ~~login ログインできなかった パスワード違い~~
// * login ログインできなかった 契約切れ
// * ネットワークに繋がらない
//

const styles = prefixer.prefix({
  popUpBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    color: '#298c8e',
    fontWeight: 700,
    fontSize: 18,
    fontFamily: 'Noto Sans JP',
    textAlign: 'center',
    lineHeight: '26px',
  },
  content: {
    color: '#454545',
    fontWeight: 400,
    fontSize: 14,
    fontFamily: 'Noto Sans JP',
    margin: '10px auto',
    backgroundClip: 'padding-box',
    padding: '4px 15px 8px',
    textAlign: 'center',
    lineHeight: '26px',
    wordWrap: 'break-word',
  },
  button: {
    background: '#298c8e',
    fontWeight: 'bold',
    fontSize: 16,
    borderRadius: 3,
    textAlign: 'center',
    margin: '5px auto 10px',
    padding: 0,
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fafafa',
    border: 0,
    height: 44,
  },
  ok: {
    width: '100%',
    textAlign: 'center',
    padding: '20px 0 20px 0',
    fontSize: 12,
  },
  buttonText: {
    color: '#fff',
    textDecoration: 'none',
    fontWeight: 'normal',
    width: '100%',
  },
  link: {
    color: '#333',
    textDecoration: 'underline',
  },
  alertArea: {
    margin: 'auto 20px',
  },
  errorTitle: {
    width: '100%',
    fontSize: '20px',
    fontWeight: '700',
    lineHeight: '29px',
    color: '#fafafa',
    padding: '20px 0px 30px',
    textAlign: 'center',
    margin: '0px auto',
    background: '#ff5354',
  },
  warningArea: {
    height: '45px',
    verticalAlign: 'middle',
    marginBottom: '10px',
  },
  warning: {
    fill: '#fafafa',
  },
  errorBox: {
    background: '#fafafa',
    maxWidth: '340px',
    margin: '0px auto 10px',
    textAlign: 'left',
    padding: '10px 10px 5px',
    color: '#555',
  },
  errorText: {
    color: '#454545',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '23px',
    textAlign: 'left',
    width: '90%',
    margin: '10px auto',
  },
  errorCode: {
    fontWeight: 'bold',
    margin: '15px auto 10px',
  },
  alertButton: {
    position: 'relative',
    background: 'rgba(69, 69, 69, 0.5)',
    fontWeight: '400px',
    fontSize: '14px',
    borderRadius: '10px',
    textAlign: 'center',
    margin: '10px auto',
    padding: '10px',
    width: '55%',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fafafa',
    border: 0,
    lineHeight: '20px',
  },
  arrow: {
    fill: '#fafafa',
  },
  arrowArea: {
    position: 'absolute',
    height: '12px',
    verticalAlign: 'middle',
    marginRight: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '5px',
  },
  border: {
    borderTop: '1px solid #dddddd',
    margin: '10px auto',
  },
});

const getLinkButton = (linkUrl, linkMessage) => {
  if (!linkUrl && !linkMessage) {
    return null;
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button style={styles.button}>
      <a style={styles.buttonText} href={linkUrl} rel="noopener noreferrer">
        {linkMessage}
      </a>
    </button>
  );
};

const getCloseButton = (label, isButton, ClosePopup, styleOverwrite? = {}) => (
  <div style={styles.ok}>
    {isButton ? (
      <div
        className="close"
        style={{ ...styles.button, ...styleOverwrite.button }}
        onClick={ClosePopup}
      >
        {label}
      </div>
    ) : (
      <div className="close" style={styles.link} onClick={ClosePopup}>
        {label}
      </div>
    )}
  </div>
);

const getAlertCloseButton = (label, ClosePopup) => (
  <div style={styles.border}>
    <div className="close" style={styles.alertButton} onClick={ClosePopup}>
      {label}
      <svg style={styles.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <path style={styles.arrow} d="M179,100,39,180V20Z" />
      </svg>
    </div>
  </div>
);

type Props = {
  popup: Object,
  intl: IntlShape,
  dispatch: Dispatch,
};

declare var config: { URLS: Array<Object> };

export const PureAlertPopup = ({ popup, intl, dispatch }: Props): ?Node => {
  const { Q_SERVICE_UNAVAILABLE } = config.URLS[intl.locale.toUpperCase()] || {};
  const { open, contentType, status, information } = popup;
  if (!open && !contentType) {
    return null;
  }

  let title = '';
  let message = '';
  let linkButton = '';
  let closeButton = '';
  let fullScreen = false;
  let isAlert = false;
  const animationDuration = 200;
  const ClosePopup = () => {
    dispatch({
      type: CLOSING_POP_UP,
    });
    setTimeout(() => {
      dispatch({ type: CLOSED_POP_UP });
    }, animationDuration);
  };

  switch (contentType) {
    case SERVICE_UNAVAILABLE:
      title = intl.formatMessage({ id: 'alertPopup.disableAquireT' });
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.disableAquireD1' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.disableAquireD2' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.disableAquireD3' })}
        </span>
      );
      linkButton = getLinkButton(
        Q_SERVICE_UNAVAILABLE,
        intl.formatMessage({ id: 'alertPopup.disableAquireLink' })
      );
      closeButton = getCloseButton(
        intl.formatMessage({ id: 'alertPopup.noAction1' }),
        false,
        ClosePopup
      );
      break;
    case APPLIANCE_FOUND:
      title = (
        <div>
          <div>{intl.formatMessage({ id: 'alertPopup.StartT1' })}</div>
          <div>{intl.formatMessage({ id: 'alertPopup.StartT2' })}</div>
        </div>
      );
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.StartD1' })}
          {intl.formatMessage({ id: 'alertPopup.StartD2' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.StartD3' })}
          {intl.formatMessage({ id: 'alertPopup.StartD4' })}
        </span>
      );
      linkButton = null;
      closeButton = getCloseButton(
        intl.formatMessage({ id: 'alertPopup.StartButton' }),
        true,
        ClosePopup
      );
      break;
    case NETWORK_DISCONNECTED:
      title = intl.formatMessage({ id: 'others.notice' });
      message = intl.formatMessage({ id: 'others.networkError' });
      if (status) {
        message = `${message}(${status})`;
      }
      linkButton = null;
      closeButton = getCloseButton(intl.formatMessage({ id: 'others.close' }), false, ClosePopup);
      break;
    case INFORMATION:
      title = intl.formatMessage({ id: 'others.notice' });
      message = <span>{information}</span>;
      linkButton = null;
      closeButton = getCloseButton(intl.formatMessage({ id: 'others.close' }), false, ClosePopup);
      break;
    case USER_STATUS_ERROR:
      title = intl.formatMessage({ id: 'alertPopup.userStatusErrorTitle' });
      message = (
        <span>
          {intl.formatMessage({ id: 'alertPopup.userStatusErrorMessage1' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.userStatusErrorMessage2' })}
          <br />
          {intl.formatMessage({ id: 'alertPopup.userStatusErrorMessage3' })}
        </span>
      );
      linkButton = getLinkButton(
        Q_SERVICE_UNAVAILABLE,
        intl.formatMessage({ id: 'alertPopup.disableAquireLink' })
      );
      closeButton = null;
      break;
    case PV_ALERT:
      title = intl.formatMessage({ id: 'alertPopup.pvAlertTitle' });
      message = <span>{intl.formatMessage({ id: 'alertPopup.pvAlertMessage' })}</span>;
      closeButton = getAlertCloseButton(
        intl.formatMessage({ id: 'alertPopup.noAction2' }),
        ClosePopup
      );
      fullScreen = true;
      isAlert = true;
      break;
    default:
      return null;
  }

  const popupStyle = {
    popupContainer: {
      display: 'table',
    },
    tableCell: {
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    popup: {
      top: '-10vh',
      margin: '0 auto',
      paddingTop: '15px',
    },
  };
  const fullScreenStyle = fullScreen ? { fullScreen: { background: '#33b2b5' } } : {};

  return isAlert ? (
    <Alert
      open={open}
      animationDuration={animationDuration}
      style={{ ...popupStyle, ...fullScreenStyle }}
    >
      <div style={styles.alertArea}>
        <div style={styles.errorTitle}>
          <svg style={styles.warningArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
              style={styles.warning}
              d="M180,149.69,112,35.94a14,14,0,0,0-24.06,0L20,149.69a14,14,0,0,0,12,21.2H168a14,14,0,0,0,12-21.2Zm-87.3-83.5A4.31,4.31,0,0,1,97,61.88h4.69A4.31,4.31,0,0,1,106,66.19v59.65a4.31,4.31,0,0,1-4.31,4.3H97.05a4.31,4.31,0,0,1-4.31-4.3Zm6.66,86.59a8.1,8.1,0,1,1,8.09-8.09h0A8.09,8.09,0,0,1,99.38,152.78Z"
            />
          </svg>
          <br />
          {title}
        </div>
        <div style={styles.errorBox}>
          <div style={styles.errorText}>{message}</div>
          {closeButton}
        </div>
      </div>
    </Alert>
  ) : (
    <Popup open={open} animationDuration={animationDuration} style={popupStyle}>
      <div style={styles.popUpBox}>
        <div style={styles.title}> {title} </div>
        <div style={styles.content}> {message} </div>
        {linkButton}
        {closeButton}
      </div>
    </Popup>
  );
};

const mapStateToProps = (state) => ({
  popup: state.popup,
  messages: state.messages,
});

const connectedPureAlertPopup: any = connect(mapStateToProps)(injectIntl(PureAlertPopup));
export default connectedPureAlertPopup;
