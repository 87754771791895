/* eslint-env browser */
// @flow

import type { State, Action } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';
import moment from 'moment';
import {
  OPEN_ALERT,
  OPEN_POP_UP,
  CLOSING_POP_UP,
  CLOSED_POP_UP,
  FAILURE_API_USER_STATUS,
  SUCCESS_API_USER_STATUS,
  REQUEST_API_ENERGY_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
  FAILURE_API_ENERGY_HOURLY,
  REQUEST_API_USE_STATS,
  FAILURE_API_USE_STATS,
  SUCCESS_API_USER_INFO,
} from '../actions/types';
import {
  SERVICE_UNAVAILABLE,
  NETWORK_DISCONNECTED,
  USER_STATUS_ERROR,
  PV_ALERT,
} from '../actions/alertPopupTypes';

const initialState = {
  open: false,
  contentType: '', // dialogType
  status: null,
  information: null,
  target: {},
  haveShownStartDialog: false,
  lastDateUnavailableShown: 0,
  page: '',
  period: '',
};

export default (state: State = initialState, action: Action = {}): State => {
  const { error, type, contentType, payload, target, period } = action;
  switch (type) {
    case REHYDRATE:
    case SUCCESS_API_USER_INFO: {
      return {
        ...state,
        haveShownStartDialog: payload.haveShownStartDialog,
        lastDateUnavailableShown: payload.lastDateUnavailableShown,
      };
    }
    case REQUEST_API_USE_STATS:
    case REQUEST_API_ENERGY_HOURLY: {
      return error
        ? {
            ...state,
            open: true,
            contentType: NETWORK_DISCONNECTED,
            status: payload.status,
          }
        : state;
    }
    case FAILURE_API_USE_STATS:
    case FAILURE_API_ENERGY_HOURLY: {
      if (moment(state.lastDateUnavailableShown).isSame(new Date().toISOString(), 'day')) {
        return state;
      }
      return error
        ? {
            ...state,
            open: true,
            contentType: SERVICE_UNAVAILABLE,
            lastDateUnavailableShown: new Date().toISOString(),
          }
        : state;
    }
    case FAILURE_API_USER_STATUS: {
      if (action.payload.status === 502 || action.payload.status === 500) {
        return {
          ...state,
          open: true,
          contentType: USER_STATUS_ERROR,
          lastDateUnavailableShown: new Date().toISOString(),
        };
      }
      return state;
    }
    case SUCCESS_API_ENERGY_HOURLY: {
      if (moment(state.lastDateUnavailableShown).isSame(new Date().toISOString(), 'day')) {
        return state;
      }
      return payload.length === 0 // payload が無いときはdatastoreAccessに失敗している
        ? {
            ...state,
            open: true,
            contentType: SERVICE_UNAVAILABLE,
            lastDateUnavailableShown: new Date().toISOString(),
          }
        : state;
    }
    case SUCCESS_API_USER_STATUS: {
      return {
        ...state,
        open: false,
        contentType: '',
        status: null,
        information: null,
        target: {},
      };
    }
    case OPEN_ALERT: {
      return {
        ...state,
        open: true,
        contentType: PV_ALERT,
      };
    }
    case OPEN_POP_UP: {
      return {
        ...state,
        open: true,
        contentType,
        target,
        period,
      };
    }
    case CLOSING_POP_UP: {
      return {
        ...state,
        open: false,
      };
    }
    case CLOSED_POP_UP: {
      return {
        ...state,
        open: false,
        contentType: '',
        status: null,
        information: null,
        target: {},
      };
    }
    default: {
      return state;
    }
  }
};
