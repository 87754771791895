// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { createSelector } from 'reselect';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import ChartApplianceRankingItem from './ChartApplianceRankingItem';
import ChartNodata, {
  CHART_NO_DATA,
  CHART_IMPERFECT_LEARNING,
  CHART_LEARNING,
} from './ChartNoData';
import { HELP_CHART_RANKING } from '../actions/helpPopupTypes';
import { OPEN_POP_UP } from '../actions/types';
import Prefixer from '../lib/Prefixer';
import { sortRanking } from '../lib/common/globalUtils';
import { timeUnitStyle } from '../lib/constants/timeUnit';
import loadingAnime from '../images/loading.gif';
import type { UnitPrice } from '../lib/types/priceInfoTypes';
import type { ApplianceEnergyDetails } from '../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  ranking: {
    width: '97%',
    margin: '10px auto 0px',
  },
  loadingAnime: {
    margin: '80px 0px',
  },
  info: {
    top: '-12px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#bbb',
    float: 'right',
  },
});

type Props = {
  requesting: boolean,
  applianceEnergy: ApplianceEnergyDetails[],
  userStatus: string,
  unitPrices: UnitPrice[],
  intl: intlShape,
  dispatch: Dispatch,
};

const renderRanking = (applianceEnergy: ApplianceEnergyDetails[], unitPrices: UnitPrice[]) => (
  <div style={style.ranking}>
    {sortRanking(applianceEnergy, unitPrices).map((appliance, index) => (
      <ChartApplianceRankingItem
        key={appliance.id}
        appliance={appliance}
        rank={index + 1}
        energyMax={sortRanking(applianceEnergy, unitPrices)[0].energyValue}
      />
    ))}
  </div>
);

const renderLoadingAnime = (intl: intlShape) => (
  <div>
    <div style={style.loadingAnime}>
      <img src={loadingAnime} alt="loading" />
    </div>
    <div>{intl.formatMessage({ id: 'chartApplianceRanking.loading' })}</div>
  </div>
);

const hasApplianceEnergy = (applianceEnergy: ApplianceEnergyDetails[] = []): boolean =>
  applianceEnergy.some((v) => v.data.some((data) => data.energy.some((value) => value > 0)));

const renderInfoButton = (dispatch: Dispatch) => (
  <div style={style.info}>
    <FontAwesomeIcon
      icon={faQuestionCircle}
      style={style.infoButton}
      onClick={(e) =>
        dispatch({
          type: OPEN_POP_UP,
          contentType: HELP_CHART_RANKING,
          target: e.target,
        })
      }
    />
  </div>
);

const renderBody = (
  requesting: boolean,
  applianceEnergy: ApplianceEnergyDetails[],
  userStatus: string,
  unitPrices: UnitPrice[],
  intl: intlShape
) => {
  if (userStatus === 'imperfect_learning') {
    return <ChartNodata type={CHART_IMPERFECT_LEARNING} />;
  }
  if (userStatus === 'learning') {
    return <ChartNodata type={CHART_LEARNING} />;
  }
  if (requesting) {
    return renderLoadingAnime(intl);
  }
  if (hasApplianceEnergy(applianceEnergy)) {
    return renderRanking(applianceEnergy, unitPrices);
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

export const PureChartApplianceRanking = ({
  requesting,
  applianceEnergy,
  userStatus,
  unitPrices,
  intl,
  dispatch,
}: Props): Node => (
  <div style={style.wrapper}>
    {renderInfoButton(dispatch)}
    {intl.formatMessage({ id: 'chartApplianceRanking.title' })}
    {renderBody(requesting, applianceEnergy, userStatus, unitPrices, intl)}
  </div>
);

const filteredAppliances = createSelector(
  [
    (state) => state.applianceState.idsToShow,
    (state) =>
      state.applianceEnergy[timeUnitStyle[state.chartTime.unit].toUnit].find(
        (ele) => state.chartTime.period === ele.dateTime
      ),
  ],
  (idsToShow, applianceEnergy) =>
    applianceEnergy
      ? applianceEnergy.applianceEnergy.filter((item) => idsToShow.includes(item.id))
      : []
);

const mapStateToProps = (state) => ({
  applianceEnergy: filteredAppliances(state),
  userStatus: state.userInfo.status,
  unitPrices: state.userInfo.unitPrices,
});

const connectedPureChartApplianceRanking: any = connect(mapStateToProps)(
  injectIntl(PureChartApplianceRanking)
);
export default connectedPureChartApplianceRanking;
