// @flow

import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

type Props = {
  loginState: string,
  tutorialFinished: boolean,
  status: string,
  questionnaires: Object,
};

const availableStatus = ['normal', 'learning', 'imperfect_learning'];

class PureForceShowQuestionnaire extends Component<Props> {
  render() {
    const { loginState, tutorialFinished, status, questionnaires } = this.props;
    if (
      loginState === 'SUCCESS' &&
      tutorialFinished &&
      availableStatus.includes(status) &&
      !Object.keys(questionnaires).length
    ) {
      return <Redirect to="/questionnaire?force=true" />;
    }
    return null;
  }
}

PureForceShowQuestionnaire.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectedForceShowQuestionnaire: any = connect((state) => ({
  loginState: state.loginInfo.loginState,
  tutorialFinished: state.tutorial.tutorialFinished,
  status: state.userInfo.status,
  questionnaires: state.userInfo.questionnaires,
}))(PureForceShowQuestionnaire);

export default connectedForceShowQuestionnaire;
