// @flow

import type { State, Action } from 'redux';

import { supportedLocale } from '../lib/common/globalUtils';
import { aplDurationFormat } from '../lib/localizedValues';

import {
  ENTER_APPLICATION,
  LOAD_TOP_PAGE,
  REQUEST_API_USE_STATS,
  SUCCESS_API_USE_STATS,
  FAILURE_API_USE_STATS,
  REQUEST_API_USE_STATS_HOURLY,
  SUCCESS_API_USE_STATS_HOURLY,
  FAILURE_API_USE_STATS_HOURLY,
} from '../actions/types';

const initialState = {
  disaggregated: true,
  requesting: false,
  hourlyDurations: [], // hourlyDurationFormat will be in this array.
  idsToShow: ['304'],
  locale: 'en',
};

const getHourlyDurationFormat = locale => ({
  hour: 0,
  appliances: supportedLocale.includes(locale) ? aplDurationFormat[locale] : aplDurationFormat.en,
});

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case ENTER_APPLICATION: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case LOAD_TOP_PAGE: {
      return {
        ...state,
        disaggregated: true,
        requesting: false,
        hourlyDurations: [],
      };
    }
    case REQUEST_API_USE_STATS: {
      const requesting = !action.error;
      return {
        ...state,
        requesting,
      };
    }
    case SUCCESS_API_USE_STATS: {
      const { payload } = action;
      const usedCount = payload.count || {};
      const newIdsToShow = Object.keys(usedCount)
        .filter(applianceId => !state.idsToShow.includes(applianceId))
        .map(applianceId => {
          if (usedCount[applianceId] > 0) {
            return applianceId;
          }
          return null;
        })
        .filter(value => value);
      return {
        ...state,
        idsToShow: [...state.idsToShow, ...newIdsToShow],
      };
    }
    case FAILURE_API_USE_STATS: {
      return {
        ...state,
        requesting: false,
      };
    }
    case REQUEST_API_USE_STATS_HOURLY: {
      const { meta = {} } = action || {};
      const targetKeys = Object.keys(meta).filter(key => key.match(/^useStats\d+$/));
      if (targetKeys.length === 0) {
        return state;
      }
      const requesting = !action.error;
      return {
        ...state,
        requesting,
      };
    }
    case SUCCESS_API_USE_STATS_HOURLY: {
      const { payload = {} } = action || {};
      const targets = Object.keys(payload)
        .filter(key => key.match(/^useStats\d+$/))
        .map(key => ({
          ...payload[key],
          name: key,
        }));
      if (targets.length === 0) {
        return {
          ...state,
          requesting: false,
        };
      }

      const hourlyDurationFormat = getHourlyDurationFormat(state.locale);
      let hourlyDurations = targets.map(target => {
        const { isUsed = {} } = target || {};
        return {
          hour: Number(target.name.replace('useStats', '')),
          appliances: hourlyDurationFormat.appliances.map(appliance => ({
            ...appliance,
            isUsed:
              typeof isUsed[appliance.id] === 'undefined' ? appliance.isUsed : isUsed[appliance.id],
          })),
        };
      });
      if (hourlyDurations.length === 2) {
        const hoursToUpdate = hourlyDurations.map(value => value.hour);
        const rest10Hours = state.hourlyDurations.filter(
          value => hoursToUpdate.indexOf(value.hour) < 0
        );
        hourlyDurations = [...hourlyDurations, ...rest10Hours];
      }

      return {
        ...state,
        disaggregated: targets.every(
          target => target && target.isUsed && !!Object.keys(target.isUsed).length
        ),
        requesting: false,
        hourlyDurations,
      };
    }
    case FAILURE_API_USE_STATS_HOURLY: {
      const { meta = {} } = action || {};
      const targetKeys = Object.keys(meta).filter(key => key.match(/^useStats\d+$/));
      if (targetKeys.length === 0) {
        return state;
      }
      return {
        ...state,
        requesting: false,
      };
    }
    default: {
      return state;
    }
  }
};
