// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import { injectIntl, intlShape } from 'react-intl';
import ChartComparisonBar from './ChartComparisonBar';
import { ChartBody, ChartLoading, ChartTitle } from './Common';
import Prefixer from '../../lib/Prefixer';
import { findUnitPrice } from '../../lib/price/priceUtils';
import { calcPowerCharge } from '../../lib/price/calculatedPrice';
import type { UnitPrice, BillingInfo } from '../../lib/types/priceInfoTypes';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  wrapper: { position: 'relative' },
  message: { fontWeight: 'normal', fontSize: '0.9rem', textAlign: 'left', margin: 8 },
  arrowArea: {
    position: 'absolute',
    height: '12px',
    verticalAlign: 'middle',
    marginRight: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
  arrow: { fill: '#fafafa' },
  link: { textDecoration: 'none' },
  buttonStyle: {
    position: 'relative',
    background: '#298c8e',
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: 8,
    margin: '20px auto 0',
    padding: 0,
    width: '65%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    height: 40,
  },
  buttonText: { justifyContent: 'center', border: 'none' },
  comparisonCharge: { display: 'table', width: '100%', margin: '20px auto' },
  comparisonChargeBlock: { display: 'table-cell', width: '50%' },
  comparisonTitle: { verticalAlign: 'middle' },
  comparisonBody: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '1.8em',
    width: '95%',
    borderRadius: '10px',
    border: '3px solid #fff',
  },
  comparisonBodyLeft: { color: '#ff5354', background: '#faeded' },
  chargeArea: { padding: '5px 8px 15px', textAlign: 'center' },
  chargeValue: {
    fontSize: '0.8em',
    padding: '5px 20px',
    borderBottom: '3px solid #999',
  },
  chargeValueLeft: { borderBottom: '3px solid #ff5354' },
});

type Props = {
  requesting: boolean,
  billings: BillingInfo[],
  unitPrices: UnitPrice[],
  compares: {
    sts: number,
    ets: number,
    date: string,
    median: { whp: number, appliances: Object },
  }[],
  intl: intlShape,
};

const ComparisonChargeArea = ({
  leftValue,
  rightValue,
  intl,
}: {
  leftValue: number | null,
  rightValue: number | null,
  intl: intlShape,
}) => {
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const left = intl.formatNumber(leftValue, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const right = intl.formatNumber(rightValue, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  return (
    <div style={style.comparisonCharge}>
      <div style={style.comparisonChargeBlock}>
        <span style={style.comparisonTitle}>
          {intl.formatMessage({ id: 'report.comparison.leftTitle' })}
        </span>
        <div style={{ ...style.comparisonBody, ...style.comparisonBodyLeft }}>
          <div style={style.chargeArea}>
            <span style={{ ...style.chargeValue, ...style.chargeValueLeft }}>{left}円</span>
          </div>
        </div>
      </div>
      <div style={style.comparisonChargeBlock}>
        <span style={style.comparisonTitle}>
          {intl.formatMessage({ id: 'report.comparison.rightTitle' })}
        </span>
        <div style={{ ...style.comparisonBody }}>
          <div style={style.chargeArea}>
            <span style={{ ...style.chargeValue }}>{right}円</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const renderBody = (
  requesting: boolean,
  intl: intlShape,
  billings: BillingInfo[],
  unitPrices: UnitPrice,
  compares: {
    sts: number,
    ets: number,
    date: string,
    median: { whp: number, appliances: Object },
  }[]
) => {
  if (requesting) {
    return <ChartLoading intl={intl} />;
  }
  const arrowSvg = () => (
    <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path style={style.arrow} d="M179,100,39,180V20Z" />
    </svg>
  );
  const latestBilling = billings[billings.length - 1];
  const latestCompare = compares[compares.length - 1];
  return (
    <div>
      <ChartComparisonBar self={billings} compares={compares} />
      <ComparisonChargeArea
        leftValue={latestBilling ? latestBilling.billingAmount : null}
        rightValue={
          latestCompare && latestCompare.median ? (
            calcPowerCharge(latestCompare.median.whp, findUnitPrice(latestCompare.ets, unitPrices))
          ) : null
        }
        intl={intl}
      />
      <div style={style.message}>{intl.formatMessage({ id: 'report.comparison.text' })}</div>
      <Link style={style.link} to="/questionnaire">
        <div style={style.buttonStyle}>
          <span style={style.buttonText}>
            {intl.formatMessage({ id: 'report.questionnaireLink' })}
          </span>
          {arrowSvg()}
        </div>
      </Link>
    </div>
  );
};

export const PureComparisonSimilarHomeArea = ({
  requesting,
  billings,
  unitPrices,
  compares,
  intl,
}: Props) => (
  <div style={style.wrapper}>
    <ChartTitle id="report.comparisonTitle" intl={intl} />
    <ChartBody>{renderBody(requesting, intl, billings, unitPrices, compares)}</ChartBody>
  </div>
);

const mapStateToProps = state => ({
  unitPrices: state.userInfo.unitPrices,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureComparisonSimilarHomeArea: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureComparisonSimilarHomeArea))
);

export default connectedPureComparisonSimilarHomeArea;
