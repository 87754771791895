/* eslint-env browser */
// @flow

import React, { useEffect } from 'react';
import type { ChildrenArray, Node } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import Router from './router/Router';
import IntlProvider from './Provider';

type Props = { children: ChildrenArray<Node> };

const AppBase = ({children}: Props) => {
  const intl = useIntl();
  useEffect(() => {
    document.title = intl.formatMessage({ id: 'application.title' });
  }, []);
  return <div>{children}</div>;
};

export const App = () => (
  <IntlProvider>
    <AppBase>
      <Router />
    </AppBase>
  </IntlProvider>
);

const connectedApp: any = connect()(App);
export default connectedApp;
