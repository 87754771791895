import { applianceId } from './id';

// ex) in case DST starts at 1 am and ends at 1am, { dstStartHour: 1, dstEndHour: 1 }
export const localDstTransferHours = {
  en: { dstStartHour: 1, dstEndHour: 1 },
};

export const aplFilterFormat = {
  ja: [
    { id: applianceId.airConditioner, display: true },
    { id: applianceId.highFever, display: true },
    { id: applianceId.microwave, display: true },
    { id: applianceId.riceCooker, display: true },
    { id: applianceId.ih, display: true },
    { id: applianceId.clothesWasher, display: true },
    { id: applianceId.vacuumCleaner, display: true },
    { id: applianceId.tv, display: true },
    { id: applianceId.ecoCute, display: true },
    { id: applianceId.storageBattery, display: true },
    { id: applianceId.storageBatteryFullBackup, display: true },
  ],
};

export const rankingFormat = {
  ja: [
    { id: applianceId.airConditioner, data: [] },
    { id: applianceId.clothesWasher, data: [] },
    { id: applianceId.microwave, data: [] },
    { id: applianceId.refrigerator, data: [] },
    { id: applianceId.riceCooker, data: [] },
    { id: applianceId.tv, data: [] },
    { id: applianceId.vacuumCleaner, data: [] },
    { id: applianceId.ih, data: [] },
    { id: applianceId.highFever, data: [] },
    { id: applianceId.light, data: [] },
    { id: applianceId.background, data: [] },
  ],
};

export const aplDurationFormat = {
  ja: [
    { id: applianceId.airConditioner, isUsed: false },
    { id: applianceId.highFever, isUsed: false },
    { id: applianceId.microwave, isUsed: false },
    { id: applianceId.riceCooker, isUsed: false },
    { id: applianceId.ih, isUsed: false },
    { id: applianceId.clothesWasher, isUsed: false },
    { id: applianceId.vacuumCleaner, isUsed: false },
    { id: applianceId.tv, isUsed: false },
    { id: applianceId.ecoCute, isUsed: false },
    { id: applianceId.storageBattery, isUsed: false },
    { id: applianceId.storageBatteryFullBackup, isUsed: false },
  ],
};
