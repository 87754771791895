// @flow

type UserInfo = {
  contractorId?: string,
  appUserId?: string,
  serviceProviderId?: string,
  status: string,
};
export const gtagClickPickup = (
  event: MouseEvent,
  link: string,
  userInfo: UserInfo,
  product: string,
  event_value?: any
) => {
  event.preventDefault();
  const { contractorId, serviceProviderId, appUserId, status } = userInfo;

  window.gtag('event', 'pickup', {
    event_category: product,
    event_label: link,
    action: 'click',
    user_id: appUserId,
    contractor_id: contractorId,
    service_provider: serviceProviderId,
    status,
    product,
    link,
    ...(typeof event_value === 'undefined' ? {} : { event_value }),
    event_callback: () => {
      document.location.href = link;
    },
  });
};

export const gtagShowPickup = (
  link: string,
  userInfo: UserInfo,
  product: string,
  event_value?: any
) => {
  const { contractorId, serviceProviderId, appUserId, status } = userInfo;
  window.gtag('event', 'pickup_show', {
    event_category: product,
    event_label: link,
    action: 'show',
    user_id: appUserId,
    contractor_id: contractorId,
    service_provider: serviceProviderId,
    status,
    product,
    link,
    ...(typeof event_value === 'undefined' ? {} : { event_value }),
  });
};

export const gtagOpenTips = (
  tipTitle: string,
  userInfo: UserInfo,
  product: string,
  event_value?: any
) => {
  const { contractorId, serviceProviderId, appUserId, status } = userInfo;
  window.gtag('event', 'open_tips', {
    event_category: product,
    event_label: tipTitle,
    action: 'open',
    user_id: appUserId,
    contractor_id: contractorId,
    service_provider: serviceProviderId,
    status,
    product,
    ...(typeof event_value === 'undefined' ? {} : { event_value }),
  });
};
