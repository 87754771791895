// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from 'victory';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup } from './gtagEvent';
import headerImg from '../../../images/pickup/bedding.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  header: { width: '100%' },
  title: { textAlign: 'left', fontSize: '18px' },
  subtitle: { textAlign: 'left', fontSize: '14px', marginBottom: '0' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  annotation: {
    color: '#999',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'left',
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    margin: '0 auto',
    color: '#fafafa',
    background: '#298c8e',
    height: '65px',
    borderRadius: '8px',
  },
});

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#454545', padding: 5 },
};

const PureChart = ({
  energies,
  size: { width },
}: {
  energies: { hour: number, power: number, color: string }[],
  width: number,
  size: { width: number },
}) => (
  <VictoryChart
    theme={VictoryTheme.material}
    containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
    width={width}
    height={286}
    padding={{ right: 3, left: 3, bottom: 50 }}
    domainPadding={20}
  >
    <VictoryAxis
      dependentAxis
      tickCount={7}
      style={{
        grid: { strokeWidth: 1, stroke: '#ccc' },
        axis: { stroke: 'transparent' },
        ticks: { stroke: 'transparent' },
        tickLabels: { fill: 'transparent' },
      }}
    />
    <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
    <VictoryBar
      style={{ data: { fill: ({ color }) => color, width: (width / energies.length) * 0.7 } }}
      data={energies.map((energy, index) => ({
        x: index + 1,
        y: energy.power,
        color: energy.color,
      }))}
    />
    <VictoryAxis
      tickValues={energies.map((v, k) => k + 1)}
      tickFormat={energies.map((v) => (v.hour % 3 === 0 ? `${v.hour}時` : '・'))}
      style={xAxisStyle}
    />
  </VictoryChart>
);

const Chart = sizeMe({ monitorHeight: true })(PureChart);

export type Props = {
  data: { active_hourly_energies: number[] },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureBedding = ({
  userInfo,
  data: { active_hourly_energies: activeHourlyEnergies },
}: Props) => {
  const link = 'https://heartonemall.com/iot-shingu/?utm_source=iotdroom&utm_campaign=sdai&k=cn';
  const energies = activeHourlyEnergies.map((v, k) => ({ hour: k, power: v }));
  const nightEnergies = [
    { ...energies[21], color: '#51afed' },
    { ...energies[22], color: '#51afed' },
    { ...energies[23], color: '#51afed' },
    { ...energies[0], color: '#747ae8' },
    { ...energies[1], color: '#747ae8' },
    { ...energies[2], color: '#747ae8' },
    { ...energies[3], color: '#747ae8' },
    { ...energies[4], color: '#747ae8' },
    { ...energies[5], color: '#747ae8' },
    { ...energies[6], color: '#747ae8' },
    { ...energies[7], color: '#f2d322' },
    { ...energies[8], color: '#f2d322' },
  ];

  gtagShowPickup(link, userInfo, 'bedding');
  return (
    <div>
      <img src={headerImg} alt="bedding" style={style.header} />
      <p style={style.title}>ゆっくり眠れていますか？</p>
      <p style={style.subtitle}>夜間に使用した電力</p>
      <Chart energies={nightEnergies} />
      <div style={style.body}>
        <p>
          夜間に使用された電力をピックアップ。
          <br />
          就寝中にはあまり使わないテレビ、電子レンジ、高熱家電、IHコンロ、そして掃除機の消費電力をグラフにしてみました。
        </p>
        <p>
          いつも決まった時間に寝る家では、就寝から起床までグラフが谷になります。
          <br /> 一方で、夜も活動していることが多いと時間によるメリハリは少なくなります。
        </p>
        <p>
          夜と朝は1日の中でも慌ただしい時間帯ですから、ご自宅の電力リズムを振り返ると新しい観点が見えてくるかもしれません。
        </p>
        <p>
          こちらからは、少しでもリラックスした時間をお過ごしいただけるように、寝具の特集をご紹介します。
        </p>
      </div>
      <p style={style.annotation}>
        ※AIによる推定技術を使っているため、機種によって表示されなかったり、学習に時間がかかることがあります。
      </p>
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) => gtagClickPickup(e, link, userInfo, 'bedding')}
      >
        ぐっすり眠れる寝具の特集を読む
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureBedding: any = connect(mapStateToProps)(PureBedding);
export default connectedPureBedding;
