// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import { injectIntl, intlShape } from 'react-intl';
import ChartNoData, { CHART_NO_DATA } from '../ChartNoData';
import ChartPie from './ChartPie';
import { ChartBody, ChartLoading, ChartTitle } from './Common';
import Prefixer from '../../lib/Prefixer';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  wrapper: { position: 'relative' },
  message: { fontWeight: 'normal', fontSize: '0.9rem', textAlign: 'left', margin: '20px 8px 0' },
});

type Props = {
  requesting: boolean,
  prevBilling: { billingAmount: number } | null,
  currentBilling: { billingAmount: number },
  objective: { rate: number },
  intl: intlShape,
};

const renderBody = (requesting, intl, prevBilling, currentBilling, objective) => {
  if (requesting) {
    return <ChartLoading intl={intl} />;
  }

  if (!currentBilling) {
    return <ChartNoData type={CHART_NO_DATA} />;
  }
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const objectiveValue = prevBilling
    ? Math.round(objective.rate * prevBilling.billingAmount)
    : Math.round(currentBilling.billingAmount);
  const diffValue = objectiveValue - Math.round(currentBilling.billingAmount);
  const chartData = [
    { x: 1, y: currentBilling.billingAmount },
    { x: 1, y: diffValue > 0 ? diffValue : 0 },
  ];
  return (
    <div>
      <ChartPie
        data={chartData}
        messageId={diffValue >= 0 ? 'report.result.successLabel' : null}
        intl={intl}
      />
      {(diffValue >= 0 && (
        <div style={style.message}>
          {intl.formatMessage(
            { id: 'report.result.successText' },
            {
              value: intl.formatNumber(diffValue, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
              }),
            }
          )}
        </div>
      )) || (
        <div style={style.message}>
          {intl.formatMessage(
            { id: 'report.result.failedText' },
            {
              value: intl.formatNumber(-diffValue, {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
              }),
            }
          )}
        </div>
      )}
    </div>
  );
};

export const PureResultArea = ({
  requesting,
  prevBilling,
  currentBilling,
  objective,
  intl,
}: Props) => (
  <div style={style.wrapper}>
    <ChartTitle id="report.resultTitle" intl={intl} />
    <ChartBody>{renderBody(requesting, intl, prevBilling, currentBilling, objective)}</ChartBody>
  </div>
);

const mapStateToProps = () => ({});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureResultArea: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureResultArea))
);

export default connectedPureResultArea;
