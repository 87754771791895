// @flow

import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import Prefixer from '../lib/Prefixer';
import { calcBuyCharge } from '../lib/price/calculatedPrice';
import buyPng from '../images/icn_buy.png';
import '../css/chargeTable.css';
import { calcTotalEnergy } from '../lib/common/calculated';
import type { UnitPrice } from '../lib/types/priceInfoTypes';
import type { EnergyPerDay, UnitEnergyPerDay } from '../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  chargeArea: {
    minWidth: '65%',
    textAlign: 'left',
  },
  lineTitleStyle: {
    marginLeft: '10px',
    textAlign: 'left',
  },
  week: {
    fontSize: '0.8em',
  },
  chargePrice: {
    fontSize: '1.2em',
  },
  billingUndecided: {
    display: 'inline-block',
    color: '#ff8f86',
    margin: '3px 5px 3px 20px',
    padding: '1px 10px',
    verticalAlign: 'middle',
    fontSize: '0.5em',
    fontWeight: 'normal',
    border: 'solid 1px #ff8f86',
    borderRadius: '50px',
    height: '50%',
    textAlign: 'right',
  },
  lineStyle: {
    display: 'inline-block',
  },
  cheaperPrice: {
    clear: 'both',
    fontWeight: 'normal',
    fontSize: '0.85em',
  },
  buyIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '5px',
    paddingBottom: '5px',
    width: '20px',
  },
  energy: {
    fontWeight: 'normal',
    fontSize: '0.8em',
    opacity: '0.5',
    marginLeft: '10px',
  },
});

type Props = {
  unitEnergy: UnitEnergyPerDay,
  unit: string,
  period: string,
  itemIndex: number,
  intl: intlShape,
  unitPrices: UnitPrice[],
  sellingPattern: string,
  soldEnergyOwner: string,
  consumedUnitPrice: number,
  soldUnitPrice: number,
};

const dateList = (index: number, intl: intlShape) => {
  switch (index) {
    case 0:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.sun' });
    case 1:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.mon' });
    case 2:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.tue' });
    case 3:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.wed' });
    case 4:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.thu' });
    case 5:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.fri' });
    default:
      return intl.formatMessage({ id: 'chartSubUnitChargeItem.sat' });
  }
};

const parentheses = (period: string) => {
  if (!period) {
    return '';
  }
  const [, month, day] = period.split('-');
  return `${month.replace(/^0/, '')}/${day.replace(/^0/, '')}`;
};

const messages = defineMessages({
  week: {
    id: 'chartSubUnitChargeItem.week',
    description: '週',
  },
  month: {
    id: 'chartSubUnitChargeItem.month',
    description: '月',
  },
  currency: {
    id: 'chartSubUnitChargeItem.currency',
    description: 'currency 円 or £{currency}',
  },
  periodDate: {
    id: 'chartSubUnitChargeItem.period',
    description: '(periodDate~) or none',
  },
});

const renderPeriodTitle = (unit: string, period: string, index: number, intl: intlShape) => {
  switch (unit) {
    case 'month': {
      const periodDate = parentheses(period);
      return (
        <div style={style.lineTitleStyle}>
          {intl.formatMessage({ id: 'chartSubUnitChargeItem.place' }).split(',')[index]}
          {intl.formatMessage({ id: 'chartSubUnitChargeItem.week' })}
          <div style={style.week}>{intl.formatMessage(messages.periodDate, { periodDate })}</div>
        </div>
      );
    }
    case 'year': {
      return (
        <div style={style.lineTitleStyle}>
          {intl.formatMessage({ id: 'chartSubUnitChargeItem.month' }).split(',')[index]}
        </div>
      );
    }
    default:
      return <div style={style.lineTitleStyle}>{dateList(index, intl)}</div>;
  }
};

const buyAndConsumeCharge = (
  unitEnergy: EnergyPerDay,
  intl: intlShape,
  unitPrices: UnitPrice[],
  consumedUnitPrice: number
) => {
  const { isBillingData } = unitEnergy;
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });

  const buyCharge = isBillingData
    ? calcTotalEnergy(unitEnergy.data, 'buyEnergy')
    : calcBuyCharge(unitPrices, unitEnergy);
  const consumeCharge = isBillingData
    ? calcTotalEnergy(unitEnergy.data, 'consumeEnergy')
    : calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice;

  return intl.formatNumber(buyCharge + consumeCharge, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
};

// TODO: 今後表示を消す可能性があるため、決まり次第修正する
// const buyAndConsumeEnergy = (
//   unitEnergy: EnergyPerDay,
//   unitPrices: UnitPrice[],
//   consumedUnitPrice: number
// ) => {
//   const { buyEnergy, consumeEnergy, isBillingData } = unitEnergy;
//   const { powerUnitPrice, fuelCostAdjustmentUnitPrice, renewableEnergy } = unitPrices[0];
//   // マッチングシステムから連携された確定金額だった場合、その値を元に使用電力量を逆算する
//   const totalBuyEnergy = isBillingData
//     ? buyEnergy.reduce((prev, current) => prev + current, 0) /
//       (powerUnitPrice + fuelCostAdjustmentUnitPrice + renewableEnergy)
//     : buyEnergy.reduce((prev, current) => prev + current, 0);
//   let totalConsumeEnergy;
//   if (!consumedUnitPrice) {
//     totalConsumeEnergy = 0;
//   } else if (isBillingData) {
//     totalConsumeEnergy =
//       consumeEnergy.reduce((prev, current) => prev + current, 0) / consumedUnitPrice;
//   } else {
//     totalConsumeEnergy = consumeEnergy.reduce((prev, current) => prev + current, 0);
//   }

//   return totalBuyEnergy + totalConsumeEnergy;
// };

// const renderCheaperChargeByConsumed = (
//   unitEnergy: EnergyPerDay,
//   unitPrices: UnitPrice[],
//   consumedUnitPrice: number,
//   soldUnitPrice: number,
//   sellingPattern: string,
//   soldEnergyOwner: string,
//   unit: string,
//   intl: intlShape
// ) => {
//   const { consumeEnergy, sellEnergy, isBillingData } = unitEnergy;
//   const currencyFractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
//   const consumed = isBillingData
//     ? [consumeEnergy.reduce((prev, current) => prev + current, 0) / consumedUnitPrice]
//     : consumeEnergy;
//   let sold;
//   if (isBillingData && soldUnitPrice) {
//     sold = sellEnergy.reduce((prev, current) => prev + current, 0) / soldUnitPrice;
//   } else if (isBillingData && !soldUnitPrice) {
//     sold = 0;
//   } else {
//     sold = daytimeTotal(sellEnergy, 0, 24);
//   }
//   const cheaperByConsumed = calcCheaperByConsumedPrice(unitPrices, consumed, consumedUnitPrice);
//   const cheaperByConsumedCurrency = intl.formatNumber(cheaperByConsumed, {
//     minimumFractionDigits: currencyFractionDigits,
//     maximumFractionDigits: currencyFractionDigits,
//   });
//   // アプリ全体で電力量は少数第一位までの表示が基本方針だが、表示領域が狭いためここでは整数部分のみの表示とする
//   const generatedEnergy = (sellingPattern === 'PPA' || soldEnergyOwner === 'resident') && (
//     <span>
//       {intl.formatNumber(daytimeTotal(consumed, 0, 24) + sold, {
//         minimumFractionDigits: 0,
//         maximumFractionDigits: 0,
//       })}kWh
//     </span>
//   );

//   /*
//   年タブのみ、自家消費単価が0円の場合にはお得金額を表示しない
//   （請求確定済みの場合、consumeEnergyには電力量ではなく確定金額が入っているが、
//   単価が0円の時その確定金額から電力量を逆算することができず、お得金額が算出できないため）
//    */
//   return (unit !== 'year' && cheaperByConsumed > 0) ||
//   (unit === 'year' && cheaperByConsumed > 0 && consumedUnitPrice) ? (
//     <span>
//       発電{generatedEnergy}して{cheaperByConsumedCurrency}円お得に利用
//     </span>
//   ) : null;
// };

const renderNoBillingDate = (unit: string, unitEnergy: UnitEnergyPerDay, intl: intlShape) =>
  unit === 'year' && unitEnergy.data.length && !unitEnergy.isBillingData ? (
    <span style={style.billingUndecided}>
      {intl.formatMessage({ id: 'chartSubUnitChargeItem.noBillingData' })}
    </span>
  ) : null;

const renderCharge = (
  unit: string,
  unitEnergy: UnitEnergyPerDay,
  intl: intlShape,
  unitPrices: UnitPrice[],
  sellingPattern: string,
  soldEnergyOwner: string,
  consumedUnitPrice: number
  // soldUnitPrice: number
) => (
  <div style={style.chargeArea}>
    <div style={style.lineStyle}>
      {!!sellingPattern && <img src={buyPng} alt="buy" style={style.buyIcon} />}
      <span style={style.chargePrice}>
        {buyAndConsumeCharge(unitEnergy, intl, unitPrices, consumedUnitPrice)}
        <span style={{ fontSize: '0.8em' }}> 円</span>
      </span>
      {/* {!!sellingPattern && (
        <span style={style.energy}>
          {intl.formatNumber(buyAndConsumeEnergy(unitEnergy, unitPrices, consumedUnitPrice), {
            maximumFractionDigits: intl.formatMessage({ id: 'energy.fractionDigits' }),
            minimumFractionDigits: intl.formatMessage({ id: 'energy.fractionDigits' }),
          })}
          {intl.formatMessage({ id: 'others.kWh' })}
        </span>
      )} */}
      {renderNoBillingDate(unit, unitEnergy, intl)}
    </div>
    {/* {!!sellingPattern && (
      <div style={style.cheaperPrice}>
        {renderCheaperChargeByConsumed(
          unitEnergy,
          unitPrices,
          consumedUnitPrice,
          soldUnitPrice,
          sellingPattern,
          soldEnergyOwner,
          unit,
          intl
        )}
      </div>
    )} */}
  </div>
);

const chargeTitle = (unit: string) => (unit === 'year' ? '15%' : '25%');

export const PureChartSubUnitChargeItem = ({
  unitEnergy,
  unit,
  period,
  itemIndex,
  intl,
  unitPrices,
  sellingPattern,
  soldEnergyOwner,
  consumedUnitPrice,
}: // soldUnitPrice,
Props): Node => (
  <div className="chargeTable">
    <div style={{ width: chargeTitle(unit) }}>
      {renderPeriodTitle(unit, period, itemIndex, intl)}
    </div>
    {renderCharge(
      unit,
      unitEnergy,
      intl,
      unitPrices,
      sellingPattern,
      soldEnergyOwner,
      consumedUnitPrice
      // soldUnitPrice
    )}
  </div>
);

const mapStateToProps = (state) => ({
  unitPrices: state.userInfo.unitPrices,
  sellingPattern: state.userInfo.sellingPattern,
  soldEnergyOwner: state.userInfo.soldEnergyOwner,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
  soldUnitPrice: state.userInfo.soldUnitPrice,
});

const connectedPureChartSubUnitChargeItem: any = connect(mapStateToProps)(
  injectIntl(PureChartSubUnitChargeItem)
);
export default connectedPureChartSubUnitChargeItem;
