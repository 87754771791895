// @flow

import moment from 'moment';
import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import sizeMe from 'react-sizeme';
import Prefixer from '../lib/Prefixer';
import { makeCurrentWeighingPeriod } from '../lib/common/periodUtils';
import { convertToEndOfDay } from '../lib/common/globalUtils';
import { calcPowerCharge } from '../lib/price/calculatedPrice';
import { checkBillingPeriod, filterUnitPrices } from '../lib/price/priceUtils';
import { sum, calcTotalEnergy } from '../lib/common/calculated';
import type { UnitPrice, BillingInfo } from '../lib/types/priceInfoTypes';
import type { EnergyPerDayDetails, EnergyPerDay } from '../lib/types/electricTypes';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  leftArea: {
    marginTop: '-10px',
  },
  messagesWrap: {
    display: 'inline-block',
    fontSize: '0.8em',
  },
  criterion: {
    textAlign: 'right',
    fontSize: '0.5em',
  },
  month: {
    fontSize: '1.4em',
  },
  cost: {
    fontSize: '1.3em',
    height: '1.5em',
    marginLeft: '6px',
  },
  border: {
    borderBottom: '0px solid #cfcfcf',
    width: '80%',
    margin: '6px auto',
  },
  weighingPeriod: {
    fontSize: '0.8em',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  link: {
    textDecoration: 'none',
  },
  buttonWrapper: {
    marginTop: '12px',
  },
  arrowArea: {
    position: 'absolute',
    height: '12px',
    verticalAlign: 'middle',
    marginRight: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
  arrow: {
    fill: '#fafafa',
  },
  detailButton: {
    position: 'relative',
    background: '#298c8e',
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: 8,
    margin: '5px auto 10px',
    padding: 0,
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    height: 30,
  },
  detailButtonText: {
    justifyContent: 'center',
    border: 'none',
  },
  detailButtonDisable: {
    opacity: '0.3',
  },
  overlay: {
    color: 'white',
    opacity: 0.3,
    zIndex: 999,
  },
});

type Props = {
  energyMonthly: EnergyPerDay,
  hourlyRequesting: boolean,
  monthlyRequesting: boolean,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
};

export const PureTopMonthlyTotalAmount = ({
  energyMonthly,
  monthlyRequesting,
  intl,
  unitPrices,
  consumedUnitPrice,
  billingInfo,
  lastBillingDate,
  accountStartDate,
}: Props): Node => {
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });

  const filterUnitPricesData = filterUnitPrices(
    unitPrices,
    moment(energyMonthly.startDate).unix(),
    convertToEndOfDay(moment(energyMonthly.endDate).unix())
  );

  const buyCharges = filterUnitPricesData.map((unitPrice: UnitPrice) => {
    const filter = (energyMonthly.data || [])
      .filter(item =>
        checkBillingPeriod(
          unitPrice.sts,
          convertToEndOfDay(unitPrice.ets),
          moment(item.daily).unix(),
          null
        )
      );
    const buyEnergy = filter.reduce(
      (p: number, n: EnergyPerDayDetails) => p + sum(n.buyEnergy) || 0,
      0
    );
    return calcPowerCharge(buyEnergy, unitPrice);
  });
  const buyCharge = sum(buyCharges);

  const consumeCharge = calcTotalEnergy(energyMonthly.data, 'consumeEnergy') * consumedUnitPrice;

  const currency = intl.formatNumber(buyCharge + consumeCharge, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const { billingRequesting, billingData } = billingInfo;

  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingData,
    lastBillingDate,
    accountStartDate
  );
  const weighingStartDate =
    currentWeighingPeriod && moment(currentWeighingPeriod.startDate).isValid()
      ? moment(currentWeighingPeriod.startDate, 'YYYY-MM-DD').format('M/D')
      : '';
  const weighingEndDate =
    currentWeighingPeriod && moment(currentWeighingPeriod.endDate).isValid()
      ? moment(currentWeighingPeriod.endDate, 'YYYY-MM-DD').format('M/D')
      : '';
  const messages = defineMessages({
    currency: {
      id: 'topMonthlyTotalAmount.currency',
      description: '',
    },
    weighingPeriod: {
      id: 'topMonthlyTotalAmount.weighingPeriod',
      description: 'TOP実請求期間 {weighingStartDate}~{weighingEndDate}',
    },
  });
  return (
    <div style={style.leftArea}>
      <div>
        <div style={style.messagesWrap}>
          <div>{intl.formatMessage({ id: 'topMonthlyTotalAmount.title' })}</div>
        </div>
      </div>
      <div style={style.cost}>
        {monthlyRequesting || billingRequesting ? (
          ''
        ) : (
          intl.formatMessage(messages.currency, { currency })
        )}
      </div>
      <hr style={style.border} />
      <span style={style.weighingPeriod}>
        {intl.formatMessage(messages.weighingPeriod, { weighingStartDate, weighingEndDate })}
      </span>
      <hr style={style.border} />
    </div>
  );
};

const mapStateToProps = state => {
  const thisMonthEnergy = state.energy.monthly.find(energyMonthly => {
    const startDate = moment(energyMonthly.startDate, 'YYYY-MM-DD');
    const endDate = moment(energyMonthly.endDate, 'YYYY-MM-DD');
    return moment().isBetween(startDate, endDate.endOf('day'), '', '[]');
  });
  const energyMonthly = thisMonthEnergy ? state.energy.monthly[0] : {};

  return {
    energyMonthly,
    hourlyRequesting: state.energy.hourlyRequesting,
    unitPrices: state.userInfo.unitPrices,
    consumedUnitPrice: state.userInfo.consumedUnitPrice,
  };
};

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureTopMonthlyTotalAmount: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureTopMonthlyTotalAmount))
);
export default connectedPureTopMonthlyTotalAmount;
