// @flow

import React from 'react';
import { connect } from 'react-redux';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup } from './gtagEvent';

import headerImg from '../../../images/pickup/high_performance_rice_cooker.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  header: { width: '100%' },
  title: { textAlign: 'left', fontSize: '18px' },
  subtitle: { textAlign: 'left', fontSize: '14px' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  compare: {
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  compareNormal: { width: '38%', margin: '0 10px', fontSize: '26px' },
  compareSmall: { width: '32%', margin: '0 10px', fontSize: '22px' },
  circle: {
    borderRadius: '50%',
    width: '100%',
    height: '0',
    paddingBottom: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  circleSelf: { backgroundColor: '#ff8e86', color: '#fff' },
  circleSelfNone: { backgroundColor: '#fff', color: '#ff8e86', border: '1px solid #ff8e86' },
  circleSimilar: { backgroundColor: '#cfcfcf', color: '#999' },
  label: { fontWeight: 'normal', fontSize: '14px', marginTop: '30%', marginBottom: '0' },
  value: { margin: '-4px' },
  annotation: {
    color: '#999',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'left',
    lineHeight: '24px',
  },
  link: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',
    margin: '0 auto',
    color: '#fafafa',
    background: '#298c8e',
    height: '65px',
    borderRadius: '8px',
  },
});

const getMessage = (selfCount: number | null, similarCount: number | null) => {
  if (selfCount === null && similarCount === null) {
    return {
      title: 'こだわりの炊飯器でおうちご飯を楽しみませんか？',
      link: 'https://heartonemall.com/iot-suihanki/?utm_source=iotdroom&utm_campaign=ksother&k=cn',
      body: (
        <div style={style.body}>
          <p>
            炊飯以外にも様々な機能がついている炊飯器がブームになっているのをご存知ですか？
            <br />
            高機能な炊飯器を取り入れてみると、料理の幅が広がるかもしれませんよ。
          </p>
        </div>
      ),
    };
  }
  if (selfCount === 0) {
    return {
      title: '炊飯器の動きを検知できませんでした。',
      link: 'https://heartonemall.com/iot-suihanki/?utm_source=iotdroom&utm_campaign=kssyou&k=cn',
      body: (
        <div style={style.body}>
          <p>
            炊飯器らしい電力の動きが見られませんでした。
            世帯人数や暮らし方が似ている家では炊飯器を使っている家も多いようですね。
          </p>
          <p>
            炊飯以外にも様々な機能がついている炊飯器がブームになっているのをご存知ですか？
            高機能な炊飯器を取り入れてみると、食事の用意が楽になるかもしれませんよ。
          </p>
        </div>
      ),
    };
  }
  if (selfCount && similarCount && selfCount > similarCount) {
    return {
      title: '炊飯器の動きをたくさん検知しました。',
      link: 'https://heartonemall.com/iot-suihanki/?utm_source=iotdroom&utm_campaign=ksdai&k=cn',
      body: (
        <div style={style.body}>
          <p>
            炊飯器の電力データを比較すると、世帯人数や暮らし方が似ている家と同様にたくさんの動きがありました。忙しい中でも炊き立てご飯を用意して、今月もがんばりましたね。
          </p>
          <p>
            炊飯以外にも様々な機能がついている炊飯器がブームになっているのをご存知ですか？
            高機能な炊飯器を取り入れてみると、料理の幅が広がるかもしれませんよ。
          </p>
        </div>
      ),
    };
  }
  return {
    title: '炊飯器の動きを検知しました。',
    link: 'https://heartonemall.com/iot-suihanki/?utm_source=iotdroom&utm_campaign=kscyuu&k=cn',
    body: (
      <div style={style.body}>
        <p>
          炊飯器の電力データを比較すると、世帯人数や暮らし方が似ている家より動きが少なかったようです。
        </p>
        <p>
          炊飯以外にも様々な機能がついている炊飯器がブームになっているのをご存知ですか？
          高機能な炊飯器を取り入れてみると、食事の用意が楽になるかもしれませんよ。
        </p>
      </div>
    ),
  };
};

export type Props = {
  data: { self_count: number | null, similar_count: number | null },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureHighPerformanceRiceCooker = ({
  data: { self_count, similar_count },
  userInfo,
}: Props) => {
  const { title, body, link } = getMessage(self_count, similar_count);
  const showData = self_count !== null && similar_count !== null;
  const selfStyle =
    self_count === 0 || (self_count: any) > (similar_count: any)
      ? style.compareNormal
      : style.compareSmall;
  const selfCircleStyle = {
    ...style.circle,
    ...(self_count === 0 ? style.circleSelfNone : style.circleSelf),
  };
  const similarStyle =
    (similar_count: any) > (self_count: any) ? style.compareNormal : style.compareSmall;

  gtagShowPickup(link, userInfo, 'high_performance_rice_cooker');
  return (
    <div>
      <img src={headerImg} alt="rice" style={style.header} />
      <p style={style.title}>{title}</p>
      {showData && <p style={style.subtitle}>暮らしが似た家との炊飯器利用比較</p>}
      {showData && (
        <div style={style.compare}>
          <div style={selfStyle}>
            <div style={selfCircleStyle}>
              <p style={style.label}>自宅</p>
              <p style={style.value}>{self_count}日</p>
            </div>
          </div>
          <div style={similarStyle}>
            <div style={{ ...style.circle, ...style.circleSimilar }}>
              <p style={style.label}>似た家</p>
              <p style={style.value}>{similar_count}日</p>
            </div>
          </div>
        </div>
      )}
      {body}
      {showData && (
        <p style={style.annotation}>
          ※AIによる推定技術を使っているため、機種によって表示されなかったり、学習に時間がかかることがあります。
        </p>
      )}
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) => gtagClickPickup(e, link, userInfo, 'high_performance_rice_cooker')}
      >
        高機能炊飯器の特集を読む
      </a>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureHighPerformanceRiceCooker: any = connect(mapStateToProps)(
  PureHighPerformanceRiceCooker
);
export default connectedPureHighPerformanceRiceCooker;
