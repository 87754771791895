// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import ChartSubUnitChart from './ChartSubUnitChart';
import ChartNodata, { CHART_NO_DATA } from './ChartNoData';
import { HELP_CHART_DETAIL, HELP_CHART_DETAIL_WEEKLY } from '../actions/helpPopupTypes';
import { OPEN_POP_UP } from '../actions/types';
import Prefixer from '../lib/Prefixer';
import type { BillingInfo } from '../lib/types/priceInfoTypes';
import type { UnitEnergyPerDay } from '../lib/types/electricTypes';
import loadingAnime from '../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  body: {
    marginTop: '10px',
  },
  areaTitle: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  chartArea: {
    maxHeight: '320px',
    position: 'relative',
  },
  clear: {
    clear: 'both',
  },
  loadingAnime: {
    margin: '80px 0px',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#bbb',
    float: 'right',
  },
});

type Props = {
  requesting: boolean,
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  unit: string,
  billingInfo: BillingInfo,
  intl: intlShape,
  dispatch: Dispatch,
};

const areaTitle = (unit, intl) => {
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartSubUnit.hourlyTitle' });
    case 'week':
      return intl.formatMessage({ id: 'chartSubUnit.dailyTitle' });
    case 'month':
      return intl.formatMessage({ id: 'chartSubUnit.weeklyTitle' });
    case 'year':
      return intl.formatMessage({ id: 'chartSubUnit.monthlyTitle' });
    default:
      return '';
  }
};

const renderLoadingAnime = (intl) => (
  <div>
    <div style={style.loadingAnime}>
      <img src={loadingAnime} alt="loading" />
    </div>
    <div>{intl.formatMessage({ id: 'chartSubUnit.loading' })}</div>
  </div>
);

const renderChartArea = (
  billingInfo: BillingInfo,
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  unit: string
) => (
  <div>
    <ChartSubUnitChart unitEnergyList={unitEnergyList} billingInfo={billingInfo} unit={unit} />
  </div>
);

const hasEnergy = (
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  target: 'buyEnergy' | 'sellEnergy' | 'consumeEnergy'
): boolean =>
  (unitEnergyList || []).some((unitEnergy) =>
    unitEnergy.data.some((v) => (v[target] || []).some((value) => value > 0))
  );

const renderBody = (
  requesting: boolean,
  billingInfo: BillingInfo,
  unitEnergyList: UnitEnergyPerDay & { isCalculated?: boolean }[],
  unit: string,
  intl: intlShape
) => {
  const { billingRequesting } = billingInfo;
  if (requesting || billingRequesting) {
    return renderLoadingAnime(intl);
  }
  if (hasEnergy(unitEnergyList, 'buyEnergy')) {
    return renderChartArea(billingInfo, unitEnergyList, unit);
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

const renderInfoButton = (unit, dispatch) => {
  switch (unit) {
    case 'month':
      return (
        <div style={style.info}>
          <Icon
            icon={faQuestionCircle}
            style={style.infoButton}
            onClick={(e) =>
              dispatch({
                type: OPEN_POP_UP,
                contentType: HELP_CHART_DETAIL_WEEKLY,
                target: e.target,
              })
            }
          />
        </div>
      );
    default:
      return (
        <div style={style.info}>
          <Icon
            icon={faQuestionCircle}
            style={style.infoButton}
            onClick={(e) =>
              dispatch({
                type: OPEN_POP_UP,
                contentType: HELP_CHART_DETAIL,
                target: e.target,
              })
            }
          />
        </div>
      );
  }
};

export const PureChartSubUnit = ({
  requesting,
  unitEnergyList,
  unit,
  billingInfo,
  intl,
  dispatch,
}: Props): Node => (
  <div style={style.wrapper}>
    {renderInfoButton(unit, dispatch)}
    <div>
      <span style={style.areaTitle}>{areaTitle(unit, intl)}</span>
    </div>
    <div style={style.body}>{renderBody(requesting, billingInfo, unitEnergyList, unit, intl)}</div>
  </div>
);

const mapStateToProps = (state) => ({
  billingInfo: state.billingInfo,
});

const connectedPureChartSubUnit: any = connect(mapStateToProps)(injectIntl(PureChartSubUnit));
export default connectedPureChartSubUnit;
