// @flow

import React, { Component } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Prefixer from '../../../../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  tips: { border: '1px solid #298c8e', borderRadius: '8px', fontSize: '14px', margin: '10px 0' },
  title: {
    color: '#298c8e',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
  },
  body: {
    fontWeight: 'normal',
    padding: '12px',
    textAlign: 'left',
    borderTop: '1px solid #298c8e',
  },
  iconSvg: { width: '9px', verticalAlign: 'middle' },
  icon: { fill: '#298c8e' },
});

const ClosedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={style.iconSvg}>
    <path style={style.icon} d="M0 0 L100 0 L50 90 Z" />
  </svg>
);

const OpenedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style={style.iconSvg}>
    <path style={style.icon} d="M50 10 L0 100 L100 100 Z" />
  </svg>
);

export type Props = {
  title: string,
  children: any,
  onOpenClick: Function,
};

export type State = {
  isOpen: boolean,
};

class Tips extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false };
    (this: any).handleToggle = this.handleToggle.bind(this);
    (this: any).handleOpen = this.handleOpen.bind(this);
    (this: any).handleCollapse = this.handleCollapse.bind(this);
  }
  handleOpen() {
    this.setState({ isOpen: true });
    this.props.onOpenClick();
  }
  handleCollapse() {
    this.setState({ isOpen: false });
  }
  handleToggle() {
    if (this.state.isOpen) {
      this.handleCollapse();
    } else {
      this.handleOpen();
    }
  }
  render() {
    return (
      <div style={style.tips}>
        <div style={style.title} onClick={() => this.handleToggle()}>
          <span>{this.props.title}</span>
          {this.state.isOpen ? <OpenedIcon /> : <ClosedIcon />}
        </div>
        <AnimatePresence initial={false}>
          {this.state.isOpen && (
            <motion.section
              key="body"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { height: 'auto', overflow: 'hidden' },
                collapsed: { height: 0, overflow: 'hidden' },
              }}
              transition={{ duration: 0.4 }}
            >
              <div style={style.body}>{this.props.children}</div>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    );
  }
}

export default Tips;
