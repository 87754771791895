// @flow
import moment from 'moment';
import type { State, Action } from 'redux';
import type { EnergyHourly } from '../../lib/types/electricTypes';

const successApiUseStatsHourly = (energyState, targets) => {
  let unitEnergy: EnergyHourly[] = targets.map(target => ({
    dateTime: moment.unix(target.timestamp).format('YYYY-MM-DD'),
    hour: Number(target.name.replace('timeSeries', '')),
    buyEnergy: target.buy_p.length ? target.buy_p[0] : 0,
    sellEnergy: target.sell_p.length ? target.sell_p[0] : 0,
    consumeEnergy: target.consume_p.length ? target.consume_p[0] : 0,
  }));
  // トップページのTimeBarの最新時刻と現在時刻が一致しない場合、直近2時間のデータを再取得する
  if (targets.length === 2) {
    const hoursToUpdate = unitEnergy.map(value => value.hour);
    const rest10Hours = energyState.hourly.filter(value => hoursToUpdate.indexOf(value.hour) < 0);
    unitEnergy = [...unitEnergy, ...rest10Hours];
  }
  return {
    ...energyState,
    hourly: unitEnergy,
  };
};

export default (energyState: State, action: Action = {}): State => {
  // don't acquire multiple units at the same time. (OK: hourly / NG: hourly and daily)
  const { payload } = action || {};
  const targetKeys = Object.keys(payload).filter(key => key.match(/^timeSeries/));
  if (!targetKeys.length) {
    return energyState;
  }
  const targets = targetKeys.map(key => ({
    ...payload[key],
    name: key,
  }));

  return successApiUseStatsHourly(energyState, targets);
};
