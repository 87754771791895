// @flow

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import sizeMe from 'react-sizeme';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { Dispatch } from 'redux';
import ChartForecastLineChart from './ChartForecastLineChart';
import ChartNodata, { CHART_NO_DATA } from './ChartNoData';
import Prefixer from '../lib/Prefixer';
import { isCurrentMonth } from '../lib/common/periodUtils';
import { isForecastExceeded } from '../lib/price/forecastPriceUtils';
import {
  calcCheaperByConsumedPrice,
  calcPowerCharge,
  calcBuyCharge,
} from '../lib/price/calculatedPrice';
import solarPng from '../images/icn_solar.png';
import { OPEN_POP_UP } from '../actions/types';
import { HELP_GENERATION_GAIN, HELP_FUEL_ADJUSTMENT_FEE_DISCOUNT } from '../actions/helpPopupTypes';
import { calcTotalEnergy } from '../lib/common/calculated';
import type { UnitPrice, BillingInfo, CurrentBillingInfo } from '../lib/types/priceInfoTypes';
import type {
  EnergyPerDay,
  UnitEnergyPerDay,
  EnergyPerDayDetails,
} from '../lib/types/electricTypes';
import loadingAnime from '../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: {
    verticalAlign: 'middle',
  },
  criterion: {
    fontSize: '0.7em',
    paddingLeft: '5px',
  },
  solarIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '3px',
    marginBottom: '3px',
    width: '17px',
  },
  untilTodayTitle: {
    verticalAlign: 'middle',
  },
  forecastTitle: {
    verticalAlign: 'middle',
  },
  charge: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '2.0em',
    width: '70%',
    borderBottom: '2px solid #ff9086',
  },
  billingUndecided: {
    color: '#ff8f86',
    margin: 'auto 0px auto 10px',
    padding: '3px 10px',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '0.3em',
    fontWeight: 'normal',
    border: 'solid 1px #ff8f86',
    borderRadius: '50px',
  },
  currentTitleArea: {
    display: 'table',
    width: '100%',
    margin: '20px auto',
  },
  currentTitle: {
    display: 'table-cell',
    width: '50%',
  },
  currentChargeArea: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '1.8em',
    width: '95%',
  },
  currentChargeSmallArea: {
    margin: '10px auto 0px',
    textAlign: 'center',
    fontSize: '1.6em',
    width: '95%',
  },
  currentTitleDefaultArea: {
    borderRadius: '10px',
    border: '3px solid #fff',
  },
  currentTitleDefault: {
    color: '#999',
    background: '#ebebeb',
    borderRadius: '10px',
    border: '3px solid #ebebeb',
  },
  currentTitleDefaultBorder: {
    borderBottom: '3px solid #999',
  },
  forecastAlert10OrMore: {
    color: '#ff5354',
    background: '#faeded',
    borderRadius: '10px',
    border: '3px solid #faeded',
  },
  forecastAlert10OrMoreBorder: {
    borderBottom: '3px solid #ff5354',
  },
  forecastAlert5OrMore: {
    color: '#ff9657',
    background: '#fcebe0',
    borderRadius: '10px',
    border: '3px solid #fcebe0',
  },
  forecastAlert5OrMoreBorder: {
    borderBottom: '3px solid #ff9657',
  },
  forecastAlert5OrLess: {
    color: '#ffb657',
    background: '#fff2e0',
    borderRadius: '10px',
    border: '3px solid #fff2e0',
  },
  forecastAlert5OrLessBorder: {
    borderBottom: '3px solid #ffb657',
  },
  currentCharge: {
    padding: '5px 8px 15px',
    textAlign: 'center',
  },
  currentSmall: {
    fontSize: '0.8em',
    padding: '5px 10px',
  },
  small: {
    fontSize: '0.8em',
  },
  loadingAnime: {
    margin: '50px 0px',
  },
  loadingMessage: {
    margin: '10px auto 0px',
    textAlign: 'center',
    width: '60%',
    color: '#ff9086',
    borderBottom: '2px solid #ff9086',
  },
  info: {
    top: '-12px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#bbb',
    float: 'right',
  },
  solarMessage: {
    width: '85%',
    fontWeight: 'lighter',
    textIndent: '-20px',
    textAlign: 'left',
    paddingLeft: '10px',
    margin: '0 auto',
    fontSize: '0.9em',
    lineHeight: '22px',
  },
  cheaperPriceMessage: {
    marginTop: '20px',
    fontWeight: 'lighter',
  },
  cheaperPrice: {
    fontWeight: 'bolder',
  },
  container: {
    color: '#454545',
    fontSize: '14px',
    fontStyle: 'normal',
    fontFamily: 'Inter',
    fontWeight: '400',
    display: 'flex',
    justifyContent: 'center',
    margin: '5px auto',
    lineHeight: '17px',
  },
  itemHeadline: {
    background: '#ebebeb',
    borderRadius: '10px',
    fontFamily: 'Noto Sans JP',
    fontWeight: '700',
    fontSize: '12px',
    padding: '0px 10px 2px',
  },
  item: {
    textAlign: 'left',
    margin: '0 5px',
  },
  itemRight: {
    textAlign: 'right',
    margin: '0 10px',
  },
  margin: {
    margin: '5px auto',
  },
  discountItem: {
    textAlign: 'left',
    margin: '0px',
  },
  discount: {
    background: '#ff9a92',
    borderRadius: '5px',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 12,
    fontStyle: 'normal',
    color: '#fafafa',
    lineHeight: '15px',
    padding: '2px 5px',
  },
  discountHelp: {
    padding: '2px 0px',
  },
});

type Props = {
  requesting: boolean,
  unitEnergy: EnergyPerDay | null,
  currentEnergyList: UnitEnergyPerDay[],
  estimatedEnergy: EnergyPerDay | null,
  currentBillingInfo: CurrentBillingInfo,
  billingInfo: BillingInfo,
  unitPrices: UnitPrice[],
  lastBillingDate: string,
  accountStartDate: string,
  unit: string,
  period: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
  sellingPattern: string,
  consumedUnitPrice: number,
  dispatch: Dispatch,
};

const AlertAreaStyle = (currentChargeArea, overPercentage) => {
  if (overPercentage === 0) {
    return { ...currentChargeArea, ...style.currentTitleDefault };
  }
  if (overPercentage < 5) {
    return { ...currentChargeArea, ...style.forecastAlert5OrLess };
  }
  if (overPercentage < 10) {
    return { ...currentChargeArea, ...style.forecastAlert5OrMore };
  }
  return { ...currentChargeArea, ...style.forecastAlert10OrMore };
};

const alertStyle = (overPercentage) => {
  if (overPercentage === 0) {
    return { ...style.currentSmall, ...style.currentTitleDefaultBorder };
  }
  if (overPercentage < 5) {
    return { ...style.currentSmall, ...style.forecastAlert5OrLessBorder };
  }
  if (overPercentage < 10) {
    return { ...style.currentSmall, ...style.forecastAlert5OrMoreBorder };
  }
  return { ...style.currentSmall, ...style.forecastAlert10OrMoreBorder };
};

const criterion = (intl: intlShape) => intl.formatMessage({ id: 'others.criterion' });
const title = (
  billingInfo: BillingInfo,
  lastBillingDate: string,
  unit: string,
  period: string,
  intl: intlShape
) => {
  const { billingRequesting, weighingPeriodInitialized, currentWeighingPeriod } = billingInfo;
  if (billingRequesting) {
    return '';
  }
  switch (unit) {
    case 'day':
      return intl.formatMessage({ id: 'chartCharge.dailyTitle' });
    case 'week':
      return intl.formatMessage({ id: 'chartCharge.weeklyTitle' });
    case 'month':
      if (isCurrentMonth(weighingPeriodInitialized, currentWeighingPeriod, unit, period)) {
        return intl.formatMessage({ id: 'chartCharge.currentMonthTitle' });
      }
      return intl.formatMessage({ id: 'chartCharge.monthlyTitle' });
    case 'year':
      return intl.formatMessage({ id: 'chartCharge.yearlyTitle' });
    default:
      return '';
  }
};
const messages = defineMessages({
  currency: {
    id: 'chartCharge.currency',
    description: 'トータル電気代',
  },
  forecastCurrency: {
    id: 'chartCharge.forecastCurrency',
    description: '予測電気代',
  },
});

const renderFuelAdjustmentFeeDiscount = (period: string, dispatch: Dispatch) => {
  const format = 'YYYY-MM';
  const current = moment(period, format);
  // 電気価格激変緩和対策の期間: 2023/02~2024/06
  if (!current.isBetween(moment('2023-02', format), moment('2024-06', format), 'month', '[]')) {
    return null;
  }

  return (
    <>
      <div style={style.discountItem}>
        <div style={{ ...style.margin, ...style.discount }}>割引</div>
      </div>
      <div style={style.item}>
        <Icon
          icon={faQuestionCircle}
          style={{ ...style.infoButton, ...style.discountHelp }}
          onClick={(e) =>
            dispatch({
              type: OPEN_POP_UP,
              contentType: HELP_FUEL_ADJUSTMENT_FEE_DISCOUNT,
              target: e.target,
              period,
            })
          }
        />
      </div>
    </>
  );
};

const renderBillingItems = (
  currentBillingInfo: CurrentBillingInfo,
  period: string,
  intl: intlShape,
  dispatch: Dispatch
) => {
  const { fuelCostAdjustmentAmount, renewableEnergyPowerPromotionSurchargeAmount } =
    currentBillingInfo;

  return (
    <div style={style.container}>
      <div style={style.item}>
        <div style={{ ...style.itemHeadline, ...style.margin }}>内訳</div>
      </div>
      <div style={style.item}>
        <div style={style.margin}>燃料費調整</div>
        <div style={style.margin}>再エネ発電賦課金</div>
      </div>
      <div style={style.item}>
        <div style={style.margin}>{intl.formatNumber(fuelCostAdjustmentAmount)}円</div>
        <div style={style.margin}>
          {intl.formatNumber(renewableEnergyPowerPromotionSurchargeAmount)}円
        </div>
      </div>
      {renderFuelAdjustmentFeeDiscount(period, dispatch)}
    </div>
  );
};

const renderBuyCharge = (
  unitEnergy: EnergyPerDay,
  estimatedEnergy: EnergyPerDay,
  currentBillingInfo: CurrentBillingInfo,
  unitPrices: UnitPrice[],
  unit: string,
  period: string,
  intl: intlShape,
  sellingPattern: string,
  consumedUnitPrice: number,
  dispatch: Dispatch
) => {
  const { billingAmount, consumedBillingAmount } = currentBillingInfo;
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });

  const buyCharge = calcBuyCharge(unitPrices, unitEnergy);

  const consumedCharge = calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice;

  const totalCharge = buyCharge + consumedCharge;
  const cheaperCharge = calcCheaperByConsumedPrice(unitPrices, estimatedEnergy, consumedUnitPrice);
  const totalBillingAmount = billingAmount + consumedBillingAmount;
  const currency =
    unit === 'month'
      ? intl.formatNumber(totalBillingAmount === 0 ? totalCharge : totalBillingAmount, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        })
      : intl.formatNumber(totalBillingAmount + totalCharge, {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        });
  const cheaperByConsumedCurrency = intl.formatNumber(cheaperCharge, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const cheaperByConsumedPrice =
    cheaperCharge > 0 && sellingPattern ? (
      <div style={style.cheaperPriceMessage}>
        <img src={solarPng} alt="solar" style={style.solarIcon} />
        発電して
        <span style={style.cheaperPrice}>
          約<span style={{ fontSize: '1.3em' }}>{cheaperByConsumedCurrency}</span>円
        </span>
        お得になりました
      </div>
    ) : null;
  return (
    <div>
      <div style={style.charge}>
        <span style={style.small}>{intl.formatMessage(messages.currency, { currency })}</span>
        {unit === 'month' && totalBillingAmount === 0 && (
          <span style={style.billingUndecided}>
            {intl.formatMessage({ id: 'chartCharge.noBillingData' })}
          </span>
        )}
      </div>
      {unit === 'month' &&
        totalBillingAmount !== 0 &&
        renderBillingItems(currentBillingInfo, period, intl, dispatch)}
      {cheaperByConsumedPrice}
    </div>
  );
};

const renderCurrentBuyCharge = (
  unitEnergy: EnergyPerDay,
  billingInfo: BillingInfo,
  unitPrices: UnitPrice[],
  lastBillingDate: string,
  accountStartDate: string,
  unit: string,
  period: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
  sellingPattern: string,
  consumedUnitPrice: number
) => {
  const { billingData, forecastPower, consumedForecastPower } = billingInfo;
  const currentChargeArea =
    size && size.width < 300 ? style.currentChargeSmallArea : style.currentChargeArea;
  const fractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });

  const buyCharge = calcBuyCharge(unitPrices, unitEnergy);

  const consumeCharge = calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice;
  const currency = intl.formatNumber(buyCharge + consumeCharge, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  const forecastValue = calcPowerCharge(forecastPower, unitPrices[unitPrices.length - 1]);

  const forecastCurrency = intl.formatNumber(
    forecastValue + consumedForecastPower * consumedUnitPrice,
    {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }
  );

  const overPercentage = isForecastExceeded(
    billingData,
    lastBillingDate,
    accountStartDate,
    forecastValue
  );

  return (
    <div>
      {!billingInfo.billingRequesting && (
        <div style={style.currentTitleArea}>
          <div style={style.currentTitle}>
            <span style={style.untilTodayTitle}>
              {intl.formatMessage({ id: 'chartCharge.currentMonthlyTitle' })}
            </span>
            <div style={{ ...currentChargeArea, ...style.currentTitleDefaultArea }}>
              <div style={style.currentCharge}>
                <span style={{ ...style.currentSmall, ...style.currentTitleDefaultBorder }}>
                  {intl.formatMessage(messages.currency, { currency })}
                </span>
              </div>
            </div>
          </div>
          <div style={style.currentTitle}>
            <span style={style.forecastTitle}>
              {intl.formatMessage({ id: 'chartCharge.forecastTitle' })}
            </span>
            <div style={AlertAreaStyle(currentChargeArea, overPercentage)}>
              <div style={style.currentCharge}>
                <span style={alertStyle(overPercentage)}>
                  {intl.formatMessage(messages.forecastCurrency, { forecastCurrency })}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!!sellingPattern && (
        <p style={style.solarMessage}>
          <img src={solarPng} alt="solar" style={style.solarIcon} />
          発電してお得になった分を差し引いた電気代です。発電量は天気によって変わるので、予想からずれることがあります。
        </p>
      )}
    </div>
  );
};

const renderInfoButton = (dispatch: Dispatch) => (
  <div style={style.info}>
    <Icon
      icon={faQuestionCircle}
      style={style.infoButton}
      onClick={(e) =>
        dispatch({
          type: OPEN_POP_UP,
          contentType: HELP_GENERATION_GAIN,
          target: e.target,
        })
      }
    />
  </div>
);

const renderLoading = (intl: intlShape) => (
  <div>
    <div style={style.loadingAnime}>
      <img src={loadingAnime} alt="loading" />
    </div>
    <div style={style.loadingMessage}>{intl.formatMessage({ id: 'chartCharge.loading' })}</div>
  </div>
);

const renderChartArea = (currentEnergyList: UnitEnergyPerDay[], unit: string) => (
  <div>
    <ChartForecastLineChart currentEnergyList={currentEnergyList} unit={unit} />
  </div>
);

const hasEnergy = (
  energy: EnergyPerDayDetails[],
  target: 'buyEnergy' | 'sellEnergy' | 'consumeEnergy'
): boolean => (energy || []).some((v) => (v[target] || []).some((value) => value > 0));

const renderCurrentChartArea = (
  billingInfo: BillingInfo,
  currentEnergyList: UnitEnergyPerDay[],
  unit: string
) => {
  const { weighingPeriodInitialized } = billingInfo;
  if (
    weighingPeriodInitialized &&
    currentEnergyList.some((energy) => hasEnergy(energy.data, 'buyEnergy'))
  ) {
    return renderChartArea(currentEnergyList, unit);
  }
  return <div />;
};
const renderBody = (
  requesting: boolean,
  unitEnergy: EnergyPerDay,
  estimatedEnergy: EnergyPerDay,
  currentBillingInfo: CurrentBillingInfo,
  billingInfo: BillingInfo,
  unitPrices: UnitPrice[],
  lastBillingDate: string,
  accountStartDate: string,
  unit: string,
  period: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
  sellingPattern: string,
  consumedUnitPrice: number,
  dispatch: Dispatch
) => {
  const { billingAmount, consumedBillingAmount } = currentBillingInfo;
  const { weighingPeriodInitialized, currentWeighingPeriod } = billingInfo;

  if (
    !isCurrentMonth(weighingPeriodInitialized, currentWeighingPeriod, unit, period) &&
    (hasEnergy(unitEnergy.data, 'buyEnergy') ||
      billingAmount > 0 ||
      hasEnergy(unitEnergy.data, 'consumeEnergy') ||
      consumedBillingAmount > 0)
  ) {
    return renderBuyCharge(
      unitEnergy,
      estimatedEnergy,
      currentBillingInfo,
      unitPrices,
      unit,
      period,
      intl,
      sellingPattern,
      consumedUnitPrice,
      dispatch
    );
  }
  if (
    isCurrentMonth(weighingPeriodInitialized, currentWeighingPeriod, unit, period) &&
    (hasEnergy(unitEnergy.data, 'buyEnergy') ||
      billingAmount > 0 ||
      hasEnergy(unitEnergy.data, 'consumeEnergy') ||
      consumedBillingAmount > 0)
  ) {
    return renderCurrentBuyCharge(
      unitEnergy,
      billingInfo,
      unitPrices,
      lastBillingDate,
      accountStartDate,
      unit,
      period,
      intl,
      size,
      sellingPattern,
      consumedUnitPrice
    );
  }
  return <ChartNodata type={CHART_NO_DATA} />;
};

export const PureChartCharge = ({
  requesting,
  unitEnergy,
  currentEnergyList,
  estimatedEnergy,
  currentBillingInfo,
  billingInfo,
  unitPrices,
  lastBillingDate,
  accountStartDate,
  unit,
  period,
  intl,
  size,
  sellingPattern,
  consumedUnitPrice,
  dispatch,
}: Props) => (
  <div>
    {!!sellingPattern && renderInfoButton(dispatch)}
    <div>
      <span style={style.title}>{title(billingInfo, lastBillingDate, unit, period, intl)}</span>
      {unit === 'month' &&
        !requesting &&
        currentBillingInfo.billingAmount + currentBillingInfo.consumedBillingAmount === 0 &&
        !isCurrentMonth(
          billingInfo.weighingPeriodInitialized,
          billingInfo.currentWeighingPeriod,
          unit,
          period
        ) && <span style={style.criterion}>{criterion(intl)}</span>}
    </div>
    {requesting || !unitEnergy || !estimatedEnergy ? (
      renderLoading(intl)
    ) : (
      <div>
        {isCurrentMonth(
          billingInfo.weighingPeriodInitialized,
          billingInfo.currentWeighingPeriod,
          unit,
          period
        ) && renderCurrentChartArea(billingInfo, currentEnergyList, unit)}
        <div>
          {renderBody(
            requesting,
            unitEnergy,
            estimatedEnergy,
            currentBillingInfo,
            billingInfo,
            unitPrices,
            lastBillingDate,
            accountStartDate,
            unit,
            period,
            intl,
            size,
            sellingPattern,
            consumedUnitPrice,
            dispatch
          )}
        </div>
      </div>
    )}
  </div>
);

const mapStateToProps = (state) => ({
  billingInfo: state.billingInfo,
  lastBillingDate: state.userInfo.lastBillingDate,
  unitPrices: state.userInfo.unitPrices,
  sellingPattern: state.userInfo.sellingPattern,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChartCharge: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartCharge))
);
export default connectedPureChartCharge;
