/* eslint-env browser */
// @flow

declare var config: Object;

export default (
  location: ?string,
  contractorId: ?string,
  serviceProviderId: ?string,
  appUserId: ?string,
  status: string = ''
) => {
  if (contractorId && serviceProviderId && appUserId) {
    window.gtag('event', 'page_view', {
      page_path: location,
      user_id: appUserId,
      contractor_id: contractorId,
      service_provider: serviceProviderId,
      status,
    });
  }
};
