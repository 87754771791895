// @flow

import React from 'react';
import { connect } from 'react-redux';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Link as DomLink } from 'react-router-dom';
import TopMonthlyTotalAmount from './TopMonthlyTotalAmount';
import TopApplianceRanking from './TopApplianceRanking';
import { OPEN_POP_UP } from '../actions/types';
import { HELP_HOME_TOP } from '../actions/helpPopupTypes';
import Prefixer from '../lib/Prefixer';
import USER_STATUS from '../../common/user-status';
import { makeCurrentWeighingPeriod, makeValidPeriod } from '../lib/common/periodUtils';

import loadingAnime from '../images/loading.gif';
import imperfectLearningRanking from '../images/img_study_s.png';

const { IMPERFECT_LEARNING, LEARNING } = USER_STATUS;

const prefixer = new Prefixer();
const style = prefixer.prefix({
  areaWrapper: {
    position: 'relative',
  },
  container: {
    display: 'table',
    width: '100%',
  },
  leftCell: {
    display: 'table-cell',
    width: '45%',
    verticalAlign: 'middle',
    fontSize: '1.5em',
    fontWeight: 'bold',
  },
  rightCell: {
    display: 'table-cell',
    width: '55%',
    verticalAlign: 'middle',
  },
  tableHeader: {
    width: '100%',
    textAlign: 'right',
  },
  infoButton: {
    fontSize: '22px',
    marginRight: '10px',
    marginTop: '5px',
    color: '#bbb',
  },
  loadingAnime: {
    zIndex: '100',
    position: 'absolute',
    top: '41%',
    left: '43%',
  },
  img: {
    width: '100%',
  },
  paddingButton: {
    width: '40%',
  },
  buttons: { display: 'flex', justifyContent: 'center' },
  detailLink: { textDecoration: 'none', width: '55%' },
  reportLink: { textDecoration: 'none', width: '40%' },
  arrowArea: {
    position: 'absolute',
    height: '12px',
    verticalAlign: 'middle',
    marginRight: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
  detailArrow: { fill: '#fafafa' },
  reportArrow: { fill: '#298c8e' },
  linkButton: {
    position: 'relative',
    background: '#298c8e',
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: 8,
    margin: '0 auto 10px',
    padding: 0,
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    height: 38,
  },
  detailButton: {
    background: '#298c8e',
    color: '#fff',
    border: '1px solid #298c8e',
  },
  reportButton: {
    background: '#fff',
    color: '#298c8e',
    border: '1px solid #298c8e',
  },
  detailButtonText: {
    justifyContent: 'center',
    border: 'none',
  },
  disableButton: {
    opacity: '0.3',
  },
});

const renderLoadingAnime = (requesting) => {
  if (requesting) {
    return (
      <div style={style.loadingAnime}>
        <img src={loadingAnime} alt="loading" />
      </div>
    );
  }
  return null;
};

const renderTopApplianceRanking = (
  requesting,
  userStatus,
  billingInfo,
  lastBillingDate,
  accountStartDate
) => {
  if ([IMPERFECT_LEARNING, LEARNING].includes(userStatus)) {
    return (
      <div style={{ ...style.rightCell, paddingBottom: 0 }}>
        <img style={style.img} src={imperfectLearningRanking} alt="imperfect_learning" />
      </div>
    );
  }
  return (
    <TopApplianceRanking
      requesting={requesting}
      billingInfo={billingInfo}
      lastBillingDate={lastBillingDate}
      accountStartDate={accountStartDate}
    />
  );
};

const renderDetailButton = (requesting, billingMonth, intl) => {
  const unit = 'month';
  const period = makeValidPeriod(
    unit,
    unit === 'week' ? moment() : moment(billingMonth, 'YYYY-MM')
  );
  const buttonStyle = requesting
    ? { ...style.linkButton, ...style.detailButton, ...style.disableButton }
    : { ...style.linkButton, ...style.detailButton };
  const arrowSvg = () => (
    <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path style={style.detailArrow} d="M179,100,39,180V20Z" />
    </svg>
  );

  return (
    <DomLink style={style.detailLink} to={requesting ? '#' : `/chart/${unit}/${period}`}>
      <div style={{ ...buttonStyle }}>
        <span style={style.detailButtonText}>
          {intl.formatMessage({ id: 'topMonthlyTotalAmount.detailButton' })}
        </span>
        {arrowSvg()}
      </div>
    </DomLink>
  );
};

const renderReportButton = (requesting, lastBillingDate, hasInvoice, intl) => {
  const buttonStyle = requesting
    ? { ...style.linkButton, ...style.reportButton, ...style.disableButton }
    : { ...style.linkButton, ...style.reportButton };
  const arrowSvg = () => (
    <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path style={style.reportArrow} d="M179,100,39,180V20Z" />
    </svg>
  );

  if (!hasInvoice) {
    return <div style={style.paddingButton} />;
  }

  return (
    <DomLink style={style.reportLink} to={requesting ? '#' : `/report/${lastBillingDate}`}>
      <div style={{ ...buttonStyle }}>
        <span style={style.detailButtonText}>
          {intl.formatMessage({ id: 'topMonthlyTotalAmount.reportButton' })}
        </span>
        {arrowSvg()}
      </div>
    </DomLink>
  );
};

type Props = {
  requesting: boolean,
  hourlyRequesting: boolean,
  dispatch: Dispatch,
  userStatus: string,
  billingInfo: {
    billingRequesting: boolean,
    predictRequesting: boolean,
    billingData: Array<{
      billingDate: string,
      startDate: string,
      endDate: string,
      billingAmount: number,
      consumedBillingAmount: number | null,
      soldAmount: number | null,
    }>,
    forecastPower: number,
  },
  lastBillingDate: string,
  accountStartDate: string,
  hasInvoice: boolean,
  intl: intlShape,
};

export const PureTopTotalAmountArea = ({
  requesting,
  hourlyRequesting,
  userStatus,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  hasInvoice,
  dispatch,
  intl,
}: Props): Node => {
  const someRequesting = requesting || hourlyRequesting || billingInfo.billingRequesting;
  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingInfo.billingData,
    lastBillingDate,
    accountStartDate
  );
  const billingMonth =
    currentWeighingPeriod && moment(currentWeighingPeriod.billingMonth).isValid()
      ? moment(currentWeighingPeriod.billingMonth, 'YYYY-MM').format('YYYY-MM')
      : '';
  return (
    <div style={style.areaWrapper}>
      <div style={style.tableHeader}>
        <Icon
          style={style.infoButton}
          icon={faQuestionCircle}
          onClick={(e) =>
            dispatch({
              type: OPEN_POP_UP,
              contentType: HELP_HOME_TOP,
              target: e.target,
            })
          }
        />
      </div>
      {renderLoadingAnime(
        requesting || billingInfo.billingRequesting || billingInfo.predictRequesting
      )}
      <div style={style.container}>
        <div style={style.leftCell}>
          <TopMonthlyTotalAmount
            monthlyRequesting={requesting}
            billingInfo={billingInfo}
            lastBillingDate={lastBillingDate}
            accountStartDate={accountStartDate}
          />
        </div>
        <div style={style.rightCell}>
          {renderTopApplianceRanking(
            requesting,
            userStatus,
            billingInfo,
            lastBillingDate,
            accountStartDate
          )}
        </div>
      </div>
      <div style={style.buttons}>
        {renderReportButton(someRequesting, lastBillingDate, hasInvoice, intl)}
        {renderDetailButton(someRequesting, billingMonth, intl)}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  requesting: state.energy.monthlyRequesting,
  hourlyRequesting: state.energy.hourlyRequesting,
  userStatus: state.userInfo.status,
  lastBillingDate: state.userInfo.lastBillingDate,
  accountStartDate: state.userInfo.accountStartDate,
  hasInvoice: state.userInfo.hasInvoice,
});

const connectedPureTopTotalAmountArea: any = connect(mapStateToProps)(
  injectIntl(PureTopTotalAmountArea)
);
export default connectedPureTopTotalAmountArea;
