/* eslint-env browser */
// @flow

import type { Store, Middleware, Action } from 'redux';
import { OPEN_POP_UP } from '../../actions/types';
import { APPLIANCE_FOUND } from '../../actions/alertPopupTypes';

export default (store: Store) => (next: Middleware) => (action: Action) => {
  const { popup, tutorial } = store.getState();
  if (
    action.type === OPEN_POP_UP &&
    !tutorial.tutorialFinished &&
    popup.contentType === APPLIANCE_FOUND
  ) {
    // prevent all pop ups from opening if tutorial is not finished
    next();
    return;
  }
  next(action);
};
