// @flow

import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import {
  VictoryContainer,
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTheme,
  Rect,
} from 'victory';
import sizeMe from 'react-sizeme';
import { floorForecastValue, isForecastExceeded } from '../lib/price/forecastPriceUtils';
import { calcPowerCharge, calcBuyCharge } from '../lib/price/calculatedPrice';
import { calcTotalEnergy } from '../lib/common/calculated';
import type { UnitPrice, BillingInfo } from '../lib/types/priceInfoTypes';
import type { UnitEnergyPerDay } from '../lib/types/electricTypes';

const xAxisStyle = {
  grid: { fill: '#cfcfcf', strokeWidth: 1 },
  axis: { stroke: '#454545', strokeWidth: 1 },
  ticks: { stroke: '#454545', size: 0 },
  tickLabels: { fontSize: 12, fill: '#454545' },
  fill: '#ebebeb',
};

type Props = {
  currentEnergyList: UnitEnergyPerDay[],
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unit: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
  predictRequesting: boolean,
};

const renderMonthAxis = (intl: intlShape) => (
  // 位置調整のためx軸ラベル位置を変更
  <VictoryAxis
    tickValues={[4, 9, 14, 19, 24, 29]}
    tickFormat={(x) => {
      if (x === 4) {
        return intl.formatMessage({ id: 'ChartForecastLineChart.5day' });
      }
      if (x === 9) {
        return intl.formatMessage({ id: 'ChartForecastLineChart.10day' });
      }
      if (x === 14) {
        return intl.formatMessage({ id: 'ChartForecastLineChart.15day' });
      }
      if (x === 19) {
        return intl.formatMessage({ id: 'ChartForecastLineChart.20day' });
      }
      if (x === 24) {
        return intl.formatMessage({ id: 'ChartForecastLineChart.25day' });
      }
      if (x === 29) {
        return intl.formatMessage({ id: 'ChartForecastLineChart.30day' });
      }
      return '';
    }}
    style={xAxisStyle}
  />
);

const forecastPowerStyle = (overPercentage: number) => {
  if (overPercentage === 0) {
    return {
      data: { stroke: '#fff', strokeWidth: 5 },
      labels: {
        fill: '#999',
        fontSize: 13,
        padding: -8,
      },
    };
  }
  if (overPercentage < 5) {
    return {
      data: { stroke: '#fff', strokeWidth: 5 },
      labels: {
        fill: '#ffb657',
        fontSize: 13,
        padding: -8,
      },
    };
  }
  if (overPercentage < 10) {
    return {
      data: { stroke: '#fff', strokeWidth: 5 },
      labels: {
        fill: '#ff9657',
        fontSize: 13,
        padding: -8,
      },
    };
  }
  return {
    data: { stroke: '#fff', strokeWidth: 5 },
    labels: {
      fill: '#ff5354',
      fontSize: 13,
      padding: -8,
    },
  };
};

const forecastPowerLineStyle = (overPercentage: number) => {
  if (overPercentage === 0) {
    return {
      data: { stroke: '#999', strokeWidth: 1 },
    };
  }
  if (overPercentage < 5) {
    return {
      data: { stroke: '#ffb657', strokeWidth: 1 },
    };
  }
  if (overPercentage < 10) {
    return {
      data: { stroke: '#ff9657', strokeWidth: 1 },
    };
  }
  return {
    data: { stroke: '#ff5354', strokeWidth: 1 },
  };
};

/**
 * X軸ラベル
 */
const renderXAxis = (unit: string, intl: intlShape) => {
  switch (unit) {
    case 'month':
      return renderMonthAxis(intl);
    default:
      return <VictoryAxis />;
  }
};

/**
 * 今日までの折れ線グラフ
 */
const renderBuyLineData = (
  currentEnergyList: UnitEnergyPerDay[],
  unitPrices: UnitPrice[],
  consumedUnitPrice: number
) => {
  const buyLineData = [];
  const buyLineDataList = currentEnergyList.filter((unitEnergy) => unitEnergy.period);

  buyLineDataList.forEach((unitEnergy, index) => {
    const buyPrice = calcBuyCharge(unitPrices, unitEnergy);
    const consumePrice = calcTotalEnergy(unitEnergy.data, 'consumeEnergy') * consumedUnitPrice;
    buyLineData.push({
      x: index,
      y: index === 0 ? buyPrice + consumePrice : buyLineData[index - 1].y + buyPrice + consumePrice,
    });
  });

  return buyLineData;
};

/**
 * 予測電気代折れ線グラフ
 */
const renderForecastPowerLineData = (
  currentEnergyList: UnitEnergyPerDay[],
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
  predictRequesting: boolean,
  forecastPower: number,
  consumedForecastPower: number
) => {
  const buyLineData = renderBuyLineData(currentEnergyList, unitPrices, consumedUnitPrice);
  const index = buyLineData.length - 1;
  const lastData = buyLineData[index].y;
  const lastIndex = currentEnergyList.length;

  return [
    {
      x: index,
      y: lastData,
    },
    {
      x: lastIndex < 30 ? 30 : lastIndex,
      y:
        calcPowerCharge(forecastPower, unitPrices[unitPrices.length - 1]) +
        consumedForecastPower * consumedUnitPrice,
    },
  ];
};

/**
 * 予測電気代ボーダーライン
 */
const renderForecastPowerLine = (currentEnergyList: UnitEnergyPerDay[], forecastValue: number) => {
  const index = currentEnergyList.length;

  return [
    {
      x: 5,
      y: forecastValue,
    },
    {
      x: index < 30 ? 30 : index,
      y: forecastValue,
    },
  ];
};

const messages = defineMessages({
  forecastValue: {
    id: 'ChartForecastLineChart.forecastValue',
    description: '予測電気代金額',
  },
});

const renderChart = (
  currentEnergyList: UnitEnergyPerDay[],
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unit: string,
  intl: intlShape,
  width: number,
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
  predictRequesting: boolean
) => {
  const { billingData, forecastPower, consumedForecastPower } = billingInfo;
  const forecastValueNumber =
    calcPowerCharge(forecastPower, unitPrices[unitPrices.length - 1]) +
    consumedForecastPower * consumedUnitPrice;
  const forecastValue = intl.formatNumber(floorForecastValue(forecastValueNumber));
  const overPercentage = isForecastExceeded(
    billingData,
    lastBillingDate,
    accountStartDate,
    forecastValueNumber
  );
  return (
    <VictoryChart
      theme={VictoryTheme.material}
      containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
      width={width}
      height={290}
      padding={{ top: 20, right: 3, left: 3, bottom: 50 }}
      domainPadding={{ x: 10, y: 45 }}
    >
      <Rect x={3} y={240} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
      <VictoryAxis
        dependentAxis
        tickCount={6}
        style={{
          grid: { stroke: '#cfcfcf', strokeDasharray: '4', strokeWidth: 1 },
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { textAnchor: 'end' },
        }}
      />
      {renderXAxis(unit, intl)}
      <VictoryLine
        style={{
          grid: { strokeWidth: 1, stroke: '#ff8f86' },
          data: { stroke: '#ff8f86', strokeWidth: 3 },
        }}
        data={renderBuyLineData(currentEnergyList, unitPrices, consumedUnitPrice)}
      />
      <VictoryLine
        style={{
          parent: { border: '1px dashed #999' },
          data: { stroke: '#999', strokeDasharray: '4', strokeWidth: 3 },
        }}
        data={renderForecastPowerLineData(
          currentEnergyList,
          unitPrices,
          consumedUnitPrice,
          predictRequesting,
          forecastPower,
          consumedForecastPower
        )}
      />
      <VictoryLine
        style={forecastPowerStyle(overPercentage)}
        data={[
          {
            x: 0,
            y: floorForecastValue(forecastValueNumber),
          },
          {
            x: 2,
            y: floorForecastValue(forecastValueNumber),
          },
          {
            x: 4.5,
            y: floorForecastValue(forecastValueNumber),
          },
        ]}
        labels={(d) => {
          if (d.x === 2) {
            return intl.formatMessage(messages.forecastValue, { forecastValue });
          }
          return '';
        }}
      />
      <VictoryLine
        style={forecastPowerLineStyle(overPercentage)}
        data={renderForecastPowerLine(currentEnergyList, floorForecastValue(forecastValueNumber))}
      />
    </VictoryChart>
  );
};

export const PureChartForecastLineChart = ({
  currentEnergyList,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  unit,
  intl,
  size,
  unitPrices,
  consumedUnitPrice,
  predictRequesting,
}: Props): Node => (
  <div>
    {!billingInfo.billingRequesting &&
      renderChart(
        currentEnergyList,
        billingInfo,
        lastBillingDate,
        accountStartDate,
        unit,
        intl,
        size.width,
        unitPrices,
        consumedUnitPrice,
        predictRequesting
      )}
  </div>
);

const mapStateToProps = (state) => ({
  unitPrices: state.userInfo.unitPrices,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
  predictRequesting: state.billingInfo.predictRequesting,
  lastBillingDate: state.userInfo.lastBillingDate,
  accountStartDate: state.userInfo.accountStartDate,
  billingInfo: state.billingInfo,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChartForecastLineChart: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartForecastLineChart))
);
export default connectedPureChartForecastLineChart;
