import moment from 'moment';
import { currentMonthXAxis, getStartDateList } from '../chart/chartUtils';
import { subTimeUnit, timeFormat, timeUnitStyle } from '../constants/timeUnit';
import { isCurrentMonth } from '../common/periodUtils';
import type { EnergyPerDay, UnitEnergyPerDay } from '../types/electricTypes';
import type { BillingInfo, BillingData, WeighingPeriod } from '../types/priceInfoTypes';
import { getEnergy } from './getUnitEnergy';

export const subUnitEnergyListOfBillingPeriod = (
  billingInfo: BillingInfo,
  unit: string,
  period: string
): UnitEnergyPerDay[] => {
  const { billingRequesting, billingData } = billingInfo;
  if (unit !== 'year' || billingRequesting || billingData.length === 0) {
    return [];
  }

  const format = timeFormat[unit];
  const billingDataList = billingData.filter(billing =>
    moment(billing.billingDate, format).isSame(moment(period, format), timeUnitStyle[unit].toMulti)
  );

  if (billingDataList.length === 0) {
    return [];
  }

  const result = billingDataList.map(billing => ({
    unit: subTimeUnit[unit],
    period: billing.billingDate,
    startDate: billing.startDate,
    endDate: billing.endDate,
    data: [
      {
        daily: billing.startDate,
        buyEnergy: [billing.billingAmount],
        consumeEnergy: [billing.consumedBillingAmount],
        sellEnergy: [billing.soldAmount],
      },
    ],
    isBillingData: true,
  }));

  const firstBillingMonth = moment(result[0].period, 'YYYY-MM').format('M');
  const paddingBillingList =
    firstBillingMonth !== '1'
      ? Array(Number(firstBillingMonth) - 1)
          .fill(
            moment(period, format)
              .startOf('year')
              .format('YYYY-MM')
          )
          .map((startDate, i) => ({
            unit: subTimeUnit[unit],
            period: moment(startDate, 'YYYY-MM').add(i, 'month'),
            startDate: '',
            endDate: '',
            data: [],
            isBillingData: true,
          }))
      : [];

  return [...paddingBillingList, ...result];
};

export const getSubUnitEnergyList = (
  requesting: boolean,
  subUnitEnergyList: EnergyPerDay[],
  billingInfo: BillingInfo,
  unit: string,
  period: string,
  subPeriod: {
    subStartDate: ?string,
    subEndDate: ?string,
    subDateTime: ?string,
  }
): UnitEnergyPerDay[] => {
  const { billingRequesting, weighingPeriodArray = [] } = billingInfo;
  if (requesting || !subPeriod.subDateTime || billingRequesting) {
    return [];
  }

  const billingList = subUnitEnergyListOfBillingPeriod(billingInfo, unit, period);
  const startDateList = getStartDateList(
    subPeriod,
    unit,
    (billingList[billingList.length - 1] && billingList[billingList.length - 1].period) || ''
  );

  const subUnitEnergy = startDateList.map((startDate, i) => {
    if (unit === 'day') {
      const filter = subUnitEnergyList.filter(
        item =>
          // 日値ではなく時間値で取得したデータのみを追加する
          (item.data || []).filter(energy => energy.buyEnergy.length === 24).length
      );
      const energy = getEnergy(filter, startDate) || {
        unit: subTimeUnit[unit],
        period: startDate,
        startDate,
        endDate: startDateList[i + 1] || subPeriod.subEndDate,
        data: [],
      };

      return { ...energy, isBillingData: false };
    }

    const weighingPeriod =
      unit === 'year' && weighingPeriodArray.find(v => v.billingMonth === startDate);

    const energy = getEnergy(subUnitEnergyList, startDate) || {
      unit: subTimeUnit[unit],
      period: startDate,
      startDate: (weighingPeriod && weighingPeriod.startDate) || startDate,
      endDate:
        (weighingPeriod && weighingPeriod.endDate) ||
        (startDateList[i + 1] &&
          moment(startDateList[i + 1])
            .subtract(1, 'days')
            .format(timeFormat[subTimeUnit[unit]])) ||
        subPeriod.subEndDate,
      data: [],
    };

    return { ...energy, isBillingData: false };
  });

  const result = [...billingList, ...subUnitEnergy];

  if (unit === 'day') {
    // 日値の場合、時間値の配列に変換する
    const resultWithData = result.filter(energy => energy.data.length > 0);
    return resultWithData.reduce(
      (p, n) =>
        [...p, ...Array(24).fill(n.period)].map((v, i) => {
          const date = moment(v, 'YYYY-MM-DD').add(i, 'hour');

          const buyEnergy = n.data[0] && n.data[0].buyEnergy[i];
          const consumeEnergy = n.data[0] && n.data[0].consumeEnergy[i];
          const sellEnergy = n.data[0] && n.data[0].sellEnergy[i];

          return {
            unit: subTimeUnit[unit],
            period: date.clone().format('YYYY-MM-DD'),
            startDate: date.clone().format('YYYY-MM-DD HH:mm'),
            endDate: date
              .clone()
              .endOf('hour')
              .format('YYYY-MM-DD HH:mm'),
            data:
              typeof buyEnergy === 'number' ||
              typeof consumeEnergy === 'number' ||
              typeof sellEnergy === 'number'
                ? [
                    {
                      daily: date.clone().format('YYYY-MM-DD HH:mm'),
                      buyEnergy: [buyEnergy || 0],
                      consumeEnergy: [consumeEnergy || 0],
                      sellEnergy: [sellEnergy || 0],
                    },
                  ]
                : [],
            isBillingData: false,
          };
        }),
      []
    );
  }

  return result;
};

export const getYearlyEnergy = (
  unitEnergy: EnergyPerDay,
  billingData: BillingData[],
  lastBillingDate: string,
  period: string,
  unit: string
): EnergyPerDay | null => {
  const endDate = (billingData.find(v => v.billingDate === lastBillingDate) || {}).endDate;

  if (!endDate) {
    return unitEnergy;
  }

  const unitEnergyYearlyList = (unitEnergy.data || [])
    .filter(
      energy =>
        moment(energy.daily, 'YYYY-MM-DD').isAfter(moment(endDate, 'YYYY-MM-DD'), 'day') &&
        moment(unitEnergy.period, timeFormat[unit]).isSame(
          moment(period, timeFormat[unit]),
          timeUnitStyle[unit].toMulti
        )
    );
  return {
    ...unitEnergy,
    data: unitEnergyYearlyList,
  };
};

export const getUnitEnergyList = (
  requesting: boolean,
  billingInfo: BillingInfo,
  unitEnergyList: EnergyPerDay[],
  unit: string,
  argPeriod: string,
  lastBillingDate: string
): EnergyPerDay | null => {
  const { billingRequesting, currentWeighingPeriod, billingData } = billingInfo || {};

  if (requesting || billingRequesting || (!argPeriod && !currentWeighingPeriod.billingMonth)) {
    return null;
  }
  const period =
    argPeriod || moment(currentWeighingPeriod.billingMonth, 'YYYY-MM').format('YYYY-MM');

  const energy = getEnergy(unitEnergyList, period) || [];

  return (
    (unit === 'year'
      ? getYearlyEnergy(energy, billingData, lastBillingDate, period, unit)
      : energy) || null
  );
};

export const currentEnergyListOfPeriod = (
  requesting: boolean,
  dailyUnitEnergyList: EnergyPerDay[],
  unit: string,
  period: string,
  weighingPeriodInitialized: boolean,
  currentWeighingPeriod: WeighingPeriod
): UnitEnergyPerDay[] => {
  if (
    requesting ||
    !currentWeighingPeriod ||
    !currentWeighingPeriod.billingMonth ||
    !isCurrentMonth(weighingPeriodInitialized, currentWeighingPeriod, unit, period)
  ) {
    return [];
  }

  const startDateList = currentMonthXAxis(
    currentWeighingPeriod.startDate,
    moment(currentWeighingPeriod.endDate, 'YYYY-MM-DD').add(1, 'day'),
    currentWeighingPeriod.billingMonth,
    period,
    'week'
  );

  return startDateList.map(startDate => {
    const dailyUnitEnergy = dailyUnitEnergyList.find(v => v.period === startDate);
    const dateTime = dailyUnitEnergy ? startDate : '';
    const energy = getEnergy(dailyUnitEnergyList, startDate) || {
      period: dateTime,
      unit: 'day',
      startDate: (dailyUnitEnergy && dailyUnitEnergy.startDate) || '',
      endDate: (dailyUnitEnergy && dailyUnitEnergy.endDate) || '',
      data: [],
    };
    return {
      ...energy,
      isBillingData: false,
    };
  });
};
