// @flow

import type { Action } from 'redux';

import { REQUEST_API_PICKUP, SUCCESS_API_PICKUP, FAILURE_API_PICKUP } from '../actions/types';

export type State = {
  initialized: boolean,
  requesting: boolean,
  data: {
    month: string,
    product:
      | 'high_performance_rice_cooker'
      | 'automatic_cooker'
      | 'bedding'
      | 'water_saving_clothes_washer'
      | 'high_performance_microwave'
      | 'circulator'
      | 'vacuum_cleaner'
      | null,
    data: Object | null,
  }[],
};

const initialState: State = {
  initialized: false,
  requesting: false,
  data: [],
};

export default (state: State = initialState, action: Action = {}): State & $Shape<State> => {
  switch (action.type) {
    case REQUEST_API_PICKUP: {
      return { ...state, requesting: !action.error };
    }
    case SUCCESS_API_PICKUP: {
      const { month, product, data = {} } = action.payload || {};

      return {
        ...state,
        initialized: true,
        requesting: false,
        data: [...state.data, { month, product, data }],
      };
    }
    case FAILURE_API_PICKUP: {
      return { ...state, initialized: true, requesting: false };
    }
    default: {
      return state;
    }
  }
};
