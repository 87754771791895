import React from 'react';
import { VictoryAxis } from 'victory';

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#454545', padding: 5 },
};

const renderHourAxis = (intl, isOnlyToDisplayDayTime) => {
  const axisMessage = isOnlyToDisplayDayTime
    ? intl.formatMessage({ id: 'chartSubUnitChart.dayTime' })
    : intl.formatMessage({ id: 'chartSubUnitChart.day' });
  // 日中の表示とする場合には6時〜18時の計13時間分の表示とする
  const displayBarCount = isOnlyToDisplayDayTime ? 13 : 24;

  return (
    <VictoryAxis
      tickValues={[...Array(displayBarCount)].map((_, i) => i + 1)}
      tickFormat={x => axisMessage.split(',')[x - 1]}
      style={xAxisStyle}
    />
  );
};

const renderDayAxis = intl => (
  <VictoryAxis
    tickValues={[1, 2, 3, 4, 5, 6, 7]}
    tickFormat={[
      intl.formatMessage({ id: 'chartSubUnitChart.sun' }),
      intl.formatMessage({ id: 'chartSubUnitChart.mon' }),
      intl.formatMessage({ id: 'chartSubUnitChart.tue' }),
      intl.formatMessage({ id: 'chartSubUnitChart.wed' }),
      intl.formatMessage({ id: 'chartSubUnitChart.thu' }),
      intl.formatMessage({ id: 'chartSubUnitChart.fri' }),
      intl.formatMessage({ id: 'chartSubUnitChart.sat' }),
    ]}
    style={xAxisStyle}
  />
);

const renderWeekAxis = (itemSize, intl) => (
  <VictoryAxis
    tickValues={Array.from(Array(itemSize)).map((value, index) => index + 1)}
    tickFormat={x =>
      intl.formatMessage({ id: 'chartSubUnitChart.place' }).split(',')[x - 1] +
      intl.formatMessage({ id: 'chartSubUnitChart.week' })}
    style={xAxisStyle}
  />
);

const renderMonthAxis = intl => (
  <VictoryAxis
    tickValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
    tickFormat={x => intl.formatMessage({ id: 'chartSubUnitChart.month' }).split(',')[x - 1]}
    style={xAxisStyle}
  />
);

export const barWidth = (chartWidth: number, itemSize: number): number =>
  itemSize ? chartWidth / itemSize * 0.8 : 0;

export const barChartPadding = {
  day: 20,
  week: 20,
  month: 35,
  year: 20,
};

export const renderXAxis = (unit, itemSize, isOnlyToDisplayDayTime, intl) => {
  switch (unit) {
    case 'day':
      return renderHourAxis(intl, isOnlyToDisplayDayTime);
    case 'week':
      return renderDayAxis(intl);
    case 'month':
      return renderWeekAxis(itemSize, intl);
    case 'year':
      return renderMonthAxis(intl);
    default:
      return <VictoryAxis />;
  }
};
