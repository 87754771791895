// @flow

import React from 'react';
import type { Node } from 'react';
import { Animate } from 'react-move';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();

const styles = prefixer.prefix({
  background: {
    zIndex: 65535,
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    height: '100vh',
    width: '100vw',
    background: '#33b2b5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
  },
});

const getAnimationParameters = (isOpen) => {
  if (isOpen) {
    return {
      origin: {
        scale: 0.7,
        opacity: 0,
      },
      target: {
        scale: 1.0,
        opacity: 1.0,
      },
      easing: 'easeCubicOut',
    };
  }
  return {
    target: {
      scale: 0.7,
      opacity: 0,
    },
    origin: {
      scale: 1.0,
      opacity: 1.0,
    },
    easing: 'easeCubicOut',
  };
};

type Props = {
  open: boolean,
  closeHandler?: () => void,
  animationDuration: number,
  style?: {
    fullScreen?: Object,
  },
  children: Node,
};

const Popup = (props: Props) => {
  const { origin, target, easing } = getAnimationParameters(props.open);
  const { children, closeHandler, style: styleOverwrite = {}, animationDuration } = props;
  return (
    <Animate default={origin} data={target} duration={animationDuration} easing={easing}>
      {(data) => (
        <div
          onClick={closeHandler}
          style={{
            ...styles.background,
            opacity: data.opacity,
            ...styleOverwrite.fullScreen,
          }}
        >
          <div
            style={{
              transform: `scale(${data.scale})`,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {children}
          </div>
        </div>
      )}
    </Animate>
  );
};

Popup.defaultProps = {
  style: {},
  closeHandler: () => {},
};

export default Popup;
