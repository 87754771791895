import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import SettingPage from '../SettingPage';
import FadeTransition from '../FadeTransition';
import { apiGetUseStatsForLastYear, apiGetUserInfo } from '../../actions/apis';
import { applianceId } from '../../lib/id';
import { CLOSING_POP_UP } from '../../actions/types';

const settingPageOnEnter = ({ idsToShow, dispatch }) => {
  // only LIGHT means the initial state
  const { light: LIGHT } = applianceId;
  if (idsToShow && idsToShow.length === 1 && idsToShow[0] === LIGHT) {
    dispatch(apiGetUseStatsForLastYear());
  }
};

type Props = {
  location: {
    pathname: string,
  },
  dispatch: Dispatch,
};

class ExtendSettingPageBase extends Component<Props> {
  componentDidMount() {
    settingPageOnEnter(this.props);
    this.props.dispatch(apiGetUserInfo());
    this.props.dispatch({ type: CLOSING_POP_UP });
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLOSING_POP_UP });
  }

  render() {
    return (
      <FadeTransition pathname={this.props.location.pathname}>
        <SettingPage key="settingPage" />
      </FadeTransition>
    );
  }
}

const connectedExtendSettingPage: any = connect(state => ({
  idsToShow: state.applianceState.idsToShow,
}))(ExtendSettingPageBase);

export default connectedExtendSettingPage;
