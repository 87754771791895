// @flow

import React from 'react';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import sizeMe from 'react-sizeme';
import 'react-toggle/style.css';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  body: {
    maxWidth: '480px',
    margin: '0px auto',
  },
  background: {
    height: '100vh',
    background: '#33b2b5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  area: {
    width: '80%',
    maxWidth: '340px',
    margin: '-100px auto 0px',
  },
  warningArea: {
    height: '45px',
    verticalAlign: 'middle',
    marginBottom: '10px',
  },
  warning: {
    fill: '#fafafa',
  },
  errorTitle: {
    borderRadius: '3px 3px 0px 0px',
    width: '100%',
    maxWidth: '340px',
    fontSize: '1.4em',
    fontWeight: 'bold',
    color: '#fff',
    padding: '20px 0px 30px',
    textAlign: 'center',
    margin: '0px auto',
    background: '#ff5354',
  },
  errorBox: {
    borderRadius: '0px 0px 3px 3px',
    background: '#fff',
    maxWidth: '340px',
    margin: '0px auto 10px',
    textAlign: 'left',
    padding: '20px 10px',
    color: '#555',
  },
  errorText: {
    color: '#454545',
    fontSize: '1.1em',
    textAlign: 'center',
    width: '90%',
    margin: '0px auto',
  },
  errorCode: {
    fontWeight: 'bold',
    margin: '15px auto 10px',
  },
});

const bodyFontSize = (width: number): string => (width >= 400 ? '16px' : '12px');

type Props = {
  errorCode: string,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
};

export const PureErrorPage = ({ errorCode, intl, size }: Props): Node => (
  <div style={{ ...style.body, fontSize: bodyFontSize(size.width) }}>
    <div style={style.background}>
      <div style={style.area}>
        <div style={style.errorTitle}>
          <svg style={style.warningArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
              style={style.warning}
              d="M180,149.69,112,35.94a14,14,0,0,0-24.06,0L20,149.69a14,14,0,0,0,12,21.2H168a14,14,0,0,0,12-21.2Zm-87.3-83.5A4.31,4.31,0,0,1,97,61.88h4.69A4.31,4.31,0,0,1,106,66.19v59.65a4.31,4.31,0,0,1-4.31,4.3H97.05a4.31,4.31,0,0,1-4.31-4.3Zm6.66,86.59a8.1,8.1,0,1,1,8.09-8.09h0A8.09,8.09,0,0,1,99.38,152.78Z"
            />
          </svg>
          <br />
          {intl.formatMessage({ id: 'errorPage.title' })}
        </div>
        <div style={style.errorBox}>
          <div style={style.errorText}>
            <div>{intl.formatMessage({ id: 'errorPage.text' })}</div>
            <div style={style.errorCode}>{errorCode}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureErrorPage: any = connect()(sizeMeHOC(injectIntl(PureErrorPage)));
export default connectedPureErrorPage;
