// @flow

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import type { Node } from 'react';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryStack,
  VictoryTheme,
} from 'victory';
import sizeMe from 'react-sizeme';
import type { Dispatch } from 'redux';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import Prefixer from '../lib/Prefixer';
import { OPEN_POP_UP } from '../actions/types';
import { HELP_GENERATED_POWER } from '../actions/helpPopupTypes';
import { barChartPadding, barWidth, renderXAxis } from './ChartGraphVictoryAxis';
import type { UnitEnergyPerDay } from '../lib/types/electricTypes';
import { calcTotalEnergy } from '../lib/common/calculated';
import { calcSellCharge } from '../lib/price/calculatedPrice';
import loadingAnime from '../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: {
    verticalAlign: 'middle',
  },
  wrapper: {
    position: 'relative',
  },
  body: {
    marginTop: '10px',
  },
  loadingAnime: {
    margin: '80px 0px',
  },
  info: {
    top: '-11px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#bbb',
    float: 'right',
  },
});

type Props = {
  requesting: boolean,
  unit: string,
  unitEnergyList: UnitEnergyPerDay[],
  soldUnitPrice: number,
  consumedUnitPrice: number,
  size: {
    width: number,
    height: number,
  },
  intl: intlShape,
  dispatch: Dispatch,
};

const renderInfoButton = (dispatch: Dispatch) => (
  <div style={style.info}>
    <Icon
      icon={faQuestionCircle}
      style={style.infoButton}
      onClick={(e) =>
        dispatch({
          type: OPEN_POP_UP,
          contentType: HELP_GENERATED_POWER,
          target: e.target,
        })
      }
    />
  </div>
);

const renderLoadingAnime = (intl: intlShape) => (
  <div>
    <div style={style.loadingAnime}>
      <img src={loadingAnime} alt="loading" />
    </div>
    <div>{intl.formatMessage({ id: 'chartSubUnit.loading' })}</div>
  </div>
);

const renderGenerateBar = (
  displayUnitEnergyList: UnitEnergyPerDay[],
  soldUnitPrice: number,
  consumedUnitPrice: number,
  width: number
) => (
  <VictoryBar
    style={{ data: { fill: '#33B2B5', width } }}
    data={displayUnitEnergyList.map((unitEnergy, index) => {
      const totalConsumeEnergy = calcTotalEnergy(unitEnergy.data, 'consumeEnergy');
      const totalSellEnergy = calcTotalEnergy(unitEnergy.data, 'sellEnergy');
      const totalGeneratedPrice = unitEnergy.isBillingData
        ? totalConsumeEnergy + totalSellEnergy
        : totalConsumeEnergy * consumedUnitPrice + calcSellCharge(totalSellEnergy, soldUnitPrice);
      return {
        x: index + 1,
        y: totalGeneratedPrice,
      };
    })}
  />
);

const renderBody = (
  requesting: boolean,
  unit: string,
  unitEnergyList: UnitEnergyPerDay[],
  soldUnitPrice: number,
  consumedUnitPrice: number,
  width: number,
  intl: intlShape
) => {
  if (requesting) {
    return renderLoadingAnime(intl);
  }
  const displayUnitEnergyList =
    unit === 'day' && unitEnergyList && unitEnergyList.length
      ? unitEnergyList.slice(6, 19) // 6時〜18時の表示とする
      : unitEnergyList;
  return (
    <div>
      <VictoryChart
        theme={VictoryTheme.material}
        containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
        width={width}
        height={286}
        padding={{ right: 3, left: 3, bottom: 50 }}
        domainPadding={barChartPadding[unit]}
      >
        <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
        <VictoryAxis
          dependentAxis
          tickCount={7}
          style={{
            grid: { strokeWidth: 1, stroke: '#ccc' },
            axis: { stroke: 'transparent' },
            ticks: { stroke: 'transparent' },
            tickLabels: { fill: 'transparent' },
          }}
        />
        <VictoryStack>
          {renderGenerateBar(
            displayUnitEnergyList,
            soldUnitPrice,
            consumedUnitPrice,
            barWidth(width, displayUnitEnergyList.length)
          )}
        </VictoryStack>
        {renderXAxis(unit, displayUnitEnergyList.length, true, intl)}
      </VictoryChart>
    </div>
  );
};

export const PureChartGeneratedPower = ({
  requesting,
  unit,
  unitEnergyList,
  soldUnitPrice,
  consumedUnitPrice,
  size,
  intl,
  dispatch,
}: Props): Node => (
  <div style={style.wrapper}>
    {renderInfoButton(dispatch)}
    <span style={style.title}>{intl.formatMessage({ id: 'chartGeneratedPower.title' })}</span>
    <div style={style.body}>
      {renderBody(
        requesting,
        unit,
        unitEnergyList,
        soldUnitPrice,
        consumedUnitPrice,
        size.width,
        intl
      )}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  soldUnitPrice: state.userInfo.soldUnitPrice,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
});

const sizeMeHOC = sizeMe({ monitorHeight: true, monitorWidth: true });

const connectedPureCharGeneratedPower: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartGeneratedPower))
);
export default connectedPureCharGeneratedPower;
