import React from 'react';
import { connect } from 'react-redux';
import type { Node } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import Scroll from 'react-scroll';
import ChartTimeUnit from './ChartTimeUnit';
import ChartPeriod from './ChartPeriod';
import ChartCharge from './ChartCharge';
import ChartApplianceRanking from './ChartApplianceRanking';
import ChartSubUnit from './ChartSubUnit';
import ChartSoldCharge from './ChartSoldCharge';
import ChartGeneratedPower from './ChartGeneratedPower';
import { isCurrentMonth } from '../lib/common/periodUtils';
import Prefixer from '../lib/Prefixer';
import ChartSubUnitChargeItem from './ChartSubUnitChargeItem';
import loadingAnime from '../images/loading.gif';
import { estimatedEnergy } from '../lib/electric/getUnitEnergy';
import {
  getSubUnitEnergyList,
  getUnitEnergyList,
  currentEnergyListOfPeriod,
} from '../lib/electric/convertUnitEnergy';
import { currentBillingInfo } from '../lib/price/priceUtils';
import { totalSoldAmount } from '../lib/price/calculatedPrice';
import type { BillingInfo } from '../lib/types/priceInfoTypes';
import type { EnergyPerDay, SubUnitEnergy } from '../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  card: {
    position: 'relative',
    width: '90%',
    margin: '0px auto 10px',
    padding: '16px 10px',
    background: '#fff',
    color: '#454545',
    fontWeight: 'bold',
    border: '1px solid #e5e5e5',
    borderBottom: '2px solid #e5e5e5',
    borderRadius: '5px',
  },
  body: {
    maxWidth: '480px',
    margin: '0px auto',
  },
  footer: {
    width: '100%',
    margin: '20px auto 0px',
    padding: '8px 0px',
    textAlign: 'center',
    borderTop: '1px solid #e5e5e5',
    background: '#fff',
    fontSize: '0.8em',
    fontWeight: 'bold',
    clear: 'both',
  },
});

const renderLoading = () => (
  <div style={style.loading}>
    <img src={loadingAnime} alt="loading" />
  </div>
);

const renderChargeTable = (
  requesting: boolean,
  subUnitEnergyList: EnergyPerDay[],
  unit: string
) => {
  if (requesting && unit !== 'day') {
    return <div style={style.card}>{renderLoading()}</div>;
  } 
  if (unit !== 'day') {
    return (
      <div style={style.card}>
        {subUnitEnergyList.map((energy, index) => {
          const key = `${energy.period || ''}-${index}`;
          return (
            <ChartSubUnitChargeItem
              key={key}
              unitEnergy={energy}
              unit={unit}
              period={energy.period}
              itemIndex={index}
            />
          );
        })}
        <div style={style.clear} />
      </div>
    );
  }
  return null;
};

const renderChartGeneratedPower = (
  requesting,
  subUnitEnergyList,
  billingInfo,
  unit,
  period,
  subPeriod,
  soldEnergyOwner,
  sellingPattern
) => {
  if (requesting) {
    return <div style={style.card}>{renderLoading()}</div>;
  } 
  if (soldEnergyOwner !== 'resident' && sellingPattern !== 'PPA') {
    return null;
  }

  return (
    <div style={style.card}>
      <ChartGeneratedPower
        requesting={requesting}
        unitEnergyList={getSubUnitEnergyList(
          requesting,
          subUnitEnergyList,
          billingInfo,
          unit,
          period,
          subPeriod
        )}
        unit={unit}
      />
    </div>
  );
};

type Props = {
  requesting: boolean,
  subRequesting: boolean,
  rankingRequesting: boolean,
  unitEnergyList: EnergyPerDay[],
  subUnitEnergyList: SubUnitEnergy[],
  dailyUnitEnergyList: EnergyPerDay[],
  unit: string,
  period: string,
  subPeriod: ?{
    subStartDate: string,
    subEndDate: string,
    subDateTime: string,
  },
  billingInfo: BillingInfo,
  lastBillingDate: string,
  soldEnergyOwner: string,
  sellingPattern: string,
  intl: intlShape,
};

export const PureChartBody = ({
  requesting,
  subRequesting,
  rankingRequesting,
  unitEnergyList,
  subUnitEnergyList,
  dailyUnitEnergyList,
  unit,
  period,
  subPeriod,
  billingInfo,
  lastBillingDate,
  soldEnergyOwner,
  sellingPattern,
  intl,
}: Props): Node => {
  return (
  <div>
    <ChartTimeUnit
      requesting={requesting || subRequesting || rankingRequesting || billingInfo.billingRequesting}
    />
    <div style={style.body}>
      <ChartPeriod period={period} />
      <div style={style.card}>
        <ChartCharge
          requesting={
            requesting || subRequesting || rankingRequesting || billingInfo.billingRequesting
          }
          unitEnergy={getUnitEnergyList(
            requesting,
            billingInfo,
            unitEnergyList,
            unit,
            period,
            lastBillingDate
          )}
          currentEnergyList={currentEnergyListOfPeriod(
            requesting,
            dailyUnitEnergyList,
            unit,
            period,
            billingInfo.weighingPeriodInitialized,
            billingInfo.currentWeighingPeriod
          )}
          estimatedEnergy={estimatedEnergy(
            requesting,
            billingInfo.currentWeighingPeriod,
            unitEnergyList,
            period
          )}
          currentBillingInfo={currentBillingInfo(requesting, billingInfo, unit, period)}
          unit={unit}
          period={period}
        />
      </div>
      {soldEnergyOwner === 'resident' &&
      !isCurrentMonth(
        billingInfo.weighingPeriodInitialized,
        billingInfo.currentWeighingPeriod,
        unit,
        period
      ) && (
        <div style={style.card}>
          <ChartSoldCharge
            requesting={
              requesting || subRequesting || rankingRequesting || billingInfo.billingRequesting
            }
            unitEnergy={getUnitEnergyList(
              requesting,
              billingInfo,
              unitEnergyList,
              unit,
              period,
              lastBillingDate
            )}
            soldAmount={totalSoldAmount(requesting, billingInfo, unit, period)}
            unit={unit}
          />
        </div>
      )}
      <div style={style.card}>
        <ChartSubUnit
          requesting={
            requesting || subRequesting || rankingRequesting || billingInfo.billingRequesting
          }
          unitEnergyList={getSubUnitEnergyList(
            requesting,
            subUnitEnergyList,
            billingInfo,
            unit,
            period,
            subPeriod
          )}
          unit={unit}
        />
      </div>
      {renderChargeTable(
        requesting,
        getSubUnitEnergyList(requesting, subUnitEnergyList, billingInfo, unit, period, subPeriod),
        unit,
        period,
        subPeriod
      )}
      {renderChartGeneratedPower(
        requesting,
        subUnitEnergyList,
        billingInfo,
        unit,
        period,
        subPeriod,
        soldEnergyOwner,
        sellingPattern
      )}
      <div style={style.card}>
        <ChartApplianceRanking requesting={requesting || subRequesting || rankingRequesting} />
      </div>
    </div>
    <div style={style.footer}>
      <div 
        onClick={() => Scroll.animateScroll.scrollToTop({ duration: 500 })}
      >
        {intl.formatMessage({ id: 'chartBody.toTop' })}
      </div>
    </div>
  </div>
)};

const mapStateToProps = state => ({
  lastBillingDate: state.userInfo.lastBillingDate,
  sellingPattern: state.userInfo.sellingPattern,
  soldEnergyOwner: state.userInfo.soldEnergyOwner,
  billingInfo: state.billingInfo,
  subPeriod: state.chartTime.subPeriod,
});

const connectedPureChartBody: any = connect(mapStateToProps)(injectIntl(PureChartBody));
export default connectedPureChartBody;
