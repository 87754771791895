// @flow

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import Scroll from 'react-scroll';
import Navigator from './Navigator';
import ResultArea from './ResultArea';
import ChargeHourlyTotalArea from './ChargeHourlyTotalArea';
import ChargeWeeklyAverageArea from './ChargeWeeklyAverageArea';
import ComparisonSimilarHomeArea from './ComparisonSimilarHomeArea';
import PickupArea from './pickup';

import type { State as ObjectiveEnergyState } from '../../reducers/objectiveEnergy';
import type { State as PickupState } from '../../reducers/pickup';

import Prefixer from '../../lib/Prefixer';
import { estimatedEnergy } from '../../lib/electric/getUnitEnergy';
import {
  getSubUnitEnergyList,
  getUnitEnergyList,
  currentEnergyListOfPeriod,
} from '../../lib/electric/convertUnitEnergy';
import ChartCharge from '../ChartCharge';
import GeneratedResultArea from './GeneratedResultArea';
import { isCurrentMonth } from '../../lib/common/periodUtils';
import ChartSoldCharge from '../ChartSoldCharge';
import { totalSoldAmount } from '../../lib/price/calculatedPrice';
import { currentBillingInfo } from '../../lib/price/priceUtils';
import type { BillingInfo } from '../../lib/types/priceInfoTypes';
import type { EnergyPerDay, ApplianceEnergy } from '../../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  card: {
    position: 'relative',
    width: '90%',
    margin: '0px auto 10px',
    padding: '16px 10px',
    background: '#fff',
    color: '#454545',
    fontWeight: 'bold',
    border: '1px solid #e5e5e5',
    borderBottom: '2px solid #e5e5e5',
    borderRadius: '5px',
  },
  body: {
    maxWidth: '480px',
    margin: '0px auto',
  },
  footer: {
    width: '100%',
    margin: '20px auto 0px',
    padding: '8px 0px',
    textAlign: 'center',
    borderTop: '1px solid #e5e5e5',
    background: '#fff',
    fontSize: '0.8em',
    fontWeight: 'bold',
    clear: 'both',
  },
  generatedResultCard: {
    position: 'relative',
    marginBottom: '10px',
    padding: '0',
    width: '100%',
    backgroundColor: '#33B2B5',
    minHeight: '40vh',
  },
});

type Props = {
  period: string,
  billingRequesting: boolean,
  energyRequesting: boolean,
  dailyRequesting: boolean,
  energyMonthly: EnergyPerDay[],
  energyDaily: EnergyPerDay[],
  applianceEnergyMonthly: ApplianceEnergy[],
  billingInfo: BillingInfo,
  lastBillingDate: string,
  sellingPattern: string,
  soldEnergyOwner: string,
  objectiveRequesting: boolean,
  objectiveEnergy: ObjectiveEnergyState,
  pickup: PickupState,
  subPeriod: ?{
    subStartDate: string,
    subEndDate: string,
    subDateTime: string,
  },
  intl: intlShape,
};

export const PureReportBody = ({
  period,
  billingRequesting,
  energyRequesting,
  dailyRequesting,
  energyMonthly,
  energyDaily,
  applianceEnergyMonthly,
  billingInfo,
  lastBillingDate,
  sellingPattern,
  soldEnergyOwner,
  objectiveRequesting,
  objectiveEnergy,
  pickup,
  subPeriod,
  intl,
}: Props) => {
  const { billingData } = billingInfo;
  // 達成グラフ
  const billingIndex = billingData.findIndex((v) => v.billingDate === period);
  const resultBilling = billingIndex < 0 ? null : billingData[billingIndex];
  const prevBilling = billingData[billingIndex - 1] ? billingData[billingIndex - 1] : null;
  const { startDate = null, endDate = null } = resultBilling || {};
  // 時間ごとの電気料金グラフ
  const hourlyAverageMonthEnergy = energyMonthly.find((v) => v.period === period) || null;
  const hourlyAverageMonthAplEnergy =
    applianceEnergyMonthly.find((v) => v.dateTime === period) || null;
  const startDateIndex = energyDaily.findIndex((v) => v.period === startDate);
  const endDateIndex = energyDaily.findIndex((v) => v.period === endDate);
  // 曜日ごとの平均電気料金グラフ
  const weeklyAverageDailyEnergies = energyDaily.slice(startDateIndex, endDateIndex + 1);
  // 暮らしが似た家との電気料金比較グラフ
  const compareBillings = resultBilling
    ? billingData.filter((v, k) => k > billingIndex - 6 && k <= billingIndex)
    : null;
  const objectiveIndex = objectiveEnergy.data.findIndex((v) => v.date === period);
  const objective = objectiveIndex < 0 ? null : objectiveEnergy.data[objectiveIndex];
  const objectiveEnergies = objectiveEnergy.data.filter(
    (v, k) => k > objectiveIndex - 6 && k <= objectiveIndex
  );
  // PickUp
  const showPickup = pickup.data.find((v) => v.month === period);
  const unit = 'month';

  return (
    <div>
      <div style={style.body}>
        <Navigator requesting={billingRequesting || !resultBilling} billing={resultBilling} />
        {sellingPattern ? (
          <>
            <div style={style.generatedResultCard}>
              <GeneratedResultArea
                requesting={energyRequesting}
                currentBilling={resultBilling}
                unitEnergy={getUnitEnergyList(
                  energyRequesting,
                  billingInfo,
                  energyMonthly,
                  unit,
                  period,
                  lastBillingDate
                )}
              />
            </div>
            <div style={style.card}>
              <ChartCharge
                requesting={energyRequesting || billingInfo.billingRequesting}
                unitEnergy={getSubUnitEnergyList(
                  energyRequesting,
                  energyDaily,
                  billingInfo,
                  unit,
                  period,
                  subPeriod
                )}
                currentEnergyList={currentEnergyListOfPeriod(
                  energyRequesting,
                  energyDaily,
                  unit,
                  period,
                  billingInfo.weighingPeriodInitialized,
                  billingInfo.currentWeighingPeriod
                )}
                estimatedEnergy={estimatedEnergy(
                  energyRequesting,
                  billingInfo.currentWeighingPeriod,
                  energyMonthly,
                  period
                )}
                currentBillingInfo={currentBillingInfo(energyRequesting, billingInfo, unit, period)}
                unit={unit}
                period={period}
              />
            </div>
          </>
        ) : (
          <div style={style.card}>
            <ResultArea
              requesting={billingRequesting || objectiveRequesting || !resultBilling || !objective}
              prevBilling={prevBilling}
              currentBilling={resultBilling}
              objective={objective}
            />
          </div>
        )}
        {soldEnergyOwner === 'resident' &&
          !isCurrentMonth(
            billingInfo.weighingPeriodInitialized,
            billingInfo.currentWeighingPeriod,
            unit,
            period
          ) && (
            <div style={style.card}>
              <ChartSoldCharge
                requesting={energyRequesting || dailyRequesting || billingInfo.billingRequesting}
                unitEnergy={getUnitEnergyList(
                  energyRequesting,
                  billingInfo,
                  energyMonthly,
                  unit,
                  period,
                  lastBillingDate
                )}
                soldAmount={totalSoldAmount(energyRequesting, billingInfo, unit, period)}
                unit={unit}
              />
            </div>
          )}
        {sellingPattern !== 'PPA' && sellingPattern !== 'ZEH-M' && (
          <div style={style.card}>
            <ComparisonSimilarHomeArea
              requesting={billingRequesting || objectiveRequesting || !compareBillings}
              billings={compareBillings}
              compares={objectiveEnergies}
            />
          </div>
        )}
        <div style={style.card}>
          <ChargeHourlyTotalArea
            requesting={
              energyRequesting || !hourlyAverageMonthEnergy || !hourlyAverageMonthAplEnergy
            }
            monthEnergy={hourlyAverageMonthEnergy}
            monthAplEnergy={hourlyAverageMonthAplEnergy}
          />
        </div>
        <div style={style.card}>
          <ChargeWeeklyAverageArea
            requesting={energyRequesting || !weeklyAverageDailyEnergies.length}
            dailyEnergies={weeklyAverageDailyEnergies}
          />
        </div>
        {showPickup && showPickup.product && showPickup.data && (
          <div style={style.card}>
            <PickupArea pickup={showPickup} />
          </div>
        )}
        <div style={style.footer}>
          <div onClick={() => Scroll.animateScroll.scrollToTop({ duration: 500 })}>
            {intl.formatMessage({ id: 'chartBody.toTop' })}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  energyMonthly: state.energy.monthly,
  energyDaily: state.energy.daily,
  dailyRequesting: state.energy.dailyRequesting,
  applianceEnergyMonthly: state.applianceEnergy.monthly,
  billingInfo: state.billingInfo,
  lastBillingDate: state.userInfo.lastBillingDate,
  sellingPattern: state.userInfo.sellingPattern,
  soldEnergyOwner: state.userInfo.soldEnergyOwner,
  objectiveEnergy: state.objectiveEnergy,
  pickup: state.pickup,
  subPeriod: state.chartTime.subPeriod,
});

const connectedPureReportBody: any = connect(mapStateToProps)(injectIntl(PureReportBody));
export default connectedPureReportBody;
