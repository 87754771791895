// @flow
import type { State, Action } from 'redux';
import successApiUseStatsHourly from './successApiUseStatsHourly';
import successApiEnergyHourly from './successApiEnergyHourly';

import {
  LOAD_TOP_PAGE,
  RESET_CHART_PAGE,
  SUCCESS_API_USE_STATS_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
} from '../../actions/types';

export const initialEnergyState: State = {
  hourly: [],
  daily: [],
  weekly: [],
  monthly: [],
  yearly: [],
};

export const total = (array: number[]): number =>
  (array || []).reduce((prev, current) => prev + current, 0);

export default (energyState: State = initialEnergyState, action: Action = {}): State => {
  switch (action.type) {
    case SUCCESS_API_USE_STATS_HOURLY:
      return successApiUseStatsHourly(energyState, action);
    case LOAD_TOP_PAGE:
      return initialEnergyState;
    case RESET_CHART_PAGE:
      return initialEnergyState;
    case SUCCESS_API_ENERGY_HOURLY:
      return successApiEnergyHourly(energyState, action);
    default: {
      return energyState;
    }
  }
};
