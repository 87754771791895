export const timeUnitId = {
  second: '10',
  minute: '20',
  halfHour: '25',
  hour: '30',
  day: '40',
  week: '45',
  month: '50',
  year: '60',
};

export const applianceId = {
  airConditioner: '2',
  clothesWasher: '5',
  microwave: '20',
  refrigerator: '24',
  riceCooker: '25',
  tv: '30',
  vacuumCleaner: '31',
  dishwasher: '34',
  oven: '35',
  ih: '37',
  ecoCute: '39',
  highFever: '300',
  heater: '301',
  light: '304',
  storageBattery: '994',
  chargingStorageBattery: 'c994', // IMT4DLでは未使用
  storageBatteryFullBackup: '9941',
  chargingStorageBatteryFullBackup: 'c9941', // IMT4DLでは未使用
  background: '998',
};
