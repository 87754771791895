/* eslint-env browser */
// eslint-disable-next-line no-redeclare
/* global localStorage */
// @flow

import React, { Component } from 'react';
import qs from 'qs';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  apiCheckLogin,
  apiGetUserStatus,
  apiGetUserInfo,
  apiLoginExecute,
} from '../../actions/apis';
import {
  LOGIN_STATUS_200,
  LOGIN_STATUS_500,
  FORCIBLY_TRANSITION_TO_LOGIN,
} from '../../actions/types';
import Prefixer from '../../lib/Prefixer';
import '../../css/loadingAnimation.css'; // CAUTION: this style is temporary one at v2 design.

const prefixer = new Prefixer();

const style = prefixer.prefix({
  body: {
    backgroundColor: '#31bfc2',
    maxWidth: '480px',
    margin: '0px auto',
    padding: '0',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingAnime: {
    width: '66px',
    height: '54px',
    position: 'relative',
    borderBottom: '2px solid white',
  },
  loadingAnimationSpan: {
    display: 'block',
    width: '14px',
    position: 'absolute',
    bottom: '0',
    background: 'white',
    borderTopLeftRadius: '2px',
    borderTopRightRadius: '2px',
  },
});

const renderLoadingAnime = () => (
  <div style={style.body}>
    <div style={style.loadingAnime} className="loading-animation">
      <span style={style.loadingAnimationSpan} />
      <span style={style.loadingAnimationSpan} />
      <span style={style.loadingAnimationSpan} />
    </div>
  </div>
);

const userLoginCheck = (props) => {
  const { initialized } = props.loginInfo;
  if (!initialized) {
    props.dispatch(apiCheckLogin());
    props.dispatch({ type: LOGIN_STATUS_200 });
  }
};

const canUseApp = (loginState, userStatus, statusCode) =>
  loginState === 'SUCCESS' &&
  userStatus !== 'no_data' &&
  !(userStatus === 'error' && statusCode !== '200');

const userWillTransfer = (props, router) => {
  const { initialized, requesting, loginState } = props.loginInfo;
  const { status: userStatus, infoRequesting, statusRequesting } = props.userInfo;

  if (statusRequesting) {
    return;
  }

  const token = qs.parse(window.location.search.substr(1) || '').idtoken;
  const path = qs.parse(window.location.search.substr(1) || '').location;

  if (!token) {
    props.dispatch({ type: LOGIN_STATUS_500 });
  } else if (initialized && (loginState === 'LOADING_LOGIN' || loginState === 'UNAUTHORIZED')) {
    if (!token.match(/^[\u0021-\u007e]+$/)) {
      props.dispatch({ type: LOGIN_STATUS_500 });
      return;
    }

    props.dispatch(apiLoginExecute(token));
  }

  if (userStatus === 'error' && props.statusCode === '200') {
    props.dispatch({ type: LOGIN_STATUS_500 });
    return;
  }

  if (
    loginState === 'SUCCESS' &&
    userStatus === 'no_data' &&
    !statusRequesting &&
    !infoRequesting &&
    requesting
  ) {
    props.dispatch(apiGetUserStatus());
    props.dispatch(apiGetUserInfo());
    return;
  }

  if (canUseApp(loginState, userStatus, props.statusCode) && requesting) {
    props.dispatch(apiCheckLogin());
    props.dispatch({ type: LOGIN_STATUS_200 });
    if (path && (path.indexOf('/chart/month/') !== -1 || path.indexOf('/report/') !== -1)) {
      router.history.replace(props.location.nextPathname || path);
      return;
    }
    router.history.replace(props.location.nextPathname || '/top');
    return;
  }
  if (loginState === 'SUCCESS' && !requesting) {
    props.dispatch({ type: FORCIBLY_TRANSITION_TO_LOGIN });
    if (token) {
      if (!token.match(/^[\u0021-\u007e]+$/)) {
        props.dispatch({ type: LOGIN_STATUS_500 });
        return;
      }
      props.dispatch(apiLoginExecute(token));
    }
  }
};

const isErrorComponent = (props, router) => {
  const { loginState } = props.loginInfo;
  const { status: userStatus } = props.userInfo;
  if (!(props.statusCode === '200' && loginState !== 'FAILED' && userStatus !== 'error')) {
    router.history.replace(props.location.nextPathname || '/error');
  }
};

type Props = {
  location: {
    state?: Object,
    pathname?: string,
  },
  loginInfo: { loginState: string, initialized: boolean, requesting: boolean },
  userInfo: {
    status: string,
    infoRequesting: boolean,
    statusRequesting: boolean,
    contractorId: string,
  },
  statusCode: string,
  location: { nextPathname: string },
  dispatch: Dispatch,
};

class LoginAreaBase extends Component<Props> {
  componentDidMount() {
    userLoginCheck(this.props);
    userWillTransfer(this.props, this.context.router);
  }
  componentDidUpdate() {
    isErrorComponent(this.props, this.context.router);
    userWillTransfer(this.props, this.context.router);
  }
  render() {
    return renderLoadingAnime();
  }
}

LoginAreaBase.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectedLoginArea: any = connect((state) => ({
  loginInfo: state.loginInfo,
  userInfo: state.userInfo,
  tutorialFinished: state.tutorial.tutorialFinished,
  statusCode: state.loginStatus.statusCode,
}))(LoginAreaBase);

export default connectedLoginArea;
