// @flow

import moment from 'moment';
import type { intlShape } from 'react-intl';
import { createSelector } from 'reselect';
import { applianceId, timeUnitId } from '../id';
import { calcPowerCharge } from '../price/calculatedPrice';
import { checkBillingPeriod, filterUnitPrices } from '../price/priceUtils';
import type { UnitPrice } from '../types/priceInfoTypes';
import type { ApplianceEnergyDetails } from '../types/electricTypes';
import { sum } from './calculated';

import aircon from '../../images/icn_aircon.png';
import highfever from '../../images/icn_highfever.png';
import microwave from '../../images/icn_microwave.png';
import ricecooker from '../../images/icn_ricecooker.png';
import ih from '../../images/icn_IH.png';
import ecocute from '../../images/icn_ecocute.png';
import wash from '../../images/icn_wash.png';
import vacuum from '../../images/icn_vacuum.png';
import tv from '../../images/icn_tv.png';
import dishwasher from '../../images/icn_dishwasher.png';
import oven from '../../images/icn_oven.png';
import fridge from '../../images/icn_refrigerator.png';
import storagebattery from '../../images/icn_battery.png';
import background from '../../images/icn_plug.png';
import heater from '../../images/icn_heat.png';
import light from "../../images/icn_light.png"

export const switchImage = (id: string): string => {
  switch (id) {
    case applianceId.airConditioner: {
      return aircon;
    }
    case applianceId.clothesWasher: {
      return wash;
    }
    case applianceId.microwave: {
      return microwave;
    }
    case applianceId.refrigerator: {
      return fridge;
    }
    case applianceId.riceCooker: {
      return ricecooker;
    }
    case applianceId.tv: {
      return tv;
    }
    case applianceId.vacuumCleaner: {
      return vacuum;
    }
    case applianceId.dishwasher: {
      return dishwasher;
    }
    case applianceId.oven: {
      return oven;
    }
    case applianceId.ecoCute: {
      return ecocute;
    }
    case applianceId.ih: {
      return ih;
    }
    case applianceId.highFever: {
      return highfever;
    }
    case applianceId.heater: {
      return heater;
    }
    case applianceId.light: {
      return light;
    }
    case applianceId.storageBattery:
    case applianceId.storageBatteryFullBackup: {
      return storagebattery;
    }
    case applianceId.background: {
      return background;
    }
    default: {
      return '';
    }
  }
};

export const convertToEndOfDay = (unix: number | null): number | null =>
  (unix &&
    moment
      .unix(unix)
      .endOf('day')
      .unix()) ||
  null;

// 既存処理共通化
const applianceNameMemo = [];
export const getApplianceNameFromId = (idString: string, intl: intlShape) => {
  const id = Number(idString);
  if (applianceNameMemo[id]) {
    return applianceNameMemo[id];
  }

  const foundItem = Object.entries(applianceId).find(arrayItem => {
    const [applianceName, applianceIndex] = arrayItem;
    if (String(id) === applianceIndex) {
      return applianceName;
    }
    return false;
  });

  if (foundItem) {
    const name = foundItem[0];
    applianceNameMemo[id] = intl.formatMessage({ id: `topApplianceRankingItem.${name}` });
  } else {
    applianceNameMemo[id] = ' - ';
  }

  return applianceNameMemo[id];
};

export const extractServiceProviderIdFromState = createSelector(
  state => state.userInfo.contractorId,
  contractorId => contractorId.split('_')[0]
);

export const supportedLocale = ['ja'];

export const getHourlyRequestMeta = (unit: string) => {
  const setUnit = unit || 'day';
  return {
    reqUnit: timeUnitId[setUnit],
    subUnitFlg: false,
  };
};

export const getHourlyRequestMetaSub = (unit: string) => {
  const setUnit = unit || 'week';
  return {
    reqUnit: timeUnitId[setUnit],
    subUnitFlg: true,
  };
};

const compareNumbers = (a, b) => b.energyValue - a.energyValue;

export const sortRanking = (
  appliance: ApplianceEnergyDetails[],
  unitPrices: UnitPrice[]
): ApplianceEnergyDetails & { energyValue: number }[] => {
  const filterUnitPricesData = filterUnitPrices(
    unitPrices,
    appliance[0].data[0].unix,
    convertToEndOfDay(appliance[0].data[appliance[0].data.length - 1].unix)
  );
  const aplsPowerCharge = appliance.map(apl => ({
    ...apl,
    energyValue: filterUnitPricesData.map((unitPrice: UnitPrice) => {
      const filter = apl.data.filter(v =>
        checkBillingPeriod(unitPrice.sts, convertToEndOfDay(unitPrice.ets), v.unix, null)
      );
      const energy = filter.reduce((p: number, n) => p + sum(n.energy), 0);
      return calcPowerCharge(energy, unitPrice);
    }),
  }));

  return aplsPowerCharge
    .map(target => ({
      ...target,
      energyValue: sum(target.energyValue),
    }))
    .sort(compareNumbers);
};
