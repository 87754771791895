// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
  CAUTION
  This css styles are temporary to develop v2 design copied from v1.
  We should reconsider to use this when decided direction of v2 design.
*/

@keyframes left-bar {
    0% {height: 0;}
    20%{height: 25%;}
    40%{height: 100%;}
    60%{height: 0%;}
    80%{height: 50%;}
    100%{height: 0;}
}

@keyframes center-bar {
    0% {height: 0;}
    20%{height: 75%;}
    40%{height: 50%;}
    60%{height: 0%;}
    80%{height: 100%;}
    100%{height: 0;}
}

@keyframes right-bar {
    0% {height: 0;}
    20%{height: 100%;}
    40%{height: 20%;}
    60%{height: 100%;}
    80%{height: 50%;}
    100%{height: 0;}
}

.loading-animation span:first-child {
    left: 6px;
    animation: left-bar 1800ms linear infinite;
}

.loading-animation span:nth-child(2) {
    left: calc(6px * 2 + 14px);
    animation: center-bar 1800ms linear infinite;
}

.loading-animation span:last-child {
    right: 6px;
    animation: right-bar 1800ms linear infinite;
}`, "",{"version":3,"sources":["webpack://./src/client/css/loadingAnimation.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED;IACI,IAAI,SAAS,CAAC;IACd,IAAI,WAAW,CAAC;IAChB,IAAI,YAAY,CAAC;IACjB,IAAI,UAAU,CAAC;IACf,IAAI,WAAW,CAAC;IAChB,KAAK,SAAS,CAAC;AACnB;;AAEA;IACI,IAAI,SAAS,CAAC;IACd,IAAI,WAAW,CAAC;IAChB,IAAI,WAAW,CAAC;IAChB,IAAI,UAAU,CAAC;IACf,IAAI,YAAY,CAAC;IACjB,KAAK,SAAS,CAAC;AACnB;;AAEA;IACI,IAAI,SAAS,CAAC;IACd,IAAI,YAAY,CAAC;IACjB,IAAI,WAAW,CAAC;IAChB,IAAI,YAAY,CAAC;IACjB,IAAI,WAAW,CAAC;IAChB,KAAK,SAAS,CAAC;AACnB;;AAEA;IACI,SAAS;IACT,0CAA0C;AAC9C;;AAEA;IACI,0BAA0B;IAC1B,4CAA4C;AAChD;;AAEA;IACI,UAAU;IACV,2CAA2C;AAC/C","sourcesContent":["/*\n  CAUTION\n  This css styles are temporary to develop v2 design copied from v1.\n  We should reconsider to use this when decided direction of v2 design.\n*/\n\n@keyframes left-bar {\n    0% {height: 0;}\n    20%{height: 25%;}\n    40%{height: 100%;}\n    60%{height: 0%;}\n    80%{height: 50%;}\n    100%{height: 0;}\n}\n\n@keyframes center-bar {\n    0% {height: 0;}\n    20%{height: 75%;}\n    40%{height: 50%;}\n    60%{height: 0%;}\n    80%{height: 100%;}\n    100%{height: 0;}\n}\n\n@keyframes right-bar {\n    0% {height: 0;}\n    20%{height: 100%;}\n    40%{height: 20%;}\n    60%{height: 100%;}\n    80%{height: 50%;}\n    100%{height: 0;}\n}\n\n.loading-animation span:first-child {\n    left: 6px;\n    animation: left-bar 1800ms linear infinite;\n}\n\n.loading-animation span:nth-child(2) {\n    left: calc(6px * 2 + 14px);\n    animation: center-bar 1800ms linear infinite;\n}\n\n.loading-animation span:last-child {\n    right: 6px;\n    animation: right-bar 1800ms linear infinite;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
