/* eslint-env browser */
export default userAgent => {
  const ua = userAgent.toLowerCase();
  if (ua.indexOf('chrome') >= 0 || ua.indexOf('crios') >= 0) {
    // iOSとAndroidでChromeのuserAgentが違う
    // Chromeは無条件でOK(Microsoft EdgeもChrome扱い)
    return true;
  }
  // Chrome以外
  if (ua.indexOf('safari') >= 0) {
    const array1 = /version\/([\d.]+)/.exec(ua);
    const version1 = array1 ? array1[1] : '';
    if (version1 !== '') {
      const array2 = /(\d+)\./.exec(version1);
      const version2 = array2 ? array2[1] : '';
      if (Number(version2) >= 10) {
        // Safariの場合は10から対応だがとりあえず10以上はOKとする
        return true;
      }
      if (ua.indexOf('android') >= 0 && Number(version2) >= 5) {
        // Android標準ブラウザの対応は5以上はOKとする
        return true;
      }
    }
  }
  return true;
};
