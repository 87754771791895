// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import sizeMe from 'react-sizeme';
import ReactScroll from 'react-scroll';
import { Animate } from 'react-move';
import { TUTORIAL_FINISHED } from '../../actions/types';
import Prefixer from '../../lib/Prefixer';
import tutorial from '../../images/tutorial.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  tutorial: {
    position: 'relative',
    color: '#fff',
    backgroundColor: '#33b2b5',
    maxWidth: '480px',
    margin: '0px auto',
    padding: '0',
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    margin: '15px auto',
  },
  contentLarge: {
    height: '100%',
    margin: '60px auto',
  },
  title: {
    fontSize: '1.8em',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleSmall: {
    fontSize: '1.4em',
  },
  emphasis: {
    fontSize: '1.2em',
  },
  tutorialImageWrapper: {
    width: '100%',
    margin: '3% auto',
    textAlign: 'center',
  },
  tutorialImage: {
    maxWidth: '480px',
  },
  details: {
    fontSize: '0.9em',
    margin: '0px 20px',
  },
  buttonWrapper: {
    margin: '0px auto',
    textAlign: 'center',
  },
  button: {
    position: 'absolute',
    color: '#fff',
    fontWeight: 'bold',
    width: '100%',
    height: '55px',
    background: '#298c8e',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  arrowArea: {
    height: '15px',
    marginLeft: '15px',
  },
  arrow: {
    fill: '#fafafa',
  },
});

const contentStyle = size => {
  if (size && size.height > 800) {
    return style.contentLarge;
  }
  return style.content;
};

const titleStyle = size => {
  if (size && size.width < 360) {
    return { ...style.title, ...style.titleSmall };
  }
  return style.title;
};

const tutorialImageStyle = size => ({
  ...style.tutorialImage,
  width: size && size.height - size.width < 260 && size.height > 0 ? '45%' : '55%',
});

const renderContent = (intl, size) => {
  const origin = { opacity: 0 };
  const target = { opacity: 1 };
  return (
    <div style={contentStyle(size)}>
      <div style={titleStyle(size)}>
        {intl.formatMessage({ id: 'tutorialPage.title1' })}
        <span style={style.emphasis}>{intl.formatMessage({ id: 'tutorialPage.title2' })}</span>
        {intl.formatMessage({ id: 'tutorialPage.title3' })}
      </div>
      <Animate default={origin} data={target} duration={1000} easing="easeCubicOut">
        {data => (
          <div style={{ ...style.tutorialImageWrapper, opacity: data.opacity }}>
            <img style={tutorialImageStyle(size)} src={tutorial} alt="tutorial" />
          </div>
        )}
      </Animate>
      <div style={style.details}>{intl.formatMessage({ id: 'tutorialPage.details' })}</div>
    </div>
  );
};

const renderGoTopPage = (dispatch, intl) => (
  <div style={style.buttonWrapper}>
    <Link to="/top" className="TopPage" onClick={() => dispatch({ type: TUTORIAL_FINISHED })}>
      <div style={style.button}>
        <span>{intl.formatMessage({ id: 'tutorialPage.start' })}</span>
        <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
          <path style={style.arrow} d="M179,100,39,180V20Z" />
        </svg>
      </div>
    </Link>
  </div>
);

type Props = {
  dispatch: Dispatch,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
};

export const PureTutorialPage = ({ dispatch, intl, size }: Props): Node => (
  <div style={style.tutorial}>
    {renderContent(intl, size)}
    {renderGoTopPage(dispatch, intl)}
    {ReactScroll.animateScroll.scrollToTop()}
  </div>
);

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureTutorialPage: any = connect()(sizeMeHOC(injectIntl(PureTutorialPage)));
export default connectedPureTutorialPage;
