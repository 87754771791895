// @flow

import moment from 'moment';
import React from 'react';
import type { Node } from 'react';
import { Link } from 'react-router-dom';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import sizeMe from 'react-sizeme';
import TopTotalAmountArea from './TopTotalAmountArea';
import TopTimeBar from './TopTimeBar';
import TopHourlyAnalysisArea from './TopHourlyAnalysisArea';
import Prefixer from '../lib/Prefixer';
import { makeValidPeriod, makeCurrentWeighingPeriod } from '../lib/common/periodUtils';
import { calcPowerCharge } from '../lib/price/calculatedPrice';
import { isForecastExceeded, floorForecastValue } from '../lib/price/forecastPriceUtils';
import { houseAnimationType } from '../lib/topPageUtils';
import { OPEN_ALERT } from '../actions/types';
import type { UnitPrice, BillingInfo } from '../lib/types/priceInfoTypes';
import type { AplFilter, Durations } from '../lib/types/electricTypes';
import type { PvAlert } from '../lib/types/pvAlertTypes';
import preInstallation from '../images/pre_installation.png';
import loadingAnime from '../images/loading_top.gif';
import houseAlert from '../images/house-alert.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  body: {
    maxWidth: '480px',
    margin: '0px auto',
  },
  upperArea: {
    background: '#33b2b5',
    width: '100%',
    padding: '20px 0px 0px',
  },
  balloon: {
    background: '#fff',
    borderRadius: '5px',
    width: '90%',
    margin: '0px auto',
    padding: '0px 0px 0px 0px',
  },
  lowerArea: {
    display: 'table',
    width: '92%',
    margin: '15px auto',
    padding: '0px',
  },
  timeBar: {
    display: 'table-cell',
    width: '25%',
    margin: '0px',
    padding: '0px',
    fontWeight: 'bold',
    color: '#454545',
  },
  hourlyAnalysisArea: {
    width: '68%',
    display: 'table-cell',
    textAlign: 'center',
    margin: '0px',
    padding: '0px',
    fontWeight: 'bold',
  },
  houseAnimeArea: {
    padding: '0px',
    margin: '0px',
    clear: 'both',
    width: '100%',
    display: 'block',
    height: 'auto',
    position: 'relative',
  },
  houseAnimeImage: {
    margin: '0px',
    width: '100%',
    verticalAlign: 'bottom',
  },
  houseAlertArea: {
    margin: '0 auto',
    width: '90%',
    height: '21px',
    textAlign: 'left',
  },
  houseAlert: {
    position: 'absolute',
    width: '30px',
    margin: '40px 0px 0px 19%',
  },
  link: {
    textDecoration: 'none',
  },
  forecastAlertArea: {
    position: 'relative',
    top: '29px',
    background: '#faeded',
    borderRadius: '5px',
    width: '55%',
    minWidth: '175px',
    margin: '-22px 0px 0px 23%',
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '100',
  },
  forecastAlert10OrMore: {
    color: '#ff5354',
    background: '#faeded',
    border: 'solid 1px #faeded',
  },
  forecastAlert5OrMore: {
    color: '#ff9657',
    background: '#fcebe0',
    border: 'solid 1px #fcebe0',
  },
  forecastAlert5OrLess: {
    color: '#ffb657',
    background: '#fff2e0',
    border: 'solid 1px #fff2e0',
  },
  forecastAlert: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    margin: '0px auto',
    padding: '0px 0px 0px 0px',
  },
  forecastAlertSmall: {
    fontSize: '1.0em',
    fontWeight: 'bold',
    margin: '0px auto',
    padding: '0px 0px 0px 0px',
  },
  pvAlertArea: {
    position: 'relative',
    top: '29px',
    color: '#ff5354',
    background: '#f9cdc7',
    borderRadius: '5px',
    width: '65%',
    minWidth: '220px',
    margin: '-22px 0px 0px 23%',
    padding: '4px 8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '100',
    border: 'solid 1px #faeded',
  },
  pvAlert: {
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '23px',
    margin: '0px auto',
    padding: '0px 10px 0px 0px',
  },
  arrowPvAlert: {
    fill: '#ff5354',
  },
  arrowArea: {
    position: 'absolute',
    height: '12px',
    verticalAlign: 'middle',
    marginRight: '5px',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
  arrow10OrMore: {
    fill: '#ff5354',
  },
  arrow5OrMore: {
    fill: '#ff9657',
  },
  arrow5OrLess: {
    fill: '#ffb657',
  },
  infoBackground: {
    height: '100vh',
    background: '#33b2b5',
    paddingTop: '30px',
  },
  infoArea: {
    width: '80%',
    maxWidth: '340px',
    margin: '0px auto',
  },
  warningArea: {
    height: '45px',
    verticalAlign: 'middle',
    marginBottom: '10px',
  },
  warning: {
    fill: '#fafafa',
  },
  infoTitle: {
    borderRadius: '3px 3px 0px 0px',
    width: '100%',
    maxWidth: '340px',
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: '#fff',
    padding: '20px 0px',
    textAlign: 'center',
    margin: '0px auto',
    background: '#ff5354',
  },
  infoPreInstallationTitle: {
    borderRadius: '3px 3px 0px 0px',
    width: '100%',
    maxWidth: '340px',
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: '#fff',
    padding: '30px 0px',
    textAlign: 'center',
    margin: '0px auto',
    background: '#ffbd00',
  },
  infoImage: {
    width: '100%',
  },
  infoBox: {
    borderRadius: '0px 0px 3px 3px',
    background: '#fff',
    maxWidth: '340px',
    margin: '0px auto 10px',
    textAlign: 'left',
    padding: '20px 10px',
    color: '#555',
  },
  infoText: {
    color: '#454545',
    fontSize: '1.0em',
    textAlign: 'left',
    width: '90%',
    margin: '0px auto',
  },
  infoAnnotation: {
    color: '#888',
    fontSize: '0.9em',
  },
  infoLink: {
    color: '#555',
  },
  infoButton: {
    background: '#298c8e',
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '20px auto 0',
    padding: '10px',
    width: '110px',
    display: 'block',
    color: '#fff',
    borderRadius: 8,
  },
  loadingAnimeWp: {
    position: 'absolute',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
    margin: '30% 0px',
    height: '300px',
  },
  loadingAnime: {
    width: '80%',
    maxWidth: '300px',
  },
  howToConnectBtn: {
    color: 'white',
    textDecoration: 'none',
  },
});

type Size = {
  width: number,
  height: number,
};

type Props = {
  range: string[],
  aplFilter: AplFilter[],
  hourlyDurations: Durations[],
  userStatus: string,
  statusRequesting: boolean,
  infoRequesting: boolean,
  hourlyRequesting: boolean,
  monthlyRequesting: boolean,
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unitPrices: UnitPrice[],
  pvAlert: PvAlert,
  dispatch: Dispatch,
  intl: intlShape,
  size: Size,
};

const alertStyle = (overPercentage: number, forecastAlertArea: any) => {
  if (overPercentage < 5) {
    return { ...forecastAlertArea, ...style.forecastAlert5OrLess };
  }
  if (overPercentage < 10) {
    return { ...forecastAlertArea, ...style.forecastAlert5OrMore };
  }
  return { ...forecastAlertArea, ...style.forecastAlert10OrMore };
};
const forecastAlertStyle = (overPercentage: number) => {
  if (overPercentage < 5) {
    return 'forecast-alert-5OrLess';
  }
  if (overPercentage < 10) {
    return 'forecast-alert-5OrMore';
  }
  return 'forecast-alert-10OrMore';
};
const alertArrowStyle = (overPercentage: number) => {
  if (overPercentage < 5) {
    return style.arrow5OrLess;
  }
  if (overPercentage < 10) {
    return style.arrow5OrMore;
  }
  return style.arrow10OrMore;
};

const messages = defineMessages({
  overForecastValue: {
    id: 'topBody.overForecastValue',
    description: '予測電気代超過アラート',
  },
});

const alertToDisplay = (
  requesting: boolean,
  intl: intlShape,
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unitPrices: UnitPrice[],
  pvAlert: PvAlert,
  dispatch: Dispatch,
  size: Size
) => {
  const forecastAlertArea = style.forecastAlertArea;
  const forecastAlert = size && size.width > 400 ? style.forecastAlertSmall : style.forecastAlert;

  const { billingRequesting, billingData, forecastPower } = billingInfo;

  if (pvAlert.isPvAlert) {
    return (
      <div
        style={style.link}
        onClick={() =>
          dispatch({
            type: OPEN_ALERT,
          })
        }
      >
        <div style={style.houseAlertArea}>
          <div style={style.pvAlertArea} className="pv-alert">
            <span style={style.pvAlert}>{intl.formatMessage({ id: 'topBody.pvAlert' })}</span>
            <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <path style={style.arrowPvAlert} d="M179,100,39,180V20Z" />
            </svg>
          </div>
          <img src={houseAlert} alt="houseAlert" style={style.houseAlert} />
        </div>
      </div>
    );
  }

  const forecastValue = calcPowerCharge(forecastPower, unitPrices[unitPrices.length - 1]);
  const overPercentage = isForecastExceeded(
    billingData,
    lastBillingDate,
    accountStartDate,
    forecastValue
  );

  if (billingRequesting || overPercentage <= 0) {
    return null;
  }

  const overForecastValue = intl.formatNumber(floorForecastValue(forecastValue));

  if (requesting) {
    return (
      <div style={style.houseAlertArea}>
        <div
          style={alertStyle(overPercentage, forecastAlertArea)}
          className={forecastAlertStyle(overPercentage)}
        >
          <span style={forecastAlert}>
            {intl.formatMessage(messages.overForecastValue, { overForecastValue })}
          </span>
          <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path style={alertArrowStyle(overPercentage)} d="M179,100,39,180V20Z" />
          </svg>
        </div>
        <img src={houseAlert} alt="houseAlert" style={style.houseAlert} />
      </div>
    );
  }

  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingData,
    lastBillingDate,
    accountStartDate
  );
  const billingMonth =
    currentWeighingPeriod && moment(currentWeighingPeriod.billingMonth).isValid()
      ? moment(currentWeighingPeriod.billingMonth, 'YYYY-MM').format('YYYY-MM')
      : '';

  const unit = 'month';
  const period = makeValidPeriod(
    unit,
    unit === 'week' ? moment() : moment(billingMonth, 'YYYY-MM')
  );

  return (
    <Link style={style.link} to={`/chart/${unit}/${period}`}>
      <div style={style.houseAlertArea}>
        <div
          style={alertStyle(overPercentage, forecastAlertArea)}
          className={forecastAlertStyle(overPercentage)}
        >
          <span style={forecastAlert}>
            {intl.formatMessage(messages.overForecastValue, { overForecastValue })}
          </span>
          <svg style={style.arrowArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path style={alertArrowStyle(overPercentage)} d="M179,100,39,180V20Z" />
          </svg>
        </div>
        <img src={houseAlert} alt="houseAlert" style={style.houseAlert} />
      </div>
    </Link>
  );
};

const renderNormal = (
  range: string[],
  aplFilter: AplFilter[],
  hourlyDurations: Durations[],
  intl: intlShape,
  hourlyRequesting: boolean,
  monthlyRequesting: boolean,
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unitPrices: UnitPrice[],
  pvAlert: PvAlert,
  dispatch: Dispatch,
  locale: any,
  size: Size
) => {
  const requesting = hourlyRequesting || monthlyRequesting || billingInfo.billingRequesting;

  return (
    <div>
      <div style={style.upperArea}>
        <div style={style.balloon}>
          <TopTotalAmountArea billingInfo={billingInfo} dispatch={dispatch} />
        </div>

        <div style={style.houseAnimeArea}>
          {alertToDisplay(
            requesting,
            intl,
            billingInfo,
            lastBillingDate,
            accountStartDate,
            unitPrices,
            pvAlert,
            dispatch,
            size
          )}
          <img
            src={houseAnimationType(range, aplFilter, hourlyDurations)}
            alt="anime"
            style={style.houseAnimeImage}
          />
        </div>
      </div>
      <div style={style.lowerArea}>
        <div style={style.timeBar}>
          <TopTimeBar
            billingInfo={billingInfo}
            lastBillingDate={lastBillingDate}
            accountStartDate={accountStartDate}
            dispatch={dispatch}
          />
        </div>
        <div style={style.hourlyAnalysisArea}>
          <TopHourlyAnalysisArea dispatch={dispatch} size={size} />
        </div>
      </div>
    </div>
  );
};

const showPreInstallation = (intl: intlShape) =>
  intl.formatMessage({ id: 'topBody.showPreInstallation' }) === 'true';

declare var config: { URLS: { [key: string]: { [key: string]: string } } };
const renderPreInstallation = (intl: intlShape) => {
  const { Q_WAITING_ONE_WEEK } = config.URLS[intl.locale.toUpperCase()] || {};

  return (
    <div style={style.infoBackground}>
      <div style={style.infoArea}>
        <div style={style.infoPreInstallationTitle}>
          {intl.formatMessage({ id: 'topBody.waitingT1' })}
          <br />
          {intl.formatMessage({ id: 'topBody.waitingT2' })}
        </div>
        <div style={style.infoBox}>
          <img src={preInstallation} alt="preInstallation" style={style.infoImage} />
          <div style={style.infoText}>
            {intl.formatMessage({ id: 'topBody.waitingDF1' })}
            <br />
            <br />
            {intl.formatMessage({ id: 'topBody.waitingDS1' })}
            <br />
            <br />
            {intl.formatMessage({ id: 'topBody.waitingDT1' })}
            <br />
            <br />
            {showPreInstallation(intl) && (
              <div style={style.infoAnnotation}>
                {intl.formatMessage({ id: 'topBody.waitingA1' })}
                <a href={Q_WAITING_ONE_WEEK} rel="noopener noreferrer" style={style.infoLink}>
                  {intl.formatMessage({ id: 'topBody.waitingA2' })}
                </a>
                {intl.formatMessage({ id: 'topBody.waitingA3' })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const showNullRootPowerButton = (intl: intlShape) =>
  intl.formatMessage({ id: 'topBody.showNullRootPowerButton' }) === 'true';

const renderButton = (intl: intlShape) => {
  const { Q_HOW_TO_CONNECT } = config.URLS[intl.locale.toUpperCase()] || {};
  return (
    <div style={style.infoButton}>
      <a href={Q_HOW_TO_CONNECT} rel="noopener noreferrer" style={style.howToConnectBtn}>
        {intl.formatMessage({ id: 'topBody.disconnectedButton' })}
      </a>
    </div>
  );
};

const renderNullRootPower = (intl: intlShape) => {
  const nullRootPower = require(`../images/intro_ja.png`);
  return (
    <div style={style.infoBackground}>
      <div style={style.infoArea}>
        <div style={style.infoTitle}>
          <svg style={style.warningArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
            <path
              style={style.warning}
              d="M180,149.69,112,35.94a14,14,0,0,0-24.06,0L20,149.69a14,14,0,0,0,12,21.2H168a14,14,0,0,0,12-21.2Zm-87.3-83.5A4.31,4.31,0,0,1,97,61.88h4.69A4.31,4.31,0,0,1,106,66.19v59.65a4.31,4.31,0,0,1-4.31,4.3H97.05a4.31,4.31,0,0,1-4.31-4.3Zm6.66,86.59a8.1,8.1,0,1,1,8.09-8.09h0A8.09,8.09,0,0,1,99.38,152.78Z"
            />
          </svg>
          <br />
          {intl.formatMessage({ id: 'topBody.disconnectedT1' })}
          <br />
          {intl.formatMessage({ id: 'topBody.disconnectedT2' })}
        </div>
        <div style={style.infoBox}>
          <img src={nullRootPower} alt="nullRootPower" style={style.infoImage} />
          <div style={style.infoText}>{intl.formatMessage({ id: 'topBody.disconnectedD' })}</div>
          {showNullRootPowerButton(intl) && renderButton(intl)}
        </div>
      </div>
    </div>
  );
};

const renderLoadingAnime = () => (
  <div style={style.loadingAnimeWp}>
    <img src={loadingAnime} alt="loading" style={style.loadingAnime} />
  </div>
);

const renderBody = (
  range: string[],
  aplFilter: AplFilter[],
  hourlyDurations: {
    hour: number,
    appliances: {
      id: string,
      isUsed: boolean,
    }[],
  }[],
  userStatus: string,
  hourlyRequesting: boolean,
  monthlyRequesting: boolean,
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unitPrices: UnitPrice[],
  pvAlert: PvAlert,
  dispatch: Dispatch,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  }
) => {
  switch (userStatus) {
    case 'pre_installation':
      return renderPreInstallation(intl);
    case 'null_root_power':
      return renderNullRootPower(intl);
    default:
      return renderNormal(
        range,
        aplFilter,
        hourlyDurations,
        intl,
        hourlyRequesting,
        monthlyRequesting,
        billingInfo,
        lastBillingDate,
        accountStartDate,
        unitPrices,
        pvAlert,
        dispatch,
        intl.locale,
        size
      );
  }
};

const bodyFontSize = (width) => (width >= 400 ? '16px' : '12px');

export const PureTopBody = ({
  range,
  aplFilter,
  hourlyDurations,
  userStatus,
  statusRequesting,
  infoRequesting,
  hourlyRequesting,
  monthlyRequesting,
  billingInfo,
  lastBillingDate,
  accountStartDate,
  unitPrices,
  pvAlert,
  dispatch,
  intl,
  size,
}: Props): Node => (
  <div style={{ ...style.body, fontSize: bodyFontSize(size.width) }}>
    {statusRequesting || infoRequesting
      ? renderLoadingAnime()
      : renderBody(
          range,
          aplFilter,
          hourlyDurations,
          userStatus,
          hourlyRequesting,
          monthlyRequesting,
          billingInfo,
          lastBillingDate,
          accountStartDate,
          unitPrices,
          pvAlert,
          dispatch,
          intl,
          size
        )}
  </div>
);

const mapStateToProps = (state) => ({
  range: state.timeRange.range,
  aplFilter: state.userSetting.aplFilter,
  hourlyDurations: state.applianceState.hourlyDurations,
  userStatus: state.userInfo.status,
  statusRequesting: state.userInfo.statusRequesting,
  infoRequesting: state.userInfo.infoRequesting,
  monthlyRequesting: state.energy.monthlyRequesting,
  hourlyRequesting: state.energy.hourlyRequesting,
  unitPrices: state.userInfo.unitPrices,
  pvAlert: state.pvAlert,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureTopBody: any = connect(mapStateToProps)(sizeMeHOC(injectIntl(PureTopBody)));
export default connectedPureTopBody;
