// @flow

import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import type { Dispatch } from 'redux';
import type { Node } from 'react';

import Prefixer from '../lib/Prefixer';
import sellPng from '../images/icn_sell.png';
import { OPEN_POP_UP } from '../actions/types';
import { HELP_SOLD_CHARGE } from '../actions/helpPopupTypes';
import type { EnergyPerDay } from '../lib/types/electricTypes';
import { calcTotalEnergy } from '../lib/common/calculated';
import { calcSellCharge } from '../lib/price/calculatedPrice';
import loadingAnime from '../images/loading.gif';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
  },
  body: {
    marginTop: '10px',
  },
  loadingAnime: {
    margin: '80px 0px',
  },
  info: {
    top: '-23px',
    right: '-5px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#bbb',
    float: 'right',
  },
  soldArea: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  sellIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '5px',
    paddingBottom: '3px',
    width: '20px',
  },
  rightCell: {
    textAlign: 'right',
    energy: {
      fontWeight: 'lighter',
      fontSize: '0.8em',
      opacity: '0.5',
      marginTop: '5px',
    },
  },
  billingUndecided: {
    color: '#ff8f86',
    margin: 'auto 0px auto 10px',
    padding: '3px 10px',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '0.3em',
    fontWeight: 'normal',
    border: 'solid 1px #ff8f86',
    borderRadius: '50px',
  },
});

type Props = {
  requesting: boolean,
  billingRequesting: boolean,
  unitEnergy: EnergyPerDay | null,
  soldAmount: number | null,
  unit: string,
  sellingPattern: string,
  soldUnitPrice: number,
  intl: intlShape,
  dispatch: Dispatch,
};

const renderInfoButton = (dispatch: Dispatch) => (
  <div style={style.info}>
    <Icon
      icon={faQuestionCircle}
      style={style.infoButton}
      onClick={(e) =>
        dispatch({
          type: OPEN_POP_UP,
          contentType: HELP_SOLD_CHARGE,
          target: e.target,
        })
      }
    />
  </div>
);

const renderLoadingAnime = (intl: intlShape) => (
  <div>
    <div style={style.loadingAnime}>
      <img src={loadingAnime} alt="loading" />
    </div>
    <div>{intl.formatMessage({ id: 'chartSubUnit.loading' })}</div>
  </div>
);

const renderBody = (
  requesting: boolean,
  billingRequesting: boolean,
  unitEnergy: EnergyPerDay | null,
  soldAmount: number | null,
  unit: string,
  sellingPattern: string,
  soldUnitPrice: number,
  intl: intlShape
) => {
  if (requesting || billingRequesting || !unitEnergy) {
    return renderLoadingAnime(intl);
  }

  const totalSellEnergy = calcTotalEnergy(unitEnergy.data, 'sellEnergy');
  let actualSellEnergy;
  if (typeof soldAmount === 'number' && unit === 'year') {
    actualSellEnergy = soldAmount / soldUnitPrice + totalSellEnergy;
  } else if (typeof soldAmount === 'number') {
    actualSellEnergy = soldAmount / soldUnitPrice;
  } else {
    actualSellEnergy = totalSellEnergy;
  }
  const sellCharge = calcSellCharge(actualSellEnergy, soldUnitPrice);
  const currencyFractionDigits = intl.formatMessage({ id: 'currency.fractionDigits' });
  const energyFractionDigits = intl.formatMessage({ id: 'energy.fractionDigits' });
  const sellCurrency = intl.formatNumber(sellCharge, {
    minimumFractionDigits: currencyFractionDigits,
    maximumFractionDigits: currencyFractionDigits,
  });
  const displaySellEnergy = intl.formatNumber(actualSellEnergy, {
    minimumFractionDigits: energyFractionDigits,
    maximumFractionDigits: energyFractionDigits,
  });
  return (
    <div style={style.soldArea}>
      <div>
        <img src={sellPng} alt="sell" style={style.sellIcon} />
        <span>{intl.formatMessage({ id: 'chartCharge.sellEnergy' })}</span>
      </div>
      <div style={style.rightCell}>
        <div>
          <span style={{ fontSize: '1.3em' }}>+{sellCurrency}</span>
          <span style={{ fontSize: '0.9em' }}> 円</span>
        </div>
        <div style={style.rightCell.energy}>
          {displaySellEnergy}
          {intl.formatMessage({ id: 'others.kWh' })}
        </div>
      </div>
      <div>
        {unit === 'month' && sellingPattern === 'PPA' && soldAmount === null && (
          <span style={style.billingUndecided}>
            {intl.formatMessage({ id: 'chartCharge.noAmountData' })}
          </span>
        )}
      </div>
    </div>
  );
};

export const PureChartSoldCharge = ({
  requesting,
  billingRequesting,
  unitEnergy,
  soldAmount,
  unit,
  sellingPattern,
  soldUnitPrice,
  intl,
  dispatch,
}: Props): Node => (
  <div style={style.wrapper}>
    {renderInfoButton(dispatch)}
    <div style={style.body}>
      {renderBody(
        requesting,
        billingRequesting,
        unitEnergy,
        soldAmount,
        unit,
        sellingPattern,
        soldUnitPrice,
        intl
      )}
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  billingRequesting: state.billingInfo.billingRequesting,
  sellingPattern: state.userInfo.sellingPattern,
  soldUnitPrice: state.userInfo.soldUnitPrice,
});

const connectedPureChartSoldCharge: any = connect(mapStateToProps)(injectIntl(PureChartSoldCharge));
export default connectedPureChartSoldCharge;
