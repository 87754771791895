import React from 'react';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';
import HeaderBar from '../HeaderBar';
import ErrorPage from '../ErrorPage';
import FadeTransition from '../FadeTransition';

import Prefixer from '../../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#454545',
  },
  boxUp: {
    width: '100%',
    paddingTop: '43px',
  },
});

type Props = {
  location: {
    pathname: string,
  },
  errorCode: string,
  dispatch: Dispatch,
};

const ExtendSettingPageBase = ({errorCode, location }: Props) => {
    return (
      <FadeTransition pathname={location.pathname}>
        <div>
          <HeaderBar pageType="top" />
          <div style={style.content}>
            <div style={style.boxUp}>
              <ErrorPage key="errorPage" errorCode={errorCode} />
            </div>
          </div>
        </div>
      </FadeTransition>
    );
}

const connectedPureRouteErrorPage: any = connect(state => ({
  errorCode: state.loginStatus.errorCode,
}))(ExtendSettingPageBase);

export default connectedPureRouteErrorPage;
