// @flow

import moment from 'moment';
import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FadeTransition from '../FadeTransition';
import HeaderBar from '../HeaderBar';
import Prefixer from '../../lib/Prefixer';
import ChartBody from '../ChartBody';
import {
  apiGetUseStatsForLastYear,
  apiGetBillingInfo,
  apiGetBillingForecast,
  apiGetEnergyHourly,
  apiGetEnergyHourlyByUnit,
  apiGetEnergyHourlyBySubUnit,
} from '../../actions/apis';
import {
  subTimeUnit,
  timeUnitStyle,
  timeFormat,
  hourlyRequestUnit,
  timeUnitList,
} from '../../lib/constants/timeUnit';
import {
  isValidDateFormat,
  getSubUnitDateTime,
  getSubPeriod,
  getStartDateList,
} from '../../lib/chart/chartUtils';
import { getEnergy } from '../../lib/electric/getUnitEnergy';
import {
  makeValidPeriod,
  isCurrentMonth,
  getWeighingPeriod,
  getTemporaryWeighingPeriod,
  makeWeighingPeriodArray,
  makeCurrentWeighingPeriod,
} from '../../lib/common/periodUtils';
import { getHourlyRequestMeta, getHourlyRequestMetaSub } from '../../lib/common/globalUtils';
import { applianceId } from '../../lib/id';
import {
  LOAD_TOP_PAGE_BILLING_INFO,
  LOAD_CURRENT_WEIGHTING_PERIOD,
  LOAD_CHART_PAGE,
  CHANGE_CHART_SUB_PERIOD,
  CLOSING_POP_UP,
} from '../../actions/types';
import type { BillingInfo } from '../../lib/types/priceInfoTypes';
import type { EnergyPerDay } from '../../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#454545',
  },
  boxUp: {
    width: '100%',
    padding: '93px 0px 0px',
  },
});

type Props = {
  location: {
    pathname: string,
  },
  requesting: boolean,
  subRequesting: boolean,
  rankingRequesting: boolean,
  unitEnergyList: EnergyPerDay[],
  subUnitEnergyList: EnergyPerDay[],
  dailyUnitEnergyList: EnergyPerDay[],
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  unit: string,
  period: string,
  idsToShow: string[],
  firstWeighingStartDate: string,
  dispatch: Dispatch,
  match: {
    params: {
      unit?: string,
      period?: string,
    },
  },
};

export const createValidUnit = (unit: string): { isValidUnit: boolean, validUnit: string } => {
  const defaultUnit = 'week';
  const isValidUnit = !!timeUnitList.find((ele) => ele === unit);
  return { isValidUnit, validUnit: isValidUnit ? unit : defaultUnit };
};

export const createValidPeriod = (
  unit: string,
  period: string,
  firstWeighingStartDate: string,
  currentWeighingPeriodData: {
    startDate: string,
    endDate: string,
    billingMonth: string,
  }
): { isValidPeriod: boolean, validPeriod: string } => {
  // フォーマットチェック
  if (!isValidDateFormat(unit, period) || !moment(period, timeFormat[unit]).isValid()) {
    return { isValidPeriod: false, validPeriod: makeValidPeriod(unit, moment()) };
  }

  // 今月の請求年月を取得
  const now =
    unit === 'day' || unit === 'week'
      ? moment()
      : moment(currentWeighingPeriodData.billingMonth, timeFormat[unit]);
  const inputInMoment = moment(period, timeFormat[unit]);

  // 未来日チェック
  if (now.isBefore(inputInMoment)) {
    return { isValidPeriod: false, validPeriod: makeValidPeriod(unit, now) };
  }

  // 学習開始日チェック
  const roundedStartDate = moment(firstWeighingStartDate).format(timeFormat[unit]);
  const isStartDateAfterInput =
    unit === 'week'
      ? moment(roundedStartDate, timeFormat[unit]).subtract(7, 'days').isAfter(inputInMoment)
      : moment(roundedStartDate, timeFormat[unit]).isAfter(inputInMoment);
  if (isStartDateAfterInput) {
    return {
      isValidPeriod: false,
      validPeriod: makeValidPeriod(unit, moment(roundedStartDate, timeFormat[unit])),
    };
  }

  // 曜日チェック
  if (unit === 'week' && moment(period).day() !== 0) {
    return { isValidPeriod: false, validPeriod: makeValidPeriod(unit, moment(period)) };
  }

  return { isValidPeriod: true, validPeriod: period };
};

const getValidatedData = (
  props: Props,
  currentWeighingPeriodData: {
    startDate: string,
    endDate: string,
    billingMonth: string,
  }
): {
  isValidUnit: boolean,
  validUnit: string,
  isValidPeriod: boolean,
  validPeriod: string,
  prevBillingData: Array<{
    billingDate: string,
    startDate: string,
    endDate: string,
    billingAmount: number,
    consumedBillingAmount: number | null,
    soldAmount: number | null,
  }>,
  prevWeighingPeriodArray: Array<{
    startDate: string,
    endDate: string,
    billingMonth: string,
  }>,
} => {
  const { firstWeighingStartDate, billingInfo: prevBillingInfo } = props;
  const { billingData: prevBillingData, weighingPeriodArray: prevWeighingPeriodArray } =
    prevBillingInfo;
  const { unit = 'month', period = moment().format('YYYY-MM') } = props.match.params;
  const { isValidUnit, validUnit } = createValidUnit(unit);
  const { isValidPeriod, validPeriod } = createValidPeriod(
    validUnit,
    period,
    firstWeighingStartDate,
    currentWeighingPeriodData
  );

  return {
    isValidUnit,
    validUnit,
    isValidPeriod,
    validPeriod,
    prevBillingData,
    prevWeighingPeriodArray,
  };
};

export class ChartPageClass extends Component<Props> {
  componentDidMount() {
    const { billingInfo, dispatch } = this.props;
    dispatch({ type: CLOSING_POP_UP });
    const { unit = 'month', period = moment().format('YYYY-MM') } = this.props.match.params;
    const { isValidUnit, validUnit } = createValidUnit(unit);

    if (!isValidUnit) {
      this.context.router.history.replace(`/chart/${validUnit}/${period}`);
      return;
    }

    dispatch({
      type: LOAD_CHART_PAGE,
      payload: { unit, period },
    });
    dispatch({ type: LOAD_TOP_PAGE_BILLING_INFO });
    this.loadBillingInfo(unit, period);
    if (billingInfo.billingInitialized) {
      this.loadEnergyHourly(validUnit, period);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { billingInfo, lastBillingDate, accountStartDate, unit, period } = this.props;
    const {
      billingInitialized,
      billingRequesting,
      billingData,
      weighingPeriodInitialized,
      currentWeighingPeriod: currentWeighingPeriodData,
      weighingPeriodArray: weighingPeriodList,
    } = billingInfo;

    if (billingInitialized && !billingRequesting) {
      if (!weighingPeriodInitialized) {
        const billingDataPeriod = getWeighingPeriod(billingData, lastBillingDate, 'month');
        // 当月の請求期間を取得
        const currentWeighingPeriod = makeCurrentWeighingPeriod(
          billingData,
          lastBillingDate,
          accountStartDate
        );

        // 最終請求年月の翌月から当年の12月までの各請求期間を取得
        const weighingPeriodArray = makeWeighingPeriodArray(
          billingDataPeriod
            ? moment(billingDataPeriod.endDate).add(1, 'day').format('YYYY-MM-DD')
            : accountStartDate,
          currentWeighingPeriod
            ? moment(currentWeighingPeriod.billingMonth, 'YYYY-MM').endOf('year')
            : moment().endOf('year'),
          billingDataPeriod
            ? moment(billingDataPeriod.billingDate, 'YYYY-MM').add(1, 'month').format('YYYY-MM')
            : moment(lastBillingDate, 'YYYY-MM').format('YYYY-MM')
        );
        if (currentWeighingPeriod || weighingPeriodArray) {
          this.props.dispatch({
            type: LOAD_CURRENT_WEIGHTING_PERIOD,
            currentWeighingPeriod,
            weighingPeriodArray,
          });
        }
      }

      const {
        isValidUnit,
        validUnit,
        isValidPeriod,
        validPeriod,
        prevBillingData,
        prevWeighingPeriodArray,
      } = getValidatedData(prevProps, currentWeighingPeriodData);
      if (!isValidUnit || !isValidPeriod) {
        this.context.router.history.replace(`/chart/${validUnit}/${validPeriod}`);
        return;
      }

      // check each props to prevent infinite loops
      if (validUnit !== unit || validPeriod !== period) {
        this.loadBillingInfo(validUnit, validPeriod);
      }

      if (
        billingData !== prevBillingData ||
        weighingPeriodList !== prevWeighingPeriodArray ||
        validUnit !== unit ||
        validPeriod !== period
      ) {
        this.loadEnergyHourly(validUnit, validPeriod);
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLOSING_POP_UP });
  }

  loadBillingInfo(unit: string, period: string) {
    const { lastBillingDate, billingInfo, dispatch } = this.props;

    const format = timeFormat[unit];
    const { billingRequesting, billingInitialized, billingData } = billingInfo;

    const isValid =
      moment(period, format).isAfter(
        moment(lastBillingDate, 'YYYY-MM').startOf('month').subtract(1, 'year'),
        unit
      ) ||
      moment(period, format).isAfter(
        moment(lastBillingDate, format).startOf('month'),
        'YYYY-MM-DD'
      );

    const billingStartDate = isValid
      ? moment(lastBillingDate, 'YYYY-MM').startOf('month').subtract(1, 'year').format('YYYY-MM')
      : moment(period, format).format('YYYY-MM');

    const billingDataPeriod = billingData
      ? getWeighingPeriod(
          billingData,
          unit === 'year'
            ? moment(period, format).startOf(unit).format('YYYY-MM')
            : billingStartDate,
          unit === 'year' ? 'month' : unit
        )
      : false;

    if (billingRequesting || billingDataPeriod) {
      return;
    }

    this.props.dispatch({
      type: LOAD_CHART_PAGE,
      payload: { unit, period },
    });

    if ((unit === 'day' || unit === 'week') && !billingInitialized) {
      dispatch(
        apiGetBillingInfo(
          moment(lastBillingDate, 'YYYY-MM').startOf('day').unix(),
          moment(lastBillingDate, 'YYYY-MM').endOf('day').unix()
        )
      );
      return;
    }

    // URLのパラメータからstsを取得
    // URLのパラメータがlastBillingDateの1年前より後ならlastBillingDateの1年前から取得する
    dispatch(
      apiGetBillingInfo(
        moment(
          moment(
            isValid
              ? moment(lastBillingDate, 'YYYY-MM')
                  .startOf('month')
                  .subtract(1, 'year')
                  .format('YYYY-MM')
              : period,
            unit === 'year' ? format : 'YYYY-MM'
          ).startOf(unit)
        ).unix(),
        moment(lastBillingDate, 'YYYY-MM').endOf('day').unix()
      )
    );
  }

  loadBillingForecast(startDate: string, endDate: string) {
    const { dispatch } = this.props;
    dispatch(
      apiGetBillingForecast(
        moment(startDate).startOf('day').unix(),
        moment(endDate).add(1, 'day').startOf('day').unix()
      )
    );
  }

  loadEnergyHourly(unit: string, period: string) {
    const {
      subUnitEnergyList,
      billingInfo,
      unitEnergyList,
      idsToShow,
      firstWeighingStartDate,
      lastBillingDate,
      dispatch,
    } = this.props;
    const {
      billingRequesting,
      billingInitialized,
      predictInitialized,
      billingData,
      weighingPeriodInitialized,
      currentWeighingPeriod,
      weighingPeriodArray: weighingPeriodList,
    } = billingInfo;

    if (billingRequesting || !billingInitialized || !weighingPeriodInitialized) {
      return;
    }

    this.props.dispatch({
      type: LOAD_CHART_PAGE,
      payload: { unit, period },
    });

    const format = timeFormat[unit];

    // 表示データが実請求に存在する場合、請求年月と計量期間を取得
    const billingDataPeriod =
      getWeighingPeriod(
        billingData,
        moment(period, format).startOf(unit).format('YYYY-MM'),
        'month'
      ) || getWeighingPeriod(billingData, period, unit);
    const billingPeriodData = billingDataPeriod && {
      startDate: billingDataPeriod.startDate,
      endDate: billingDataPeriod.endDate,
      billingMonth: billingDataPeriod.billingDate,
    };

    // 表示データが実請求に存在しない場合、仮請求年月と計量期間を取得
    const temporaryBillingDataPeriod = getTemporaryWeighingPeriod(weighingPeriodList, period, unit);

    // 表示データが実請求に存在する場合、請求年月と計量期間を取得
    // 表示データが実請求に存在しない場合、仮請求年月と計量期間を取得
    const weighingPeriodStartData = billingPeriodData || temporaryBillingDataPeriod;

    // 表示データの年の1月分の実請求が存在する場合、請求年月と計量期間を取得
    // 表示データの年の1月分の実請求が存在しない場合、同年の一番最初の請求年月と計量期間を取得
    const billingDataPeriodStartYear =
      getWeighingPeriod(
        billingData,
        moment(period, format).startOf('year').format('YYYY-MM'),
        'month'
      ) || getWeighingPeriod(billingData, period, 'year');
    const billingPeriodStartYearData = billingDataPeriodStartYear && {
      startDate: billingDataPeriodStartYear.startDate,
      endDate: billingDataPeriodStartYear.endDate,
      billingMonth: billingDataPeriodStartYear.billingDate,
    };

    // 表示データの年の1月分の実請求の、仮請求年月と計量期間を取得
    const billingPeriodStartYear =
      billingPeriodStartYearData ||
      getTemporaryWeighingPeriod(
        weighingPeriodList,
        moment(period, format).startOf('year').format('YYYY-MM'),
        'month'
      );

    // 表示データの年の12月分の実請求が存在する場合、請求年月と計量期間を取得
    const billingDataPeriodYear = getWeighingPeriod(
      billingData,
      moment(period, format).endOf('year').format('YYYY-MM'),
      'month'
    );
    const billingDataPeriodYearData = billingDataPeriodYear && {
      startDate: billingDataPeriodYear.startDate,
      endDate: billingDataPeriodYear.endDate,
      billingMonth: billingDataPeriodYear.billingDate,
    };

    // 表示データの年の12月分の実請求の、仮請求年月と計量期間を取得
    const billingDataPeriodEndYear =
      billingDataPeriodYearData ||
      getTemporaryWeighingPeriod(
        weighingPeriodList,
        moment(period, format).endOf('year').format('YYYY-MM'),
        'month'
      );

    // 最後の実請求データを取得
    const lastBillingDataPeriod = getWeighingPeriod(billingData, lastBillingDate, 'month');
    // 表示データの年の1月分の仮計量期間が存在すれば取得
    const temporaryWeighingPeriod = getTemporaryWeighingPeriod(
      weighingPeriodList,
      moment(period, format).startOf('year').format('YYYY-MM'),
      'month'
    );
    const temporaryFirstBillingStartDate =
      temporaryWeighingPeriod && temporaryWeighingPeriod.startDate;
    const temporaryFirstBillingMonth =
      temporaryWeighingPeriod && temporaryWeighingPeriod.billingMonth;

    const firstWeighingMonth =
      moment(firstWeighingStartDate, 'YYYY-MM-DD').date() < 15
        ? moment(firstWeighingStartDate, 'YYYY-MM-DD').format('YYYY-MM')
        : moment(firstWeighingStartDate, 'YYYY-MM-DD').add(1, 'month').format('YYYY-MM');
    const diff = moment(
      moment(firstWeighingStartDate, 'YYYY-MM-DD').date() < 15
        ? moment(firstWeighingStartDate, 'YYYY-MM-DD').format('YYYY-MM')
        : moment(firstWeighingStartDate, 'YYYY-MM-DD').add(1, 'month').format('YYYY-MM'),
      'YYYY-MM'
    ).diff(moment(period, 'YYYY').startOf('year'), 'months');

    const weighingPeriodYear = {
      startDate: lastBillingDataPeriod
        ? moment(lastBillingDataPeriod.endDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD')
        : temporaryFirstBillingStartDate ||
          moment(firstWeighingStartDate, 'YYYY-MM-DD').subtract(diff, 'month').format('YYYY-MM-DD'),
      endDate: billingDataPeriodEndYear
        ? billingDataPeriodEndYear.endDate
        : moment(period, format).endOf('year').format('YYYY-MM-DD'),
      billingMonth: lastBillingDataPeriod
        ? moment(lastBillingDataPeriod.billingDate, 'YYYY-MM').add(1, 'month').format('YYYY-MM')
        : temporaryFirstBillingMonth ||
          (moment(firstWeighingMonth, 'YYYY-MM').month() === 1
            ? firstWeighingMonth
            : moment(period, format).startOf('year').format('YYYY-MM')),
    };

    const weighingPeriodSubUnitYear = {
      startDate: billingPeriodStartYear
        ? moment(billingPeriodStartYear.startDate, 'YYYY-MM-DD').format('YYYY-MM-DD')
        : temporaryFirstBillingStartDate ||
          moment(firstWeighingStartDate, 'YYYY-MM-DD').subtract(diff, 'month').format('YYYY-MM-DD'),
      endDate: billingDataPeriodEndYear
        ? billingDataPeriodEndYear.endDate
        : moment(period, format).endOf('year').format('YYYY-MM-DD'),
      billingMonth: billingPeriodStartYear
        ? moment(billingPeriodStartYear.billingMonth, 'YYYY-MM').format('YYYY-MM')
        : temporaryFirstBillingMonth ||
          (moment(firstWeighingMonth, 'YYYY-MM').month() === 1
            ? firstWeighingMonth
            : moment(period, format).startOf('year').format('YYYY-MM')),
    };

    const subPeriod = getSubPeriod(
      temporaryBillingDataPeriod,
      billingPeriodData,
      weighingPeriodList,
      unit,
      period
    );
    dispatch({
      type: CHANGE_CHART_SUB_PERIOD,
      subPeriod,
    });

    // 予測値リクエスト
    if (
      !predictInitialized &&
      currentWeighingPeriod &&
      (isCurrentMonth(weighingPeriodInitialized, currentWeighingPeriod, unit, period) ||
        (unit === 'year' &&
          moment(period, format).isSame(
            moment(currentWeighingPeriod.billingMonth, format),
            'years'
          )))
    ) {
      this.loadBillingForecast(currentWeighingPeriod.startDate, currentWeighingPeriod.endDate);
    }

    // Unitデータリクエスト
    if (unit === 'day') {
      dispatch(
        apiGetEnergyHourlyByUnit(getHourlyRequestMeta(unit), unit, hourlyRequestUnit[unit], period)
      );
    }
    if (unit !== 'day' && !getEnergy(unitEnergyList, period)) {
      if (unit === 'week') {
        dispatch(
          apiGetEnergyHourlyByUnit(
            getHourlyRequestMeta(unit),
            unit,
            hourlyRequestUnit[unit],
            period
          )
        );
      } else if (unit !== 'week' && weighingPeriodStartData) {
        const dateTime = moment(weighingPeriodStartData.billingMonth, format);
        const unitStartDate = weighingPeriodStartData.startDate;
        const startDateUnix = moment(unitStartDate, 'YYYY-MM-DD').startOf('day').unix();
        const unitEndDate = weighingPeriodStartData.endDate;
        const endDateUnix = moment(
          unit === 'year' ? weighingPeriodYear.endDate : unitEndDate,
          'YYYY-MM-DD'
        )
          .endOf('day')
          .unix();

        dispatch(
          apiGetEnergyHourly(
            getHourlyRequestMeta(unit),
            moment(dateTime).format(format),
            hourlyRequestUnit[unit],
            unit === 'year'
              ? moment(weighingPeriodStartData.startDate, 'YYYY-MM-DD').startOf('day').unix()
              : startDateUnix,
            endDateUnix > moment().unix() ? moment().unix() : endDateUnix,
            [],
            weighingPeriodList
          )
        );
      }
    }

    const subUnitDateTimeList = getStartDateList(subPeriod, unit, lastBillingDate);

    // SubUnitグラデータリクエスト
    if (subUnitDateTimeList.length > 0) {
      if (unit === 'week') {
        dispatch(
          apiGetEnergyHourlyBySubUnit(
            getHourlyRequestMetaSub(subTimeUnit[unit]),
            subTimeUnit[unit],
            hourlyRequestUnit[unit],
            subUnitDateTimeList
          )
        );
      } else if (billingDataPeriod || temporaryBillingDataPeriod) {
        if (unit === 'year') {
          dispatch(
            apiGetEnergyHourly(
              getHourlyRequestMetaSub(subTimeUnit[unit]),
              moment(weighingPeriodSubUnitYear.billingMonth, 'YYYY-MM').format(
                timeFormat[subTimeUnit[unit]]
              ),
              'day',
              moment(weighingPeriodSubUnitYear.startDate, 'YYYY-MM-DD').startOf('day').unix(),
              moment(weighingPeriodYear.endDate, 'YYYY-MM-DD').endOf('day').unix(),
              subUnitDateTimeList,
              weighingPeriodList
            )
          );
        } else if (unit === 'month') {
          dispatch(
            apiGetEnergyHourlyBySubUnit(
              getHourlyRequestMetaSub(subTimeUnit[unit]),
              subTimeUnit[unit],
              hourlyRequestUnit[unit],
              subUnitDateTimeList
            )
          );
        }
      }
    }

    // 予測電気代折れ線グラフデータリクエスト
    if (
      unit === 'month' &&
      currentWeighingPeriod &&
      isCurrentMonth(weighingPeriodInitialized, currentWeighingPeriod, unit, period)
    ) {
      const currentDateTime = moment(currentWeighingPeriod.billingMonth);
      const currentStartDate = moment(currentWeighingPeriod.startDate).startOf('day');
      const currentEndDate = moment(currentWeighingPeriod.endDate).endOf('day');

      const currentDateTimeList = getSubUnitDateTime(
        currentStartDate.format('YYYY-MM-DD'),
        currentEndDate,
        currentDateTime.format('YYYY-MM'),
        unit,
        period,
        firstWeighingStartDate,
        subUnitEnergyList
      );

      if (currentDateTimeList.length > 0) {
        dispatch(
          apiGetEnergyHourly(
            getHourlyRequestMeta('day'),
            currentDateTime.format(format),
            'day',
            currentStartDate.unix(),
            currentEndDate.unix(),
            [],
            weighingPeriodList
          )
        );
      }
    }

    // only LIGHT means the initial state
    const { light: LIGHT } = applianceId;
    if (idsToShow && idsToShow.length === 1 && idsToShow[0] === LIGHT) {
      dispatch(apiGetUseStatsForLastYear());
    }
  }

  render() {
    const {
      location,
      requesting,
      subRequesting,
      rankingRequesting,
      unitEnergyList,
      subUnitEnergyList,
      dailyUnitEnergyList,
      unit,
      period,
    } = this.props;
    return (
      <FadeTransition pathname={location.pathname}>
        <div key="chartPage">
          <HeaderBar pageType="chart" />
          <div style={style.content}>
            <div style={style.boxUp}>
              <ChartBody
                requesting={requesting}
                subRequesting={subRequesting}
                rankingRequesting={rankingRequesting}
                unitEnergyList={unitEnergyList}
                subUnitEnergyList={subUnitEnergyList}
                dailyUnitEnergyList={dailyUnitEnergyList}
                unit={unit}
                period={period}
              />
            </div>
          </div>
        </div>
      </FadeTransition>
    );
  }
}

ChartPageClass.contextTypes = {
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const energy = state.energy;
  const unitDays = 'day';
  const { unit, period } = state.chartTime;
  const requesting = unit ? energy[timeUnitStyle[unit].toRequesting] : false;
  const subRequesting = unit ? energy[timeUnitStyle[subTimeUnit[unit]].toRequesting] : false;
  const unitEnergyList = unit ? energy[timeUnitStyle[unit].toUnit] : false;
  const subUnitEnergyList = unit ? energy[timeUnitStyle[subTimeUnit[unit]].toUnit] : false;
  const dailyUnitEnergyList = unit ? energy[timeUnitStyle[unitDays].toUnit] : false;
  return {
    requesting,
    subRequesting,
    rankingRequesting: state.applianceEnergy.requesting,
    unitEnergyList,
    subUnitEnergyList,
    dailyUnitEnergyList,
    billingInfo: state.billingInfo,
    lastBillingDate: state.userInfo.lastBillingDate,
    accountStartDate: state.userInfo.accountStartDate,
    unit,
    period,
    idsToShow: state.applianceState.idsToShow,
    firstWeighingStartDate: state.userInfo.firstWeighingStartDate,
  };
};

const connectedChartPageClass: any = connect(mapStateToProps)(ChartPageClass);
export default connectedChartPageClass;
