// @flow

import React from 'react';
import moment from 'moment';
import type { Node } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import { VictoryContainer, VictoryBar, VictoryChart, VictoryAxis, VictoryTheme } from 'victory';
import sizeMe from 'react-sizeme';
import Prefixer from '../../lib/Prefixer';
import { findUnitPrice } from '../../lib/price/priceUtils';
import { calcPowerCharge } from '../../lib/price/calculatedPrice';
import type { UnitPrice, BillingInfo } from '../../lib/types/priceInfoTypes';

const prefixer = new Prefixer();

const COLUMN_COUNT = 6;
const BAR_SHIFT_WIDTH = 0.2;

const style = prefixer.prefix({
  legend: {
    textAlign: 'left',
    fontSize: '0.9em',
    marginTop: '0px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
  },
  legendSelf: {
    color: '#ff9086',
  },
  legendCompare: {
    color: '#ddd',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSelfIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendCompareIcon: {
    marginLeft: 30,
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
});

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#847965', padding: 5 },
};

type Props = {
  self: BillingInfo[],
  compares: {
    sts: number,
    ets: number,
    date: string,
    median: { whp: number, appliances: Object },
  }[],
  unitPrices: UnitPrice[],
  intl: intlShape,
  size: { width: number, height: number },
};

const barWidth = (chartWidth: number, itemSize: number): number => {
  const fullWidth = itemSize ? chartWidth / itemSize * 0.8 : 0;
  return fullWidth / 2;
};

const renderChart = (
  self: BillingInfo[],
  compares: {
    sts: number,
    ets: number,
    date: string,
    median: { whp: number, appliances: Object },
  }[],
  unitPrices: UnitPrice,
  intl: intlShape,
  width: number
) => (
  <VictoryChart
    theme={VictoryTheme.material}
    containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
    width={width}
    height={286}
    padding={{ right: 3, left: 3, bottom: 30 }}
    domainPadding={30}
  >
    <VictoryAxis
      tickValues={[0, 1, 2, 3, 4, 5]}
      tickFormat={x =>
        self[x]
          ? `${moment(self[x].billingDate, 'YYYY-MM').month() + 1}${intl.formatMessage({
              id: 'report.monthSuffix',
            })}`
          : ''}
      style={xAxisStyle}
    />
    <VictoryAxis
      dependentAxis
      tickCount={7}
      style={{
        grid: { strokeWidth: 1, stroke: '#ccc' },
        axis: { stroke: 'transparent' },
        ticks: { stroke: 'transparent' },
        tickLabels: { fill: 'transparent' },
      }}
    />
    <VictoryBar
      style={{ data: { fill: '#ff8f86', width: barWidth(width, COLUMN_COUNT) } }}
      data={Array.from(Array(COLUMN_COUNT).keys()).map(index => ({
        x: index - BAR_SHIFT_WIDTH,
        y: self[index] ? self[index].billingAmount : 0,
      }))}
    />
    <VictoryBar
      style={{ data: { fill: '#ddd', width: barWidth(width, COLUMN_COUNT) / 2 } }}
      data={compares.map((billing, index) => ({
        x: index + BAR_SHIFT_WIDTH,
        y: calcPowerCharge(
          billing.median ? billing.median.whp : 0,
          findUnitPrice(billing.ets, unitPrices)
        ),
      }))}
    />
  </VictoryChart>
);

export const PureChartComparisonBar = ({ self, compares, unitPrices, intl, size }: Props): Node => (
  <div>
    <div style={style.legend}>
      <div style={{ ...style.legendIcon, ...style.legendSelfIcon }} />
      <span>{intl.formatMessage({ id: 'report.legendSelf' })}</span>
      <div style={{ ...style.legendIcon, ...style.legendCompareIcon }} />
      <span>{intl.formatMessage({ id: 'report.legendCompare' })}</span>
    </div>
    {renderChart(self, compares, unitPrices, intl, size.width)}
  </div>
);

const mapStateToProps = state => ({
  unitPrices: state.userInfo.unitPrices,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChartComparisonBar: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChartComparisonBar))
);
export default connectedPureChartComparisonBar;
