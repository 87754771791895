// @flow

import React from 'react';
import type { Node } from 'react';
import { Animate } from 'react-move';
import Prefixer from '../lib/Prefixer';

const prefixer = new Prefixer();

const styles = prefixer.prefix({
  popupContainer: {
    backgroundColor: 'rgba(0, 0, 0, .3)',
    zIndex: 65535,
    top: 0,
    left: 0,
    margin: 0,
    padding: 0,
    alignContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    height: '120vh',
    width: '100vw',
    position: 'fixed',
  },
  popup: {
    color: '#fff',
    borderRadius: 8,
    background: '#ffffff',
    opacity: 1,
    fontSmoothing: 'antialiased',
    textAlign: 'center',
    maxWidth: 285,
    width: '90%',
    position: 'relative',
  },
});

const getAnimationParameters = (isOpen) => {
  if (isOpen) {
    return {
      origin: {
        scale: 0.7,
        opacity: 0,
      },
      target: {
        scale: 1.0,
        opacity: 1.0,
      },
      easing: 'easeCubicOut',
    };
  }
  return {
    target: {
      scale: 0.7,
      opacity: 0,
    },
    origin: {
      scale: 1.0,
      opacity: 1.0,
    },
    easing: 'easeCubicOut',
  };
};

type Props = {
  open: boolean,
  closeHandler?: () => void,
  animationDuration: number,
  style?: {
    popup?: Object,
    popupContainer?: Object,
    tableCell?: Object,
  },
  children: Node,
};

const Popup = (props: Props) => {
  const { origin, target, easing } = getAnimationParameters(props.open);
  const { children, closeHandler, style: styleOverwrite = {}, animationDuration } = props;
  return (
    <Animate default={origin} data={target} duration={animationDuration} easing={easing}>
      {(data) => (
        <div
          onClick={closeHandler}
          style={{
            ...styles.popupContainer,
            opacity: data.opacity,
            ...styleOverwrite.popupContainer,
          }}
        >
          <div style={styleOverwrite.tableCell}>
            <div
              style={{
                ...styles.popup,
                transform: `scale(${data.scale})`,
                ...styleOverwrite.popup,
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </div>
          </div>
        </div>
      )}
    </Animate>
  );
};

Popup.defaultProps = {
  style: {},
  closeHandler: () => {},
};

export default Popup;
