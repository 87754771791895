// @flow

import type { State, Action } from 'redux';
/* eslint-env browser */
import { REHYDRATE } from 'redux-persist/constants';
import { aplFilterFormat } from '../lib/localizedValues';
import {
  ENTER_APPLICATION,
  SWITCH_APL_DISPLAY_FLAG,
  SUCCESS_API_USER_INFO,
  SUCCESS_API_LOGIN_INFO,
} from '../actions/types';

const initialState = {
  aplFilter: [],
};

const isSameAplFilterFormat = (aplFilter, locale) => {
  const localizedAplFilter = aplFilterFormat[locale];
  const isSameLength = aplFilter.length === localizedAplFilter.length;
  const includesSameElements = () => {
    const idsShouldBeIncluded = localizedAplFilter.map(elem => elem.id);
    const filteredArray = aplFilter.filter(elem => idsShouldBeIncluded.includes(elem.id));
    return filteredArray.length === aplFilter.length;
  };
  return isSameLength && includesSameElements();
};

export default (state: State = initialState, action: Action = {}): State => {
  switch (action.type) {
    case REHYDRATE:
    case SUCCESS_API_USER_INFO:
    case SUCCESS_API_LOGIN_INFO: {
      const { payload } = action;
      return {
        ...state,
        aplFilter: payload.aplFilter ? payload.aplFilter : state.aplFilter,
      };
    }
    case ENTER_APPLICATION: {
      const { aplFilter } = state;
      const { locale } = action;
      return {
        ...state,
        aplFilter:
          aplFilter.length && isSameAplFilterFormat(aplFilter, locale)
            ? aplFilter
            : aplFilterFormat[locale],
      };
    }
    case SWITCH_APL_DISPLAY_FLAG: {
      const { id } = action;
      const aplFilter = state.aplFilter.slice();
      const index = aplFilter.findIndex(appliance => appliance.id === id);
      aplFilter.splice(index, 1, { id, display: !aplFilter[index].display });
      return {
        ...state,
        aplFilter,
      };
    }
    default: {
      return state;
    }
  }
};
