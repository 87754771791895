// @flow

import type { Store, Middleware, Action } from 'redux';
import { REHYDRATE } from 'redux-persist/constants';
import {
  CLOSING_POP_UP,
  TUTORIAL_FINISHED,
  SUCCESS_API_USER_INFO,
  SUCCESS_API_LOGIN_INFO,
} from '../../actions/types';
import { APPLIANCE_FOUND, SERVICE_UNAVAILABLE } from '../../actions/alertPopupTypes';

export const HAVE_SHOWN_START_DIALOG = 'haveShownStartDialog';
export const LAST_DATE_UNAVAILABLE_SHOWN = 'lastDateUnavailableShown';
export const IS_TUTORIAL_FINISHED = 'tutorialFinished';
export const APL_FILTER = 'aplFilter';
const APLS_DISPLAY = 'aplsDisplay';

const setItem = id => (key: string, value: any) => {
  if (id) {
    const keyWithCid = `${id}-${key}`;
    localStorage.setItem(keyWithCid, value);
    const storageKey = Object.keys(localStorage).filter(itemKey => itemKey.indexOf(id) !== -1);
    const config = storageKey.reduce(
      (pre, now) => ({ ...pre, [now]: localStorage.getItem(now) }),
      {}
    );
    window.sendMessageToNative('update-config', config);
  }
};

const getItem = id => (key: string, state: Object): any => {
  const stateValue = Array.isArray(state[key]) ? JSON.stringify(state[key]) : state[key];
  if (id) {
    const value = localStorage.getItem(`${id}-${key}`);
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value || stateValue;
  }
  return stateValue;
};

export default (store: Store) => (next: Middleware) => (originalAction: Action) => {
  try {
    const action = originalAction;
    const { popup, userSetting, userInfo, tutorial } = store.getState();
    const { contractorId = '' } = userInfo;

    const setItemByKey = setItem(contractorId);
    if (action.type === CLOSING_POP_UP && popup.contentType === APPLIANCE_FOUND) {
      setItemByKey(HAVE_SHOWN_START_DIALOG, popup.haveShownStartDialog);
    }
    if (action.type === CLOSING_POP_UP && popup.contentType === SERVICE_UNAVAILABLE) {
      setItemByKey(LAST_DATE_UNAVAILABLE_SHOWN, popup.lastDateUnavailableShown);
    }
    if (action.type === TUTORIAL_FINISHED) {
      setItemByKey(IS_TUTORIAL_FINISHED, true);
      setItemByKey(HAVE_SHOWN_START_DIALOG, true);
    }
    if (action.type === CLOSING_POP_UP && popup.contentType === APLS_DISPLAY) {
      setItemByKey(APL_FILTER, JSON.stringify(userSetting.aplFilter));
    }
    if (action.type === SUCCESS_API_LOGIN_INFO) {
      const obj = window.config;
      if (obj) {
        const keys = Object.keys(obj);
        keys.forEach(key => {
          if (!localStorage.getItem(key)) {
            localStorage.setItem(key, obj[key]);
          }
        });
      }
    }
    if (
      action.type === SUCCESS_API_USER_INFO ||
      action.type === REHYDRATE ||
      action.type === SUCCESS_API_LOGIN_INFO
    ) {
      const { payload } = action;
      let cid = '';
      if (action.type === REHYDRATE) {
        cid = payload.userInfo && payload.userInfo.contractorId;
      }
      if (action.type === SUCCESS_API_USER_INFO) {
        cid = payload.user && payload.user.contractor_id;
      }
      if (action.type === SUCCESS_API_LOGIN_INFO) {
        cid = payload.user && payload.user.contractorId;
      }

      const getItemByKey = getItem(cid);
      action.payload = {
        ...payload,
        haveShownStartDialog: getItemByKey(HAVE_SHOWN_START_DIALOG, popup),
        lastDateUnavailableShown: getItemByKey(LAST_DATE_UNAVAILABLE_SHOWN, popup),
        aplFilter: JSON.parse(getItemByKey(APL_FILTER, userSetting)),
        tutorialFinished: getItemByKey(IS_TUTORIAL_FINISHED, tutorial),
      };
    }
    next(action);
  } catch (e) {
    next(originalAction);
  }
};
