import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import type { State as ObjectiveEnergyState } from '../../reducers/objectiveEnergy';
import type { State as PickupState } from '../../reducers/pickup';
import { CLOSING_POP_UP, LOAD_TOP_PAGE_BILLING_INFO, LOAD_REPORT_PAGE } from '../../actions/types';
import {
  apiGetBillingInfo,
  apiGetEnergyHourly,
  apiGetObjectiveEnergy,
  apiGetPickup,
} from '../../actions/apis';
import FadeTransition from '../FadeTransition';
import HeaderBar from '../HeaderBar';
import Prefixer from '../../lib/Prefixer';
import ReportBody from '../report/ReportBody';
import { getHourlyRequestMeta } from '../../lib/common/globalUtils';
import type { BillingInfo } from '../../lib/types/priceInfoTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#454545',
  },
  boxUp: { width: '100%', padding: '43px 0px 0px' },
});

type Props = {
  billingRequesting: boolean,
  energyRequesting: boolean,
  billingInfo: BillingInfo,
  lastBillingDate: string,
  firstWeighingStartDate: string,
  objectiveEnergy: ObjectiveEnergyState,
  pickup: PickupState,
  dispatch: Dispatch,
  match: { params: { period?: string } },
  router: Object,
};

const isValidPeriod = (
  period: string,
  firstWeighingStartDate: string,
  lastBillingDate: string
): boolean => {
  const target = moment(period, 'YYYY-MM');
  const start = moment(firstWeighingStartDate, 'YYYY-MM');
  const end = moment(lastBillingDate, 'YYYY-MM');

  if (!target.isValid()) {
    return false;
  }
  return start.isSameOrBefore(target) && end.isSameOrAfter(target);
};

export class ReportPageClass extends Component<Props> {
  componentDidMount() {
    this.props.dispatch({ type: CLOSING_POP_UP });
    const { billingInfo } = this.props;
    const { period = moment().format('YYYY-MM') } = this.props.match.params;

    this.props.dispatch({ type: LOAD_REPORT_PAGE, payload: { period } });
    this.props.dispatch({ type: LOAD_TOP_PAGE_BILLING_INFO });
    this.loadBillingInfo();
    if (billingInfo.billingInitialized) {
      this.loadEnergyHourly(period);
      this.loadObjectiveEnergy(period, billingInfo.billingData);
      this.loadPickup(period);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { billingInfo } = this.props;
    const { period: currentPeriod } = this.props.match.params;
    if (
      prevProps.billingInfo.billingData.length === billingInfo.billingData.length &&
      prevProps.match.params.period === currentPeriod
    ) {
      return;
    }
    if (billingInfo.billingData.some(v => v.billingDate === currentPeriod)) {
      this.loadEnergyHourly(currentPeriod);
      this.loadObjectiveEnergy(currentPeriod);
      this.loadPickup(currentPeriod);
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: CLOSING_POP_UP });
  }

  loadBillingInfo() {
    const { lastBillingDate, billingInfo, dispatch, firstWeighingStartDate } = this.props;
    const { billingRequesting } = billingInfo;

    if (billingRequesting) {
      return;
    }
    this.props.dispatch({ type: LOAD_REPORT_PAGE });

    const sts = moment(firstWeighingStartDate, 'YYYY-MM-DD')
      .startOf('day')
      .unix();
    const ets = moment(lastBillingDate, 'YYYY-MM')
      .endOf('day')
      .unix();

    dispatch(apiGetBillingInfo(sts, ets));
  }

  loadObjectiveEnergy(period: string) {
    const { dispatch, objectiveEnergy, billingInfo: { billingData } } = this.props;
    if (objectiveEnergy.requesting) {
      return;
    }
    const endIndex = billingData.findIndex(v => v.billingDate === period);
    const startIndex = endIndex < 6 ? 0 : endIndex - 6 + 1;
    const data = billingData.slice(startIndex, endIndex + 1);
    const param = data
      .map(v => ({
        sts: moment(v.startDate, 'YYYY-MM-DD').unix(),
        ets: moment(v.endDate, 'YYYY-MM-DD').unix(),
        date: v.billingDate,
      }))
      .filter(v => !objectiveEnergy.data.some(d => d.date === v.date));
    if (param.length) {
      dispatch(apiGetObjectiveEnergy(param));
    }
  }

  loadPickup(period) {
    const { dispatch, pickup } = this.props;
    if (pickup.requesting) {
      return;
    }
    if (!pickup.data.some(v => v.month === period)) {
      dispatch(apiGetPickup(period));
    }
  }

  loadEnergyHourly(period) {
    const { dispatch, billingInfo } = this.props;
    const billing = billingInfo.billingData.find(v => v.billingDate === period);
    const sts = moment(billing.startDate, 'YYYY-MM-DD').startOf('day');
    const ets = moment(billing.endDate, 'YYYY-MM-DD').endOf('day');
    dispatch(
      apiGetEnergyHourly(
        getHourlyRequestMeta('month'),
        billing.billingDate,
        'hour',
        sts.unix(),
        ets.unix(),
        [],
        []
      )
    );
    dispatch(
      apiGetEnergyHourly(
        getHourlyRequestMeta('day'),
        billing.billingDate,
        'day',
        sts.unix(),
        ets.unix(),
        [],
        []
      )
    );
  }
  render() {
    const { match: { params: { period } }, firstWeighingStartDate, lastBillingDate } = this.props;
    if (!isValidPeriod(period, firstWeighingStartDate, lastBillingDate)) {
      return <Redirect to={`/report/${lastBillingDate}`} />;
    }
    return (
      <FadeTransition pathname={window.location.pathname}>
        <div key="reportPage">
          <HeaderBar pageType="report" />
          <div style={style.content}>
            <div style={style.boxUp}>
              <ReportBody
                period={period}
                billingRequesting={this.props.billingRequesting}
                energyRequesting={this.props.energyRequesting}
                objectiveRequesting={this.props.objectiveEnergy.requesting}
              />
            </div>
          </div>
        </div>
      </FadeTransition>
    );
  }
}

const mapStateToProps = state => ({
  billingRequesting: state.billingInfo.billingRequesting,
  energyRequesting: state.energy.monthlyRequesting,
  billingInfo: state.billingInfo,
  lastBillingDate: state.userInfo.lastBillingDate,
  firstWeighingStartDate: state.userInfo.firstWeighingStartDate,
  objectiveEnergy: state.objectiveEnergy,
  pickup: state.pickup,
});

const connectedReportPageClass: any = connect(mapStateToProps)(ReportPageClass);
export default connectedReportPageClass;
