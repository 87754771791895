// @flow
import moment from 'moment';

import { convertToEndOfDay } from '../common/globalUtils';
import { timeFormat, timeUnitStyle } from '../constants/timeUnit';
import type {
  UnitPrice,
  BillingData,
  BillingInfo,
  CurrentBillingInfo,
} from '../types/priceInfoTypes';

export const checkPeriod = (sts: number, ets: number | null, targetTimestamp: number): boolean =>
  ets ? sts <= targetTimestamp && ets >= targetTimestamp : sts <= targetTimestamp;

export const checkBillingPeriod = (
  billingSts: number, // 単価情報の適用開始日
  billingEts: number | null, // 単価情報の適用終了日
  sts: number, // チェックしたい対象の開始日
  ets: number | null // チェックしたい対象の終了日、対象の期間が日単位の場合はnull
): boolean =>
  checkPeriod(billingSts, billingEts, sts) ||
  (ets && checkPeriod(billingSts, billingEts, ets)) ||
  (ets && checkPeriod(sts, ets, billingSts)) ||
  (ets && billingEts && checkPeriod(sts, ets, billingEts)) ||
  false;

export const findUnitPrice = (unix: number, unitPrices: UnitPrice[]): UnitPrice =>
  unix > moment(new Date()).unix()
    ? unitPrices[unitPrices.length - 1]
    : unitPrices.find((unitPrice: UnitPrice) =>
        checkBillingPeriod(unitPrice.sts, convertToEndOfDay(unitPrice.ets), unix, null)
      ) || unitPrices[0];

export const filterUnitPrices = (
  unitPrices: UnitPrice[],
  sts: number,
  ets: number | null
): UnitPrice[] => {
  if (sts > moment().unix()) {
    return [unitPrices[unitPrices.length - 1]];
  }

  const filter = unitPrices.filter((unitPrice: UnitPrice) =>
    checkBillingPeriod(unitPrice.sts, convertToEndOfDay(unitPrice.ets), sts, ets)
  );
  return filter.length ? filter : [unitPrices[0]];
};

export const getBillingDataList = (
  billingInfo: BillingInfo,
  period: string,
  unit: string
): BillingData[] => {
  const { billingData } = billingInfo;

  return billingData.filter(billing =>
    moment(billing.billingDate, timeFormat[unit]).isSame(
      moment(period, timeFormat[unit]),
      timeUnitStyle[unit].toMulti
    )
  );
};

export const currentBillingInfo = (
  requesting: boolean,
  billingInfo: BillingInfo,
  unit: string,
  period: string
): CurrentBillingInfo => {
  const initial = {
    billingAmount: 0,
    consumedBillingAmount: 0,
    fuelCostAdjustmentAmount: 0,
    renewableEnergyPowerPromotionSurchargeAmount: 0,
  };

  if (requesting || unit === 'week' || unit === 'day') {
    return initial;
  }

  const billingDataList = getBillingDataList(billingInfo, period, unit);

  return billingDataList.reduce(
    (prev, current: BillingData) => ({
      billingAmount: prev.billingAmount + (current.billingAmount || 0),
      consumedBillingAmount: prev.consumedBillingAmount + (current.consumedBillingAmount || 0),
      fuelCostAdjustmentAmount:
        prev.fuelCostAdjustmentAmount + (current.fuelCostAdjustmentAmount || 0),
      renewableEnergyPowerPromotionSurchargeAmount:
        prev.renewableEnergyPowerPromotionSurchargeAmount +
        (current.renewableEnergyPowerPromotionSurchargeAmount || 0),
    }),
    initial
  );
};
