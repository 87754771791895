// @flow
import type { State, Action } from 'redux';
import { timeUnitId } from '../../lib/id';

import {
  LOAD_TOP_PAGE,
  RESET_CHART_PAGE,
  REQUEST_API_ENERGY_HOURLY,
  SUCCESS_API_ENERGY_HOURLY,
  FAILURE_API_ENERGY_HOURLY,
} from '../../actions/types';

export const initialRequestsState: State = {
  hourlyRequesting: false,
  dailyRequesting: false,
  weeklyRequesting: false,
  monthlyRequesting: false,
  yearlyRequesting: false,
};

const setHourlyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  hourlyRequesting: requesting,
});

const setWeeklyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  weeklyRequesting: requesting,
});

const setMonthlyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  monthlyRequesting: requesting,
});

const setYearlyRequesting = (requestsState, requesting) => ({
  ...requestsState,
  yearlyRequesting: requesting,
});

const setRequestsStateByUnit = (requestsState, timeUnit, requesting) => {
  switch (timeUnit) {
    case timeUnitId.hour:
      return setHourlyRequesting(requestsState, requesting);
    case timeUnitId.week:
      return setWeeklyRequesting(requestsState, requesting);
    case timeUnitId.month:
      return setMonthlyRequesting(requestsState, requesting);
    case timeUnitId.year:
      return setYearlyRequesting(requestsState, requesting);
    default:
      return requestsState;
  }
};

const endRequestByUnit = (requestsState, action) => {
  const { meta } = action || {};
  return setRequestsStateByUnit(requestsState, meta.unit, false);
};

const requestApiEnergyHourly = (requestsState, action) => {
  const { meta } = action || {};
  const requesting = !action.error;
  return setRequestsStateByUnit(requestsState, meta.reqUnit, requesting);
};

const successApiEnergyHourly = (requestsState, action) => {
  const { payload, meta } = action || {};
  if (!Object.keys(payload).length) {
    return {
      ...requestsState,
      dailyRequesting: false,
      weeklyRequesting: false,
      monthlyRequesting: false,
      yearlyRequesting: false,
    };
  }
  switch (meta.reqUnit) {
    case timeUnitId.week:
      return setWeeklyRequesting(requestsState, false);
    case timeUnitId.month:
      return setMonthlyRequesting(requestsState, false);
    case timeUnitId.year:
      return setYearlyRequesting(requestsState, false);
    default: {
      return requestsState;
    }
  }
};

export default (requestsState: State = initialRequestsState, action: Action = {}): State => {
  switch (action.type) {
    case LOAD_TOP_PAGE:
      return initialRequestsState;
    case RESET_CHART_PAGE:
      return initialRequestsState;
    case REQUEST_API_ENERGY_HOURLY:
      return requestApiEnergyHourly(requestsState, action);
    case SUCCESS_API_ENERGY_HOURLY:
      return successApiEnergyHourly(requestsState, action);
    case FAILURE_API_ENERGY_HOURLY:
      return endRequestByUnit(requestsState, action);
    default:
      return requestsState;
  }
};
