// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TopHourlyCharge from './TopHourlyCharge';
import TopHourlyApplianceState from './TopHourlyApplianceState';
import Prefixer from '../lib/Prefixer';
import { withinOneHours } from '../lib/topPageUtils';
import type { EnergyHourly } from '../lib/types/electricTypes';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  hourlyAnalysis: {
    width: '100%',
    height: 'auto',
    background: '#fff',
    padding: '4px 0px 40px 0',
    margin: '0px 16px 20px 0px',
  },
  time: {
    background: '#ffbd00',
    color: '#fff',
    width: '100%',
    fontSize: '1.0em',
    textAlign: 'center',
    margin: '0px auto',
    borderRadius: '30px',
    height: '25px',
    lineHeight: '25px',
  },
  link: {
    textDecoration: 'none',
  },
  settingButtonItem: {
    marginTop: '10px',
    textAlign: 'right',
  },
  settingWhiteArea: {
    height: '20px',
    verticalAlign: 'middle',
    marginRight: '5px',
  },
  settingWhite: {
    fill: '#fafafa',
  },
  settingButton: {
    background: '#298c8e',
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: 8,
    textAlign: 'center',
    margin: '5px 0px 10px auto',
    padding: 0,
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    height: 40,
  },
  settingButtonText: {
    border: 'none',
  },
  actionDisable: {
    opacity: '0.3',
  },
});

type Props = {
  topRequesting: boolean,
  hourlyEnergyList: EnergyHourly[],
  selectedHour: number,
  currentTime: string,
  currentDate: string,
  dispatch: Dispatch,
  intl: intlShape,
  size: {
    width: number,
    height: number,
  },
};

const renderSettingButton = (requesting: boolean, width: number, intl: intlShape) => {
  const settingButtonWidth = () => (width >= 150 ? '65%' : '80%');
  const buttonStyle = requesting
    ? { ...style.settingButton, ...style.actionDisable }
    : style.settingButton;
  const settingButtonSvg = () => (
    <svg style={style.settingWhiteArea} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
      <path
        style={style.settingWhite}
        d="M178.16,86.09H157L150.17,69.5,165.1,54.56a3.83,3.83,0,0,0,0-5.42L150.86,34.9a3.83,3.83,0,0,0-5.42,0L130.5,49.83,113.91,43V21.84A3.84,3.84,0,0,0,110.07,18H89.93a3.84,3.84,0,0,0-3.84,3.84V43L69.5,49.83,54.56,34.9a3.83,3.83,0,0,0-5.42,0L34.9,49.14a3.83,3.83,0,0,0,0,5.42L49.83,69.5,43,86.09H21.84A3.84,3.84,0,0,0,18,89.93v20.14a3.84,3.84,0,0,0,3.84,3.84H43l6.87,16.59L34.9,145.44a3.83,3.83,0,0,0,0,5.42L49.14,165.1a3.83,3.83,0,0,0,5.42,0L69.5,150.17,86.09,157v21.12A3.84,3.84,0,0,0,89.93,182h20.14a3.84,3.84,0,0,0,3.84-3.84V157l16.59-6.87,14.94,14.93a3.83,3.83,0,0,0,5.42,0l14.24-14.24a3.83,3.83,0,0,0,0-5.42L150.17,130.5,157,113.91h21.12a3.84,3.84,0,0,0,3.84-3.84V89.93A3.84,3.84,0,0,0,178.16,86.09ZM100,126.85A26.85,26.85,0,1,1,126.85,100,26.85,26.85,0,0,1,100,126.85Z"
      />
    </svg>
  );

  return requesting ? (
    <div style={{ ...buttonStyle, width: settingButtonWidth() }}>
      {settingButtonSvg()}
      <span style={style.settingButtonText}>
        {intl.formatMessage({ id: 'topHourlyAnalysisArea.settingButton' })}
      </span>
    </div>
  ) : (
    <Link style={style.link} to="/setting">
      <div style={{ ...buttonStyle, width: settingButtonWidth() }}>
        {settingButtonSvg()}
        <span style={style.settingButtonText}>
          {intl.formatMessage({ id: 'topHourlyAnalysisArea.settingButton' })}
        </span>
      </div>
    </Link>
  );
};

const hourlyEnergy = (hourlyEnergyList: EnergyHourly, selectedHour: number): ?EnergyHourly => {
  const e = hourlyEnergyList.find((x) => x.hour === selectedHour);
  return (
    e && {
      dateTime: e.dateTime,
      hour: selectedHour,
      buyEnergy: e.buyEnergy,
      sellEnergy: e.sellEnergy,
      consumeEnergy: e.consumeEnergy,
    }
  );
};

const renderSelectedTimeTile = (
  intl: intlShape,
  selectedHour: number,
  currentDate: string,
  currentTime: string
) => {
  const hour = selectedHour % 100;
  const oclock = moment({ hour }).format('H');
  const hourFrom = moment({ hour }).format('h a');
  const hourTo = moment({ hour }).add(1, 'hour').format('h a');
  const ukCurrentTime = moment(currentTime, 'HH:mm').format('hh:mm a');

  const messages = defineMessages({
    hour: {
      id: 'topHourlyAnalysisArea.hour',
      description: '今日 xx時台',
    },
    current: {
      id: 'topHourlyApplianceState.current',
      description: 'xx pm at presents',
    },
  });
  if (withinOneHours(currentDate, currentTime, selectedHour)) {
    return intl.formatMessage(messages.current, { ukCurrentTime, currentTime });
  }
  return intl.formatMessage(messages.hour, { oclock, hourFrom, hourTo });
};

export const PureTopHourlyAnalysisArea = ({
  topRequesting,
  hourlyEnergyList,
  selectedHour,
  currentDate,
  currentTime,
  dispatch,
  intl,
  size,
}: Props): Node => {
  const isToday = selectedHour % 100 <= moment().hour();
  return (
    <div>
      <div style={style.hourlyAnalysis}>
        <div style={style.time}>
          {isToday
            ? intl.formatMessage({ id: 'topHourlyAnalysisArea.today' })
            : intl.formatMessage({ id: 'topHourlyAnalysisArea.yesterday' })}
          &nbsp;
          {renderSelectedTimeTile(intl, selectedHour, currentDate, currentTime)}
        </div>
        <TopHourlyApplianceState selectedHour={selectedHour} intl={intl} dispatch={dispatch} />
        <TopHourlyCharge
          hourlyEnergy={hourlyEnergy(hourlyEnergyList, selectedHour)}
          selectedHour={selectedHour}
          intl={intl}
        />
        <div style={style.settingButtonItem}>
          {renderSettingButton(topRequesting, size.width, intl)}
        </div>
      </div>
    </div>
  );
};

PureTopHourlyAnalysisArea.defaultProps = {
  topRequesting: false,
};

const mapStateToProps = (state) => ({
  topRequesting: state.energy.monthlyRequesting || state.energy.hourlyRequesting,
  hourlyEnergyList: state.energy.hourly,
  currentDate: state.timeRange.currentDate,
  currentTime: state.timeRange.currentTime,
  selectedHour: state.timeRange.selectedHour,
});

const connectedPureTopHourlyAnalysisArea: any = connect(mapStateToProps)(
  injectIntl(PureTopHourlyAnalysisArea)
);
export default connectedPureTopHourlyAnalysisArea;
