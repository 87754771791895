import loginInfo from './loginInfo';
import loginStatus from './loginStatus';
import userInfo from './userInfo';
import userSetting from './userSetting';
import timeRange from './timeRange';
import energy from './energy';
import applianceState from './applianceState';
import tutorial from './tutorial';
import popup from './popup';
import applianceEnergy from './applianceEnergy';
import chartTime from './chartTime';
import billingInfo from './billingInfo';
import objectiveEnergy from './objectiveEnergy';
import pickup from './pickup';
import pvAlert from './pvAlert';

const reducers = {
  loginInfo,
  loginStatus,
  userInfo,
  userSetting,
  timeRange,
  energy,
  applianceState,
  tutorial,
  popup,
  applianceEnergy,
  chartTime,
  billingInfo,
  objectiveEnergy,
  pickup,
  pvAlert,
};

export default reducers;
