// @flow

import type { Action } from 'redux';

import { REQUEST_API_PV_ALERT, SUCCESS_API_PV_ALERT, FAILURE_API_PV_ALERT } from '../actions/types';
import type { PvAlert } from '../lib/types/pvAlertTypes';

export type State = PvAlert;

const initialState: State = {
  initialized: false,
  requesting: false,
  isPvAlert: false,
};

export default (state: State = initialState, action: Action = {}): State & $Shape<State> => {
  switch (action.type) {
    case REQUEST_API_PV_ALERT: {
      return { ...state, requesting: !action.error };
    }
    case SUCCESS_API_PV_ALERT: {
      const { isPvAlert = false } = action.payload || {};

      return {
        ...state,
        initialized: true,
        requesting: false,
        isPvAlert,
      };
    }
    case FAILURE_API_PV_ALERT: {
      return { ...state, initialized: true, requesting: false };
    }
    default: {
      return state;
    }
  }
};
