// @flow

import moment from 'moment';
import type { Dispatch } from 'redux';
import { timeFormat } from './constants/timeUnit';
import {
  apiGetBillingForecast,
  apiGetBillingInfo,
  apiGetEnergyHourly,
  apiGetUseStatsForLastYear,
  apiGetUseStatsHourly,
} from '../actions/apis';
import { makeCurrentWeighingPeriod } from './common/periodUtils';
import { getHourlyRequestMeta } from './common/globalUtils';
import type { BillingInfo } from './types/priceInfoTypes';
import type { AplFilter, Durations } from './types/electricTypes';
import type { PvAlert } from './types/pvAlertTypes';
import { LOAD_TOP_PAGE, LOAD_TOP_PAGE_LATEST_TWO_HOURS } from '../actions/types';

import houseAnime0 from '../images/animation0.png';
import houseAnime1 from '../images/animation1.gif';
import houseAnime2 from '../images/animation2.gif';
import houseAnime3 from '../images/animation3.gif';
import houseAnime4 from '../images/animation4.gif';
import houseAnime5 from '../images/animation5.gif';

const houseAnimeJA = [
  houseAnime0,
  houseAnime1,
  houseAnime1,
  houseAnime1,
  houseAnime1,
  houseAnime2,
  houseAnime2,
  houseAnime2,
  houseAnime3,
  houseAnime3,
  houseAnime4,
  houseAnime4,
  houseAnime5,
];

const loadTotalAmountArea = (lastBillingDate, accountStartDate, billingData, dispatch) => {
  const unit = 'month';
  const format = timeFormat[unit];
  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingData,
    lastBillingDate,
    accountStartDate
  );
  if (currentWeighingPeriod) {
    const dateTime = moment(currentWeighingPeriod.billingMonth, format);
    dispatch(
      apiGetEnergyHourly(
        getHourlyRequestMeta('month'),
        moment(dateTime).format(format),
        'day',
        moment(currentWeighingPeriod.startDate, 'YYYY-MM-DD').unix(),
        moment(currentWeighingPeriod.endDate, 'YYYY-MM-DD')
          .endOf('day')
          .unix() > moment().unix()
          ? moment().unix()
          : dateTime.endOf(unit).unix()
      )
    );
  }
};

export const loadBillingInfo = (lastBillingDate: string, dispatch: Dispatch) => {
  // sts 最終実請求年月から1年以上前
  // ets 最終実請求年月
  dispatch(
    apiGetBillingInfo(
      moment(lastBillingDate, 'YYYY-MM')
        .startOf('month')
        .subtract(1, 'years')
        .unix(),
      moment(lastBillingDate, 'YYYY-MM')
        .endOf('day')
        .unix()
    )
  );
};

const loadBillingForecast = (lastBillingDate, accountStartDate, billingData, dispatch) => {
  const currentWeighingPeriod = makeCurrentWeighingPeriod(
    billingData,
    lastBillingDate,
    accountStartDate
  );
  if (currentWeighingPeriod) {
    dispatch(
      apiGetBillingForecast(
        moment(currentWeighingPeriod.startDate)
          .startOf('day')
          .unix(),
        moment(currentWeighingPeriod.endDate)
          .add(1, 'day')
          .startOf('day')
          .unix()
      )
    );
  }
};

export const loadTopPage = (
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  pvAlert: PvAlert,
  dispatch: Dispatch
): void => {
  const {
    billingRequesting,
    billingInitialized,
    predictInitialized,
    predictRequesting,
    billingData,
  } = billingInfo;

  if (billingInitialized && !billingRequesting) {
    dispatch({ type: LOAD_TOP_PAGE });
    if (!predictInitialized && !predictRequesting) {
      loadBillingForecast(lastBillingDate, accountStartDate, billingData, dispatch);
      return;
    }
    if (predictInitialized && !predictRequesting) {
      const latestTwelveHours = 12;
      loadTotalAmountArea(lastBillingDate, accountStartDate, billingData, dispatch);
      dispatch(apiGetUseStatsHourly(latestTwelveHours - 1));
      dispatch(apiGetUseStatsForLastYear());
    }
  }
};

export const loadTopPageLatestTwoHours = (
  billingInfo: BillingInfo,
  lastBillingDate: string,
  accountStartDate: string,
  dispatch: Dispatch
): void => {
  const { billingRequesting, billingInitialized, billingData } = billingInfo;
  const latestTwoHours = 2;
  dispatch({ type: LOAD_TOP_PAGE_LATEST_TWO_HOURS });
  dispatch(apiGetUseStatsHourly(latestTwoHours - 1));
  if (billingRequesting && billingInitialized) {
    loadTotalAmountArea(lastBillingDate, accountStartDate, billingData, dispatch);
  }
};

export const needsUpdate = (currentDate: string, currentTime: string): boolean => {
  const now = moment();

  const matched = currentTime.match(/^[0-9]*/);
  return (
    now.format('YYYY-MM-DD') !== currentDate && now.format('HH') !== (matched ? matched[0] : '')
  );
};

export const currentHour = (currentTime: string): number => Number(currentTime.match(/^[0-9]*/));

export const withinOneHours = (
  currentDate: string,
  currentTime: string,
  selectedHour: number
): boolean =>
  moment().format('YYYY-MM-DD') === currentDate && selectedHour === currentHour(currentTime);

const isAplUsed = (hourlyDuration, apl) => {
  const hourlyDurationApl = hourlyDuration.appliances.find(x => x.id === apl.id);
  return hourlyDurationApl && hourlyDurationApl.isUsed;
};

export const isUsedAtHour = (
  hour: string,
  aplFilter: AplFilter[],
  hourlyDurations: Durations[]
): boolean => {
  const hourlyDuration = hourlyDurations.find(x => x.hour === hour);
  if (!hourlyDuration) {
    return false;
  }
  return !!aplFilter.find(apl => apl.display && isAplUsed(hourlyDuration, apl));
};

const aplUsedHourCount = (range, aplFilter, hourlyDurations) =>
  range.filter(hour => isUsedAtHour(hour, aplFilter, hourlyDurations)).length;

export const houseAnimationType = (
  range: string[],
  aplFilter: AplFilter[],
  hourlyDurations: Durations[]
) => houseAnimeJA[aplUsedHourCount(range, aplFilter, hourlyDurations)];
