// @flow

import React, { Component } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import HeaderBar from '../HeaderBar';
import TopBody from '../TopBody';
import FadeTransition from '../FadeTransition';
import { loadBillingInfo, loadTopPage } from '../../lib/topPageUtils';
import { RESET_TUTORIAL, LOAD_TOP_PAGE_BILLING_INFO } from '../../actions/types';
import type { PvAlert } from '../../lib/types/pvAlertTypes';
import { apiGetPvAlert } from '../../actions/apis';

import Prefixer from '../../lib/Prefixer';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  content: {
    textAlign: 'center',
    marginTop: '25px',
    maxWidth: '980px',
    margin: '0px auto',
    color: '#454545',
  },
  boxUp: {
    width: '100%',
    paddingTop: '43px',
  },
});

const availableStatus = ['normal', 'learning', 'imperfect_learning'];

type Props = {
  location: {
    pathname: string,
  },
  contractorId: string,
  tutorialFinished: boolean,
  loginState: string,
  status: string,
  billingInfo: {
    billingRequesting: boolean,
    billingInitialized: boolean,
    predictInitialized: boolean,
    predictRequesting: boolean,
    billingData: {
      billingDate: string,
      startDate: string,
      endDate: string,
      billingAmount: number,
      consumedBillingAmount: number | null,
      soldAmount: number | null,
    }[],
    forecastPower: number,
  },
  lastBillingDate: string,
  accountStartDate: string,
  open: boolean,
  questionnaires: Object,
  pvAlert: PvAlert,
  dispatch: Dispatch,
};

const topPageOnEnter = (props: Props) => {
  const {
    contractorId,
    status,
    billingInfo,
    lastBillingDate,
    accountStartDate,
    pvAlert,
    dispatch,
  } = props;
  if (contractorId && availableStatus.includes(status)) {
    loadTopPage(billingInfo, lastBillingDate, accountStartDate, pvAlert, dispatch);
  }
};

class ExtendTopPageBase extends Component<Props> {
  componentDidMount() {
    const {
      loginState,
      tutorialFinished,
      lastBillingDate,
      pvAlert,
      dispatch,
      status,
      questionnaires,
    } = this.props;
    if (loginState === 'SUCCESS' && !tutorialFinished && availableStatus.includes(status)) {
      dispatch({ type: RESET_TUTORIAL });
      this.context.router.history.replace('/tutorial');
      return;
    }

    if (
      loginState === 'SUCCESS' &&
      tutorialFinished &&
      availableStatus.includes(status) &&
      !Object.keys(questionnaires).length
    ) {
      this.context.router.history.replace('/questionnaire?force=true');
      return;
    }

    dispatch({ type: LOAD_TOP_PAGE_BILLING_INFO });
    loadBillingInfo(lastBillingDate, dispatch);

    if (!pvAlert.requesting) {
      dispatch(apiGetPvAlert());
    }
  }

  componentDidUpdate() {
    topPageOnEnter(this.props);
  }

  render() {
    const { billingInfo, lastBillingDate, accountStartDate, dispatch } = this.props;
    return (
      <FadeTransition pathname={this.props.location.pathname}>
        <div>
          <HeaderBar pageType="top" />
          <div style={style.content}>
            <div style={style.boxUp}>
              <TopBody
                billingInfo={billingInfo}
                lastBillingDate={lastBillingDate}
                accountStartDate={accountStartDate}
                dispatch={dispatch}
              />
            </div>
          </div>
        </div>
      </FadeTransition>
    );
  }
}

ExtendTopPageBase.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectedExtendTopPage: any = connect(state => ({
  loginState: state.loginInfo.loginState,
  contractorId: state.userInfo.contractorId,
  tutorialFinished: state.tutorial.tutorialFinished,
  status: state.userInfo.status,
  billingInfo: state.billingInfo,
  lastBillingDate: state.userInfo.lastBillingDate,
  accountStartDate: state.userInfo.accountStartDate,
  questionnaires: state.userInfo.questionnaires,
  pvAlert: state.pvAlert,
}))(ExtendTopPageBase);

export default connectedExtendTopPage;
