// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import Prefixer from '../lib/Prefixer';
import { SWITCH_APL_DISPLAY_FLAG } from '../actions/types';
import { switchImage } from '../lib/common/globalUtils';
import '../css/aplCheckboxLabel.css'; // CAUTION: this style is temporary one at v2 design.
import bgOff from '../images/today_detail_content_box_bg_off.png';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  applianceState: {
    position: 'relative',
    margin: '0 5px 15px',
  },
  state: {
    width: '85%',
    height: '85%',
    display: 'block',
    margin: 'auto',
  },
  appliance: {
    position: 'absolute',
    left: '13%',
    top: '8%',
    width: '73%',
  },
});

type Props = {
  appliance: {
    id: string,
    display: boolean,
  },
  dispatch: Dispatch,
};

const SettingApplianceCheckBox = ({ appliance, dispatch }: Props): Node => {
  return (
    <div>
      <input
        type="checkbox"
        className="aplCheckbox"
        style={{ display: 'none' }}
        checked={appliance.display}
        readOnly
      />
      <div
        className="aplCheckboxLabel"
        onClick={() => dispatch({ type: SWITCH_APL_DISPLAY_FLAG, id: appliance.id })}
      />
      <div style={style.applianceState}>
        <img src={bgOff} alt="bg" style={style.state} />
        <img
          src={switchImage(appliance.id)}
          alt="appliance"
          style={style.appliance}
          onClick={() => dispatch({ type: SWITCH_APL_DISPLAY_FLAG, id: appliance.id })}
        />
      </div>
    </div>
  );
};

export default SettingApplianceCheckBox;
