// @flow

import React, { useEffect, useState, useRef } from 'react';
import type { Element } from 'react';
import { Transition } from 'react-transition-group';
import ReactScroll from 'react-scroll';

const isRouteChanged = (() => {
  let prevChildrenKey = '';
  return (children) => {
    if (!children) {
      return false;
    }

    if (prevChildrenKey === children.key) {
      return false;
    }
    prevChildrenKey = children.key || '';
    return true;
  };
})();

const DURATION = 100;

const DEFAULT_STYLE = {
  transition: `opacity ${DURATION}ms ease-in-out`,
  opacity: 0,
};

const TRANSITION_STYLES = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

type Props = {
  pathname: string,
  children: Element<any>,
};

const FadeTransition = ({ children }: Props): Element<any> => {
  const nodeRef = useRef(null);
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setImmediate(() => setInProp(true));
  }, [setInProp]);

  if (isRouteChanged(children)) {
    ReactScroll.animateScroll.scrollToTop({ delay: 0, duration: 0 });
  }

  return (
    <Transition nodeRef={nodeRef} in={inProp} timeout={DURATION}>
      {(state) => (
        <div ref={nodeRef} style={{ ...DEFAULT_STYLE, ...TRANSITION_STYLES[state] }}>
          {children}
        </div>
      )}
    </Transition>
  );
};

export default FadeTransition;
