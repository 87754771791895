export const sum = (numbers: (number | null)[]): number =>
  numbers.reduce((p, n) => p + (n || 0), 0);

export const daytimeTotal = (array: number[], from: number, to: number) => {
  const daytimeArray = (array || []).slice(from, to);
  return (daytimeArray || []).reduce((prev, current) => prev + (current || 0), 0);
};

export const calcTotalEnergy = (
  // eslint-disable-next-line default-param-last
  energy: EnergyPerDayDetails[] = [],
  target: 'buyEnergy' | 'consumeEnergy' | 'sellEnergy'
): number => energy.reduce((p, v) => p + ((v[target] && sum(v[target])) || 0), 0);
