// @flow

import React from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import {
  Rect,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTheme,
} from 'victory';
import Prefixer from '../../../lib/Prefixer';
import { gtagClickPickup, gtagShowPickup, gtagOpenTips } from './gtagEvent';
import Tips from './common/Tips';
import linkImg from '../../../images/pickup/vacuum_cleaner.png';

const ROUND_THRESHOLD = 9;
const prefixer = new Prefixer();

const style = prefixer.prefix({
  title: { textAlign: 'center', fontSize: '18px' },
  digest: { fontSize: '14px', border: '1px solid #cfcfcf', padding: '10px 0' },
  digestText: { fontWeight: 'normal' },
  digestValue: { color: '#ff8f86', fontSize: '24px', marginLeft: '4px' },
  digestUnit: { margin: '0 4px' },
  body: { fontWeight: 'normal', textAlign: 'left', fontSize: '14px', lineHeight: '24px' },
  tipsTitle: { textAlign: 'left', margin: '8px 0', fontSize: '14px' },
  linkImg: { width: '100%', marginBottom: '10px' },
  legend: {
    textAlign: 'left',
    fontSize: '0.9em',
    marginTop: '0px',
    marginBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'normal',
  },
  legendIcon: {
    width: 16,
    height: 16,
    marginRight: 5,
    backgroundSize: 'cover',
  },
  legendSelfIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAAD/mZL/mpL/mZL/mZH/mo//mpH/m5P/l4//mZL/mpLKzfsxAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendCompareIcon: {
    backgroundImage:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAIVBMVEUAAADd3d3c3Nzd3d3d3d3f39/c3Nzd3d3f39/c3Nzd3d1wHqkQAAAACnRSTlMA8MBwgDCwfyCvXTsbnAAAAF9JREFUKM9jYGAIV1wFBUKlDEDAvAoBFhoABbJWIYGVUAUIYMAQhSogyuCFKrCEQQtVYBGDFKrAQoZVaGCkC6AHEEYQYgZyIKqABEZEoUXlMlBkS6FFNkMIIjl4MDAAAMhEO2WKCaPxAAAAAElFTkSuQmCC)',
  },
  legendMargin: {
    marginLeft: 30,
  },
});

const xAxisStyle = {
  grid: { stroke: 'transparent' },
  axis: { stroke: 'black', strokeWidth: 1 },
  ticks: { stroke: 'transparent' },
  tickLabels: { fontSize: 10, fill: '#454545', padding: 5 },
};

const PureChart = ({
  counts,
  showSelfLegend,
  size: { width },
}: {
  counts: { x: number, y: number, color: string }[],
  showSelfLegend: boolean,
  width: number,
  size: { width: number },
}) => (
  <div>
    <div style={style.legend}>
      {showSelfLegend && <div style={{ ...style.legendIcon, ...style.legendSelfIcon }} />}
      {showSelfLegend && <span>あなたの家</span>}
      <div
        style={{
          ...style.legendIcon,
          ...style.legendCompareIcon,
          ...(showSelfLegend ? style.legendMargin : {}),
        }}
      />
      <span>ほかの家</span>
    </div>
    <VictoryChart
      theme={VictoryTheme.material}
      containerComponent={<VictoryContainer responsive={false} style={{ touchAction: 'auto' }} />}
      width={width}
      height={286}
      padding={{ right: 3, left: 3, bottom: 50 }}
      domainPadding={20}
    >
      <VictoryAxis
        dependentAxis
        tickCount={7}
        style={{
          grid: { strokeWidth: 1, stroke: '#ccc' },
          axis: { stroke: 'transparent' },
          ticks: { stroke: 'transparent' },
          tickLabels: { fill: 'transparent' },
        }}
      />
      <Rect x={3} y={235} style={{ fill: '#ebebeb', width: width - 5, height: '32px' }} />
      <VictoryBar
        style={{ data: { fill: ({ color }) => color, width: (width / counts.length) * 0.7 } }}
        data={counts.map((count) => ({
          x: count.x + 1,
          y: count.y,
          color: count.color,
        }))}
      />
      <VictoryAxis
        tickValues={counts.map((v) => v.x + 1)}
        tickFormat={counts.map((v) => (v.x + 1 >= ROUND_THRESHOLD ? `${v.x + 1}~` : `${v.x + 1}`))}
        style={xAxisStyle}
      />
    </VictoryChart>
  </div>
);

const Chart = sizeMe({ monitorHeight: true })(PureChart);

export type Props = {
  data: { self_count: number | null, total_count: number[] },
  userInfo: {
    contractorId?: string,
    appUserId?: string,
    serviceProviderId?: string,
    status: string,
  },
};

export const PureVacuumCleaner = ({ userInfo, data: { self_count, total_count } }: Props) => {
  const utmCampaign = self_count || 0;
  const link = `https://heartonemall.com/iot-cleaner/?utm_source=iotdroom&utm_campaign=cl${
    utmCampaign > ROUND_THRESHOLD ? ROUND_THRESHOLD : utmCampaign
  }&k=cn`;

  const useSumCount = total_count.slice(1).reduce((p, n, i) => p + n * (i + 1), 0);
  const useAvgCount =
    Math.round((useSumCount / total_count.slice(1).reduce((p, n) => p + n, 0)) * 10) / 10;
  const over9 = total_count.slice(ROUND_THRESHOLD).reduce((p, n) => p + n, 0);
  const counts = [...total_count.slice(1, ROUND_THRESHOLD), over9].map((total, count) => ({
    x: count,
    y: total,
    color:
      self_count &&
      (self_count === count + 1 || (ROUND_THRESHOLD === count + 1 && self_count >= ROUND_THRESHOLD))
        ? '#ff8f86'
        : '#cfcfcf',
  }));

  const openTips = (title: string) => {
    gtagOpenTips(title, userInfo, 'vacuum_cleaner', self_count !== null ? self_count : 'null');
  };

  gtagShowPickup(link, userInfo, 'vacuum_cleaner', self_count !== null ? self_count : 'null');
  return (
    <div>
      <p style={style.title}>みんなはどのくらい掃除機を使ってる？</p>
      <Chart counts={counts} showSelfLegend={self_count !== null && self_count !== 0} />
      {(self_count !== null && self_count !== 0 && (
        <div style={style.digest}>
          <span style={style.digestText}>8月は掃除機を</span>
          <span style={style.digestValue}>{self_count}</span>
          <span style={style.digestUnit}>日</span>
          <span style={style.digestText}>使いました</span>
        </div>
      )) || (
        <div style={style.digest}>
          <span style={style.digestText}>平均利用日数は月に</span>
          <span style={style.digestValue}>{useAvgCount}</span>
          <span style={style.digestUnit}>日</span>
        </div>
      )}
      <div style={style.body}>
        {(self_count !== null && self_count !== 0 && (
          <p>
            グラフは8月の掃除機使用日数と、それに対する世帯の分布です。あなたの家は色がついたところに含まれます。
          </p>
        )) || <p>グラフは8月の掃除機使用日数と、それに対するユーザーの分布です。</p>}
      </div>
      <a
        href={link}
        style={style.link}
        rel="noopener noreferrer"
        onClick={(e) =>
          gtagClickPickup(
            e,
            link,
            userInfo,
            'vacuum_cleaner',
            self_count !== null ? self_count : 'null'
          )
        }
      >
        <img src={linkImg} style={style.linkImg} alt="bedding" />
      </a>
      <div style={style.tipsTitle}>掃除機の豆知識をご紹介：</div>
      <Tips title="節約のポイント" onOpenClick={() => openTips('saving_points')}>
        掃除機の運転モードはどのように使い分けていますか？フローリングや畳を掃除する場合、運転モードが「強」と「弱」どちらであっても実はゴミの取れ方はあまり変わりません。
        「弱」の消費電力は「強」の約1/3〜1/4と差が大きく、節電効果も期待できます。一方、カーペットの場合は運転モードでゴミの取れ方に違いがでるため、「強」で手早く掃除をすることをおすすめします。
      </Tips>
      <Tips title="効率のよい使い方" onOpenClick={() => openTips('efficient_usage')}>
        掃除機のスイッチをオンにする前に、まずは部屋を片付けるところから掃除をはじめてみませんか？片付けながら掃除機をかけると余計に時間がかかってしまいます。
        掃除機を使用する時間を5分短縮すると消費電力を約30%節約できるという試算もあるので、掃除機利用の時短を意識してみてはいかがでしょう。コードレス掃除機は小回りが効くので、こまめに短時間で掃除をするのに便利です。
      </Tips>
      <Tips title="環境への影響" onOpenClick={() => openTips('environment_influence')}>
        掃除機の使用を減らすことは省エネにつながりますが、日々の暮らしの中で出てくるホコリやゴミを掃除しないワケにもいきません。そんな時に便利なのがホウキや粘着式クリーナーなどのお掃除グッズ。
        必要な時にすぐ使うことができ、消費するエネルギーはゼロ。掃除機とそれらのグッズを必要に応じて使い分けることで環境への影響に配慮しつつ、住環境の快適さも維持することができます。
      </Tips>
      <div style={style.body}>※コード式掃除機のみを検知して集計した結果になります</div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
});

const connectedPureVacuumCleaner: any = connect(mapStateToProps)(PureVacuumCleaner);
export default connectedPureVacuumCleaner;
