// @flow

import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import { injectIntl, intlShape } from 'react-intl';
import ChartNoData, { CHART_NO_DATA } from '../ChartNoData';
import { calcConsumedPrice, calcPrice, calcAppliancePrice } from '../../lib/price/calculatedPrice';
import ChartCircle from './ChartCircle';
import { ChartBody, ChartLoading, ChartTitle } from './Common';
import Prefixer from '../../lib/Prefixer';
import type { UnitPrice } from '../../lib/types/priceInfoTypes';
import type { ApplianceEnergy, EnergyPerDay } from '../../lib/types/electricTypes';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  wrapper: { position: 'relative' },
  message: { fontWeight: 'normal', fontSize: '0.9rem', textAlign: 'left', margin: '20px 8px 0' },
});

type Props = {
  requesting: boolean,
  monthEnergy: EnergyPerDay | null,
  monthAplEnergy: ApplianceEnergy | null,
  unitPrices: UnitPrice[],
  consumedUnitPrice: number,
  intl: intlShape,
};

const renderBody = (
  requesting: boolean,
  intl: intlShape,
  monthEnergy: EnergyPerDay | null,
  monthAplEnergy: ApplianceEnergy | null,
  unitPrices: UnitPrice[],
  consumedUnitPrice
) => {
  if (requesting || !monthEnergy || !monthAplEnergy) {
    return <ChartLoading intl={intl} />;
  }
  const background = monthAplEnergy.applianceEnergy.find(v => v.id === '998');
  const backgroundEnergyData = background ? background.data : [];

  // 待機電力が買電か自家消費どちらから賄われているか判定できないため、全て買電から賄われているとして計算
  const buyEnergySubtractBackGround = monthEnergy.data.map(v => {
    const target = backgroundEnergyData.find(data =>
      moment.unix(data.unix).isSame(moment(v.daily, 'YYYY-MM-DD'), 'days')
    );
    return target
      ? {
          ...v,
          buyEnergy: v.buyEnergy.map((energy, i) =>
            Math.max(energy - ((target && target.energy[i]) || 0), 0)
          ),
        }
      : v;
  });

  if (!backgroundEnergyData.length && !buyEnergySubtractBackGround.length) {
    return <ChartNoData type={CHART_NO_DATA} />;
  }

  const monthEnergySubtractBackGround = { ...monthEnergy, data: buyEnergySubtractBackGround };

  const chartData = [
    {
      x: 'midnight',
      y:
        calcPrice(0, 6, unitPrices, monthEnergySubtractBackGround, 'buyEnergy') +
        calcConsumedPrice(0, 6, consumedUnitPrice, buyEnergySubtractBackGround),
      color: '#747ae8',
    },
    {
      x: 'morning',
      y:
        calcPrice(6, 12, unitPrices, monthEnergySubtractBackGround, 'buyEnergy') +
        calcConsumedPrice(6, 12, consumedUnitPrice, buyEnergySubtractBackGround),
      color: '#f2d422',
    },
    {
      x: 'noon',
      y:
        calcPrice(12, 18, unitPrices, monthEnergySubtractBackGround, 'buyEnergy') +
        calcConsumedPrice(12, 18, consumedUnitPrice, buyEnergySubtractBackGround),
      color: '#b8dd1f',
    },
    {
      x: 'night',
      y:
        calcPrice(18, 24, unitPrices, monthEnergySubtractBackGround, 'buyEnergy') +
        calcConsumedPrice(18, 24, consumedUnitPrice, buyEnergySubtractBackGround),
      color: '#51aeed',
    },
    {
      x: 'background',
      y: calcAppliancePrice(0, 24, unitPrices, background),
      color: '#ddd',
    },
  ];

  return (
    <div>
      <ChartCircle
        data={chartData}
        totalCharge={chartData.reduce((total, data) => total + data.y, 0)}
        intl={intl}
      />
      <div style={style.message}>{intl.formatMessage({ id: 'report.hourly.text' })}</div>
    </div>
  );
};

export const PureChargeHourlyTotalArea = ({
  requesting,
  monthEnergy,
  monthAplEnergy,
  unitPrices,
  consumedUnitPrice,
  intl,
}: Props) => (
  <div style={style.wrapper}>
    <ChartTitle id="report.hourlyTitle" intl={intl} />
    <ChartBody>
      {renderBody(requesting, intl, monthEnergy, monthAplEnergy, unitPrices, consumedUnitPrice)}
    </ChartBody>
  </div>
);

const mapStateToProps = state => ({
  unitPrices: state.userInfo.unitPrices,
  consumedUnitPrice: state.userInfo.consumedUnitPrice,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureChargeHourlyTotalArea: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureChargeHourlyTotalArea))
);

export default connectedPureChargeHourlyTotalArea;
