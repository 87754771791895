// @flow

import type { State, Action } from 'redux';
import moment from 'moment';
import {
  CHANGE_CHART_UNIT,
  CHANGE_CHART_SUB_PERIOD,
  SELECT_NEXT_PERIOD,
  SELECT_PREV_PERIOD,
  LOAD_CHART_PAGE,
  RESET_CHART_PAGE,
  LOAD_TOP_PAGE,
} from '../actions/types';
import { nextPeriod, prevPeriod } from '../lib/chart/chartUtils';

export const initialState = {
  unit: 'month', // note: if this is empty, CSRF error occurs. Cause unknown.
  period: '',
  currentTime: '',
  subPeriod: {},
};

export default (state: State = initialState, action: Action = {}): State => {
  const { type } = action;
  switch (type) {
    case CHANGE_CHART_UNIT: {
      const { unit, period } = action;
      if (!unit || !period) {
        return state;
      }
      return {
        ...state,
        unit,
        period,
      };
    }
    case CHANGE_CHART_SUB_PERIOD: {
      const { subPeriod } = action;
      if (!subPeriod) {
        return state;
      }
      return {
        ...state,
        subPeriod,
      };
    }
    case SELECT_NEXT_PERIOD: {
      const { unit, period } = state;
      if (!unit || !period) {
        return state;
      }
      return {
        ...state,
        period: nextPeriod(unit, period),
      };
    }
    case SELECT_PREV_PERIOD: {
      const { unit, period } = state;
      return {
        ...state,
        period: prevPeriod(unit, period),
      };
    }
    case LOAD_CHART_PAGE: {
      const { unit, period } = action.payload;
      const { subPeriod } = state;
      if (!unit || !period) {
        return state;
      }
      return {
        unit,
        period,
        currentTime: moment().format('YYYY-MM-DD kk:mm'),
        subPeriod,
      };
    }
    case RESET_CHART_PAGE: {
      return {
        ...state,
        currentTime: moment().format('YYYY-MM-DD kk:mm'),
      };
    }
    case LOAD_TOP_PAGE: {
      return {
        ...initialState,
        currentTime: moment().format('YYYY-MM-DD kk:mm'),
      };
    }

    default: {
      return state;
    }
  }
};
