// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chargeTable{
    display: flex;
    align-items: center;
    clear: both;
}

.chargeTable:nth-child(n + 2){
    border-top: 1px solid #ccc;
    margin: 10px auto 0;
    padding-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/client/css/chargeTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".chargeTable{\n    display: flex;\n    align-items: center;\n    clear: both;\n}\n\n.chargeTable:nth-child(n + 2){\n    border-top: 1px solid #ccc;\n    margin: 10px auto 0;\n    padding-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
