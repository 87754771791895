// @flow

import React from 'react';
import type { Node } from 'react';
import type { Dispatch } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages } from 'react-intl';
import moment from 'moment';
import { SELECT_NEXT_PERIOD, SELECT_PREV_PERIOD, RESET_CHART_PAGE } from '../actions/types';
import { subTimeUnit, timeUnitStyle, timeFormat } from '../lib/constants/timeUnit';
import { isOneHourPassed, nextPeriod, prevPeriod } from '../lib/chart/chartUtils';
import Prefixer from '../lib/Prefixer';
import { getWeighingPeriod, getTemporaryWeighingPeriod } from '../lib/common/periodUtils';

const prefixer = new Prefixer();

const style = prefixer.prefix({
  periodArea: {
    color: '#454545',
    fontSize: '1.2em',
    fontWeight: 'bold',
    padding: '16px 0px 12px',
    display: 'table',
    width: '80%',
    margin: '0px auto',
  },
  subTitle: {
    fontSize: '0.8em',
    fontWeight: 'normal',
  },
  moveButton: {
    display: 'table-cell',
    textAlign: 'center',
    color: '#298c8e',
  },
  arrowArea: {
    width: '17px',
    verticalAlign: 'middle',
  },
  arrow: {
    fill: '#298c8e',
  },
  arrowDisable: {
    opacity: '0.3',
    visibility: 'hidden',
  },
});

const canProceed = (weighingPeriodInitialized, currentWeighingPeriod, unit, period) => {
  const format = timeFormat[unit];
  const now =
    unit === 'day' || unit === 'week'
      ? moment()
      : moment(currentWeighingPeriod.billingMonth, format);
  const target = moment(period, timeFormat[unit]);
  return now.diff(target, timeUnitStyle[unit].toMulti) > 0;
};

const canGoBack = (
  unit,
  period,
  firstWeighingStartDate,
  accountStartDate,
  learningStartDate,
  billingInfo
) => {
  if (!firstWeighingStartDate) {
    return false;
  }
  const { billingData } = billingInfo;
  const format = timeFormat[unit];
  const firstBillingData = getWeighingPeriod(billingData, firstWeighingStartDate, 'week');
  const billingStartMonth = firstBillingData && firstBillingData.billingDate;
  const startDate =
    billingStartMonth ||
    (moment(firstWeighingStartDate, 'YYYY-MM-DD').date() < 15
      ? moment(firstWeighingStartDate, 'YYYY-MM-DD').format('YYYY-MM')
      : moment(firstWeighingStartDate, 'YYYY-MM-DD').add(1, 'month').format('YYYY-MM'));
  const firstStartDate = moment(learningStartDate, 'YYYY-MM-DD').isBefore(
    moment(accountStartDate, 'YYYY-MM-DD'),
    'day'
  )
    ? accountStartDate
    : learningStartDate;
  const start =
    unit === 'week'
      ? moment(billingData.length > 0 ? firstWeighingStartDate : firstStartDate, format)
      : moment(startDate, format);

  const target = moment(period, format);
  const diffUnit = unit === 'week' ? 'days' : timeUnitStyle[unit].toMulti;

  return start.diff(target, diffUnit) < 0;
};

const canProcess = (
  actionType,
  weighingPeriodInitialized,
  currentWeighingPeriod,
  unit,
  period,
  firstWeighingStartDate,
  accountStartDate,
  learningStartDate,
  billingInfo
) =>
  actionType === SELECT_NEXT_PERIOD
    ? canProceed(weighingPeriodInitialized, currentWeighingPeriod, unit, period)
    : canGoBack(
        unit,
        period,
        firstWeighingStartDate,
        accountStartDate,
        learningStartDate,
        billingInfo
      );

const clickButton = (
  requesting,
  unitEnergyList,
  subUnitEnergyList,
  unit,
  period,
  currentTime,
  firstWeighingStartDate,
  accountStartDate,
  learningStartDate,
  billingInfo,
  dispatch,
  actionType,
  router
) => {
  const { billingRequesting, weighingPeriodInitialized, currentWeighingPeriod } = billingInfo;
  if (requesting || billingRequesting) {
    return;
  }
  if (
    !canProcess(
      actionType,
      weighingPeriodInitialized,
      currentWeighingPeriod,
      unit,
      period,
      firstWeighingStartDate,
      accountStartDate,
      learningStartDate,
      billingInfo
    )
  ) {
    return;
  }
  if (isOneHourPassed(currentTime)) {
    dispatch({ type: RESET_CHART_PAGE });
  }
  dispatch({ type: actionType });
  const movedPeriod =
    actionType === SELECT_NEXT_PERIOD ? nextPeriod(unit, period) : prevPeriod(unit, period);

  router.history.push(`/chart/${unit}/${movedPeriod}`);
};

const messages = defineMessages({
  year: {
    id: 'chartPeriod.year',
    description: '2019年',
  },
  month: {
    id: 'chartPeriod.month',
    description: '2019年4月',
  },
  weighingPeriod: {
    id: 'chartPeriod.weighingPeriod',
    description: '{weighingStartDate} ~ {weighingEndDate}',
  },
  weekOf: {
    id: 'chartPeriod.weekOf',
    description: '{month}月{day}日の週',
  },
  day: {
    id: 'chartPeriod.day',
    description: '{month}月{day}日（{dayOfWeek}）',
  },
});

const weighingPeriodData = (period, billingInfo) => {
  const { billingData, weighingPeriodArray } = billingInfo;
  // 表示データが実請求に存在する場合、請求年月と計量期間を取得
  const billingDataPeriod = getWeighingPeriod(billingData, period, 'month');

  // 表示データが実請求に存在しない場合、仮請求年月と計量期間を取得
  const temporaryBillingDataPeriod = getTemporaryWeighingPeriod(
    weighingPeriodArray,
    period,
    'month'
  );
  if (!billingDataPeriod && !temporaryBillingDataPeriod) {
    return {
      startDate: '',
      endDate: '',
      billingMonth: '',
    };
  }
  if (billingDataPeriod) {
    return {
      startDate: billingDataPeriod.startDate,
      endDate: billingDataPeriod.endDate,
      billingMonth: billingDataPeriod.billingDate,
    };
  }

  return {
    startDate: temporaryBillingDataPeriod ? temporaryBillingDataPeriod.startDate : '',
    endDate: temporaryBillingDataPeriod ? temporaryBillingDataPeriod.endDate : '',
    billingMonth: temporaryBillingDataPeriod ? temporaryBillingDataPeriod.billingMonth : '',
  };
};

const chartTitleYear = (year, intl) => intl.formatMessage(messages.year, { year });
const chartTitleMonth = (year, month, monthName, intl) =>
  intl.formatMessage(messages.month, { year, month, monthName });
const chartTitleWeek = (month, monthName, day, intl) =>
  intl.formatMessage(messages.weekOf, { month, monthName, day });
const chartTitleDay = (month, day, dayOfWeek, intl) =>
  intl.formatMessage(messages.day, { month, day, dayOfWeek });

const chartSubTitle = (period, billingInfo, intl) => {
  const weighingPeriod = weighingPeriodData(period, billingInfo);

  const weighingStartDate =
    weighingPeriod && moment(weighingPeriod.startDate).isValid()
      ? moment(weighingPeriod.startDate).format('M/D')
      : '';
  const weighingEndDate =
    weighingPeriod && moment(weighingPeriod.endDate).isValid()
      ? moment(weighingPeriod.endDate).format('M/D')
      : '';

  return intl.formatMessage(messages.weighingPeriod, { weighingStartDate, weighingEndDate });
};

const chartTitle = (unit, period, billingInfo, intl) => {
  if (!moment(period, timeFormat[unit]).isValid()) {
    return moment()
      .format(intl.formatMessage({ id: `formatDate.${unit}` }))
      .split('-');
  }

  const weighingPeriod = weighingPeriodData(period, billingInfo);

  const [year, month, day] =
    unit === 'week'
      ? moment(period, timeFormat[unit])
          .format(intl.formatMessage({ id: `formatDate.${unit}` }))
          .split('-')
      : moment(
          weighingPeriod && moment(weighingPeriod.billingMonth, 'YYY-MM').isValid()
            ? weighingPeriod.billingMonth
            : period,
          timeFormat[unit]
        )
          .format(intl.formatMessage({ id: `formatDate.${unit}` }))
          .split('-');

  const monthName = month && moment(month, 'MM').locale(intl.locale).format('MMMM');
  switch (unit) {
    case 'day': {
      const targetDay = moment(period).locale(intl.locale);
      return chartTitleDay(month, targetDay.format('D'), targetDay.format('dd'), intl);
    }
    case 'week':
      return chartTitleWeek(month, monthName, day, intl);
    case 'month':
      return chartTitleMonth(year, month, monthName, intl);
    case 'year':
      return chartTitleYear(year, intl);
    default:
      return '';
  }
};

type Props = {
  requesting: boolean,
  unitEnergyList: Array<{
    week?: string,
    month?: string,
    year?: string,
    buyEnergy: Array<number>,
  }>,
  subUnitEnergyList: Array<{
    day?: string,
    week?: string,
    month?: string,
    buyEnergy: Array<number>,
  }>,
  unit: string,
  period: string,
  currentTime: string,
  firstWeighingStartDate: string,
  accountStartDate: string,
  learningStartDate: string,
  billingInfo: {
    billingRequesting: boolean,
    billingData: Array<{
      billingDate: string,
      startDate: string,
      endDate: string,
      billingAmount: number,
      consumedBillingAmount: number | null,
      soldAmount: number | null,
    }>,
    forecastPower: number,
    weighingPeriodInitialized: boolean,
    currentWeighingPeriod: {
      startDate: string,
      endDate: string,
      billingMonth: string,
    },
    weighingPeriodArray: Array<{
      startDate: string,
      endDate: string,
      billingMonth: string,
    }>,
  },
  dispatch: Dispatch,
  intl: intlShape,
};

export const PureChartPeriod = (
  {
    requesting,
    unitEnergyList,
    subUnitEnergyList,
    unit,
    period,
    currentTime,
    firstWeighingStartDate,
    accountStartDate,
    learningStartDate,
    billingInfo,
    dispatch,
    intl,
  }: Props,
  { router }: Object
): Node => (
  <div style={style.periodArea}>
    <div
      style={style.moveButton}
      onClick={() => {
        clickButton(
          requesting,
          unitEnergyList,
          subUnitEnergyList,
          unit,
          period,
          currentTime,
          firstWeighingStartDate,
          accountStartDate,
          learningStartDate,
          billingInfo,
          dispatch,
          SELECT_PREV_PERIOD,
          router
        );
      }}
    >
      <svg
        style={
          !requesting &&
          !billingInfo.billingRequesting &&
          canGoBack(
            unit,
            period,
            firstWeighingStartDate,
            accountStartDate,
            learningStartDate,
            billingInfo
          )
            ? style.arrowArea
            : { ...style.arrowArea, ...style.arrowDisable }
        }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
      >
        <path style={style.arrow} d="M166,20V180L26,100Z" />
      </svg>
    </div>
    <div>{!billingInfo.billingRequesting && chartTitle(unit, period, billingInfo, intl)}</div>
    {!billingInfo.billingRequesting && unit === 'month' && (
      <span style={style.subTitle}>{chartSubTitle(period, billingInfo, intl)}</span>
    )}
    <div
      style={style.moveButton}
      onClick={() =>
        clickButton(
          requesting,
          unitEnergyList,
          subUnitEnergyList,
          unit,
          period,
          currentTime,
          firstWeighingStartDate,
          accountStartDate,
          learningStartDate,
          billingInfo,
          dispatch,
          SELECT_NEXT_PERIOD,
          router
        )
      }
    >
      <svg
        style={
          canProceed(
            billingInfo.weighingPeriodInitialized,
            billingInfo.currentWeighingPeriod,
            unit,
            period
          ) &&
          !requesting &&
          !billingInfo.billingRequesting
            ? style.arrowArea
            : { ...style.arrowArea, ...style.arrowDisable }
        }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 200"
      >
        <path style={style.arrow} d="M179,100,39,180V20Z" />
      </svg>
    </div>
  </div>
);

const requesting = ({ energy, applianceEnergy, chartTime }) =>
  energy[timeUnitStyle[chartTime.unit].toRequesting] ||
  energy[timeUnitStyle[subTimeUnit[chartTime.unit]].toRequesting] ||
  applianceEnergy.requesting;

const mapStateToProps = (state) => ({
  requesting: requesting(state),
  unitEnergyList: state.energy[timeUnitStyle[state.chartTime.unit].toUnit],
  subUnitEnergyList: state.energy[timeUnitStyle[subTimeUnit[state.chartTime.unit]].toUnit],
  unit: state.chartTime.unit,
  period: state.chartTime.period,
  currentTime: state.chartTime.currentTime,
  firstWeighingStartDate: state.userInfo.firstWeighingStartDate,
  accountStartDate: state.userInfo.accountStartDate,
  learningStartDate: state.userInfo.learningStartDate,
  billingInfo: state.billingInfo,
});

PureChartPeriod.contextTypes = {
  router: PropTypes.object.isRequired,
};

const connectedPureChartPeriod: any = connect(mapStateToProps)(injectIntl(PureChartPeriod));
export default connectedPureChartPeriod;
