// @flow

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import sizeMe from 'react-sizeme';
import { injectIntl, intlShape } from 'react-intl';
import type { Dispatch } from 'redux';
import moment from 'moment';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import ChartNoData, { CHART_NO_DATA } from '../ChartNoData';
import { ChartBody, ChartLoading } from './Common';
import Prefixer from '../../lib/Prefixer';
import { OPEN_POP_UP } from '../../actions/types';
import { HELP_GENERATION_RESULT } from '../../actions/helpPopupTypes';
import solarPng from '../../images/icn_solar.png';
import type { EnergyPerDay } from '../../lib/types/electricTypes';
import { calcTotalEnergy } from '../../lib/common/calculated';

const prefixer = new Prefixer();
const style = prefixer.prefix({
  wrapper: {
    position: 'relative',
    color: '#FAFAFA',
    fontWeight: 'normal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  info: {
    top: '5px',
    right: '12px',
    zIndex: '2',
    position: 'absolute',
  },
  infoButton: {
    fontSize: '22px',
    color: '#FAFAFA',
    float: 'right',
  },
  planet: { width: '90%' },
  planetMessage: { lineHeight: '28px', marginTop: '20px' },
  sufficiencyRate: { width: '80%', margin: '0 auto' },
  energyMessage: {
    fontSize: '1.0em',
  },
  energyArea: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '11px',
    total: {
      borderBottom: '1px solid #FAFAFA',
    },
    consumed: {
      paddingTop: '11px',
      borderBottom: '2px solid #FAFAFA',
      marginBottom: '10px',
    },
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: '5px',
    paddingBottom: '5px',
    width: '24px',
  },
  cederImg: {
    width: '100%',
    verticalAlign: 'top',
  },
});

type Props = {
  requesting: boolean,
  currentBilling: { billingDate: string },
  unitEnergy: EnergyPerDay | null,
  accountStartDate: string,
  intl: intlShape,
  dispatch: Dispatch,
};

const renderInfoButton = (dispatch) => (
  <div style={style.info}>
    <Icon
      icon={faQuestionCircle}
      style={style.infoButton}
      onClick={(e) =>
        dispatch({
          type: OPEN_POP_UP,
          contentType: HELP_GENERATION_RESULT,
          target: e.target,
        })
      }
    />
  </div>
);

const getBillingSeason = (billingDate) => {
  const billingMonth = billingDate.month() + 1;
  switch (billingMonth) {
    case 12:
    case 1:
    case 2:
      return 'winter';
    case 3:
    case 4:
    case 5:
      return 'spring';
    case 6:
    case 7:
    case 8:
      return 'summer';
    case 9:
    case 10:
    case 11:
      return 'autumn';
    default:
      return '';
  }
};

const getSufficiencyMessage = (sufficiencyRateRoundWithFive) => {
  if (sufficiencyRateRoundWithFive <= 5) {
    return (
      <p style={style.planetMessage}>
        発電した電力を活用するには
        <br />
        日が差している時間に
        <br />
        家電を使うのがポイントです
      </p>
    );
  }
  if (sufficiencyRateRoundWithFive <= 10) {
    return (
      <p style={style.planetMessage}>
        発電した電力を活用できましたね
        <br />
        タイマーで日中に家電を動かすと
        <br />
        電気代がもっとお得になりますよ
      </p>
    );
  }
  if (sufficiencyRateRoundWithFive <= 20) {
    return (
      <p style={style.planetMessage}>
        発電した電力を効率良く使って
        <br />
        電気代がお得になりましたね
        <br />
        その調子です！
      </p>
    );
  }
  return (
    <p style={style.planetMessage}>
      発電した電力をたくさん使いましたね
      <br />
      CO<sub>2</sub>の排出が減って
      <br />
      惑星が豊かに育っています
    </p>
  );
};

const renderPlanet = (accountStartDate, sufficiencyRate, billingDate) => {
  const [imagePath, setImagePath] = useState('');
  const startDate = moment(accountStartDate, 'YYYY-MM-DD');
  const firstBillingDate =
    startDate.date() < 15 ? startDate.startOf('month') : startDate.add(1, 'month').startOf('month');
  // サービスの利用開始から何ヶ月目か(存在しないケースの想定だが、エラー回避のため0より小さい場合には1ヶ月目の表示とする)
  const monthsSinceStartDate =
    billingDate.diff(firstBillingDate, 'months') > 0
      ? billingDate.diff(firstBillingDate, 'months') + 1
      : 1;
  const sufficiencyRateRoundWithFive = (Math.round((sufficiencyRate * 2) / 10) * 10) / 2; // 自給自足率を5刻みで丸め込む
  const season = getBillingSeason(billingDate);

  useEffect(() => {
    let active = true;
    const load = async () => {
      const response =
        monthsSinceStartDate <= 3
          ? await import(
              `../../images/report/planet/${monthsSinceStartDate}/terra${monthsSinceStartDate}_${sufficiencyRateRoundWithFive}.png`
            )
          : await import(
              `../../images/report/planet/4/${season}/${season}_terra4_${sufficiencyRateRoundWithFive}.png`
            );
      if (active) {
        setImagePath(response.default);
      }
    };
    load();
    return () => {
      active = false;
    };
  }, [monthsSinceStartDate, sufficiencyRateRoundWithFive, season]);

  const imgTag = imagePath.length ? (
    <img style={style.planet} src={imagePath} alt="grewUpPlanet" />
  ) : null;

  switch (monthsSinceStartDate) {
    case 1:
      return (
        <div>
          {imgTag}
          <p style={style.planetMessage}>
            テラリウムに惑星が生まれました
            <br />
            発電して利用した電力が多いほど
            <br />
            豊かな世界に成長します
          </p>
        </div>
      );
    case 2:
      return (
        <div>
          {imgTag}
          <p style={style.planetMessage}>
            惑星らしい形になってきましたね
            <br />
            発電した電力をたくさん使って
            <br />
            テラリウムを成長させましょう
          </p>
        </div>
      );
    case 3:
      return (
        <div>
          {imgTag}
          <p style={style.planetMessage}>
            もうすぐ生き物が誕生しそうです
            <br />
            太陽光発電はCO<sub>2</sub>を排出しないので
            <br />
            たくさん使うと生き物が喜びます
          </p>
        </div>
      );
    default:
      return (
        <div>
          {imgTag}
          {getSufficiencyMessage(sufficiencyRateRoundWithFive)}
        </div>
      );
  }
};

const renderSufficiencyRate = (totalBuyEnergy, totalConsumeEnergy, sufficiencyRate, intl) => (
  <div style={style.sufficiencyRate}>
    <div style={{ ...style.energyArea, ...style.energyArea.total }}>
      <div style={style.energyMessage}>{intl.formatMessage({ id: 'report.totalUseEnergy' })}</div>
      <div>
        {intl.formatNumber(Math.round(totalBuyEnergy + totalConsumeEnergy))}
        {intl.formatMessage({ id: 'others.kWh' })}
      </div>
    </div>
    <div style={{ ...style.energyArea, ...style.energyArea.consumed }}>
      <div style={style.energyMessage}>{intl.formatMessage({ id: 'report.consumedEnergy' })}</div>
      <div>
        {intl.formatNumber(Math.round(totalConsumeEnergy))}
        {intl.formatMessage({ id: 'others.kWh' })}
      </div>
    </div>
    <div style={{ ...style.energyArea, fontWeight: 'bold' }}>
      <div style={style.energyMessage}>{intl.formatMessage({ id: 'report.sufficiencyRate' })}</div>
      <div style={{ fontSize: '30px' }}>
        <img src={solarPng} alt="solar" style={style.icon} />
        {sufficiencyRate}
        {intl.formatMessage({ id: 'others.percent' })}
      </div>
    </div>
  </div>
);

const renderReducedCedar = (totalConsumeEnergy, billingDate) => {
  const [imgPath, setImgPath] = useState('');
  /*
    CO2排出係数：電気の供給1kWhあたりどれだけのCO2を排出しているかを示す数値
    以下URLの最下部にある「代替値」を使用している。
    https://ghg-santeikohyo.env.go.jp/files/calc/r04_coefficient.pdf
    この値は毎年変わるため、その都度値を変えた方がより正確なCO2削減量を算出できる。
   */
  const CO2_EMISSION_FACTOR = 0.453;

  // 1kg-CO2を1ヵ月で吸収するのに必要な杉の本数
  const CEDER_FOR_ABSORBING_CO2_IN_MONTH = 0.857;

  const reducedCederAmount = Math.ceil(
    totalConsumeEnergy * CO2_EMISSION_FACTOR * CEDER_FOR_ABSORBING_CO2_IN_MONTH
  );
  const reducedCederAmountOfImage = reducedCederAmount < 10 ? reducedCederAmount : 10;
  const season = getBillingSeason(billingDate);

  useEffect(() => {
    let active = true;
    const load = async () => {
      if (!reducedCederAmount) {
        return;
      }
      const response = await import(
        `../../images/report/ceder/${season}/sugi-${season}-${reducedCederAmountOfImage}.png`
      );
      if (active) {
        setImgPath(response.default);
      }
    };
    load();
    return () => {
      active = false;
    };
  }, [season, reducedCederAmountOfImage]);

  const cederImgTag = imgPath.length ? (
    <img style={style.cederImg} src={imgPath} alt="reducedCeder" />
  ) : null;

  return reducedCederAmount ? (
    <div>
      <p>
        太陽光発電を利用して
        <br />杉{reducedCederAmount}本分のCO<sub>2</sub>を減らすことができました
      </p>
      {cederImgTag}
    </div>
  ) : null;
};

const renderBody = (
  requesting: boolean,
  intl: intlShape,
  currentBilling: { billingDate: string },
  unitEnergy: EnergyPerDay | null,
  accountStartDate: string
) => {
  if (requesting || !unitEnergy) {
    return <ChartLoading intl={intl} />;
  }
  if (!currentBilling) {
    return <ChartNoData type={CHART_NO_DATA} />;
  }
  const totalBuyEnergy = calcTotalEnergy(unitEnergy.data, 'buyEnergy');
  const totalConsumeEnergy = calcTotalEnergy(unitEnergy.data, 'consumeEnergy');
  const sufficiencyRate =
    Math.round((totalConsumeEnergy / (totalBuyEnergy + totalConsumeEnergy)) * 100) || 0;
  const billingDate = moment(currentBilling.billingDate, 'YYYY-MM').endOf('month');

  return (
    <div>
      {renderPlanet(accountStartDate, sufficiencyRate, billingDate)}
      {renderSufficiencyRate(totalBuyEnergy, totalConsumeEnergy, sufficiencyRate, intl)}
      {renderReducedCedar(totalConsumeEnergy, billingDate)}
    </div>
  );
};

export const PureResultArea = ({
  requesting,
  currentBilling,
  unitEnergy,
  accountStartDate,
  intl,
  dispatch,
}: Props) => (
  <div style={style.wrapper}>
    {renderInfoButton(dispatch)}
    <ChartBody>
      {renderBody(requesting, intl, currentBilling, unitEnergy, accountStartDate)}
    </ChartBody>
  </div>
);

const mapStateToProps = (state) => ({
  accountStartDate: state.userInfo.accountStartDate,
});

const sizeMeHOC = sizeMe({ monitorHeight: true });

const connectedPureResultArea: any = connect(mapStateToProps)(
  sizeMeHOC(injectIntl(PureResultArea))
);

export default connectedPureResultArea;
